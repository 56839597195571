import { useEffect } from 'react';
import { API } from '@vfit/shared/data-access';
import {
  CONSUMER_CMS_SHOPPING_CART,
  IPortabilityCMS,
  useCmsConfig,
} from '@vfit/consumer/data-access';
import { GenericCard } from '@vfit/consumer/components';
import { ICommonData } from '@vfit/shared/models';
import { organizePortabilityInternetCard } from './portabilityInternetCard.utils';
import { useCheckout } from '../../../iBuyFixed.context';
import { ID_FLOWS } from '../../checkout.constants';
import TaggingPortabilityInternetCard from './portabilityInternetCard.tagging';

const PortabilityInternetCard = ({ handleOnChangeEnableGoNext }: ICommonData) => {
  const { setCurrentStepKey } = useCheckout();
  const dataFromCms = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_PORTABILITY
  ) as IPortabilityCMS;
  const { title, description } = organizePortabilityInternetCard(dataFromCms);
  useEffect(() => {
    setCurrentStepKey(ID_FLOWS.PORTABILITY_INTERNET);
    if (handleOnChangeEnableGoNext) handleOnChangeEnableGoNext(true);
  }, []);

  return (
    <>
      <TaggingPortabilityInternetCard />
      <GenericCard title={title} description={description} />;
    </>
  );
};

export default PortabilityInternetCard;
