import { IPortabilityMobileCMS } from '@vfit/consumer/data-access';

export const evaluatePortabilityLabels = (labels: IPortabilityMobileCMS) => ({
  title: labels?.portabilitymobile?.migrationData?.title,
  description: labels?.portabilitymobile?.migrationData?.description,
  modalTitle: labels?.portabilitymobile?.migrationData?.modalTitle,
  modalDescription: labels?.portabilitymobile?.migrationData?.modalDescription,
  checkboxText: labels?.portabilitymobile?.migrationData?.checkboxText,
  operatorLabel: labels?.portabilitymobile?.selectInput?.operator,
  contractLabel: labels?.portabilitymobile?.selectInput?.contract,
  secondActionLabel: labels?.portabilitymobile?.migrationData?.actionLabel,
  validationMessage: labels?.portabilitymobile?.validationMessage
});
