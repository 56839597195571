import { useEffect, useState } from 'react';
import {
  CONSUMER_CMS_SHOPPING_CART,
  errorMock,
  IGenericErrorCMS,
  IPersonalInfoCMS,
  IProduct,
  OTP_CHOICE,
  useCmsConfig,
  useGenerateOTP,
  useVerifyOTP,
} from '@vfit/consumer/data-access';
import { resetData, trackView } from '@vfit/shared/data-access';
import { API, openPopup } from '@vfit/shared/data-access';
import { useQueryClient } from 'react-query';
import { organizeOtp, Otp } from '@vfit/consumer/components';
import { useCheckout } from '../../../iBuyMobile.context';
import { ICheckoutData } from '../../checkout.models';
import { getTrackError } from '../../checkout.utils';
import TaggingOtpCard from './otpCard.tagging';

const OtpCard = ({ handleOnChangeEnableGoNext, handleGoPrevSlide }: ICheckoutData) => {
  const queryClient = useQueryClient();
  const {
    checkoutErrors,
    findCaller,
    isModalButtonSticky,
    product,
    setFindCaller,
    setIsDisabledBackButton,
  } = useCheckout();
  const {
    mutate: generateOtp,
    isError: isGenerateError,
    error: errorGenerateOtp,
  } = useGenerateOTP();
  const {
    mutate,
    data,
    isSuccess,
    isError: isErrorOtp,
    isLoading,
    error: verifyOtpErrorData,
  } = useVerifyOTP();

  const genericError = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_GENERIC_ERROR_MOBILE
  ) as IGenericErrorCMS;
  const dataFromCms = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_PERSONAL_DATA
  ) as IPersonalInfoCMS;

  const [isError, setIsError] = useState(false);

  const handleSendOtp = (field: string, fieldType: string) => {
    resetData(queryClient, ['generateOTP']);
    generateOtp({
      identifier: field,
      identifierType: fieldType,
    });
  };

  const handleVerify = (otp: string) => {
    mutate(otp);
  };

  const handleSetError = () => {
    setIsError(false);
  };

  useEffect(() => {
    if (isErrorOtp || (!isErrorOtp && isSuccess && !data)) {
      setIsError(true);
      if (verifyOtpErrorData) {
        trackView({
          ...getTrackError(errorMock('VerifyOtp', verifyOtpErrorData), 'OTP', queryClient),
          page_error_code: verifyOtpErrorData.status || '',
        });
      }
    }
  }, [isSuccess, data, isErrorOtp, verifyOtpErrorData]);

  useEffect(() => {
    if (handleOnChangeEnableGoNext) {
      setFindCaller({ ...findCaller, isVerified: !!data, verifyOtp: (data as any)?.id || '' });
      localStorage.setItem(OTP_CHOICE, findCaller?.selectedContactMedium?.fieldType || '');
      resetData(queryClient, ['findCaller']);
      handleOnChangeEnableGoNext(!!data);
    }
  }, [data]);

  useEffect(() => {
    setIsDisabledBackButton(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (isGenerateError && handleGoPrevSlide) {
      checkoutErrors?.showAndTrackError?.(
        genericError,
        handleGoPrevSlide,
        errorMock('generateOtp', errorGenerateOtp),
        undefined,
        undefined,
        handleGoPrevSlide,
        genericError?.genericerror?.buttonLabel || '',
        () => openPopup(product?.genericErrorCallMeNow?.url || '', () => handleGoPrevSlide),
        product?.removeCTC ? undefined : product?.genericErrorCallMeNow?.url || '',
        isModalButtonSticky,
        true
      );
    }
  }, [isGenerateError]);

  useEffect(() => {
    if (findCaller.selectedContactMedium?.field)
      handleSendOtp(
        findCaller.selectedContactMedium.field,
        findCaller.selectedContactMedium.fieldType
      );
  }, []);

  return (
    <>
      <TaggingOtpCard />
      <Otp
        product={product as IProduct}
        contactMedium={findCaller.selectedContactMedium}
        isError={isError}
        isLoading={isLoading}
        reSendOtp={handleSendOtp}
        onVerify={handleVerify}
        setIsError={handleSetError}
        configOtp={organizeOtp(
          dataFromCms,
          findCaller?.selectedContactMedium?.fieldType === 'msisdn',
          findCaller?.selectedContactMedium
        )}
      />
    </>
  );
};

export default OtpCard;
