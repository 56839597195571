import type { SVGProps } from 'react';
import { memo } from 'react';

const Paypal = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg width="48" height="32" viewBox="0 0 48 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M44 0H4C1.79086 0 0 1.79086 0 4V28C0 30.2091 1.79086 32 4 32H44C46.2091 32 48 30.2091 48 28V4C48 1.79086 46.2091 0 44 0Z"
      fill="white"
    />
    <path
      d="M44 2C45.1046 2 46 2.89543 46 4V28C46 29.1046 45.1046 30 44 30H4C2.89543 30 2 29.1046 2 28V4C2 2.89543 2.89543 2 4 2H44ZM44 0H4C1.79086 0 0 1.79086 0 4V28C0 30.2091 1.79086 32 4 32H44C46.2091 32 48 30.2091 48 28V4C48 1.79086 46.2091 0 44 0Z"
      fill="#CCCCCB"
    />
    <path
      d="M20.3385 25.3202L20.6885 23.1202H19.9085H16.2285L18.7885 6.86018C18.7953 6.8098 18.8202 6.76361 18.8585 6.73018C18.8987 6.69911 18.9477 6.6816 18.9985 6.68018H25.2085C27.2785 6.68018 28.6985 7.11018 29.4485 7.96018C29.7804 8.3167 30.0116 8.75498 30.1185 9.23018C30.2351 9.80768 30.2351 10.4027 30.1185 10.9802V11.4802L30.4685 11.6802C30.735 11.813 30.9754 11.9925 31.1785 12.2102C31.4842 12.5792 31.6815 13.0257 31.7485 13.5002C31.8234 14.1204 31.7964 14.7487 31.6685 15.3602C31.5366 16.103 31.2761 16.817 30.8985 17.4702C30.5934 17.9938 30.1811 18.4471 29.6885 18.8002C29.1928 19.1382 28.6408 19.3854 28.0585 19.5302C27.4048 19.6951 26.7327 19.7757 26.0585 19.7702H25.5685C25.2251 19.7702 24.8925 19.8905 24.6285 20.1102C24.3631 20.3338 24.1888 20.6468 24.1385 20.9902V21.1902L23.5285 25.0702V25.2202C23.5358 25.2463 23.5358 25.274 23.5285 25.3002H23.4685L20.3385 25.3202Z"
      fill="#253D80"
    />
    <path
      d="M30.8002 11.0801L30.7402 11.4501C29.9202 15.6501 27.1102 17.1101 23.5302 17.1101H21.7102C21.2724 17.1095 20.8991 17.4276 20.8302 17.8601L19.9002 23.7801L19.6302 25.4601C19.61 25.5946 19.649 25.7313 19.7372 25.8349C19.8254 25.9384 19.9542 25.9987 20.0902 26.0001H23.3302C23.7144 25.9997 24.0411 25.7197 24.1002 25.3401V25.1801L24.7102 21.3101V21.1001C24.7648 20.722 25.0883 20.4411 25.4702 20.4401H26.0002C29.1302 20.4401 31.5902 19.1701 32.3002 15.4401C32.6844 14.1652 32.4474 12.784 31.6602 11.7101C31.4105 11.4539 31.1198 11.241 30.8002 11.0801Z"
      fill="#189BD7"
    />
    <path
      d="M29.9384 10.7398L29.5584 10.6398L29.1384 10.5598C28.6089 10.4807 28.0738 10.4439 27.5384 10.4498H22.6584C22.5444 10.4467 22.4313 10.4707 22.3284 10.5198C22.0966 10.6284 21.9349 10.8464 21.8984 11.0998L20.8984 17.6698V17.8598C20.9673 17.4274 21.3405 17.1093 21.7784 17.1098H23.5984C27.1784 17.1098 29.9884 15.6498 30.8084 11.4498L30.8684 11.0798C30.653 10.9685 30.429 10.8749 30.1984 10.7998L29.9384 10.7398Z"
      fill="#242E65"
    />
    <path
      d="M21.8994 11.1C21.9359 10.8466 22.0975 10.6286 22.3294 10.52C22.4323 10.4709 22.5454 10.4469 22.6594 10.45H27.5394C28.0748 10.444 28.6098 10.4808 29.1394 10.56L29.5594 10.64L29.9394 10.74L30.1294 10.8C30.3599 10.8751 30.584 10.9687 30.7994 11.08C31.1245 9.83063 30.8265 8.50118 29.9994 7.51C28.9994 6.45 27.3594 6 25.2194 6H18.9994C18.5615 5.99947 18.1883 6.31756 18.1194 6.75L15.5294 23.16C15.5062 23.3148 15.5513 23.472 15.653 23.5909C15.7547 23.7098 15.9029 23.7788 16.0594 23.78H19.8994L20.8994 17.67L21.8994 11.1Z"
      fill="#253D80"
    />
  </svg>
);

export default memo<SVGProps<SVGSVGElement>>(Paypal);
