import {
  CONSUMER_CMS_SHOPPING_CART,
  ErrorCodes,
  errorMock,
  IGenericErrorCMS,
  useCmsConfig,
  useGetCustomerReference,
} from '@vfit/consumer/data-access';
import { useQueryClient } from 'react-query';
import { API, getFromLocalStorageByKey, openPopup } from '@vfit/shared/data-access';
import { resetData } from '@vfit/shared/data-access';
import { useEffect } from 'react';
import { ICMSApiError, ICMSError, ICommonData, ICountryObject } from '@vfit/shared/models';
import { retrieveOwningIndividual } from '../../checkout.utils';
import { useCheckout } from '../../../iBuyFixed.context';

export const useIAddFirstCardFlow = (props: ICommonData) => {
  const { handleOnClose } = props;
  const {
    product,
    findCaller,
    customerFlow,
    customerAsyncInfo,
    owningData,
    checkoutErrors,
    isModalButtonSticky,
    lockInAppFlow,
    setIsStartLockInAppFlow,
    setIsDisabledBackButton,
    setCustomerId,
    setOwningData,
    setIsStartCustomerFlow,
    setFindCaller,
  } = useCheckout();
  const queryClient = useQueryClient();
  const countries: ICountryObject[] = getFromLocalStorageByKey('getCountry');
  const otpVerifyResponse = { id: findCaller.verifyOtp };
  const {
    isSuccess: isReferenceSuccess,
    isLoading: isReferenceLoading,
    isError: isReferenceError,
    error: errorCustomerReference,
  } = useGetCustomerReference();
  const genericError = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_GENERIC_ERROR
  ) as IGenericErrorCMS;
  const {
    errorcomponent: { errors },
  } = useCmsConfig(CONSUMER_CMS_SHOPPING_CART, API.CMS_GET_ORDER_ERROR) as ICMSApiError;
  const blockedCustomer = errors?.find(
    (item: ICMSError) => item?.error === ErrorCodes.ASSOCIATE_CUSTOMER_BLOCKED
  );
  const isLoadingLockin =
    lockInAppFlow?.campaigns?.isLoading ||
    lockInAppFlow?.accept?.isLoading ||
    lockInAppFlow?.shoppingCart?.isLoading ||
    customerFlow?.associateCustomer?.isLoading;
  const isSuccessLockin =
    lockInAppFlow?.campaigns?.isSuccess &&
    lockInAppFlow?.accept?.isSuccess &&
    lockInAppFlow?.shoppingCart?.isSuccess &&
    customerFlow?.associateCustomer?.isSuccess;
  const isLoadingCustomerInfo =
    isReferenceLoading ||
    !isReferenceSuccess ||
    (!!otpVerifyResponse?.id && !customerFlow?.associateCustomer?.isSuccess);
  const isErrorLockin =
    lockInAppFlow?.campaigns?.isError ||
    lockInAppFlow?.accept?.isError ||
    lockInAppFlow?.shoppingCart?.isError ||
    customerFlow?.associateCustomer?.isError ||
    (lockInAppFlow?.campaigns?.isSuccess && !lockInAppFlow?.checkEligibility?.eligible);

  const preconditionCustomer = () => {
    if (
      customerFlow?.associateCustomer?.isSuccess ||
      customerFlow?.associateCustomer?.isError ||
      isReferenceError
    ) {
      setFindCaller({
        ...findCaller,
        ...(customerFlow?.findcaller?.data?.paymean && {
          paymean: customerFlow?.findcaller?.data?.paymean,
        }),
        showOtp: false,
        verifyOtp: '',
        isVerified: true,
      });
      if (
        customerFlow?.associateCustomer?.isError &&
        customerFlow?.associateCustomer?.error?.error?.statusText ===
          ErrorCodes.ASSOCIATE_CUSTOMER_BLOCKED
      ) {
        const handleClose = () => {
          if (handleOnClose) {
            resetData(queryClient);
            handleOnClose();
          }
        };
        const goBackAndReset = () => {
          if (blockedCustomer?.button?.urlForRedirect) {
            openPopup(blockedCustomer?.button?.urlForRedirect || '', () => {
              if (handleOnClose) {
                resetData(queryClient);
                handleOnClose();
              }
            });
          } else if (handleOnClose) {
            resetData(queryClient);
            handleOnClose();
          }
        };
        checkoutErrors?.showAndTrackError?.(
          genericError,
          () => handleClose(),
          customerFlow?.associateCustomer?.isError
            ? customerFlow?.associateCustomer?.error
            : undefined,
          blockedCustomer?.title || 'Attenzione',
          blockedCustomer?.message ||
            'I seguenti articoli non sono attivabili dal cliente selezionato',
          () => goBackAndReset(),
          blockedCustomer?.button?.title || 'Ti richiamiamo',
          () => openPopup(product?.genericErrorCallMeNow?.url || '', handleClose),
          product?.genericErrorCallMeNow?.label,
          isModalButtonSticky
        );
      } else if (customerFlow?.associateCustomer?.isError || isReferenceError) {
        let error;
        if (isReferenceError && errorCustomerReference) {
          error = errorMock(ErrorCodes.REFERENCE_CUSTOMER, errorCustomerReference);
        }
        if (customerFlow?.associateCustomer?.isError && customerAsyncInfo?.customerInfo?.isError) {
          error = customerAsyncInfo?.customerInfo?.error;
        }
        checkoutErrors?.showAndTrackError?.(
          genericError,
          handleOnClose,
          { ...error, isBlocking: true },
          '',
          '',
          handleOnClose,
          genericError?.genericerror?.buttonLabel || '',
          () => openPopup(product?.genericErrorCallMeNow?.url || '', () => handleOnClose),
          product?.genericErrorCallMeNow?.label,
          isModalButtonSticky
        );
      }
    }
  };

  const showError = () => {
    const error = customerFlow?.findcaller?.error || customerFlow?.customerInfo?.error;
    checkoutErrors?.showAndTrackError?.(
      genericError,
      () => {
        lockInAppFlow?.resetLockInAppFlow?.();
        handleOnClose?.();
      },
      { ...error, isBlocking: true },
      undefined,
      undefined,
      () => {
        lockInAppFlow?.resetLockInAppFlow?.();
        handleOnClose?.();
      },
      genericError?.genericerror?.buttonLabel || 'Chiudi'
    );
  };

  useEffect(() => {
    preconditionCustomer();
    if (customerFlow?.associateCustomer?.isSuccess) {
      const customer = customerFlow?.customerInfo?.data?.[0];
      if (customer) {
        const retrivedIndividual = retrieveOwningIndividual(
          customer,
          countries as ICountryObject[]
        );
        setOwningData({
          ...owningData,
          owningIndividual: {
            ...owningData.owningIndividual,
            ...retrivedIndividual,
          },
        });
        setCustomerId(customer.id);
      }
    }
  }, [lockInAppFlow, isReferenceError, customerAsyncInfo]);

  useEffect(() => {
    if (isSuccessLockin && customerFlow.findcaller?.data?.customerRef?.id) {
      setCustomerId(customerFlow.findcaller?.data?.customerRef?.id);
    }
  }, [isSuccessLockin]);

  useEffect(() => {
    if (isErrorLockin) {
      // TODO: Manage error if user is NOT eligible
      // TODO: Create cart with fallback offer and associate customer
      showError();
    }
  }, [isErrorLockin]);

  useEffect(() => {
    setIsDisabledBackButton(isLoadingCustomerInfo || isLoadingLockin);
  }, [isLoadingLockin, isLoadingCustomerInfo]);

  useEffect(() => {
    if (
      customerFlow?.findcaller?.isSuccess &&
      customerFlow?.findcaller?.data?.customerRef?.id &&
      !lockInAppFlow?.campaigns?.isSuccess
    ) {
      setIsStartLockInAppFlow(true);
    }
    if (customerFlow?.findcaller?.isError || customerFlow?.customerInfo?.isError) {
      showError();
    }
  }, [customerFlow]);

  useEffect(() => {
    setFindCaller({
      ...findCaller,
      showOtp: false,
    });
    setIsStartCustomerFlow(true);
  }, []);

  return {
    isLoading: isLoadingLockin || isLoadingCustomerInfo,
  };
};
