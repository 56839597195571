import { setTrackView, useTracking } from '@vfit/shared/data-access';
import { useQueryClient } from 'react-query';
import { setTrackLink } from '@vfit/shared/data-access';
import { getTagging, getUserInfo } from '../../checkout.utils';
import { withAnimationDelay } from '../../hoc/withAnimationDelay';

const TaggingComponent = () => {
  const client = useQueryClient();
  const taggingMobile = getTagging('payment method pre-filled', 'mobile', client);
  const { trackView } = useTracking({
    event: ['checkout_step9.0'],
    event_label: 'payment method pre-filled',
    opts: taggingMobile?.opts,
    cartProduct: taggingMobile?.trackingProduct,
    pageProduct: taggingMobile?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });
  setTrackLink(trackView, 'payment method pre-filled');
  setTrackView(trackView);

  return null;
};

const TaggingPaymentMethodCard = withAnimationDelay()(TaggingComponent);

export default TaggingPaymentMethodCard;
