import {
  CONSUMER_CMS_SHOPPING_CART,
  getCurrentUserType,
  IBillingCard,
  IGlobalConfigCMS,
  IUserType,
  useBillingAccount,
  useCmsConfig,
} from '@vfit/consumer/data-access';
import { API } from '@vfit/shared/data-access';
import { InvoiceType } from './chooseInvoice.models';

const checkUserType = () =>
  getCurrentUserType() === IUserType.NEXT_USER_ACTIVE ||
  getCurrentUserType() === IUserType.LOGGED_USER;

const organizeItemExtra = (
  billingCheckboxProps: IBillingCard,
  isNextOrProspect: boolean,
  fieldValue: string,
  isOptionHighlated: boolean,
  action?: () => void
) => {
  const prefix = billingCheckboxProps?.billinginformation?.card;
  let extra = {};
  let linkExtra = {};
  switch (fieldValue) {
    case InvoiceType?.PAPER.toLowerCase():
      extra = isOptionHighlated
        ? {
            text: prefix?.highlightLabel || '',
            color: '#fff',
            extraClass: 'extraClass',
          }
        : {
            text: '',
          };
      linkExtra = {
        text: '',
      };
      break;
    case InvoiceType?.EBILL.toLowerCase():
      extra = isOptionHighlated
        ? {
            text: prefix?.highlightLabel || '',
            color: '#fff',
            extraClass: 'extraClass',
          }
        : {
            text: '',
          };
      linkExtra = {
        text: isNextOrProspect
          ? prefix?.choice?.option2?.linkAddressLabel || 'cambia indirizzo email'
          : '',
        action: checkUserType() ? action : undefined,
        variant: 'action',
      };
      break;
    default:
      extra = {
        text: '',
      };
      linkExtra = {
        text: '',
      };
      break;
  }

  return {
    extra,
    linkExtra,
  };
};

export const organizeBillingInvoiceCard = (
  billingCheckboxProps: IBillingCard,
  address: string,
  billingEmail?: string,
  secondItemAction?: () => void
) => {
  const prefix = billingCheckboxProps?.billinginformation?.card;
  const firstOption =
    prefix?.choice?.option1?.index === '1' ? prefix?.choice?.option1 : prefix?.choice?.option2;
  const secondOption =
    prefix?.choice?.option2?.index === '2' ? prefix?.choice?.option2 : prefix?.choice?.option1;

  return {
    title: prefix?.title || '',
    description: prefix?.description || '',
    isFirstPreflagged: firstOption?.isPreflagged === 'true',
    isSecondPreflagged: secondOption?.isPreflagged === 'true',
    items: [
      {
        title: firstOption?.title || '',
        description:
          firstOption?.description
            ?.replace('FIRSTADDRESS', address || '')
            ?.replace('EMAIL', billingEmail || '') || '',
        ...organizeItemExtra(
          billingCheckboxProps,
          checkUserType(),
          firstOption?.value?.trim().toLowerCase(),
          firstOption?.isHighlighted === 'true',
          secondItemAction
        ),
        value:
          firstOption?.value?.trim().toLowerCase() === InvoiceType?.PAPER.toLowerCase()
            ? InvoiceType?.PAPER
            : InvoiceType?.EBILL,
      },
      {
        title: secondOption?.title || '',
        titleIcon: secondOption?.icon,
        description:
          secondOption?.description
            ?.replace('FIRSTADDRESS', address || '')
            ?.replace('EMAIL', billingEmail || '') || '',
        ...organizeItemExtra(
          billingCheckboxProps,
          checkUserType(),
          secondOption?.value?.trim().toLowerCase(),
          secondOption?.isHighlighted === 'true',
          secondItemAction
        ),
        value: InvoiceType?.EBILL,
      },
    ],
  };
};

export const useGetUpdatedBilling = () => {
  const globalConfig = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_GLOBAL_CONFIG
  ) as IGlobalConfigCMS;
  const {
    isSuccess,
    isLoading,
    isError,
    refetch: refecthBillingAccount,
  } = useBillingAccount({ enabled: false }, globalConfig);

  const handleRefetch = (enabler: boolean) => enabler && refecthBillingAccount();

  return {
    handleRefetch,
    isError,
    isLoading,
    isSuccess,
  };
};
