import { checkFwAmdIntegration, getFwToken } from '@vfit/consumer/data-access';
import { useEffect, useState } from 'react';
import { errorManager, ErrorService } from '@vfit/shared/data-access';
import { useThirdPartyEligibilityCustomer } from '@vfit/consumer/hooks';
import { useCheckout } from '../../iBuyMobile.context';

export const useTpoCheck = (): {
  isEnabledFwAmdIntegration: boolean;
  isTpoOffer: boolean;
  isInCampaignTpo: boolean;
  isLoadingCheck: boolean;
  isEligible: boolean;
  token: string | null;
} => {
  const { product } = useCheckout();
  const [isLoadingCheck, setIsLoadingCheck] = useState(true);
  const [tpoEligibilityCheck, setTpoEligibilityCheck] = useState<{
    isStartFlow: boolean;
    customerToken: string;
  }>({
    isStartFlow: false,
    customerToken: '',
  });
  const tpoToken = getFwToken();
  const { isLoading, isEligible } = useThirdPartyEligibilityCustomer({
    ...tpoEligibilityCheck,
  });

  const errorIsTpoNotEligible = () => {
    errorManager.handleError(ErrorService.getSeverityErrorHigh(), {
      title: 'Ops',
      message: 'Si è verificato un problema. Riprovare in seguito',
      fullscreen: true,
      disableCloseModalOnClickAction: true,
    });
  };

  useEffect(() => {
    const isEnabledFwAmdIntegration = checkFwAmdIntegration();
    if (!isEnabledFwAmdIntegration) {
      if (!isLoading && !isEligible && tpoToken) {
        errorIsTpoNotEligible();
      } else if (isEligible && !isLoading) {
        setIsLoadingCheck(false);
      }
    }
  }, [isLoading, isEligible]);

  useEffect(() => {
    const isEnabledFwAmdIntegration = checkFwAmdIntegration();
    if (product?.isTpoOffer && !product?.forceCampaign && tpoToken && !isEnabledFwAmdIntegration) {
      setTpoEligibilityCheck({
        isStartFlow: true,
        customerToken: tpoToken,
      });
    } else if (product?.isTpoOffer && !product?.forceCampaign && !isEnabledFwAmdIntegration) {
      errorIsTpoNotEligible();
    } else {
      setIsLoadingCheck(false);
    }
  }, [product]);

  return {
    isEnabledFwAmdIntegration: !!checkFwAmdIntegration(),
    isTpoOffer: product?.isTpoOffer || false,
    isInCampaignTpo: product?.forceCampaign || false,
    isLoadingCheck,
    isEligible,
    token: tpoToken,
  };
};
