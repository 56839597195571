import { setTrackView, useTracking } from '@vfit/shared/data-access';
import { useQueryClient } from 'react-query';
import { setTrackLink } from '@vfit/shared/data-access';
import { getTagging, getUserInfo } from '../../../../checkout.utils';
import { withAnimationDelay } from '../../../../hoc/withAnimationDelay';
import { getCurrentUserType, IUserType } from '@vfit/consumer/data-access';

const TaggingComponent = () => {
  const client = useQueryClient();
  const taggingMobile = getTagging('add payment method', 'mobile', client);

  const { trackView } = useTracking({
    event:
      getCurrentUserType() === IUserType.PROSPECT_USER
        ? ['checkout_step9.0']
        : ['checkout_step9.1'],
    event_label: 'add payment method',
    opts: taggingMobile?.opts,
    cartProduct: taggingMobile?.trackingProduct,
    pageProduct: taggingMobile?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });
  setTrackLink(trackView, 'add payment method');
  setTrackView(trackView);

  return null;
};

const TaggingDefaultWalletRecurrentPaymentCard = withAnimationDelay()(TaggingComponent);

export default TaggingDefaultWalletRecurrentPaymentCard;
