import { IStepCard } from '@vfit/shared/models';
import {
  IFlow,
  IOtpFLow,
  IPersonalInfoFlow,
  IPortabilityFlow,
  IDeliveryFlow,
  ITermsFlow,
  IPaymentRecognizedFlow,
  IPaymentFlow,
  IUpfrontPaymentFlow,
  IUpfrontSelectionFlow,
  IVoucherFlow,
  IPersonalInfoLockInMMFlow,
  IPersonalInfoLockInFlow,
  IPersonalInfoLockInProspectFlow, IAddonFlow,
} from './checkout.models';
import DocumentIdCard from './components/DocumentIdCard/documentIdCard';
import {AddonCard} from "./components/AddonCard/addonCard";
import NameFiscalCodeCard from './components/NameFiscalCodeCard/nameFiscalCodeCard';
import ContactCard from './components/ContactCard/contactCard';
import OtpCard from './components/OtpCard/otpCard';
import ConfirmIdentityCard from './components/ConfirmIdentityCard/confirmIdentityCard';
import PortabilityLineCard from './components/PortabilityLineCard/portabilityLineCard';
import PortabilityInternetCard from './components/PortabilityInternetCard/portabilityInternetCard';
import PortabilityInfoCard from './components/PortabilityInfoCard/portabilityInfoCard';
import PortabilityMigrationCard from './components/PortabilityMigrationCard/portabilityMigrationCard';
import PortabilityOperatorsCard from './components/PortabilityOperatorsCard/portabilityOperatorsCard';
import PortabilityCard from './components/PortabilityCard/portabilityCard';
import PortabilityNumberCard from './components/PortabilityNumberCard/portabilityNumberCard';
import YourAddressCard from './components/YourAddressCard/yourAddressCard';
import TermsCard from './components/TermsCard/termsCard';
import ConsentCard from './components/ConsentCard/consentCard';
import RecurrentPaymentCard from './components/RecurrentPaymentCard/recurrentPaymentCard';
import UpfrontPaymentCard from './components/UpfrontPaymentCard/upfrontPaymentCard';
import InvoiceCard from './components/InvoiceCard/invoiceCard';
import Voucher from './components/VoucherCard/voucherCard';
import UpfrontSelectionCard from './components/UpfrontSelectionCard/upfrontSelectionCard';
import PaymentMethodWithoutRecurringCard from './components/PaymentMethodWithoutRecurringCard/paymentMethodWithoutRecurringCard';
import ConfirmIdentityAppCard from './components/ConfirmIdentityAppCard/confirmIdentityAppCard';
import PortabilityLineAppCard from './components/PortabilityLineAppCard/portabilityLineAppCard';
import ContactLockInCard from './components/ContactLockinCard/contactLockInCard';
import ContactLockinProspectCard from './components/ContactLockinProspectCard/contactLockinProspectCard';
import { ID_FLOWS } from './checkout.constants';

export const COMPONENTS_FLOW = [
  {
    title: ID_FLOWS.PERSONAL_INFO,
    component: NameFiscalCodeCard,
  },
  {
    title: ID_FLOWS.ADDON_CARD,
    component: AddonCard
  },
  {
    title: ID_FLOWS.DOCUMENT_ID,
    component: DocumentIdCard,
  },
  {
    title: ID_FLOWS.CONTACT_CARD,
    component: ContactCard,
  },
  {
    title: ID_FLOWS.CONTACT_CARD_LOCKIN,
    component: ContactLockInCard,
  },
  {
    title: ID_FLOWS.CONTACT_CARD_LOCKIN_PROSPECT,
    component: ContactLockinProspectCard,
  },
  {
    title: ID_FLOWS.CONFIRM_IDENDITY,
    component: ConfirmIdentityCard,
  },
  {
    title: ID_FLOWS.CONFIRM_IDENDITY_APP,
    component: ConfirmIdentityAppCard,
  },
  {
    title: ID_FLOWS.OTP,
    component: OtpCard,
  },
  {
    title: ID_FLOWS.PORTABILITY_NUMBER,
    component: PortabilityNumberCard,
  },
  {
    title: ID_FLOWS.INVOICE,
    component: InvoiceCard,
  },
  {
    title: ID_FLOWS.TERMS_AND_CONDITIONS,
    component: TermsCard,
  },
  {
    title: ID_FLOWS.CONSENTS,
    component: ConsentCard,
  },
  {
    title: ID_FLOWS.UPFRONT_SELECTION,
    component: UpfrontSelectionCard,
  },
  {
    title: ID_FLOWS.DELIVERY_ADDRESS,
    component: YourAddressCard,
  },
  {
    title: ID_FLOWS.RECURRENT_PAYMENT_CARD,
    component: RecurrentPaymentCard,
  },
  {
    title: ID_FLOWS.UPFRONT_PAYMENT,
    component: UpfrontPaymentCard,
  },
  {
    title: ID_FLOWS.VOUCHER,
    component: Voucher,
  },
  {
    title: ID_FLOWS.PORTABILITY_LINE,
    component: PortabilityLineCard,
  },
  {
    title: ID_FLOWS.PORTABILITY_LINE_APP,
    component: PortabilityLineAppCard,
  },
  {
    title: ID_FLOWS.PORTABILITY_INTERNET,
    component: PortabilityInternetCard,
  },
  {
    title: ID_FLOWS.PORTABILITY,
    component: PortabilityCard,
  },
  {
    title: ID_FLOWS.PORTABILITY_NUMBER,
    component: PortabilityNumberCard,
  },
  {
    title: ID_FLOWS.PORTABILITY_INFORMATIONS,
    component: PortabilityInfoCard,
  },
  {
    title: ID_FLOWS.PORTABILITY_MIGRATIONS,
    component: PortabilityMigrationCard,
  },
  {
    title: ID_FLOWS.OPERATORS_INFORMATIONS,
    component: PortabilityOperatorsCard,
  },
  {
    title: ID_FLOWS.PAYMENT_METHOD_WITHOUT_RECURRING,
    component: PaymentMethodWithoutRecurringCard,
  },
];

export const getFlowComponentByTitle = (title: string): IStepCard => {
  const flow = COMPONENTS_FLOW.find((el) => el.title === title) || {};
  return flow
}

export const ADDON_FLOW: IAddonFlow = {
  ADDONS: [getFlowComponentByTitle(ID_FLOWS.ADDON_CARD)]
}

export const VOUCHER_FLOW: IVoucherFlow = {
  VOUCHER: [getFlowComponentByTitle(ID_FLOWS.VOUCHER)],
};

const PERSONAL_INFO_FLOW: IPersonalInfoFlow = {
  PERSONAL_INFO: [
    getFlowComponentByTitle(ID_FLOWS.PERSONAL_INFO),
    getFlowComponentByTitle(ID_FLOWS.CONTACT_CARD),
    getFlowComponentByTitle(ID_FLOWS.DOCUMENT_ID),
  ],
};

export const PERSONAL_INFO_LOCKIN_MM_FLOW: IPersonalInfoLockInMMFlow = {
  PERSONAL_INFO_LOCKIN_MM: [
    getFlowComponentByTitle(ID_FLOWS.CONTACT_CARD),
    getFlowComponentByTitle(ID_FLOWS.DOCUMENT_ID),
  ],
};

export const PERSONAL_INFO_LOCKIN_FLOW: IPersonalInfoLockInFlow = {
  PERSONAL_INFO_LOCKIN: [
    getFlowComponentByTitle(ID_FLOWS.CONTACT_CARD_LOCKIN),
    getFlowComponentByTitle(ID_FLOWS.DOCUMENT_ID),
  ],
};

const PERSONAL_INFO_LOCKIN_PROSPECT_FLOW: IPersonalInfoLockInProspectFlow = {
  PERSONAL_INFO_LOCKIN_PROSPECT: [
    getFlowComponentByTitle(ID_FLOWS.CONTACT_CARD_LOCKIN_PROSPECT),
    getFlowComponentByTitle(ID_FLOWS.DOCUMENT_ID),
  ],
};

const OTP_FLOW: IOtpFLow = {
  OTP: [getFlowComponentByTitle(ID_FLOWS.CONFIRM_IDENDITY), getFlowComponentByTitle(ID_FLOWS.OTP)],
};

export const OTP_FLOW_APP: IOtpFLow = {
  OTP: [
    getFlowComponentByTitle(ID_FLOWS.CONFIRM_IDENDITY_APP),
    getFlowComponentByTitle(ID_FLOWS.OTP),
  ],
};

const PORTABILITY_FLOW: IPortabilityFlow = {
  PORTABILITY_LINE: [getFlowComponentByTitle(ID_FLOWS.PORTABILITY_LINE)],
  PORTABILITY_LINE_APP: [getFlowComponentByTitle(ID_FLOWS.PORTABILITY_LINE_APP)],
  PORTABILITY: [getFlowComponentByTitle(ID_FLOWS.PORTABILITY)],
  PORTABILITY_INTERNET: [getFlowComponentByTitle(ID_FLOWS.PORTABILITY_INTERNET)],
  PORTABILITY_INFO: [
    getFlowComponentByTitle(ID_FLOWS.PORTABILITY_NUMBER),
    getFlowComponentByTitle(ID_FLOWS.PORTABILITY_INFORMATIONS),
    getFlowComponentByTitle(ID_FLOWS.PORTABILITY_MIGRATIONS),
  ],
  PORTABILITY_OPERATORS: [getFlowComponentByTitle(ID_FLOWS.OPERATORS_INFORMATIONS)],
};

export const TERMS_FLOW: ITermsFlow = {
  TERMS: [
    getFlowComponentByTitle(ID_FLOWS.INVOICE),
    getFlowComponentByTitle(ID_FLOWS.TERMS_AND_CONDITIONS),
    getFlowComponentByTitle(ID_FLOWS.CONSENTS),
  ],
};

export const DELIVERY_FLOW: IDeliveryFlow = {
  DELIVERY: [getFlowComponentByTitle(ID_FLOWS.DELIVERY_ADDRESS)],
};

export const UPFRONT_SELECTION_FLOW: IUpfrontSelectionFlow = {
  UPFRONT_SELECTION: [getFlowComponentByTitle(ID_FLOWS.UPFRONT_SELECTION)],
};

export const UPFRONT_PAYMENT_FLOW: IUpfrontPaymentFlow = {
  UPFRONT_PAYMENT: [getFlowComponentByTitle(ID_FLOWS.UPFRONT_PAYMENT)],
};

export const PAYMENT_METHOD_WITHOUT_RECURRING_FLOW: IPaymentRecognizedFlow = {
  PAYMENT_METHOD: [getFlowComponentByTitle(ID_FLOWS.PAYMENT_METHOD_WITHOUT_RECURRING)],
};

export const EMPTY_FLOWS = {
  EMPTY: [
    {
      title: '',
      component: undefined,
    },
    {
      title: '',
      component: undefined,
    },
  ],
};

const PAYMENT_FLOW: IPaymentFlow = {
  RECURRENT_PAYMENT_CARD: [getFlowComponentByTitle(ID_FLOWS.RECURRENT_PAYMENT_CARD)],
};

const FIXED_LINE_FLOW: IFlow = {
  DEFAULT: [
    ...PERSONAL_INFO_FLOW.PERSONAL_INFO,
    ...PORTABILITY_FLOW.PORTABILITY_LINE,
    ...DELIVERY_FLOW.DELIVERY,
    ...TERMS_FLOW.TERMS,
    ...UPFRONT_SELECTION_FLOW.UPFRONT_SELECTION,
    ...VOUCHER_FLOW.VOUCHER,
    ...PAYMENT_FLOW.RECURRENT_PAYMENT_CARD,
    ...EMPTY_FLOWS.EMPTY,
  ],
  LOCKIN: [
    ...PERSONAL_INFO_LOCKIN_FLOW.PERSONAL_INFO_LOCKIN,
    ...PORTABILITY_FLOW.PORTABILITY_LINE,
    ...DELIVERY_FLOW.DELIVERY,
    ...TERMS_FLOW.TERMS,
    ...UPFRONT_SELECTION_FLOW.UPFRONT_SELECTION,
    ...VOUCHER_FLOW.VOUCHER,
    ...PAYMENT_FLOW.RECURRENT_PAYMENT_CARD,
    ...EMPTY_FLOWS.EMPTY,
  ],
  LOCKIN_MM: [
    ...PERSONAL_INFO_LOCKIN_MM_FLOW.PERSONAL_INFO_LOCKIN_MM,
    ...PORTABILITY_FLOW.PORTABILITY_LINE,
    ...DELIVERY_FLOW.DELIVERY,
    ...TERMS_FLOW.TERMS,
    ...UPFRONT_SELECTION_FLOW.UPFRONT_SELECTION,
    ...VOUCHER_FLOW.VOUCHER,
    ...PAYMENT_FLOW.RECURRENT_PAYMENT_CARD,
    ...EMPTY_FLOWS.EMPTY,
  ],
  LOCKIN_PROSPECT: [
    ...PERSONAL_INFO_LOCKIN_PROSPECT_FLOW.PERSONAL_INFO_LOCKIN_PROSPECT,
    ...PORTABILITY_FLOW.PORTABILITY_LINE,
    ...DELIVERY_FLOW.DELIVERY,
    ...TERMS_FLOW.TERMS,
    ...UPFRONT_SELECTION_FLOW.UPFRONT_SELECTION,
    ...VOUCHER_FLOW.VOUCHER,
    ...PAYMENT_FLOW.RECURRENT_PAYMENT_CARD,
    ...EMPTY_FLOWS.EMPTY,
  ],
  DEFAULT_WITH_RECURRING_PAYMENT: [
    ...PERSONAL_INFO_FLOW.PERSONAL_INFO,
    ...PORTABILITY_FLOW.PORTABILITY_LINE,
    ...DELIVERY_FLOW.DELIVERY,
    ...TERMS_FLOW.TERMS,
    ...UPFRONT_SELECTION_FLOW.UPFRONT_SELECTION,
    ...VOUCHER_FLOW.VOUCHER,
    ...PAYMENT_FLOW.RECURRENT_PAYMENT_CARD,
    ...UPFRONT_PAYMENT_FLOW.UPFRONT_PAYMENT,
    ...EMPTY_FLOWS.EMPTY,
  ],
  LOCKIN_WITH_RECURRING_PAYMENT: [
    ...PERSONAL_INFO_LOCKIN_FLOW.PERSONAL_INFO_LOCKIN,
    ...PORTABILITY_FLOW.PORTABILITY_LINE,
    ...DELIVERY_FLOW.DELIVERY,
    ...TERMS_FLOW.TERMS,
    ...UPFRONT_SELECTION_FLOW.UPFRONT_SELECTION,
    ...VOUCHER_FLOW.VOUCHER,
    ...PAYMENT_FLOW.RECURRENT_PAYMENT_CARD,
    ...UPFRONT_PAYMENT_FLOW.UPFRONT_PAYMENT,
    ...EMPTY_FLOWS.EMPTY,
  ],
  LOCKIN_MM_WITH_RECURRING_PAYMENT: [
    ...PERSONAL_INFO_LOCKIN_MM_FLOW.PERSONAL_INFO_LOCKIN_MM,
    ...PORTABILITY_FLOW.PORTABILITY_LINE,
    ...DELIVERY_FLOW.DELIVERY,
    ...TERMS_FLOW.TERMS,
    ...UPFRONT_SELECTION_FLOW.UPFRONT_SELECTION,
    ...VOUCHER_FLOW.VOUCHER,
    ...PAYMENT_FLOW.RECURRENT_PAYMENT_CARD,
    ...UPFRONT_PAYMENT_FLOW.UPFRONT_PAYMENT,
    ...EMPTY_FLOWS.EMPTY,
  ],
  LOCKIN_PROSPECT_WITH_RECURRING_PAYMENT: [
    ...PERSONAL_INFO_LOCKIN_PROSPECT_FLOW.PERSONAL_INFO_LOCKIN_PROSPECT,
    ...PORTABILITY_FLOW.PORTABILITY_LINE,
    ...DELIVERY_FLOW.DELIVERY,
    ...TERMS_FLOW.TERMS,
    ...UPFRONT_SELECTION_FLOW.UPFRONT_SELECTION,
    ...VOUCHER_FLOW.VOUCHER,
    ...PAYMENT_FLOW.RECURRENT_PAYMENT_CARD,
    ...UPFRONT_PAYMENT_FLOW.UPFRONT_PAYMENT,
    ...EMPTY_FLOWS.EMPTY,
  ],
  APP: [
    ...OTP_FLOW_APP.OTP,
    ...PORTABILITY_FLOW.PORTABILITY_LINE_APP,
    ...DELIVERY_FLOW.DELIVERY,
    ...TERMS_FLOW.TERMS,
    ...UPFRONT_SELECTION_FLOW.UPFRONT_SELECTION,
    ...VOUCHER_FLOW.VOUCHER,
    ...PAYMENT_FLOW.RECURRENT_PAYMENT_CARD,
    ...EMPTY_FLOWS.EMPTY,
  ],
  APP_WITH_RECURRING_PAYMENT: [
    ...OTP_FLOW_APP.OTP,
    ...PORTABILITY_FLOW.PORTABILITY_LINE_APP,
    ...DELIVERY_FLOW.DELIVERY,
    ...TERMS_FLOW.TERMS,
    ...UPFRONT_SELECTION_FLOW.UPFRONT_SELECTION,
    ...VOUCHER_FLOW.VOUCHER,
    ...PAYMENT_FLOW.RECURRENT_PAYMENT_CARD,
    ...UPFRONT_PAYMENT_FLOW.UPFRONT_PAYMENT,
    ...EMPTY_FLOWS.EMPTY,
  ],
};

export {
  FIXED_LINE_FLOW,
  PERSONAL_INFO_FLOW,
  PERSONAL_INFO_LOCKIN_PROSPECT_FLOW,
  OTP_FLOW,
  PORTABILITY_FLOW,
  PAYMENT_FLOW,
};
