import { useCallback, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { ButtonSlide, Divider, CustomText } from '@vfit/shared/atoms';
import { AddressManual, Form } from '@vfit/shared/components';
import { getPlaceId, useAddressGeocoder, useDeviceType } from '@vfit/shared/hooks';
import { LoggerInstance, resetData } from '@vfit/shared/data-access';
import { checkIsApp, useValidateAddressMutation } from '@vfit/consumer/data-access';
import {
  getItalianMunicipalities,
  getItalianProvinces,
  ICountry,
  IProvince,
  trackLink,
  trackView,
} from '@vfit/shared/data-access';
import { useQueryClient } from 'react-query';
import { addressSchema } from './editAddress.validations';
import {
  IAddressObject,
  IInputs,
  IGeoAddress,
  IGeoAddressElab,
} from '../CoverageTool/coverageTool.models';
import { ErrorMsg } from '../CoverageTool/coverageTool.style';
import { getAddressFromLatLng, retrieveAddress } from '../CoverageTool/coverageTool.utils';
import { Container, Title, Subtitle, Data, ButtonContainer } from './editAddress.style';
import { IEditAddressProps, IMode } from './editAddress.models';

const EditAddress = (props: IEditAddressProps) => {
  const {
    shippingAddress,
    setShippingAddress,
    billingAddress,
    setBillingAddress,
    setInvoice,
    setPickupPoint,
    mode,
    state,
    origin,
    modeAction,
    handleClose,
    lables,
    lastName,
    firstName,
    cmsConfig,
    manualAddressConfig,
  } = props;
  const queryClient = useQueryClient();

  const {
    handleSubmit,
    control,
    register,
    watch,
    setValue,
    getValues,
    reset,
    trigger,
    setError,
    clearErrors,
    formState: { isValid, errors },
  } = useForm<IInputs>({
    resolver: yupResolver(addressSchema),
    mode: 'onChange',
  });
  const [isManual, setIsManual] = useState<boolean>(false);
  const [isForceManual, setIsForceManual] = useState<boolean>(false);
  const [isDisabledFirstStep, setIsDisabledFirstStep] = useState<boolean>(true);
  const [addString, setAddrString] = useState<string>('');
  const { isMobile } = useDeviceType();
  const [isLoadingGeo, setIsLoadingGeo] = useState<boolean>(false);
  const [hiddenFields, setHiddenFields] = useState({
    placeId: false,
    city: true,
    postalCode: true,
    stateOrProvince: true,
    street: true,
    streetNumber: true,
  });

  const mutation = useValidateAddressMutation();
  const italianMunicipalities: ICountry[] = getItalianMunicipalities();
  const italianProvinces: IProvince[] = getItalianProvinces();
  const [isProvinceSelected, setIsProvinceSelected] = useState<boolean>(true);
  const [cityProvince, setCityProvince] = useState<string>(state?.stateOrProvince || '');

  const calculatedItalianMunicipalities = useCallback(
    (province: string) =>
      italianMunicipalities
        .filter((city) => (province !== '' ? city.province === province : true))
        .map((municipality) => `${municipality.municipality} (${municipality.province})`),
    [italianMunicipalities]
  );

  const calculatedItalianProvinces = useCallback(
    () => italianProvinces.map((province) => `${province.long} (${province.short})`),

    [italianMunicipalities]
  );

  useEffect(() => {
    const provinceData = getValues()?.stateOrProvince;
    const cityData = getValues()?.city;
    mutation.reset();
    if (!provinceData && !cityData) {
      setIsProvinceSelected(false);
    } else {
      setIsProvinceSelected(true);
    }
    if (cityData) {
      setCityProvince(
        italianMunicipalities.find((city) => city.municipality === cityData?.toUpperCase())
          ?.province || ''
      );
    }
  }, [isManual]);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const retrieveAddressString = function retrieveAddress(add: IAddressObject) {
    let addressString = '';
    Object.values(add).map((el: string) => {
      if (el) addressString = addressString.concat(el, ', ');
      return null;
    });
    return addressString;
  };

  const GetAddressObjectFromPlaceId = useCallback(async (placeId: string) => {
    const placeDetails = await getPlaceId(placeId);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const picked = (({ city, postalCode, stateOrProvince, street, streetNumber }) => ({
      city,
      postalCode,
      stateOrProvince,
      street,
      streetNumber,
    }))(placeDetails);
    if (placeDetails) setAddrString(retrieveAddressString(picked));
    return placeDetails;
  }, []);

  const GetAddressObjectFromString = async (text: string) =>
    Promise.resolve(await useAddressGeocoder(text));

  const setBillingShippingAddress = (inputs: IInputs) => {
    if (mode === IMode.Modify) {
      return modeAction && modeAction(inputs);
    }
    if (origin) {
      setPickupPoint({
        storeId: '',
        locationAddress: {
          id: '',
          city: '',
          country: '',
          displayCountry: '',
          stateOrProvince: '',
          displayStateOrProvince: '',
          longitude: '',
          latitude: '',
          postalCode: '',
          formattedAddress1: '',
          postalOfficeID: '',
          postalOfficeDescription: '',
        },
        aerialDistance: 1.521,
      });
      return setShippingAddress({
        ...shippingAddress,
        formattedAddress: retrieveAddress({
          city: inputs.city,
          postalCode: inputs.postalCode,
          stateOrProvince: inputs.stateOrProvince,
          street: inputs.street,
          streetNumber: inputs.streetNumber || '',
        }),
        formattedAddress1: `${inputs.streetNumber || ''} ${inputs.street || ''}`,
        formattedAddress2: `${inputs.city || ''} ${inputs.stateOrProvince || ''} ${
          inputs.postalCode || ''
        }`,
        city: inputs.city || '',
        postalCode: inputs.postalCode || '',
        stateOrProvince: inputs.stateOrProvince || '',
        street: inputs.street || '',
        streetNumber: inputs.streetNumber || '',
        postalOfficeDescription: '',
        latitude: inputs?.latitude || 0,
        longitude: inputs?.longitude || 0,
      });
    }

    // When Props Origin is setted to false
    setInvoice({ option: '' });
    resetData(queryClient, ['billingCreatedAddress', 'billingAddress', 'billDeliveryDetails']);
    return setBillingAddress?.({
      ...billingAddress,
      formattedAddress: retrieveAddress({
        city: inputs.city,
        postalCode: inputs.postalCode,
        stateOrProvince: inputs.stateOrProvince,
        street: inputs.street,
        streetNumber: inputs.streetNumber || '',
      }),
      formattedAddress1: `${inputs.streetNumber || ''} ${inputs.street || ''}`,
      formattedAddress2: `${inputs.city || ''} ${inputs.stateOrProvince || ''} ${
        inputs.postalCode || ''
      }`,
      city: inputs.city || '',
      postalCode: inputs.postalCode || '',
      stateOrProvince: inputs.stateOrProvince || '',
      street: inputs.street || '',
      streetNumber: inputs.streetNumber || '',
    });
  };

  const placeId = watch('placeId');
  const streetNumber = watch('streetNumber');

  const setAddressObject = useCallback(
    async (placeIdLocal: string | null, text?: string) => {
      //  Only if there is placeId
      if (placeIdLocal) {
        const addressObj = await GetAddressObjectFromPlaceId(placeIdLocal as string);
        //  Only if getAddressObject retireve an address object
        if (addressObj) {
          //  Shows street number text input at the first step if no retrievable from Google API placeId
          if (!addressObj?.streetNumber)
            setHiddenFields((prevState) => ({
              ...prevState,
              streetNumber: false,
            }));
          else setValue('streetNumber', addressObj.streetNumber);
          //  sets all the other fields
          if (addressObj.city) setValue('city', addressObj.city);
          if (addressObj.postalCode) setValue('postalCode', addressObj.postalCode);
          if (addressObj.street) setValue('street', addressObj.street);
          if (addressObj.stateOrProvince) setValue('stateOrProvince', addressObj.stateOrProvince);
          if (addressObj?.latitude) setValue('latitude', parseFloat(addressObj.latitude));
          if (addressObj?.longitude) setValue('longitude', parseFloat(addressObj.longitude));

          await trigger(['city', 'postalCode', 'street', 'stateOrProvince']);
        }
      } else if (!placeIdLocal && !!text) {
        //  If placeId === null, it uses the input string 'text' to get the address object
        //  instead of the placeId. It uses useAddressGeocoder()
        const addressGeocoder = await GetAddressObjectFromString(text);

        if (addressGeocoder) {
          //  Check if with address from addrString + streetNumber can get all the fields.
          //  if not, it sets isManual to true and show all the form fields
          // eslint-disable-next-line @typescript-eslint/no-shadow
          const picked = (({ city, postalCode, stateOrProvince, street, streetNumber }) => ({
            city,
            postalCode,
            stateOrProvince,
            street,
            streetNumber,
          }))(addressGeocoder);

          const inputs = getValues();

          type IPicked = 'city' | 'postalCode' | 'stateOrProvince' | 'street' | 'streetNumber';

          if (Object.values(picked).some((el) => el === '')) {
            if (!Object.values(inputs).some((el) => el === undefined)) {
              if (picked.streetNumber === '' && inputs.streetNumber !== '') {
                setIsManual(true);
                setHiddenFields({
                  placeId: true,
                  city: false,
                  postalCode: false,
                  stateOrProvince: false,
                  street: false,
                  streetNumber: false,
                });
                clearErrors();
                //  if all fields retrieved, it sets all of them but it overrides the streetNumber only if not present from google
                if (inputs.streetNumber) setValue('streetNumber', inputs.streetNumber);
                if (addressGeocoder.city) setValue('city', addressGeocoder.city);
                if (addressGeocoder.postalCode) setValue('postalCode', addressGeocoder.postalCode);
                if (addressGeocoder.street) setValue('street', addressGeocoder.street);
                if (addressGeocoder.stateOrProvince)
                  setValue('stateOrProvince', addressGeocoder.stateOrProvince);
                if (addressGeocoder.latitude)
                  setValue('latitude', parseFloat(addressGeocoder.latitude));
                if (addressGeocoder.longitude)
                  setValue('longitude', parseFloat(addressGeocoder.longitude));

                await trigger();

                setBillingShippingAddress({
                  ...inputs,
                  latitude: parseFloat(addressGeocoder.latitude),
                  longitude: parseFloat(addressGeocoder.longitude),
                });
                handleClose();
              } else {
                Object.entries(picked).filter((keyValueArray) => {
                  if (keyValueArray[1] === '')
                    return setError(
                      keyValueArray[0] as IPicked,
                      { message: cmsConfig.apiError },
                      { shouldFocus: true }
                    );
                  /* eslint-disable consistent-return */
                  /* eslint-disable no-useless-return */
                  // eslint-disable-next-line no-useless-return, consistent-return
                  return;
                });
              }
            } else {
              setIsManual(true);
              setHiddenFields({
                placeId: true,
                city: false,
                postalCode: false,
                stateOrProvince: false,
                street: false,
                streetNumber: false,
              });
            }
          } else {
            clearErrors();
            //  if all fields retrieved, it sets all of them
            if (addressGeocoder.streetNumber)
              setValue('streetNumber', addressGeocoder.streetNumber);
            if (addressGeocoder.city) setValue('city', addressGeocoder.city);
            if (addressGeocoder.postalCode) setValue('postalCode', addressGeocoder.postalCode);
            if (addressGeocoder.street) setValue('street', addressGeocoder.street);
            if (addressGeocoder.stateOrProvince)
              setValue('stateOrProvince', addressGeocoder.stateOrProvince);
            if (addressGeocoder.latitude)
              setValue('latitude', parseFloat(addressGeocoder.latitude));
            if (addressGeocoder.longitude)
              setValue('longitude', parseFloat(addressGeocoder.longitude));

            await trigger();
            setBillingShippingAddress({
              ...inputs,
              postalCode: addressGeocoder.postalCode,
              streetNumber: addressGeocoder.streetNumber,
              city: addressGeocoder.city,
              street: addressGeocoder.street,
              stateOrProvince: addressGeocoder.stateOrProvince,
              latitude: parseFloat(addressGeocoder.latitude),
              longitude: parseFloat(addressGeocoder.longitude),
            });
            handleClose();
          }
        }
      }
    },

    [
      setValue,
      trigger,
      getValues,
      GetAddressObjectFromPlaceId,
      setBillingShippingAddress,
      props,
      streetNumber,
    ]
  );

  useEffect(() => {
    //  first call of setAddressObject using placeId if no street number setted
    if (placeId && !streetNumber && hiddenFields.streetNumber === true) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setAddressObject(placeId).catch((e) => {
        LoggerInstance.debug(e);
      });
    }
    //  to handle abling/disabling button
    setIsDisabledFirstStep(
      () =>
        !(
          streetNumber !== undefined &&
          streetNumber.trim().length > 0 &&
          !errors.streetNumber &&
          !isManual
        )
    );
  }, [placeId, streetNumber, hiddenFields.streetNumber, isManual, setAddressObject, setValue]);

  const onIconClick = () => {
    reset();
    setHiddenFields({
      placeId: false,
      city: true,
      postalCode: true,
      stateOrProvince: true,
      street: true,
      streetNumber: true,
    });
  };

  const onConfirm = async (input: IInputs, e: any) => {
    e.preventDefault();
    setBillingShippingAddress(input);
    handleClose();
  };

  const handleFirstClick = () => {
    trackLink(cmsConfig.submitButton || '');
    //  Not calling setAddressObj if street number just retrieved from placeId
    if (hiddenFields.streetNumber === true) {
      const inputs = getValues();
      setBillingShippingAddress(inputs);
      handleClose();
    } else {
      setAddressObject(null, addString + streetNumber).catch((e) => {
        LoggerInstance.debug(e);
      });
    }
  };

  const forceManualSubmit = (data: IAddressObject) => {
    setBillingShippingAddress(data as IInputs);
    handleClose();
  };

  const composeAddress = (value: IGeoAddress[]) => {
    const address: IGeoAddressElab = {
      street: '',
      city: '',
      province: '',
      postalCode: '',
    };

    value.forEach((v) => {
      if (v.types.includes('route')) address.street = v.long_name;
      else if (v.types.includes('administrative_area_level_3')) address.city = v.long_name;
      else if (v.types.includes('administrative_area_level_2')) address.province = v.short_name;
      else if (v.types.includes('postal_code')) address.postalCode = v.long_name;
    });

    setAddrString(
      `${address.city}, ${address.postalCode}, ${address.province}, ${address.street},`
    );

    return address;
  };

  const findPosition = async (p: GeolocationPosition) => {
    trackLink('click geolocalization');
    return new Promise((resolve) => {
      setIsLoadingGeo(true);
      getAddressFromLatLng(p?.coords?.latitude, p?.coords?.longitude).then((value: any) => {
        let address: IGeoAddressElab = {
          street: '',
          city: '',
          province: '',
          postalCode: '',
        };
        if (value !== undefined) {
          setHiddenFields((prevState: any) => ({
            ...prevState,
            streetNumber: false,
          }));

          address = composeAddress(value.address_components);
        }

        resolve(address);
        setIsLoadingGeo(false);
      });
    });
  };

  const onSelectProvince = (value: string) => {
    setValue('stateOrProvince', (value.match(/\((.*?)\)/) || '')[1]);
    if (cityProvince !== getValues().stateOrProvince) {
      setValue('city', '');
      setCityProvince('');
    }
    trigger('stateOrProvince');
    setIsProvinceSelected(value !== '');
  };

  const onSelectCity = (value: string) => {
    setValue('city', (value.match(/^[^(]+/) || ' ')[0].trim());
    if (value !== '') {
      setCityProvince((value.match(/\((.*?)\)/) || '')[1]);
    }
    trigger('city');
  };

  const dividerMargin = isMobile ? 1 : 1.5;

  useEffect(() => {
    if (!placeId) {
      setValue('streetNumber', '');
    }
  }, [placeId]);

  useEffect(() => {
    setIsDisabledFirstStep(
      () =>
        !(
          streetNumber !== undefined &&
          streetNumber.trim().length > 0 &&
          !errors.streetNumber &&
          !isManual
        )
    );
  }, [errors.streetNumber, streetNumber]);

  const getApiErrorMessage = (): string => {
    let message = '';
    Object.keys(errors).forEach((errorKey) => {
      if (errors[errorKey].message) {
        if (message === '') message = errors[errorKey].message;
      }
    });
    if (message === '') message = cmsConfig.genericError;
    return message;
  };

  const handleForceManual = () => {
    setIsForceManual(true);
    trackView({
      event_name: 'view',
      event_label_track: 'manual address input',
      event_category: 'tools',
      page_type: 'address',
    });
  };

  return (
    <>
      {isForceManual && (
        <AddressManual cmsConfig={manualAddressConfig} onSubmit={forceManualSubmit} state={state} />
      )}
      {!isForceManual && (
        <Container>
          <div>
            <Title>{lables ? lables.title : cmsConfig.title}</Title>
            <Subtitle>
              <CustomText
                text={
                  /* eslint-disable no-nested-ternary */
                  lables
                    ? lables.subtitle
                    : origin
                    ? cmsConfig.shippingSubtitle
                    : cmsConfig.billingSubtitle
                }
              />
            </Subtitle>
            {!lables && (
              <Data>
                {cmsConfig.lable}
                <div data-cs-mask="">
                  {firstName} {lastName}
                </div>
              </Data>
            )}
            <Form onSubmit={handleSubmit(onConfirm)} autoComplete="off">
              <Controller
                control={control}
                name="placeId"
                render={({ field: { onChange, onBlur, ref } }) => (
                  <Form.AddressInput
                    data-cs-mask=""
                    label={cmsConfig.labels.addressInput}
                    placeholder={cmsConfig.placeHolders.addressInput}
                    onChange={(event) => {
                      setHiddenFields((prevState) => ({
                        ...prevState,
                        streetNumber: true,
                      }));
                      onChange(event);
                    }}
                    hidden={hiddenFields.placeId}
                    autoFocus
                    onIconClick={onIconClick}
                    onBlur={onBlur}
                    ref={ref}
                    findPosition={findPosition}
                    placeholderAutocomplete={{
                      enabled: true,
                      label:
                        cmsConfig?.labels?.addressInputPlaceHolderAutocomplete || 'Ricerca manuale',
                      action: handleForceManual,
                    }}
                  />
                )}
              />

              <Divider marginBottom={dividerMargin} hidden={hiddenFields.street} />
              <Form.TextInput
                data-cs-mask=""
                placeholder=" "
                label={cmsConfig.labels.street}
                hidden={hiddenFields.street}
                error={!hiddenFields.street ? errors.street?.message : ''}
                isErrorMessageDisabled={isManual}
                autoFocus
                {...register('street')}
              />

              <Divider marginBottom={dividerMargin} hidden={hiddenFields.streetNumber} />
              <Form.TextInput
                data-cs-mask=""
                placeholder=" "
                label={cmsConfig.labels.streetNumber}
                hidden={hiddenFields.streetNumber}
                error={!hiddenFields.streetNumber ? errors.streetNumber?.message : ''}
                isErrorMessageDisabled={isManual}
                autoFocus
                {...register('streetNumber')}
              />

              <Divider marginBottom={dividerMargin} hidden={hiddenFields.streetNumber} />
              <Form.SelectInput
                data-cs-mask=""
                label={cmsConfig.labels.stateOrProvince}
                onSelectValue={(selectedValue) => {
                  onSelectProvince(selectedValue);
                }}
                hidden={hiddenFields.stateOrProvince}
                errorMessage={!hiddenFields.stateOrProvince ? errors.stateOrProvince?.message : ''}
                isErrorMessageDisabled={isManual}
                placeholder=" "
                {...register('stateOrProvince')}
                items={calculatedItalianProvinces()}
              />

              <Divider marginBottom={dividerMargin} hidden={hiddenFields.streetNumber} />
              <Form.SelectInput
                data-cs-mask=""
                label={cmsConfig.labels.city}
                onSelectValue={(selectedValue) => {
                  onSelectCity(selectedValue);
                }}
                hidden={hiddenFields.city}
                isDisabled={!isProvinceSelected}
                errorMessage={!hiddenFields.city ? errors.city?.message : ''}
                isErrorMessageDisabled={isManual}
                placeholder=" "
                {...register('city')}
                items={calculatedItalianMunicipalities(getValues().stateOrProvince || '')}
              />

              <Divider marginBottom={dividerMargin} hidden={hiddenFields.postalCode} />
              <Form.TextInput
                data-cs-mask=""
                required
                placeholder=" "
                label={cmsConfig.labels.postalCode}
                hidden={hiddenFields.postalCode}
                error={!hiddenFields.postalCode ? errors.postalCode?.message : ''}
                isErrorMessageDisabled={isManual}
                autoFocus
                {...register('postalCode')}
              />
              <Divider marginBottom={dividerMargin} />

              <ErrorMsg
                isManual={isManual && (!isValid || getApiErrorMessage() === cmsConfig.apiError)}
              >
                {getApiErrorMessage()}
              </ErrorMsg>
            </Form>
          </div>
          <ButtonContainer>
            <ButtonSlide
              label={cmsConfig.submitButton}
              onClick={handleFirstClick}
              isApp={checkIsApp()}
              disabled={(isDisabledFirstStep && !isValid) || isLoadingGeo}
              borderColor="#0d0d0d"
              hoverColor="#262626"
              hoverTextColor="#fff"
              clickColor="#7e7e7e"
              clickTextColor="#fff"
              name="action_editAddress"
            />
          </ButtonContainer>
        </Container>
      )}
    </>
  );
};

export default EditAddress;
