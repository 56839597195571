import { useEffect, useState } from 'react';
import {
  CONSUMER_CMS_SHOPPING_CART,
  errorMock,
  getBackAndBlockinErrors,
  getOneTimeAmountForUpfront,
  ICommonLabelsInStepper,
  IGenericErrorCMS,
  IPayments,
  organizeCommonLabelsInStepper,
  productSpecificError,
  SELECTED_PAYMENT,
  useCmsConfig,
  useGetShoppingCartSilentUpdate,
} from '@vfit/consumer/data-access';
import { EMethodCode, ICMSApiError } from '@vfit/shared/models';
import { API, getFromLocalStorageByKey, openPopup, setTrackView } from '@vfit/shared/data-access';
import { useTracking } from '@vfit/shared/data-access';
import { useCheckout } from '../../../iBuyMobile.context';
import { ICheckoutData } from '../../checkout.models';
import UpfrontPayment from './UpfrontPayment/upfrontPayment';
import LoadingCard from '../LoadingCard/loadingCart';
import { DEFAULT_AUTHORIZATION_ASYNC_INFO } from '../../../iBuyMobile.context.data';
import { getTagging, getUserInfo } from '../../checkout.utils';
import UpfrontPaymentSubmitCard from './UpfrontPaymentSubmitCard/upfrontPaymentSubmitCard';
import { useQueryClient } from 'react-query';

const UpfrontPaymentCard = (props: ICheckoutData) => {
  const {
    checkoutErrors,
    authorizationAsyncInfo,
    isModalButtonSticky,
    product,
    setAuthorizationAsyncInfo,
    setIsDisabledBackButton,
    setIsStartAuthorizationAsyncInfo,
  } = useCheckout();
  const queryClient = useQueryClient();
  const { handleGoPrevSlide } = props;
  const lastSelectedType = localStorage.getItem(SELECTED_PAYMENT);
  const isWallet = lastSelectedType?.toLowerCase() === EMethodCode.WALLET.toLowerCase();
  const shoppingCart = getFromLocalStorageByKey('shoppingCart');
  const cartId = shoppingCart?.id;
  const [isLoading, setIsLoading] = useState(!isWallet);
  const [skipImmediatePayment, setSkipImmediatePayment] = useState(false);

  const genericError = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_GENERIC_ERROR_MOBILE
  ) as IGenericErrorCMS;
  const orderErrors = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_RECURRENT_ORDER_ERROR_MOBILE
  ) as ICMSApiError;
  const paymentCMS = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_PAYMENTS_MOBILE
  ) as IPayments;
  const commonLabels = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_STEPPER_COMMON_LABELS_MOBILE
  ) as ICommonLabelsInStepper;
  const { goBackInStepper } = organizeCommonLabelsInStepper(commonLabels);

  const {
    data: authorizationData,
    error: errorAuthorization,
    isError: isErrorAuthorization,
    isSuccess: isSuccessAuthorization,
  } = authorizationAsyncInfo.authorization;
  const {
    goBackErrorOnPaymentFormattedErrors,
    goBackErrorOnPayment,
    blockingFormattedError,
    blockingErrors,
  } = getBackAndBlockinErrors(orderErrors);

  const { buttonGenericError, urlGenericErrorCallMeNow } = productSpecificError(product);

  const checkAuthorization = () => {
    const callAuthorization =
      isErrorAuthorization || (!authorizationData && !isSuccessAuthorization);
    if (callAuthorization && !isWallet) setIsStartAuthorizationAsyncInfo(true);
  };

  const {
    data: shoppingCartData,
    refetch: refetchShoppingCart,
    isLoading: isLoadingShoppingCart,
    isSuccess: isSuccessShoppingCart,
    isError: isErrorShoppingCart,
    error: shoppingCartError,
  } = useGetShoppingCartSilentUpdate(cartId, { enabled: false });

  const taggingMobile = getTagging('checkout', 'mobile', queryClient);
  const { trackView } = useTracking({
    event: ['checkout_step10.1'],
    event_label: 'upfront payment',
    opts: taggingMobile?.opts,
    cartProduct: taggingMobile?.trackingProduct,
    pageProduct: taggingMobile?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
    disableInitialTrack: true,
  });
  setTrackView(trackView);

  const checkGoBackErrors = () => {
    let titleError =
      paymentCMS?.paymentsmobile?.paymentError?.title || genericError?.genericerror?.title || '';
    let messageError =
      paymentCMS?.paymentsmobile?.paymentError?.description ||
      genericError?.genericerror?.description ||
      '';
    let buttonError =
      paymentCMS?.paymentsmobile?.paymentError?.buttonLabel ||
      genericError?.genericerror?.buttonLabel ||
      '';
    if (
      errorAuthorization &&
      goBackErrorOnPaymentFormattedErrors?.includes(
        errorAuthorization?.errorType?.toLowerCase() as string
      )
    ) {
      const foundedErrors = goBackErrorOnPayment.filter(
        (el) => el.error?.toLowerCase() === errorAuthorization?.errorType?.toLowerCase()
      );
      const foundedError = errorAuthorization?.errorCode
        ? foundedErrors?.find((el) => el.errorCode == errorAuthorization.errorCode) ||
          foundedErrors?.find(
            (el) => el.error?.toLowerCase() === errorAuthorization?.errorType?.toLowerCase()
          )
        : foundedErrors?.find((el) => !el.errorCode) || foundedErrors?.[0];
      if (foundedError?.title) titleError = foundedError.title;
      if (foundedError?.message) messageError = foundedError.message;
      if (foundedError?.button?.title) buttonError = foundedError.button?.title;

      // Added to handle not foundedError (ex. timeoutsoftko with ctc and code specification)
      if (!foundedError) {
        checkBlockingErrors();
        return;
      }
    }

    checkoutErrors?.showAndTrackError?.(
      genericError,
      () => {
        setAuthorizationAsyncInfo({ ...DEFAULT_AUTHORIZATION_ASYNC_INFO });
        handleGoPrevSlide?.();
      },
      !errorAuthorization
        ? undefined
        : {
            ...errorAuthorization,
            otherInfo: {
              ...authorizationData,
            },
          },
      titleError,
      messageError,
      () => {
        setAuthorizationAsyncInfo({ ...DEFAULT_AUTHORIZATION_ASYNC_INFO });
        handleGoPrevSlide?.();
      },
      buttonError,
      undefined,
      undefined,
      isModalButtonSticky,
      true,
      false,
      false,
      paymentCMS?.paymentsmobile?.paymentError?.icon || undefined
    );
  };

  const errorOnSubmit = () => {
    checkoutErrors?.showAndTrackError?.(
      genericError,
      () => {
        return;
      },
      undefined,
      paymentCMS?.paymentsmobile?.submitError?.title,
      paymentCMS?.paymentsmobile?.submitError?.description,
      () => {
        return;
      },
      paymentCMS?.paymentsmobile?.submitError?.actionText,
      undefined,
      undefined,
      isModalButtonSticky,
      true
    );
  };

  const checkBlockingErrors = () => {
    let titleError = paymentCMS?.paymentsmobile?.paymentError?.title || 'Ops';
    let messageError =
      paymentCMS?.paymentsmobile?.paymentError?.description ||
      'Si è verificato un errore durante il recupero dei pagamenti. Riprovare in seguito';
    const buttonLabel =
      paymentCMS?.paymentsmobile?.paymentError?.buttonLabel || 'CAMBIA METODO DI PAGAMENTO';
    let buttonLabelCtc = buttonGenericError || 'TI RICHIAMIAMO NOI';
    let urlButtonCTC = urlGenericErrorCallMeNow || '';
    if (
      errorAuthorization &&
      blockingFormattedError?.includes(errorAuthorization?.errorType?.toLowerCase() as string)
    ) {
      const foundedErrors = blockingErrors.filter(
        (el) => el.error?.toLowerCase() === errorAuthorization?.errorType?.toLowerCase()
      );
      const foundedError = errorAuthorization?.errorCode
        ? foundedErrors?.find((el) => el.errorCode == errorAuthorization.errorCode) ||
          foundedErrors?.find(
            (el) => el.error?.toLowerCase() === errorAuthorization?.errorType?.toLowerCase()
          )
        : foundedErrors?.find((el) => !el.errorCode) || foundedErrors?.[0];
      if (foundedError?.title) titleError = foundedError.title;
      if (foundedError?.message) messageError = foundedError.message;
      if (foundedError?.button?.title) buttonLabelCtc = foundedError.button.title;
      if (foundedError?.button?.urlForRedirect) urlButtonCTC = foundedError.button.urlForRedirect;
    }

    const handleBackAction = () => {
      setAuthorizationAsyncInfo({ ...DEFAULT_AUTHORIZATION_ASYNC_INFO });
      handleGoPrevSlide?.();
    };

    checkoutErrors?.showAndTrackError?.(
      genericError,
      handleBackAction,
      !errorAuthorization
        ? undefined
        : {
            ...errorAuthorization,
            otherInfo: {
              ...authorizationData,
            },
          },
      titleError,
      messageError,
      handleBackAction,
      buttonLabel,
      () => openPopup(urlButtonCTC, handleBackAction),
      product?.removeCTC ? undefined : buttonLabelCtc,
      isModalButtonSticky,
      true,
      false,
      false,
      paymentCMS?.paymentsmobile?.paymentError?.icon || undefined
    );
  };

  useEffect(() => {
    if (isErrorAuthorization) {
      const isInGoBackError =
        !errorAuthorization ||
        (errorAuthorization &&
          goBackErrorOnPaymentFormattedErrors?.includes(
            errorAuthorization?.errorType?.toLowerCase() as string
          ));
      if (isInGoBackError) checkGoBackErrors();
      else checkBlockingErrors();
    }
    if (
      authorizationAsyncInfo?.payMean?.isSuccess &&
      authorizationAsyncInfo?.authorization?.isSuccess
    ) {
      if (isWallet) {
        setSkipImmediatePayment(false);
        setIsLoading(false);
      } else if (cartId) {
        refetchShoppingCart();
      }
    }
  }, [authorizationAsyncInfo]);

  useEffect(() => {
    if (isSuccessShoppingCart && shoppingCartData) {
      const immediateAmount = getOneTimeAmountForUpfront(false);
      if (immediateAmount === 0 || immediateAmount === '0') {
        setSkipImmediatePayment(true);
      }
      setIsLoading(false);
    }
  }, [isSuccessShoppingCart, shoppingCartData]);

  useEffect(() => {
    if (!isLoadingShoppingCart && isErrorShoppingCart) {
      checkoutErrors?.showAndTrackError?.(
        genericError,
        () => {
          handleGoPrevSlide?.();
        },
        errorMock(
          'shoppingCart',
          shoppingCartError,
          undefined,
          undefined,
          'get shopping cart in upfront payment',
          true,
          {
            selectedPayment: '',
            availablePaymentMethods: 'no payments',
          }
        ),
        paymentCMS?.paymentsmobile?.errorShoppingCart?.title,
        paymentCMS?.paymentsmobile?.errorShoppingCart?.description,
        () => {
          handleGoPrevSlide?.();
        },
        goBackInStepper || 'Torna indietro',
        undefined,
        undefined,
        isModalButtonSticky,
        true
      );
    }
  }, [isLoadingShoppingCart, isErrorShoppingCart]);

  useEffect(() => {
    setIsDisabledBackButton(isLoading);
  }, [isLoading]);

  useEffect(() => {
    // this timeout is because between SIA and amdocs there
    // is a delay and the authorization api goes to 500 if made too early
    setTimeout(() => {
      checkAuthorization();
    }, 10000);
  }, []);

  if (isLoading) {
    return <LoadingCard />;
  }

  if (skipImmediatePayment) {
    return <UpfrontPaymentSubmitCard onErrorRetry={errorOnSubmit} />;
  }

  return <UpfrontPayment {...props} />;
};

export default UpfrontPaymentCard;
