import { useEffect } from 'react';
import {
  CONSUMER_CMS_SHOPPING_CART,
  useCmsConfig,
  useMultiPlayOfferingConfiguration,
  IPortabilityCMS,
  productSpecificError,
  IGenericErrorCMS,
} from '@vfit/consumer/data-access';
import { API, openPopup } from '@vfit/shared/data-access';
import { ICommonData } from '@vfit/shared/models';
import {
  organizePortabilityError,
  organizePortabilityNumberCard,
} from './portabilityNumberCard.utils';
import { useCheckout } from '../../../iBuyFixed.context';
import { ID_FLOWS } from '../../checkout.constants';
import { retrieveProduct } from '../../../iBuyFixed.utils';
import TaggingPortabilityNumberCard from './portabilityNumberCard.tagging';
import PortabilityNumber from './components/PortabilityNumber/portabilityNumber';

const PortabilityNumberCard = ({ handleOnChangeEnableGoNext, handleGoPrevSlide }: ICommonData) => {
  const { checkoutErrors, portability, isModalButtonSticky, setPortability, setCurrentStepKey } =
    useCheckout();

  const productTracking = retrieveProduct();
  const { buttonGenericError, urlGenericErrorCallMeNow } = productSpecificError(productTracking);

  const portabilityNumberCardCMS = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_PORTABILITY
  ) as IPortabilityCMS;
  const genericError = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_GENERIC_ERROR
  ) as IGenericErrorCMS;
  const { isLoading, isError } = useMultiPlayOfferingConfiguration(
    portabilityNumberCardCMS?.portability?.operatorList
  );
  const { title, description, label } = organizePortabilityNumberCard(portabilityNumberCardCMS);
  const { titleError, messageError } = organizePortabilityError(portabilityNumberCardCMS);

  const checkPortabilityValidation = () => {
    if (portability?.portabilityNumber) {
      const isMatches = portability.portabilityNumber.match(/^0\d{6,9}$/);
      handleOnChangeEnableGoNext?.(!!isMatches);
    } else {
      handleOnChangeEnableGoNext?.(false);
    }
  };

  useEffect(() => {
    checkPortabilityValidation();
  }, [portability]);

  useEffect(() => {
    setCurrentStepKey(ID_FLOWS.PORTABILITY_NUMBER);
  }, []);

  useEffect(() => {
    if (isError) {
      const action = () => {
        if (handleGoPrevSlide) handleGoPrevSlide();
      };
      checkoutErrors?.showAndTrackError?.(
        genericError,
        () => action(),
        undefined,
        titleError,
        messageError,
        () => () => openPopup(urlGenericErrorCallMeNow, action),
        buttonGenericError,
        undefined,
        undefined,
        isModalButtonSticky
      );
    }
  }, [isError]);

  const onChangeData = (telephoneNumber: string) => {
    setPortability({
      ...portability,
      portabilityNumber: telephoneNumber || '',
      portabilityInfo: '',
    });
  };

  return (
    <>
      <TaggingPortabilityNumberCard />
      <PortabilityNumber
        title={title}
        description={description}
        label={label}
        value={portability.portabilityNumber}
        onChangeData={onChangeData}
        isLoading={isLoading}
      />
    </>
  );
};

export default PortabilityNumberCard;
