import { useEffect } from 'react';
import {
  CONSUMER_CMS_SHOPPING_CART,
  useCmsConfig,
  IPortabilityMobileCMS,
  productSpecificError,
  useGetPortabilityInfoMobile,
  errorMock,
  IGenericErrorCMS,
  IDigitalOperatorsConfig,
  ICommonLabelsInStepper,
  organizeCommonLabelsInStepper,
} from '@vfit/consumer/data-access';
import { API, openPopup, regex, defineYupSchema } from '@vfit/shared/data-access';
import { ShadowsGroup } from '@vfit/shared/components';
import {
  organizePortabilityError,
  organizePortabilityNumberCard,
} from './portabilityNumberCard.utils';
import PortabilityNumber from './components/PortabilityNumber/portabilityNumber';
import { ICheckoutData } from '../../checkout.models';
import { ID_FLOWS } from '../../checkout.constants';
import { Skeleton } from './portabilityNumberCard.style';
import { DEFAULT_PORTABILITY } from '../../../iBuyMobile.context.data';
import { useCheckout } from '../../../iBuyMobile.context';
import TaggingPortabilityNumberCard from './portabilityNumberCard.tagging';

const PortabilityNumberCard = ({
  handleOnChangeEnableGoNext,
  handleGoPrevSlide,
}: ICheckoutData) => {
  const {
    checkoutErrors,
    portability,
    isModalButtonSticky,
    product,
    setPortability,
    setCurrentStepKey,
    setIsValidNumberPortability,
  } = useCheckout();
  const isRequiredPortability = product?.isRequiredPortability;
  const { buttonGenericError, urlGenericErrorCallMeNow } = productSpecificError(product);
  const cmsApiKey = API.CMS_GET_PORTABILITY_MOBILE;
  const portabilityNumberCardCMS = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    cmsApiKey
  ) as IPortabilityMobileCMS;
  const genericError = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_GENERIC_ERROR
  ) as IGenericErrorCMS;
  const { blacklist } =
    (useCmsConfig(
      CONSUMER_CMS_SHOPPING_CART,
      API.CMS_GET_DIGITAL_BUNDLE_OPERATORS
    ) as IDigitalOperatorsConfig) || {};
  const commonLabels = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_STEPPER_COMMON_LABELS_MOBILE
  ) as ICommonLabelsInStepper;
  const { goBackInStepper } = organizeCommonLabelsInStepper(commonLabels);
  const { title, description, label, disclaimer, validationMessage } =
    organizePortabilityNumberCard(portabilityNumberCardCMS);
  const { titleError, messageError } = organizePortabilityError(portabilityNumberCardCMS);

  const {
    isLoading: isLoadingPortInfo,
    isError: isErrorPortInfo,
    error: errorPortIn,
  } = useGetPortabilityInfoMobile(
    portabilityNumberCardCMS.portabilitymobile.operatorList,
    portabilityNumberCardCMS.portabilitymobile.operatorsOrder,
    blacklist?.elements
  );

  const checkPortabilityValidation = () => {
    if (portability?.portabilityNumber) {
      const isMatches = portability.portabilityNumber.match(regex['mobilePhoneNumber']);
      handleOnChangeEnableGoNext?.(!!isMatches);
    }
  };

  useEffect(() => {
    if (isErrorPortInfo) {
      const action = () => {
        if (handleGoPrevSlide) handleGoPrevSlide();
      };
      checkoutErrors?.showAndTrackError?.(
        genericError,
        action,
        errorMock(
          'portininfo',
          errorPortIn,
          undefined,
          undefined,
          'portin info in portabilityCard',
          false,
          {
            portabilityNumber: portability?.portabilityNumber || '',
            portabilityOperators: portability?.portabilityOperators || '',
          }
        ),
        titleError,
        messageError,
        action,
        goBackInStepper,
        () => openPopup(urlGenericErrorCallMeNow, action),
        product?.removeCTC ? undefined : buttonGenericError,
        isModalButtonSticky,
        true
      );
    }
  }, [isErrorPortInfo]);

  useEffect(() => {
    checkPortabilityValidation();
  }, [portability]);

  useEffect(() => {
    setCurrentStepKey(ID_FLOWS.PORTABILITY_NUMBER);
    checkPortabilityValidation();
    if (isRequiredPortability) {
      localStorage.setItem('portability', '1');
    }
  }, []);

  const onChangeData = (telephoneNumber: string) => {
    setIsValidNumberPortability(false);
    setPortability({
      ...DEFAULT_PORTABILITY,
      portabilityWant: isRequiredPortability ? true : portability.portabilityWant,
      portabilityNumber: telephoneNumber || '',
    });
  };

  return (
    <>
      <TaggingPortabilityNumberCard />
      {isLoadingPortInfo && (
        <Skeleton>
          <ShadowsGroup heights={70} quantity={3} />
        </Skeleton>
      )}
      {!isLoadingPortInfo && (
        <PortabilityNumber
          validationMessage={validationMessage}
          title={title}
          description={description}
          label={label}
          disclaimer={disclaimer}
          value={portability.portabilityNumber}
          onChangeData={onChangeData}
          disabled={false}
          schema={defineYupSchema('phoneNumber')}
        />
      )}
    </>
  );
};

export default PortabilityNumberCard;
