import {
  CONSUMER_CMS_SHOPPING_CART,
  IWinBackCMS,
  useCmsConfig,
  useGenerateOTP,
  useVerifyOTP,
  productSpecificError,
  errorMock,
  IGenericErrorCMS,
} from '@vfit/consumer/data-access';
import { useQueryClient } from 'react-query';
import { useEffect, useState } from 'react';
import {
  API,
  errorManager,
  ErrorService,
  openPopup,
  resetData,
  setTrackLink,
  useTracking,
} from '@vfit/shared/data-access';
import { WarningInfo } from '@vfit/shared/components';
import { ICommonData, IWarningInfo } from '@vfit/shared/models';
import { Otp } from '@vfit/consumer/components';
import { useCheckout } from '../../../iBuyMobile.context';
import { OTP_CONSTANTS } from '../PortabilityOtpCrad/portabilityOtpCard.utils';
import {
  organizeExpiredPopup,
  organizeOtpWinback,
  organizePopupLabels,
} from './winbackLegacyOtp.utils';
import { ID_FLOWS } from '../../checkout.constants';
import { DEFAULT_PORTABILITY } from '../../../iBuyMobile.context.data';
import { getUserInfo, getTagging } from '../../checkout.utils';
import { useWinBackLegacy } from '../../hooks/useWinBackLegacy/useWinBackLegacy';

const WinbackLegacyOtp = ({
  handleOnChangeEnableGoNext,
  handleGoPrevSlide,
  handleGoNextSlide,
}: ICommonData) => {
  const {
    checkoutErrors,
    winBackNumber,
    isModalButtonSticky,
    product,
    setPortability,
    setCurrentStepKey,
    setIsDisabledBackButton,
  } = useCheckout();
  const [isError, setIsError] = useState(false);
  const cmsData = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_WINBACK_LEGACY_DATA
  ) as IWinBackCMS;

  const { urlGenericErrorCallMeNow } = productSpecificError(product);
  const cmsOrganizedData = organizeOtpWinback(cmsData, winBackNumber);
  const cmsExpiredPopup = organizeExpiredPopup(cmsData);
  const { titlePopup, ctaLabel, messagePopup, buttonLabel } = organizePopupLabels(cmsData);
  const queryClient = useQueryClient();
  const generateOtp = useGenerateOTP(OTP_CONSTANTS.MODULE_NAME);
  const taggingMobile = getTagging('winback', 'mobile', queryClient);

  const {
    mutate: verifyOtp,
    data: verifyOtpData,
    isSuccess: isSuccessOtp,
    isError: verifyOptError,
    isLoading: isLoadingOtp,
  } = useVerifyOTP();

  const handleVerify = (otp: string) => {
    verifyOtp(otp);
  };

  const {
    startWinbackFlow,
    isLoading: isLoadingWinback,
    isValidVoucher,
    isExpiredVoucher,
    isInvalidVoucher,
    isError: isErrorWinback,
  } = useWinBackLegacy();

  const handleSendOtp = (field: string, fieldType: string) => {
    resetData(queryClient, ['generateOTP']);
    generateOtp.mutate({
      identifier: `39${field}`,
      identifierType: fieldType,
      identificationScope: 'SUB',
    });
  };

  const handleSetError = () => {
    setIsError(false);
  };

  const closePopUpAndGoBack = () => {
    trackView({
      event_name: 'ui_interaction',
      event_label_track: 'click on modifica numero',
      event_category: 'users',
      event_action: 'click',
      tracking_type: 'link',
    });
    handleGoPrevSlide?.();
  };

  const actionPopup = () => {
    openPopup(urlGenericErrorCallMeNow, () => {
      closePopUpAndGoBack();
    });
  };

  const { trackView } = useTracking({
    event: ['view'],
    event_label: 'OTP',
    opts: {
      event_category: ['sales', 'journey'],
      journey_name: 'winback',
      journey_type: 'journey',
      page_section: 'campaign',
      page_subsection: 'check number',
    },
    cartProduct: taggingMobile?.trackingProduct,
    pageProduct: taggingMobile?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });
  const genericError = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_GENERIC_ERROR_MOBILE
  ) as IGenericErrorCMS;
  setTrackLink(trackView, 'OTP');

  useEffect(() => {
    if (verifyOptError || (!verifyOptError && isSuccessOtp && !verifyOtpData)) {
      setIsError(true);
    }
    if (isSuccessOtp && verifyOtpData?.id) {
      setPortability({
        ...DEFAULT_PORTABILITY,
        portabilityNumber: winBackNumber,
        portabilityWant: true,
      });
      startWinbackFlow();
    }
  }, [isSuccessOtp, verifyOtpData, verifyOptError]);

  useEffect(() => {
    setCurrentStepKey(ID_FLOWS.WINBACK_OTP);
    handleSendOtp(winBackNumber, OTP_CONSTANTS.FIELD_TYPE);
    return () => {
      resetData(queryClient, ['generateOTP', 'verifyOTP']);
    };
  }, []);

  useEffect(() => {
    handleOnChangeEnableGoNext?.(isValidVoucher);
    if (isValidVoucher) handleGoNextSlide?.();
  }, [isValidVoucher]);

  useEffect(() => {
    if (isErrorWinback || isInvalidVoucher) {
      checkoutErrors?.showAndTrackError?.(
        genericError,
        closePopUpAndGoBack,
        errorMock('campaignWinBack', { url: 'campaign', status: '500', statusText: 'KO_OTP' }),
        titlePopup,
        messagePopup,
        actionPopup,
        ctaLabel,
        closePopUpAndGoBack,
        buttonLabel,
        isModalButtonSticky,
        true
      );
    }
  }, [isErrorWinback, isInvalidVoucher]);

  useEffect(() => {
    if (isExpiredVoucher) {
      trackView({
        event_name: 'page_error',
        event_label_track: 'error',
        journey_name: 'winback',
        journey_type: 'journey',
        page_section: 'campaign',
        page_subsection: 'error pop up',
        page_type: 'error page',
        page_error: `campaign_expired_${cmsExpiredPopup?.popupMessage}`,
        page_error_code: '404',
      });
      errorManager.handleError(ErrorService.getSeverityErrorMedium(), {
        title: cmsExpiredPopup?.popupTitle,
        message: cmsExpiredPopup?.popupMessage,
        actionText: cmsExpiredPopup?.popupCtaText,
        actionEvent: actionPopup,
        secondButtonText: cmsExpiredPopup?.popupButtonText,
        secondActionEvent: () => {
          window.location.href = cmsExpiredPopup?.popupButtonRedirect;
        },
        onClose: () => {
          window.location.href = cmsExpiredPopup?.popupButtonRedirect;
        },
        disableTrack: true,
      });
    }
  }, [isExpiredVoucher]);

  useEffect(() => {
    setIsDisabledBackButton(isLoadingOtp || isLoadingWinback);
  }, [isLoadingOtp, isLoadingWinback]);

  const waitingInfo: IWarningInfo = {
    title: 'Attendere',
    subtitle: 'Stiamo caricando i dati della tua offerta',
    description: '',
    lottie: product?.loaders?.choosePayment || '',
  };

  return (
    <>
      {(isLoadingOtp || isLoadingWinback) && <WarningInfo {...waitingInfo} />}
      <div
        style={{
          display: isLoadingOtp || isLoadingWinback || isValidVoucher ? 'none' : 'block',
        }}
      >
        <Otp
          contactMedium={{
            field: winBackNumber,
            fieldType: OTP_CONSTANTS.FIELD_TYPE,
          }}
          isError={isError}
          isLoading={isLoadingOtp || isLoadingWinback}
          reSendOtp={handleSendOtp}
          onVerify={handleVerify}
          setIsError={handleSetError}
          noAccessEnabled={false}
          configOtp={cmsOrganizedData}
        />
      </div>
    </>
  );
};

export default WinbackLegacyOtp;
