import { setTrackView, useTracking } from '@vfit/shared/data-access';
import { useQueryClient } from 'react-query';
import { setTrackLink } from '@vfit/shared/data-access';
import { getTagging, getUserInfo } from '../../checkout.utils';
import { withAnimationDelay } from '../../hoc/withAnimationDelay';
import { removeCurrency } from '@vfit/consumer/hooks';
import { retrieveProduct } from '../../../iBuyFixed.utils';

const TaggingComponent = () => {
  const client = useQueryClient();
  const product = retrieveProduct();
  const taggingFixed = getTagging('portability', 'fixed', client);

  const { trackView } = useTracking({
    event: ['checkout_step4'],
    event_label: 'portability',
    opts: taggingFixed.opts,
    cartProduct: {
      ...taggingFixed.trackingProduct,
      cart_total: product?.price && removeCurrency(product.price),
    },
    pageProduct: taggingFixed?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });
  setTrackView(trackView);
  setTrackLink(trackView, 'portability');
  return null;
};

const TaggingPortabilityLineAppCard = withAnimationDelay()(TaggingComponent);

export default TaggingPortabilityLineAppCard;
