import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont } from '@vfit/shared/themes';
import { IMultipleOperators } from './portability.model';

export const Container = styled.div<IMultipleOperators>`
  padding: 32px 20px ${(props) => (props.isMultipleOperators ? 32 : 70)}px 20px;

  @media (min-width: ${breakpoints.tablet}) {
    padding: 60px 40px ${(props) => (props.isMultipleOperators ? 64 : 207)}px 40px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    padding: 60px 55px ${(props) => (props.isMultipleOperators ? 64 : 242)}px 55px;
  }
`;

export const Paragraph = styled.p`
  margin: 0;
  font-weight: 400;
  color: ${colors.$262626};
`;

export const Title = styled(Paragraph)`
  font-family: ${fonts.exbold};
  ${pxToCssFont(30, 38)}
  text-align: center;

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(42, 52)}
  }

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(36, 45)}
  }
`;

export const MigrationCodeCta = styled.button`
  display: flex;
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: none;
  border-bottom: 1px solid ${colors.$262626};
  margin-top: 16px;
  color: ${colors.$262626};
  font-weight: 700;
  font-family: ${fonts.regular};
  ${pxToCssFont(16, 20)}

  @media (min-width: ${breakpoints.tablet}) {
    margin-top: 12px;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const OtherInformation = styled(Paragraph)`
  font-family: ${fonts.regular};
  ${pxToCssFont(12, 16)}
  text-align: start;
  color: ${colors.$7e7e7e};
  margin-top: 20px;

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(14, 18)}
    margin-top: 36px;
  }

  @media (min-width: ${breakpoints.tablet}) {
    margin-top: 44px;
  }
`;

export const MigrationModalContainer = styled.div`
  @media (min-width: ${breakpoints.tablet}) {
    padding: 0;
  }
`;

export const ModalTitle = styled(Title)`
  text-align: left;

  @media (min-width: ${breakpoints.tablet}) {
    padding-top: 8px;
    ${pxToCssFont(30, 38)}
  }

  @media (min-width: ${breakpoints.desktop}) {
    padding-top: 8px;
    ${pxToCssFont(36, 45)}
  }
`;

export const ModalDescription = styled(Paragraph)`
  font-family: ${fonts.regular};
  ${pxToCssFont(20, 26)}
  margin-top: 32px;
  text-align: left;

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(18, 24)}
  }
`;

export const CtaContainer = styled.div`
  width: 100%;
  margin-top: 32px;

  .button-slide-container:last-of-type {
    margin-top: 24px;
  }

  @media (min-width: ${breakpoints.tablet}) {
    width: 328px;
    margin: 40px auto;
  }
`;

export const MigrationLabel = styled(Paragraph)`
  font-family: 'Vodafone Rg', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-top: 8px;
  text-align: center;

  @media (max-width: ${breakpoints.tablet}) {
    text-align: center;
  }
`;

export const LinkWithIconStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 16px;

  @media (min-width: ${breakpoints.tablet}) {
    margin-top: 12px;
  }

  div {
    text-align: left;

    &:first-child {
      margin-bottom: 4px;
    }
  }
`;

export const findmigrationlabel = styled.div`
  color: ${colors.$262626};
  font-family: 'Vodafone Rg', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
`;

export const CheckboxDiv = styled.div`
  display: flex;
  width: 100%;
  margin: 20px 10px 0 0;

  @media (min-width: ${breakpoints.tablet}) and (max-width: ${breakpoints.desktop}) {
    margin-top: 2px;
  }

  span {
    font-family: 'Vodafone Rg', sans-serif;
    color: ${colors.$262626};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
`;
