import { IAddressResponse, IOfferingServiceabilityResponse } from '@vfit/shared/models';

interface InstallationAddress {
  id: string;
}

interface Product {
  productCharacteristic: ProductCharacteristic[];
}

interface ProductCharacteristic {
  name: string;
  value: string;
  displayName?: string;
  displayValue?: string;
  id?: string;
}

interface FixedVoice {
  product: Product;
}

interface CartItemRequest {
  action: string;
  quantity: number;
  type: string;
  object_type: string;
  offering: {
    id: string;
  };
  multiPlayProduct: { installationAddress: InstallationAddress };
  fixedVoice?: FixedVoice[];
  flowType: string;
}

export interface IShoppingCartRequest {
  shoppingCart: { cartItem: CartItemRequest[]; serviceabilityId: string };
}

interface FixedVoiceOffering {
  id: string;
  type: string;
  name: string;
  displayName: string;
  description?: string;
  attachment?: Attachment[];
}

interface Attachment {
  type?: Type;
  purpose?: string;
  url: string;
}

enum Type {
  HTML = 'html',
  Image = 'image',
}

interface FixedVoicePlan {
  id: string;
  type: string;
  action: string;
  quantity: number;
  status: string;
  itemPrice: InstallationAddress[];
  offering: PlanOffering;
  product: PurpleProduct;
}

interface InstallationAddress {
  id: string;
}

interface PlanOffering {
  href: string;
  id: string;
  type: string;
  name: string;
  displayName: string;
  paymentManagementType: string;
}

interface PurpleProduct {
  id: string;
  type: string;
  productCharacteristic: PurpleProductCharacteristic[];
  productSpecification: ProductSpecificationClass;
}

interface FixedVoiceResponse {
  id: string;
  type: string;
  quantity: number;
  status: string;
  itemPrice: FixedVoiceItemPrice[];
  itemTotalPrice: Reason[];
  offering: FixedVoiceOffering;
  product: FixedVoiceProduct;
  requestedStartDate: Date;
  reason: Reason;
  plan: FixedVoicePlan;
}

interface FixedVoiceItemPrice {
  id: string;
  name: string;
  displayName: string;
  offering: Reason;
  isNonImmediateOC: boolean;
}

interface Reason {}

interface PurpleProductCharacteristic {
  name: string;
  displayName: string;
}

interface ProductSpecificationClass {
  id: string;
  name: string;
  type: string;
  inclusionType?: string;
  productCharacteristic?: ProductSpecificationProductCharacteristic[];
}

export interface ProductSpecificationProductCharacteristic {
  name: string;
  displayName: string;
  value?: string;
  displayValue?: string;
}

interface FixedVoiceProduct {
  id: string;
  name: string;
  type: string;
  productCharacteristic: ProductSpecificationProductCharacteristic[];
  installationAddress: InstallationAddress;
}

interface Internet {
  id: string;
  type: string;
  quantity: number;
  status: string;
  itemPrice: InternetItemPrice[];
  itemTotalPrice: CartTotalPriceElement[];
  offering: FixedVoiceOffering;
  product: ProductSpecificationClass;
  requestedStartDate: Date;
  reason: Reason;
  plan: InternetPlan;
  addOn: AddOn[];
  addOnTotalPrice: AddOnTotalPrice[];
}

interface AddOn {
  id: string;
  type: string;
  action: string;
  status: string;
  itemPrice: AddOnItemPrice[];
  offering: FixedVoiceOffering;
  internetAddOnProduct: InternetAddOnProduct;
  isCrossProductDiscount: boolean;
  showTextInsteadRate: string;
}

interface InternetAddOnProduct {
  id: string;
  name: string;
  type: string;
  status: string;
  productSpecification: ProductSpecificationClass;
  isCrossProductDiscount: boolean;
}

interface AddOnItemPrice {
  type: string;
  price: Price;
  advAdditionalDescription: string;
  isNonImmediateOC: boolean;
  recurringChargePeriod?: string;
}

interface Price {
  taxRate?: number;
  taxIncludedAmount: number;
  amount: number;
  taxAmount?: number;
  currencyCode?: CurrencyCode;
}

enum CurrencyCode {
  Eur = 'EUR',
}

interface AddOnTotalPrice {
  type: string;
  price: Price;
  recurringChargePeriod?: string;
}

interface InternetItemPrice {
  id: string;
  name: string;
  displayName: string;
  offering: ItemPriceOffering;
  isNonImmediateOC: boolean;
  recurringChargePeriod?: string;
  type?: string;
  allowedOverrideType?: string;
  originalType?: string;
  subType?: string;
  originalSubType?: string;
  price?: Price;
  showTextInsteadRate?: any;
  installmentInfo?: IInstallmentInfo;
}

interface IInstallmentInfo {
  installmentCharacteristicId: string;
  installmentExtention: IInstallmentExtension;
  installmentOptions: any;
  selectedInstallment: string;
}

interface IInstallmentExtension {
  parentProductSpecContainmentID: string;
  productID: string;
  productSpecContainmentID: string;
}

interface ItemPriceOffering {
  attachment?: Attachment[];
}

interface CartTotalPriceElement {
  recurringChargePeriod?: string;
  type?: string;
  originalprice?: Price;
  price?: Price;
  isNonImmediateOC?: boolean;
  isOnInvoiceOC?: boolean;
  isTotalPriceOC?: boolean;
  discount?: {
    duration?: number;
  };
}

interface InternetPlan {
  id: string;
  type: string;
  action: string;
  quantity: number;
  status: string;
  itemPrice: CartTotalPriceElement[];
  offering: PlanOffering;
  product: FluffyProduct;
}

interface FluffyProduct {
  id: string;
  productCharacteristic: ProductSpecificationProductCharacteristic[];
}

interface CartItemOffering {
  href: string;
  id: string;
  type: string;
  name: string;
  displayName: string;
  description: string;
  attachment: Attachment[];
  isNotR02: boolean;
}

interface CartItemProduct {
  href: string;
  id: string;
  type: string;
  installationAddress: InstallationAddress;
  productCharacteristic?: ProductCharacteristic[];
}

interface CartItemPlan {
  id: '1868856234';
  type: 'mobilePlanCartItem';
  action: 'add';
  quantity: 1;
  status: 'active';
  itemPrice: [
    {
      id: '1868856234';
      recurringChargePeriod: 'month';
      type: 'recurring';
      allowedOverrideType: 'override';
      originalprice: {
        currencyCode: 'EUR';
        taxIncludedAmount: 7.99;
        amount: 7.99;
      };
      price: {
        currencyCode: 'EUR';
        taxRate: 21.95;
        taxIncludedAmount: 5;
        amount: 4.1;
        taxAmount: 0.9;
      };
      discount: [
        {
          name: 'Sconto 2,99 euro';
          description: 'Sconto 2,99 euro';
          duration: 1;
          percentage: 2.99;
          price: {
            taxIncludedAmount: 2.99;
          };
          type: 'Other';
        }
      ];
    }
  ];
  offering: {
    href: string;
    id: string;
    type: string;
    name: string;
    displayName: string;
    attachment: [
      {
        type: string;
        purpose: string;
        url: string;
      }
    ];
    isInvisible: boolean;
    isEligibleForTopup: boolean;
  };
  mobilePlanProduct: [
    {
      id: string;
      productCharacteristic: [
        {
          name: string;
          displayName: string;
          value: string;
          displayValue: string;
          id: string;
        }
      ];
      productSpecification: {
        id: string;
        name: string;
        type: string;
      };
      phoneNumber: {
        msisdn: string;
      };
    }
  ];
}

interface CartValidation {
  status: string;
  cartItemValidation: CartItemValidation[];
}

interface CartItemValidation {
  status: string;
  rootCartItemId: string;
  offeringDisplayName: string;
  cartItemValidationMessage: CartItemValidationMessage[];
}

interface CartItemValidationMessage {
  status: string;
  cartItemId: string;
  validationMessageType: string;
}

interface Order {
  href: string;
  id: string;
}

interface ValidFor {
  startDateTime: Date;
}

interface CartItem {
  tv: any;
  type: string;
  id: string;
  quantity: number;
  device?: {
    type?: string;
    offering?: CartItemOffering;
  };
  offering: CartItemOffering;
  plan: CartItemPlan;
  product: CartItemProduct;
  poType: string;
  fixedVoice: FixedVoiceResponse[];
  internet: Internet[];
  itemTotalPrice?: any; // TODO: MISSING itemTotalPRice
  itemPrice?: any; // TODO: MISSING itemPrice
  dataSim?: any;
  sim?: any;
}

export interface IShoppingCartMutationPayload {
  createdAddress: IAddressResponse;
  offeringServiceability: IOfferingServiceabilityResponse;
}

export interface IShoppingCartPayload {
  shoppingCart: {
    fwtoken?: string;
    id?: string;
    customerId?: {
      id: string;
    };
    cartItem: Array<{
      action: ActionType;
      flowType: FlowType;
      type: ItemType;
      quantity: number;
      object_type?: ObjectType;
      offering: {
        id: string;
      };
      plan?: {
        type: ItemType;
        object_type: ObjectType;
        offering: {
          id: string;
        };
      };
      multiPlayProduct?: {
        installationAddress: {
          id: string;
        };
      };
      deviceAcquisitionType?: DeviceAcquisitionType;
      contactId?: string;
    }>;
    salesTouchPoint?: string;
    serviceabilityId?: string | null;
  };
}

export interface IShoppingCartResponse {
  fwStatus?: 'OK' | 'KO';
  fwStatusCode?: 'FW_001' | 'FW_002' | 'FW_003' | 'FW_004';
  fwStatusMessage?: string;
  href: string;
  id: string;
  validFor: ValidFor;
  isLocked: boolean;
  cartTotalPrice: CartTotalPriceElement[];
  cartItem: CartItem[];
  order: Order;
  orderPaymeanTypeR1: string;
  currentChannel: string;
  originalChannel: string;
  cartValidation: CartValidation;
  dataSim: any;
  additionalRetrievalCriteria?: {
    flexAttribute: { name: string; value: string }[];
  };
}

export interface IShoppingCartCost {
  formattedPrice: string | number;
  price: number;
  originalFormattedPrice: string | number;
  originalPrice: number;
}

export const enum ActionType {
  Add = 'add',
} // Todo: To be added other cases

export const enum FlowType {
  HOME_INTERNET = 'homeInternet_R1',
  MOBILE_PLAN = 'mobilePlan',
}

export const enum ItemType {
  MULTI_PLAY = 'multiPlayCartItem',
  MOBILE = 'mobileCartItem',
  MOBILE_PLAN = 'mobilePlanCartItem',
}

export const enum ObjectType {
  MOBILE_PLAN_V1 = 'V1MobilePlanCartItem',
  MOBILE_V1 = 'V1MobileCartItem',
  MULTI_PLAY_V1 = 'V1MultiPlayCartItem',
}

export const enum DeviceAcquisitionType {
  OWNED = 'Owned',
}
