import { useEffect, useState } from 'react';
import { API, getHome, openPopup, ErrorService, errorManager } from '@vfit/shared/data-access';
import {
  CONSUMER_CMS_SHOPPING_CART,
  IGenericErrorCMS,
  useCmsConfig,
} from '@vfit/consumer/data-access';
import { ECodeIdentification, ICommonData, IIdentificationDetails } from '@vfit/shared/models';
import { useCheckout } from '../../../../../iBuyMobile.context';
import RecognitionEsim from '../../../RecognitionCard/components/RecognitionEsim/recognitionEsim';

const RecognitionCardMainSkipMnp = ({ handleOnChangeEnableGoNext }: ICommonData) => {
  const [isError, setIsError] = useState(false);
  const { product, customerDeliveryBaseInfo, setRecognitionCode, isModalButtonSticky } =
    useCheckout();
  const identificationDetails: IIdentificationDetails | IIdentificationDetails[] =
    customerDeliveryBaseInfo?.deliveryBase?.identificationDetails;

  const genericError = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_GENERIC_ERROR
  ) as IGenericErrorCMS;

  const showBlockedError = () => {
    setIsError(true);
    const closeModalAction = () => {
      const hubPath = product?.hubPath || '';
      window.location.href = `${getHome()}/${hubPath}`;
    };
    errorManager.handleError(ErrorService.getSeverityErrorHigh(), {
      title: genericError?.genericerror?.title || '',
      message: genericError?.genericerror?.description || '',
      actionText: genericError?.genericerror?.buttonLabel || '',
      onClose: closeModalAction,
      actionEvent: closeModalAction,
      secondButtonText: product?.removeCTC ? undefined : product?.genericErrorCallMeNow?.label,
      isButtonSticky: isModalButtonSticky,
      secondActionEvent: () =>
        openPopup(product?.genericErrorCallMeNow?.url || '', closeModalAction),
      opts: {
        product_name: product?.slug || '',
      },
    });
  };

  const renderSimEsimRecognition = () => {
    let haveVR;
    if (Array.isArray(identificationDetails)) {
      haveVR = identificationDetails.find((el) => el.code === ECodeIdentification.VR);
    } else {
      haveVR = (identificationDetails as IIdentificationDetails)?.code === ECodeIdentification.VR;
    }
    if (haveVR) {
      setRecognitionCode('VR');
      localStorage.setItem('recognition', ECodeIdentification.VR);
      return <RecognitionEsim isMNP={false} />;
    }
    showBlockedError();
    return null;
  };

  const checkIdentificationDetails = () => {
    if (Array.isArray(identificationDetails)) {
      if (
        identificationDetails.length === 0 ||
        (identificationDetails.length === 1 &&
          identificationDetails?.[0]?.code === ECodeIdentification.NA)
      ) {
        return showBlockedError();
      }
    } else if (identificationDetails?.code === ECodeIdentification.NA) {
      return showBlockedError();
    }
    if (Array.isArray(identificationDetails)) {
      const haveNA =
        identificationDetails.length === 0 &&
        identificationDetails?.[0].code === ECodeIdentification.NA;
      const haveCourier = identificationDetails.find((el) => el.code === ECodeIdentification.CO);
      const haveVR = identificationDetails.find((el) => el.code === ECodeIdentification.VR);
      if (haveNA) {
        handleOnChangeEnableGoNext?.(false);
      } else if ((haveCourier && !haveVR) || (!haveCourier && haveVR)) {
        localStorage.setItem(
          'recognition',
          haveCourier && !haveVR ? ECodeIdentification.CO : ECodeIdentification.VR
        );
        handleOnChangeEnableGoNext?.(true);
      } else handleOnChangeEnableGoNext?.(false);
    }
  };

  useEffect(() => {
    checkIdentificationDetails();
  }, [identificationDetails]);

  if (isError) {
    return null;
  }

  return <div>{renderSimEsimRecognition()}</div>;
};

export default RecognitionCardMainSkipMnp;
