import { useEffect } from 'react';
import { API } from '@vfit/shared/data-access';
import {
  useCmsConfig,
  CONSUMER_CMS_SHOPPING_CART,
  IDeliveryInformationMobile,
  organizeEditAddressMobile,
  useGetPaymean,
  IGlobalConfigCMS,
} from '@vfit/consumer/data-access';
import {
  ECodeIdentification,
  IDeliveryOptions,
  IEditAddressCmsConfig,
  IIdentificationDetails,
} from '@vfit/shared/models';
import { useCheckout } from '../../../iBuyMobile.context';
import { isEsim } from '../../checkout.utils';
import { ID_FLOWS } from '../../checkout.constants';
import YourAddress from './components/YourAddress/yourAddress';
import { getDataOrganized } from './yourAddressCard.utils';
import { ICheckoutData } from '../../checkout.models';
import TaggingYouAddressCard from './yourAddressCard.tagging';

const YourAddressCard = ({ handleOnChangeEnableGoNext }: ICheckoutData) => {
  const {
    owningData: { isLogged, owningIndividual },
    billingAddress,
    shippingAddress,
    simOptions,
    termsAndConditions,
    deliveryAdvance,
    recognitionCode,
    customerDeliveryBaseInfo,
    setCurrentStepKey,
    setTermsAndConditions,
    setIsStartAddress,
  } = useCheckout();
  const globalConfig = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_GLOBAL_CONFIG
  ) as IGlobalConfigCMS;
  const deliveryInformation = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_DELIVERY_INFORMATION_MOBILE
  ) as IDeliveryInformationMobile;
  const editAddressDataMobile = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_EDIT_ADDRESS_MOBILE
  ) as IEditAddressCmsConfig;
  useGetPaymean({ enabled: isLogged }, globalConfig);
  const editAddressConfigCms = organizeEditAddressMobile(editAddressDataMobile?.editaddressconfig);
  const identificationDetails: IIdentificationDetails | IIdentificationDetails[] =
    deliveryAdvance?.identificationDetails;
  const deliveryOptions: IDeliveryOptions[] =
    customerDeliveryBaseInfo?.deliveryBase?.deliveryOptions || [];
  const selectedSimOption = simOptions?.simOptions?.find((simOption) => simOption.selection);
  const haveDI = deliveryOptions?.find((el) => el.code === ECodeIdentification.DI && el.isSelected);
  const isEsimValue = isEsim(selectedSimOption);
  const isEsimFD = !!(isEsimValue && haveDI);
  const isCourier = recognitionCode === 'CO';

  const addressCmsData = getDataOrganized(
    deliveryInformation,
    owningIndividual,
    billingAddress,
    shippingAddress,
    editAddressConfigCms,
    isEsimValue,
    isEsimFD,
    isCourier
  );

  useEffect(() => {
    setCurrentStepKey(ID_FLOWS.DELIVERY_ADDRESS);
    setTermsAndConditions({
      ...termsAndConditions,
      preConsensus: true,
      optionToReconsider: 'no',
    });
    setIsStartAddress(true);
    if (isEsimValue) {
      localStorage.setItem('isEsim', '1');
    } else {
      localStorage.removeItem('isEsim');
    }
    handleOnChangeEnableGoNext?.(true);
  }, []);

  return (
    <>
      <TaggingYouAddressCard />
      <YourAddress {...addressCmsData} isEsimFD={isEsimFD} isEsim={isEsimValue} />
    </>
  );
};

export default YourAddressCard;
