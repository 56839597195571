import { Container, Title, Info, Expiration } from './paymentCard.style';
import { IPaymentCard } from './paymentCard.models';

const PaymentCard = (data: IPaymentCard) => {
  const { title, cardNumber, cardOwner, icon, expirationLabel } = data;
  return (
    <Container>
      {title && <Title>{title}</Title>}
      <Info data-cs-mask="">
        {cardNumber || ''}
        <p>{cardOwner || ''}</p>
      </Info>
      <Expiration data-cs-mask="">
        {icon && icon}
        {expirationLabel || ''}
      </Expiration>
    </Container>
  );
};

export default PaymentCard;
