import {AddonCardOffer} from "@vfit/consumer/components";
import {IAddonDetailsCMS} from "@vfit/consumer/data-access";
import {ButtonSlide} from "@vfit/shared/atoms";
import React from "react";
import {purify} from "@vfit/shared/themes";
import {IAddonCardViewProps} from "./addonCardView.models";
import * as S from "./addonCardView.style";

export const AddonCardView = ({title, ctaLabel, addonList, addedAddons, isLoadingUpdate, saveAddon, removeAddon, goNext} : IAddonCardViewProps) => {
  const [pTitle] = purify([title]);
  const buildPriceRecurring = (addon : IAddonDetailsCMS) => {
    const priceReccurring = addon?.addondetails?.common?.priceRecurring || '';
    const recurringLabel = addon?.addondetails?.common?.recurringLabel || '';
    return `+${priceReccurring}€/${recurringLabel}`
  }

  return (
    <S.Main>
      <S.Title dangerouslySetInnerHTML={{__html: pTitle || ''}} />
      <S.CtaContainer>
        <ButtonSlide
          label={ctaLabel}
          onClick={goNext}
          buttonColor="#fff"
          labelColor="#262626"
          borderColor="#262626"
          disabled={isLoadingUpdate}
        />
      </S.CtaContainer>
      <S.CardListContainer>
        {addonList && addonList.map((addon) =>
          <AddonCardOffer
            title={addon?.addondetails?.card?.title || addon?.addondetails?.name || ''}
            price={buildPriceRecurring(addon)}
            subprice={addon?.addondetails?.common?.priceActivationLabel || ''}
            imageUri={addon?.addondetails?.card?.cardImage || ''}
            ctaAddLabel={addon?.addondetails?.common?.ctaAddLabel || ''}
            ctaRemoveLabel={addon?.addondetails?.common?.ctaRemoveLabel || ''}
            ctaAddedLabel={addon?.addondetails?.common?.ctaAddedLabel}
            description={addon?.addondetails?.card?.description || ''}
            ctaOpenDetailsLabel={addon?.addondetails?.card?.popUpCtaLabel || ''}
            popupTitle={addon?.addondetails?.detailPopup?.popupTitle || ''}
            popupBody={addon?.addondetails?.detailPopup?.popupDescription || ''}
            closePopupLabel={addon?.addondetails?.common?.ctaCloseLabel || ''}
            addonId={addon.cmsId || ''}
            added={addedAddons.some(el => el === addon.cmsId)}
            isLoadingUpdate={isLoadingUpdate}
            saveAddon={saveAddon}
            removeAddon={removeAddon}
          />
        )}
      </S.CardListContainer>
    </S.Main>
  )
};
