import { setTrackView, useTracking } from '@vfit/shared/data-access';
import { useQueryClient } from 'react-query';
import { setTrackLink } from '@vfit/shared/data-access';
import { getTagging, getUserInfo } from '../../checkout.utils';
import { withAnimationDelay } from '../../hoc/withAnimationDelay';
import { removeCurrency } from '@vfit/consumer/hooks';
import { retrieveProduct } from '../../../iBuyFixed.utils';

const TaggingComponent = () => {
  const client = useQueryClient();
  const taggingFixed = getTagging('portability', 'fixed', client);
  const productTracking = retrieveProduct();

  const { trackView } = useTracking({
    event: ['checkout_step4.5'],
    event_label: 'portability info message',
    opts: taggingFixed.opts,
    cartProduct: {
      ...taggingFixed.trackingProduct,
      cart_total: productTracking?.price && removeCurrency(productTracking.price),
    },
    pageProduct: taggingFixed?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });
  setTrackView(trackView);
  setTrackLink(trackView);
  return null;
};

const TaggingPortabilityInternetCard = withAnimationDelay()(TaggingComponent);

export default TaggingPortabilityInternetCard;
