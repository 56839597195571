import {
  CONSUMER_CMS_SHOPPING_CART,
  IAddonCMS,
  IAddonCommonsCMS,
  IAddonDetailsCMS,
  useCmsConfig,
} from '@vfit/consumer/data-access';
import { API, trackLink } from '@vfit/shared/data-access';
import { ICommonData } from '@vfit/shared/models';
import { useEffect, useState } from 'react';
import { useCheckout } from '../../../iBuyFixed.context';
import { AddonCardView } from './components/addonCardView';
import { ID_FLOWS } from '../../checkout.constants';
import { evaluateCommonLabels } from './addonCard.utils';
import { SkeletonLoader } from './components/skeletonLoader';
import TaggingAddonCard from './addonCard.tagging';

export const AddonCard = ({ handleOnChangeEnableGoNext, handleGoNextSlide }: ICommonData) => {
  const { product, addons, addonState, isLoadingCart, setAddonState, setCurrentStepKey } =
    useCheckout();
  const [startAddonFlow, setStartAddonFlow] = useState(false);
  const [addonListFiltered, setAddonListFiltered] = useState<IAddonDetailsCMS[]>([]);
  const {
    availableAddons,
    isLoadingUpdate,
    isLoadingPriceUpdate,
    isLoading: isLoadingGetAddons,
  } = addonState;
  const addonListCMS = useCmsConfig(CONSUMER_CMS_SHOPPING_CART, API.CMS_GET_ADDONS) as IAddonCMS;

  const addonCommonLabels = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_ADDONS_COMMON
  ) as IAddonCommonsCMS;

  const { title, ctaLabel } = evaluateCommonLabels(addonCommonLabels);

  const addonIdList = Object.keys(addonListCMS).filter((addonCMSId) =>
    product?.addonList?.includes(addonCMSId)
  );
  const addonList = addonIdList.map((id) => ({
    cmsId: id,
    ...addonListCMS[id],
  }));

  const callSaveAddon = (addon: string) => {
    const mecId = addonListCMS[addon]?.addondetails?.id || '';
    trackLink(`aggiungi ${mecId}`, 'users', 'click', 'cart_add');
    setAddonState({
      ...addonState,
      addonCmsId: addon,
      addonMecId: mecId,
      callSaveAddon: true,
    });
  };

  const callRemoveAddon = (addonToRemove: string) => {
    const mecId = addonListCMS[addonToRemove]?.addondetails?.id || '';
    trackLink(`rimuovi ${mecId}`, 'users', 'click', 'cart_remove');
    setAddonState({
      ...addonState,
      addonCmsId: addonToRemove,
      addonMecId: mecId,
      callRemoveAddon: true,
    });
  };

  const goNext = () => {
    trackLink(ctaLabel);
    handleGoNextSlide?.({}, false);
  };

  useEffect(() => {
    setCurrentStepKey(ID_FLOWS.ADDON_CARD);
    setStartAddonFlow(true);
  }, []);

  useEffect(() => {
    if (startAddonFlow && !isLoadingCart) {
      setAddonState({
        ...addonState,
        callGetAddons: true,
      });
      setStartAddonFlow(false);
    }
  }, [startAddonFlow, isLoadingCart]);

  useEffect(() => {
    if (availableAddons.length > 0) {
      setAddonListFiltered(
        addonList?.filter((addon) => availableAddons?.includes(addon.addondetails?.id || '0'))
      );
    }
  }, [availableAddons]);

  useEffect(() => {
    handleOnChangeEnableGoNext?.(
      !(isLoadingUpdate || isLoadingGetAddons || isLoadingCart || isLoadingPriceUpdate)
    );
  }, [isLoadingUpdate, isLoadingGetAddons, isLoadingCart, isLoadingPriceUpdate]);

  return (
    <>
      <TaggingAddonCard addonIdList={addonIdList} />
      {(isLoadingCart || isLoadingGetAddons) && <SkeletonLoader />}
      {!isLoadingCart && !isLoadingGetAddons && (
        <AddonCardView
          title={title}
          ctaLabel={ctaLabel}
          addonList={addonListFiltered}
          addedAddons={addons}
          isLoadingUpdate={isLoadingUpdate}
          saveAddon={callSaveAddon}
          removeAddon={callRemoveAddon}
          goNext={goNext}
        />
      )}
    </>
  );
};
