import {IConfigOtp, IWinBackCMS} from "@vfit/consumer/data-access";
import {fonts, pxToLineHeight} from "@vfit/shared/themes";

export const organizeOtpWinback = (
  dataFromCms: IWinBackCMS,
  phoneNumber: string
): IConfigOtp => ({
  title: dataFromCms?.winbacknumber.otp.title || '',
  description: dataFromCms?.winbacknumber.otp.description || '',
  codeResponseDescription:
    dataFromCms?.winbacknumber.otp.codeResponse?.description || '',
  instructionText: {
    text:
       `${dataFromCms?.winbacknumber.otp.telephoneVerify?.replace(
        '$number',
        `<b style="color:red">${phoneNumber}</b>`
      )}`,
    fontFamily: fonts.exbold,
    size: 20,
    lineHeight: pxToLineHeight(20, 26),
  },
  alerts: {
    error: dataFromCms?.winbacknumber.otp.codeResponse?.alertWarn || '',
    sentNew: dataFromCms?.winbacknumber.otp.codeResponse?.alertSuccess || '',
  },
  otpError: dataFromCms?.winbacknumber.otp.codeResponse?.otpError || '',
  detail: dataFromCms?.winbacknumber.otp.codeMissingLabel || '',
  links: {
    sendNew: dataFromCms?.winbacknumber.otp.codeNewLabel || '',
    noAccess: dataFromCms?.winbacknumber.otp.codeNotAccessLabel || '',
    urlNoAccess: dataFromCms?.winbacknumber.otp.contactNotAccessUrl || '',
  },
  warning: {
    title: dataFromCms?.winbacknumber?.warning?.title || '',
    subtitle: dataFromCms?.winbacknumber?.warning?.subtitle || '',
    description: dataFromCms?.winbacknumber?.warning?.description || '',
  }

});

export const organizePopupLabels = (dataFromCms: IWinBackCMS) => {
  const popupLabels = dataFromCms?.winbacknumber?.errorPopup
  return ({
    titlePopup: popupLabels?.title,
    messagePopup: popupLabels?.message,
    ctaLabel: popupLabels?.ctaLabel,
    buttonLabel: popupLabels?.secondButtonLabel
  })
}

