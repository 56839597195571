import { setTrackLink, setTrackView, useTracking } from '@vfit/shared/data-access';
import { useQueryClient } from 'react-query';
import { getTagging, getUserInfo } from '../../checkout.utils';
import { withAnimationDelay } from '../../hoc/withAnimationDelay';

const TaggingComponent = () => {
  const client = useQueryClient();
  const tagging = getTagging('contract terms', 'fixed', client);

  const { trackView } = useTracking({
    event: ['checkout_step7'],
    event_label: 'terms and conditions',
    opts: tagging?.opts,
    cartProduct: tagging?.trackingProduct,
    pageProduct: tagging?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });
  setTrackView(trackView);
  setTrackLink(trackView, 'terms and conditions');
  return null;
};

const TaggingTermsCard = withAnimationDelay()(TaggingComponent);

export default TaggingTermsCard;
