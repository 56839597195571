export const voucherCodeFieldName = 'voucherCode' as const;

export const createVoucherSchema = (minLengthMessage?: string) => ({
  [voucherCodeFieldName]: {
    minLength: {
      value: 3,
      message: minLengthMessage || '',
    },
  },
});
