import {ButtonSlide, CustomText} from "@vfit/shared/atoms";
import React from "react";
import {trackView} from "@vfit/shared/data-access";
import * as S from "../addonCardOffer.style";

interface IModalProps {
  popupTitle: string;
  popupBody: string;
  added: boolean;
  ctaAddLabel: string;
  addonId: string;
  ctaRemoveLabel: string;
  handleAddAction: (id: string) => void;
  handleRemoveAction: (id: string) => void;
  handleClosePopup: (text: string) => void;
  closePopupLabel: string;
}

export const ModalContent = ({
                               popupTitle,
                               popupBody,
                               added,
                               ctaAddLabel,
                               addonId,
                               ctaRemoveLabel,
                               handleAddAction,
                               handleRemoveAction,
                               closePopupLabel,
                               handleClosePopup,
                               } : IModalProps) => {
  trackView({
    event_name: 'view',
    event_label_track: 'addons:detail',
  })
  return (
    <>
      <h1>{popupTitle}</h1>
      <CustomText
        text={popupBody}
        textAlign='left'
        size={18}
        sizeMobile={20}
        lineHeight={24}
        lineHeightMobile={26}/>
      <S.CtaContainer>
        {!added &&
          <ButtonSlide
            label={ctaAddLabel}
            onClick={() => handleAddAction(addonId)}
            buttonColor="#e60000"
            labelColor="#fff"
            borderColor="#e60000"
            hoverColor="#bd0000"
            clickColor="#a10000"
          />
        }
        {added &&
          <ButtonSlide
            label={ctaRemoveLabel}
            onClick={() => handleRemoveAction(addonId)}
            buttonColor="#fff"
            labelColor="#262626"
            borderColor="#262626"
          />
        }
        <ButtonSlide
          label={closePopupLabel}
          onClick={() =>handleClosePopup(closePopupLabel)}
          buttonColor="#fff"
          labelColor="#262626"
          borderColor="#262626"
        />
      </S.CtaContainer>
    </>
  )
}
