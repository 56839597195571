import {
  ActionType,
  DeviceAcquisitionType,
  FlowType,
  IShoppingCartPayload,
  ItemType,
  ObjectType,
} from './createCart.models';
import { checkFwAmdIntegration } from '../../utils/utils';

export const createPayloadFixed = (
  offerId: string,
  installationAddressId: string,
  serviceabilityId: string
): IShoppingCartPayload | null =>
  offerId && installationAddressId && serviceabilityId
    ? {
        shoppingCart: {
          cartItem: [
            {
              action: ActionType.Add,
              flowType: FlowType.HOME_INTERNET,
              quantity: 1,
              type: ItemType.MULTI_PLAY,
              object_type: ObjectType.MULTI_PLAY_V1,
              offering: {
                id: offerId,
              },
              multiPlayProduct: {
                installationAddress: {
                  id: installationAddressId,
                },
              },
            },
          ],
          serviceabilityId,
        },
      }
    : null;

/**
 * Payload to create cart in mobile flow
 * if is required portability send fake number and operator
 * @param offerId
 * @param planId
 * @param isUserLogged
 * @param customerId
 * @param isRequiredPortability
 * @param configPortability
 */
export const createPayloadMobile = (
  offerId: number,
  planId: string,
  isUserLogged: boolean,
  customerId: string,
  isRequiredPortability?: boolean,
  isWinbackLegacy?: boolean,
  configPortability?: { dummyOperator: string; dummyNumber: string }
): IShoppingCartPayload | null => {
  if (!offerId || !planId) return null;
  const fwtoken = checkFwAmdIntegration();
  return {
    shoppingCart: {
      ...(fwtoken && { fwtoken }),
      ...(isUserLogged ? { customerId: { id: customerId } } : {}),
      cartItem: [
        {
          action: ActionType.Add,
          flowType: FlowType.MOBILE_PLAN,
          quantity: 1,
          type: ItemType.MOBILE,
          object_type: ObjectType.MOBILE_V1,
          offering: {
            id: `${offerId}_${planId}`,
          },
          plan: {
            type: ItemType.MOBILE_PLAN,
            object_type: ObjectType.MOBILE_PLAN_V1,
            offering: {
              id: planId,
            },
          },
          deviceAcquisitionType: DeviceAcquisitionType.OWNED,
        },
      ],
      ...(isRequiredPortability &&
        !isWinbackLegacy && {
          phoneNumber: configPortability?.dummyNumber || '39999999999',
          mobileDonatingOperator: configPortability?.dummyOperator || 'TIM_GSM',
        }),
      serviceabilityId: null,
    },
  };
};
