import { useState, useEffect } from 'react';
import { API } from '@vfit/shared/data-access';
import { ICommonData, IOptionalText } from '@vfit/shared/models';
import {
  CONSUMER_CMS_SHOPPING_CART,
  useCmsConfig,
  IPortabilityCMS,
} from '@vfit/consumer/data-access';
import { CheckboxCards } from '@vfit/consumer/components';
import { organizeCheckboxCardsPortability } from './portabilityInfoCard.utils';
import { useCheckout } from '../../../iBuyFixed.context';
import { ID_FLOWS } from '../../checkout.constants';
import TaggingPortabilityInfoCard from './portabilityInfoCard.tagging';

const PortabilityInfoCard = ({ handleOnChangeEnableGoNext }: ICommonData) => {
  const { portability, setPortability, setCurrentStepKey } = useCheckout();

  const [selectedItem, setSelectedItem] = useState<IOptionalText>();

  const portabilityCms = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_PORTABILITY
  ) as IPortabilityCMS;

  const { title, items, description } = organizeCheckboxCardsPortability(
    portabilityCms,
    portability
  );

  useEffect(() => {
    setCurrentStepKey(ID_FLOWS.PORTABILITY_INFORMATIONS);
    setSelectedItem(undefined);
    setPortability({
      ...portability,
      portabilityInfo: '',
      portabilityOperators: '',
    });
  }, []);

  const handleSelect = (item: IOptionalText | undefined) => {
    setPortability({
      ...portability,
      portabilityInfo: item?.value || '',
      portabilityOperators: '',
    });
    if (item && handleOnChangeEnableGoNext) handleOnChangeEnableGoNext(true);
  };

  return (
    <>
      <TaggingPortabilityInfoCard />
      <CheckboxCards
        items={items}
        description={description}
        title={title}
        value={portability.portabilityInfo}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        onSelectItem={handleSelect}
      />
    </>
  );
};

export default PortabilityInfoCard;
