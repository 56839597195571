import styled from "styled-components";
import {breakpoints, fonts, pxToCssFont} from "@vfit/shared/themes";

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  gap: 24px;
  align-items: center;
  margin-bottom: 48px;
  margin-top: 16px;
  @media(min-width: ${breakpoints.desktop}){
    margin-top: 32px;
  }
`;

export const Title = styled.p`
  margin: 0;
  font-family: ${fonts.light};
  ${pxToCssFont(30, 38)};
  align-self: center;
  p{
    margin: 0;
    b{
      font-family: ${fonts.exbold};
      font-weight: 400;
    }
  }
  @media(min-width: ${breakpoints.desktop}){
    ${pxToCssFont(36, 45)};
  }
`;

export const CtaContainer = styled.div`
  width: 100%;
  @media(min-width: ${breakpoints.tablet}){
    width: 288px;
  }
`;

export const CardListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media(min-width: ${breakpoints.desktop}){
    gap: 40px;
  }
`;
