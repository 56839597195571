import { IPayments, PAYMENT_PRESENT_CC_BANK_ACCONT } from '@vfit/consumer/data-access';
import { EMethodCode, IAvailablePaymentMethods, IOptionalText } from '@vfit/shared/models';
import { ISkeleton } from '@vfit/shared/components';

const organizeCheckBoxPayment = (paymentCMS: IPayments) => ({
  title: paymentCMS?.paymentsmobile?.title || '',
  description: paymentCMS?.paymentsmobile?.description || '',
  modal: {
    textLink: paymentCMS?.paymentsmobile?.modal?.linkLabel || '',
    titleModal: paymentCMS?.paymentsmobile?.modal?.title || '',
    descriptionModal: paymentCMS?.paymentsmobile?.modal?.description || '',
  },
});

const organizeCheckBoxPaymentItems = (
  paymentCMS: IPayments,
  availablePaymentMethods: IAvailablePaymentMethods[],
  selectionIndex: number,
  billingAccountPaymeans?: string[],
  isTNPUser?: boolean
): Array<IOptionalText> => {
  const items: Array<IOptionalText> = [];
  const paymentPresentInNextOrLogged = localStorage.getItem(PAYMENT_PRESENT_CC_BANK_ACCONT);
  const filteredPaymentMethods = isTNPUser
    ? availablePaymentMethods
    : availablePaymentMethods.filter(
        (availableMethod) => !availableMethod.methodCode.toLowerCase().includes('card')
      );

  if (!isTNPUser) {
    items.push({
      title: paymentCMS?.paymentsmobile?.choice?.allCards?.title || '',
      description: paymentCMS?.paymentsmobile?.choice?.allCards?.description || '',
      value: EMethodCode.PREPAID_CARD,
      extra: {
        text: paymentCMS?.paymentsmobile?.defaultChoice,
        color: '#fff',
        extraClass: 'extraClass',
      },
      selection: selectionIndex === 0,
    });
  }

  filteredPaymentMethods.forEach((paymentMethod, index) => {
    let methodCode: EMethodCode | string = paymentMethod?.methodCode;
    if (methodCode === 'payPal') {
      methodCode = methodCode.toLowerCase();
    }
    const foundedPaymentCMS = paymentCMS?.paymentsmobile?.choice?.[methodCode];
    if (
      foundedPaymentCMS &&
      billingAccountPaymeans?.map((el) => el.toLowerCase())?.includes(methodCode?.toLowerCase())
    ) {
      items.push({
        title: foundedPaymentCMS?.title,
        description: foundedPaymentCMS?.description || '',
        value: methodCode === 'paypal' ? EMethodCode?.PAYPAL : methodCode,
        extra:
          index === 0 && isTNPUser
            ? {
                text: paymentCMS?.paymentsmobile?.defaultChoice,
                color: '#fff',
                extraClass: 'extraClass',
              }
            : undefined,
        selection: selectionIndex === index,
      });
    }
  });

  const walletIndex = items?.findIndex(
    (item) => item?.value?.toLowerCase() === EMethodCode?.WALLET?.toLowerCase()
  );
  if (walletIndex !== -1) {
    const walletElement = items.splice(walletIndex, 1)[0];
    items.push(walletElement);
  }

  if (paymentPresentInNextOrLogged) {
    if (paymentPresentInNextOrLogged === '1') {
      return items.filter((el) => el.value === EMethodCode.CREDIT_CARD);
    }
    if (paymentPresentInNextOrLogged === '2') {
      return items.filter((el) => el.value !== EMethodCode.WALLET);
    }
  }

  return items;
};

const organizePaymentMethodError = (paymentCMS: IPayments) => ({
  titleSelectionPaymentError: paymentCMS?.paymentsmobile?.paymentSelectionError?.title || '',
  messageSelectionPaymentError:
    paymentCMS?.paymentsmobile?.paymentSelectionError?.description || '',
  buttonLabelSelectionPayment: paymentCMS?.paymentsmobile?.paymentSelectionError?.buttonLabel || '',
  iconSelectionPayment: paymentCMS?.paymentsmobile?.paymentSelectionError?.icon || '',
  messageSelectedPaymentError:
    paymentCMS?.paymentsmobile?.paymentError?.notFound?.message || '',
  titleSelectedPaymentError: paymentCMS?.paymentsmobile?.paymentError?.notFound?.title || '',
  buttonLabelSelectedPayment: paymentCMS?.paymentsmobile?.paymentError?.buttonLabel || '',
  iconSelectedPayment: paymentCMS?.paymentsmobile?.paymentError?.icon || '',
});

const SKELETON_SHAPE: ISkeleton = {
  margins: [60, 30],
  tabletMargins: [32, 15],
  groups: [
    { heights: 60, marginBottom: 15 },
    { heights: 90, marginBottom: 32 },
    { heights: 60, repeat: 4, gap: 15 },
  ],
};

export {
  SKELETON_SHAPE,
  organizePaymentMethodError,
  organizeCheckBoxPayment,
  organizeCheckBoxPaymentItems,
};

