import { useEffect, useState } from 'react';
import { ICommonData, IOptionalText } from '@vfit/shared/models';
import { API } from '@vfit/shared/data-access';
import {
  CONSUMER_CMS_SHOPPING_CART,
  IPortabilityCMS,
  useCmsConfig,
  resetKeyOnPortabilityError,
} from '@vfit/consumer/data-access';
import { useQueryClient } from 'react-query';
import { resetData } from '@vfit/shared/data-access';
import { CheckboxCards } from '@vfit/consumer/components';
import { organizePortabilityCard } from './portabilityCard.utils';
import { ID_FLOWS } from '../../checkout.constants';
import { useCheckout } from '../../../iBuyFixed.context';
import { DEFAULT_PORTABILITY_MIGRATION } from '../../../iBuyFixed.context.data';

const PortabilityCard = ({ handleOnChangeEnableGoNext }: ICommonData) => {
  const { portability, setPortability, setCurrentStepKey } = useCheckout();

  const client = useQueryClient();
  const portabilityCardCms = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_PORTABILITY
  ) as IPortabilityCMS;
  const { title, description, items } = organizePortabilityCard(portabilityCardCms);
  const [selectedItem, setSelectedItem] = useState<IOptionalText>();

  const handleSelect = (item: IOptionalText | undefined) => {
    const portabilityWant = item?.value === 'yes';
    setPortability({
      ...portability,
      ...(!portabilityWant && { portabilityMigration: { ...DEFAULT_PORTABILITY_MIGRATION } }),
      portabilityNumber: '',
      portabilityWant,
    });
    localStorage.setItem('portability', item?.value === 'yes' ? '1' : '0');
  };

  useEffect(() => {
    if (handleOnChangeEnableGoNext) handleOnChangeEnableGoNext(!!selectedItem);
  }, [selectedItem]);

  useEffect(() => {
    setCurrentStepKey(ID_FLOWS.PORTABILITY);
    resetData(client, resetKeyOnPortabilityError);
  }, []);

  return (
    <CheckboxCards
      title={title}
      description={description}
      items={items}
      value={portability?.portabilityWant || false}
      selectedItem={selectedItem as IOptionalText}
      setSelectedItem={setSelectedItem}
      onSelectItem={handleSelect}
    />
  );
};

export default PortabilityCard;
