import { useEffect, useState } from 'react';
import {
  checkBypassApiCallFromSIA,
  CONSUMER_CMS_SHOPPING_CART,
  errorMock,
  IFindCallerResponse,
  IGlobalConfigCMS,
  useAuthorizeMutation,
  useCmsConfig,
  useGetPaymean,
  usePayment,
  useReserveEquipmentMutation,
} from '@vfit/consumer/data-access';
import { LoggerInstance } from '@vfit/shared/data-access';
import { ISIAFlowType } from '@vfit/shared/models';
import { API, getFromLocalStorageByKey } from '@vfit/shared/data-access';

export const usePaymentAuthorizeFlow = (
  selectedItem: string | undefined,
  siaFlowType?: ISIAFlowType,
  authorizationOperationMethod?: 'capture' | 'verify',
  setIsLoadingGoNext?: (isLoading: boolean) => void,
): {
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
  payMeanIsSuccess: boolean;
  payMeanIsError: boolean;
  paymentIsError: boolean;
} => {
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const newPayMeanEnabler = !!selectedItem;

  const globalConfig = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_GLOBAL_CONFIG
  ) as IGlobalConfigCMS;

  const {
    isSuccess: paymentIsSuccess,
    isLoading: paymentIsLoading,
    isError: paymentIsError,
  } = usePayment({
    enabled: !checkBypassApiCallFromSIA(),
  });
  const {
    data: payMeanData,
    isSuccess: payMeanIsSuccess,
    isError: payMeanIsError,
  } = useGetPaymean(
    {
      enabled: paymentIsSuccess && !checkBypassApiCallFromSIA(),
    },
    globalConfig
  );

  const {
    error: reserveEquipmentError,
    isSuccess: isSuccessReserve,
    isError: isErrorReserve,
    mutate: mutateReserve,
  } = useReserveEquipmentMutation();

  const {
    data: authorizeData,
    isError: isErrorAuthorize,
    error: errorAuthorize,
    isSuccess: isSuccessAuthorize,
    mutate: mutateAuthorize,
  } = useAuthorizeMutation();

  useEffect(() => {
    if (paymentIsLoading) {
      setIsLoading(true);
    }
  }, [paymentIsLoading]);

  useEffect(() => {
    // IMPORTANT: FIX TO UPDATE THE LOGIC FOR INACTIVE NEXT RECOGNIZED
    const findCaller: IFindCallerResponse = getFromLocalStorageByKey('findCaller');
    LoggerInstance.debug('AUTHORIZE payMeanData :', payMeanData);
    LoggerInstance.debug('AUTHORIZE payMeanData.payMeans :', payMeanData?.payMeans);
    if (
      payMeanData?.payMeans &&
      findCaller?.customerHasActiveSubs &&
      payMeanIsSuccess &&
      !isSuccessReserve &&
      !newPayMeanEnabler &&
      !authorizeData &&
      selectedItem
    ) {
      mutateReserve();
    }
    if (payMeanData?.payMeans && isSuccessReserve && !isSuccessAuthorize && selectedItem) {
      mutateAuthorize({
        payMeanType: selectedItem,
        ...(authorizationOperationMethod && {
          authorizationOperation: authorizationOperationMethod,
        }),
        ...(siaFlowType && { siaFlowType }),
      });
    }
  }, [
    payMeanIsSuccess,
    isSuccessReserve,
    isSuccessAuthorize,
    newPayMeanEnabler,
    Boolean(payMeanData?.payMeans),
  ]);

  useEffect(() => {
    if (newPayMeanEnabler) {
      mutateAuthorize({
        payMeanType: selectedItem,
        ...(authorizationOperationMethod && {
          authorizationOperation: authorizationOperationMethod,
        }),
        ...(siaFlowType && { siaFlowType }),
      });
      setIsLoading(true);
    }
  }, [newPayMeanEnabler, selectedItem]);

  useEffect(() => {
    if (paymentIsError) {
      setIsLoading(false);
      setIsError(true);
      setErrorMessage('ERROR - payment');
    }
    if (payMeanIsError) {
      setIsLoading(false);
      setIsError(true);
      setErrorMessage('ERROR - payMean');
    }
    if (isErrorReserve) {
      setIsLoading(false);
      setIsError(true);
      setErrorMessage(
        reserveEquipmentError
          ? errorMock(`reserveEquipment`, reserveEquipmentError)?.errorMessage || ''
          : ''
      );
    }
    if (isErrorAuthorize) {
      setIsLoading(false);
      setIsError(true);
      setErrorMessage(
        errorAuthorize ? errorMock(`authorize`, errorAuthorize)?.errorMessage || '' : ''
      );
    }
  }, [paymentIsError, payMeanIsError, isErrorReserve, isErrorAuthorize]);

  useEffect(() => {
    if (isSuccessAuthorize) {
      setIsLoading(false);
      setIsSuccess(true);
    }
  }, [isSuccessAuthorize]);

  useEffect(() => {
    if (payMeanIsSuccess) {
      setIsLoading(false);
    }
  }, [payMeanIsSuccess]);

  useEffect(() => {
    if (!setIsLoadingGoNext) return;
    if (isLoading) setIsLoadingGoNext(true);
    else setIsLoadingGoNext(false);
  }, [isLoading]);

  return {
    isSuccess,
    isLoading,
    isError,
    errorMessage,
    payMeanIsSuccess,
    payMeanIsError,
    paymentIsError,
  };
};

