import { ReactNode, useEffect, useRef } from 'react';
import { ISupportModule, StepperModal } from '@vfit/shared/components';
import {
  IFamilyPlusActionType,
  IFamilyPlusAction,
  checkIsApp,
  useCmsConfig,
  CONSUMER_CMS_SHOPPING_CART,
} from '@vfit/consumer/data-access';
import { API, openPopup } from '@vfit/shared/data-access';
import { ITrackView, useTracking } from '@vfit/shared/data-access';
import { ETrackConvergenceModal, IConvergenceModalProps } from './convergenceModal.models';
import OfferStep from './components/OfferStep/offerStep';
import DetailsStep from './components/DetailsStep/detailsStep';

const ConvergenceModal = ({
  product,
  isOpen,
  handleClose,
  handleContinue,
  handleAccept,
}: IConvergenceModalProps) => {
  const { trackView, isReadyTrackView } = useTracking({
    event: ['view'],
    disableInitialTrack: true,
  });
  const { familyPlus } = product || {};
  const stepperRef = useRef<any>(null);
  const { OFFER_STEP, DETAILS_STEP } = ETrackConvergenceModal;
  const supportModulesCms = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_SUPPORT_MODULE
  ) as ISupportModule;

  const handleClick = (action?: IFamilyPlusAction) => {
    switch (action?.type) {
      case IFamilyPlusActionType.FAMILYPLUS_REDIRECT:
        if (action.url) window.open(action.url, '_self');
        break;
      case IFamilyPlusActionType.FAMILYPLUS_CTC:
        if (action.url) openPopup(action.url);
        break;
      case IFamilyPlusActionType.FAMILYPLUS_CONTINUE:
        handleContinue?.();
        break;
      case IFamilyPlusActionType.FAMILYPLUS_ACCEPT:
        handleAccept?.();
        break;
      default:
        break;
    }
  };

  const buildTrackingObject = (step: ETrackConvergenceModal): ITrackView => {
    let trackingObject: ITrackView;
    switch (step) {
      case OFFER_STEP: {
        trackingObject = {
          event_name: 'view',
          event_label_track: 'family plus display',
          event_category: 'journey',
          journey_name: 'checkout',
          journey_type: 'journey',
          page_section: 'mobile offers',
          page_subsection: 'checkout',
          page_type: 'family plus display',
          page_browser_system: checkIsApp() ? 'webview' : 'browser',
        };
        break;
      }
      case DETAILS_STEP: {
        trackingObject = {
          event_name: 'view',
          event_label_track: 'offer details',
          event_category: 'journey',
          journey_name: 'checkout',
          journey_type: 'journey',
          page_section: 'mobile offers',
          page_subsection: 'checkout',
          page_type: 'offer details',
          page_browser_system: checkIsApp() ? 'webview' : 'browser',
        };
        break;
      }
    }
    return trackingObject;
  };

  useEffect(() => {
    const trackingOfferStep = buildTrackingObject(OFFER_STEP);
    trackView?.(trackingOfferStep);
  }, [isReadyTrackView]);

  const trackModalLink = (label: string, step: ETrackConvergenceModal) => {
    trackView?.({
      event_name: 'ui_interaction',
      event_label_track: label,
      event_category: 'users',
      event_action: 'click',
      tracking_type: 'link',
      page_name_override: step === OFFER_STEP ? 'family plus display' : 'offer details',
    });
  };

  const components: ReactNode[] = [
    <OfferStep
      data={{
        title: familyPlus?.offerProposition?.offerPropositionTitle || '',
        offerDescription: familyPlus?.offerProposition?.offerPropositionDescription || '',
        productTitle: familyPlus?.offerDetails.title || '',
        price: familyPlus?.price || '',
        recurrence: familyPlus?.recurrence || '',
        priceDescription: familyPlus?.priceDescription || '',
        productSpecs: familyPlus?.offerProposition.propositionItems || [],
        firstButtonLabel: familyPlus?.buttonAction?.label || '',
        secondButtonLabel: familyPlus?.secondButtonAction.label,
        detailsLabel: familyPlus?.offerProposition?.labelShowDetails || '',
      }}
      onFirstButtonClick={() => {
        const firstButtonLabel = familyPlus?.buttonAction?.label || '';
        handleClick(familyPlus?.buttonAction);
        trackModalLink(firstButtonLabel, OFFER_STEP);
      }}
      onSecondButtonClick={() => {
        handleClick(familyPlus?.secondButtonAction);
        const secondButtonLabel = familyPlus?.secondButtonAction?.label || '';
        trackModalLink(secondButtonLabel, OFFER_STEP);
      }}
      onSeeDetailClick={() => {
        stepperRef?.current?._stepperModalGoNext();
        const detailsLabel = familyPlus?.offerProposition?.labelShowDetails || '';
        trackModalLink(detailsLabel, OFFER_STEP);
        trackView?.(buildTrackingObject(DETAILS_STEP));
      }}
    />,
    product ? (
      <DetailsStep
        details={{
          title: familyPlus?.offerDetails?.title || '',
          conditions: familyPlus?.offerDetails.conditions,
          description: familyPlus?.offerDetails.description,
          offerDetails: familyPlus?.offerDetails.offerDetailsFamilyPlus,
          price: familyPlus?.price || '',
          recurrence: familyPlus?.recurrence,
          disableSecondAction: familyPlus?.offerDetails?.disableSecondAction,
          firstButtonLabel: familyPlus?.buttonAction?.label || '',
          secondButtonLabel: familyPlus?.secondButtonAction.label,
        }}
        modalInfo={{
          title: supportModulesCms?.supportmodules?.needHelp?.title || '',
          ctaName: supportModulesCms?.supportmodules?.needHelp?.ctaSupportCall || '',
        }}
        onButtonClick={() => {
          handleClick(familyPlus?.buttonAction);
          const firstButtonLabel = familyPlus?.buttonAction?.label || '';
          trackModalLink(firstButtonLabel, DETAILS_STEP);
        }}
        onSecondButtonClick={() => {
          handleClick(familyPlus?.secondButtonAction);
          const secondButtonLabel = familyPlus?.secondButtonAction?.label || '';
          trackModalLink(secondButtonLabel, DETAILS_STEP);
        }}
      />
    ) : (
      <div />
    ),
  ];

  return (
    <StepperModal
      id="convergence-modal"
      isOpen={isOpen}
      handleClose={handleClose}
      ref={stepperRef}
      components={components}
    />
  );
};

export default ConvergenceModal;
