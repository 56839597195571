import { useMutation, useQueryClient } from 'react-query';
import { nextClient, LoggerInstance, INextError, CustomOptions } from '@vfit/shared/data-access';
import {
  errorMock,
  GetCustomerResponse,
  IShoppingCartResponse,
  isMobileProduct,
} from '@vfit/consumer/data-access';
import { API, getFromLocalStorageByKey } from '@vfit/shared/data-access';
import {
  IProductCharacteristicRequestPayload,
  PostProductCharacteristicResponse,
} from './updateProductCharacteristic.models';

/**
 * Recall service POST /updateProductCharacteristic
 * @param cartId
 * @param payload
 * @param customOptions
 * @returns
 */
const updateProductCharacteristicService = (
  cartId: string,
  payload: IProductCharacteristicRequestPayload,
  customOptions?: CustomOptions
): Promise<PostProductCharacteristicResponse> =>
  nextClient.post(`${API.ORDER}/${cartId}/${API.UPDATE_PRODUCT_CHARACTERISTIC}`, payload, {
    ...(customOptions?.headers && { headers: customOptions.headers }),
    ...(customOptions?.silentLoginHeaders && {
      silentLoginHeaders: customOptions.silentLoginHeaders,
    }),
  });

/**
 * Custom mutation hook for run updateProductCharacteristicService.
 * @returns
 */
export const useUpdateProductCharacteristic = () => {
  const queryClient = useQueryClient();
  let keyString = 'updateProductCharacteristic';
  return useMutation(
    ['doUpdateProductCharacteristic'],
    (optionToReconsider?: string) => {
      let payload: IProductCharacteristicRequestPayload;

      const shoppingCart: IShoppingCartResponse = getFromLocalStorageByKey('shoppingCart');
      const findCaller = getFromLocalStorageByKey('findCaller');
      const customerData: GetCustomerResponse[] = getFromLocalStorageByKey('customerData');

      LoggerInstance.log('UPDATE PRODUCT CHARACT GETDATAFROM STORAGE', customerData, shoppingCart);
      const cartId = shoppingCart?.id;

      const owningIndividualId =
        customerData?.[0]?.owningIndividual?.id || findCaller?.individualRef?.contactId;

      const fixedVoiceId = shoppingCart?.cartItem?.[0]?.fixedVoice?.[0].id;

      const cartItemId = shoppingCart?.cartItem?.[0]?.id;

      const dataSim = !!shoppingCart?.id && (shoppingCart?.cartItem?.[0]?.dataSim ?? [])[0];
      const dataSimId: string = !!dataSim && dataSim?.id;

      const internetId = shoppingCart?.cartItem?.[0]?.internet?.[0]?.id;

      if (!!owningIndividualId && !!internetId) {
        payload = {
          updatedProductCharacteristicList: [
            {
              contactId: owningIndividualId,
              orderActionId: internetId,
            },
          ],
          runRules: true,
        };

        if (fixedVoiceId) {
          payload.updatedProductCharacteristicList.push({
            contactId: owningIndividualId,
            orderActionId: fixedVoiceId,
          });
        }

        if (dataSimId && !optionToReconsider) {
          payload.updatedProductCharacteristicList.push({
            contactId: owningIndividualId,
            orderActionId: dataSimId,
          });
        }

        if (optionToReconsider)
          payload.optionToReconsider = optionToReconsider.replace(
            /^./,
            optionToReconsider[0].toUpperCase()
          );
      } else if (!!owningIndividualId && !!cartItemId && isMobileProduct()) {
        payload = {
          updatedProductCharacteristicList: [
            {
              contactId: owningIndividualId,
              orderActionId: cartItemId,
            },
          ],
          runRules: true,
        };
      }

      LoggerInstance.debug('doUpdateProductCharacteristic : ', payload);
      LoggerInstance.debug('doUpdateProductCharacteristic : ', optionToReconsider);
      localStorage.setItem('optionToReconsider', optionToReconsider?.toLowerCase());
      LoggerInstance.debug('doUpdateProductCharacteristic before calling service : ', payload);

      return updateProductCharacteristicService(cartId as string, payload);
    },
    {
      onMutate: async (data) => {
        if (data) {
          keyString = 'updateProductCharacteristic_2';
        }
      },
      onSuccess: (data: PostProductCharacteristicResponse) => {
        localStorage.setItem(keyString, JSON.stringify(data));
        queryClient.setQueryData(keyString, data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - ${keyString}: `, error);
        queryClient.setQueryData(keyString, errorMock(`${keyString}`, error));
        localStorage.setItem(`${keyString}`, JSON.stringify(errorMock(`${keyString}`, error)));
      },
    }
  );
};

export const useUPCAutomaticRecharge = () => {
  const queryClient = useQueryClient();
  const keyString = 'UPCAutomaticRecharge';
  return useMutation(
    [keyString],
    (selectedValue: string) => {
      const shoppingCart = getFromLocalStorageByKey('shoppingCart');
      const cartId = shoppingCart?.id;
      const orderActionId = shoppingCart?.cartItem?.[0]?.id;
      const productId = shoppingCart?.cartItem?.[0]?.product?.id;
      const characteristicId = shoppingCart?.cartItem?.[0]?.product?.productCharacteristic?.find(
        (element) => element.name === 'Is_Recurring_TopUp_Automatically_Included_Next_R07'
      );

      const payload: IProductCharacteristicRequestPayload = {
        updatedProductCharacteristicList: [
          {
            productId,
            orderActionId,
            productCharacteristic: {
              id: characteristicId?.id || '',
              selectedValue,
            },
          },
        ],
        runRules: true,
      };

      return updateProductCharacteristicService(cartId as string, payload);
    },
    {
      onSuccess: (data: PostProductCharacteristicResponse) => {
        localStorage.setItem(keyString, JSON.stringify(data));
        queryClient.setQueryData(keyString, data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - ${keyString}: `, error);
        queryClient.setQueryData(keyString, errorMock(`${keyString}`, error));
        localStorage.setItem(`${keyString}`, JSON.stringify(errorMock(`${keyString}`, error)));
      },
    }
  );
};
