import { useTracking } from '@vfit/shared/data-access';
import { setTrackLink } from '@vfit/shared/data-access';
import { useQueryClient } from 'react-query';
import { getTagging, getUserInfo } from '../../checkout.utils';

const ContactLockInTagging = () => {
  const queryClient = useQueryClient();
  const tagging = getTagging('contact', 'fixed', queryClient);
  const { trackView } = useTracking({
    event: ['checkout_step3'],
    event_label: 'contacts',
    opts: tagging?.opts,
    cartProduct: tagging?.trackingProduct,
    pageProduct: tagging?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });
  setTrackLink(trackView, 'contacts');
  return null;
};

export default ContactLockInTagging;
