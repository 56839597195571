import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import {
  errorMock,
  getFwToken,
  IShoppingCartResponse,
  useLightCreditVettingMutation,
  useNotify,
  usePaymentDistributionMobile,
  useReserveEquipmentMutation,
  useSubmitMobileMutation,
  useUPCAutomaticRecharge,
} from '@vfit/consumer/data-access';
import {
  API,
  getFromLocalStorageByKey,
  usePostCustomerEligibility,
  ITrackError,
  LoggerInstance,
} from '@vfit/shared/data-access';
import { useCheckout } from '../../../iBuyMobile.context';
import { useTpoCheck } from '../../../ShoppingCart/hooks/useTpoCheck';

// API FLOW
// Payment distribution
// reserve equipment
// light credit vetting
// modifyPhoneNumberPortability (only if the portIn was choosen in checkout flow)
// postCustomer
// submit
export const useCheckoutAutoWalletFlow = () => {
  const shoppingCart: IShoppingCartResponse = getFromLocalStorageByKey('shoppingCart');
  const { portability, portabilityStatus, setPortabilityStatus } = useCheckout();

  // setting internal hook state
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [error, setError] = useState<ITrackError>({
    errorMessage: '',
    error: undefined,
  });

  const queryClient = useQueryClient();
  const modifyPhoneNumberProductError: ITrackError | undefined = queryClient.getQueryData(
    'modifyPhoneNumberProductQuery'
  );

  const {
    mutate: mutateUPC,
    isLoading: isLoadingUPC,
    isSuccess: isSuccessUPC,
    isError: isErrorUPC,
  } = useUPCAutomaticRecharge();

  // (2) Check eligibility
  const {
    isTpoOffer,
    isInCampaignTpo,
    isEligible: isTpoEligible,
    isLoadingCheck,
    token,
    isEnabledFwAmdIntegration,
  } = useTpoCheck();

  // (3) calling hook mutation
  const paymentDistributionMutation = usePaymentDistributionMobile();

  // (4) calling hook mutation
  const {
    data: reserveEquipmentData,
    error: reserveEquipmentError,
    isLoading: isLoadingReserve,
    isSuccess: isSuccessReserve,
    isError: isErrorReserve,
    mutate: mutateReserve,
  } = useReserveEquipmentMutation();

  // (5) calling hook mutation or lightCreditVettingMutation
  const lightCreditVettingMutation = useLightCreditVettingMutation();

  // (6) calling hook mutation final
  const {
    isLoading: isLoadingSubmit,
    isError: isErrorSubmit,
    isSuccess: isSuccessSubmit,
    data: submitData,
    error: submitError,
    mutate: mutateSubmit,
  } = useSubmitMobileMutation();

  const notifyMutation = useNotify();

  // (7) post submit eligibility
  const {
    isLoading: isLoadingPostCustomerEligibility,
    isSuccess: isSuccessPostCustomerEligibility,
    isError: isErrorPostCustomerEligibility,
    mutate: mutatePostCustomerEligibility,
  } = usePostCustomerEligibility();

  useEffect(() => {
    if (!isLoadingPostCustomerEligibility) {
      if (isSuccessPostCustomerEligibility || isErrorPostCustomerEligibility) {
        setIsError(false);
        setIsLoading(false);
        setIsSuccess(true);
      }
    }
  }, [
    isSuccessPostCustomerEligibility,
    isLoadingPostCustomerEligibility,
    isErrorPostCustomerEligibility,
  ]);

  const afterSubmitForFastwebAndAmdocsIntegration = () => {
    const fwToken = getFwToken();
    if (!fwToken && isTpoOffer && isInCampaignTpo) {
      setIsError(false);
      setIsLoading(false);
      setIsSuccess(true);
    } else if (fwToken && submitData?.fwStatus === 'KO') {
      setIsLoading(false);
      setIsSuccess(false);
      setIsError(true);
      setError({
        errorCode: submitData?.fwStatusCode,
      });
    } else if (fwToken) {
      LoggerInstance.log('TPO::Eligible with TOKEN');
      mutatePostCustomerEligibility({
        t: fwToken,
        idOrder: shoppingCart?.id,
      });
    } else {
      LoggerInstance.log('TPO::Eligible without TOKEN');
      setIsLoading(false);
      setIsSuccess(false);
      setIsError(true);
    }
  };

  const afterSubmitStandard = () => {
    if (isTpoEligible) {
      LoggerInstance.log('TPO::Eligible');
      if (token) {
        LoggerInstance.log('TPO::Eligible with TOKEN');
        mutatePostCustomerEligibility({
          t: token,
          idOrder: shoppingCart?.id,
        });
      } else {
        LoggerInstance.log('TPO::Eligible without TOKEN');
        setIsLoading(false);
        setIsSuccess(false);
        setIsError(true);
      }
    } else {
      LoggerInstance.log('TPO:: NOT Eligible');
      setIsError(false);
      setIsLoading(false);
      setIsSuccess(true);
    }
  };

  /**
   * After Submit hook to finalize the flow
   * setting internal state isLoading=false && isSuccess=true
   */
  useEffect(() => {
    if (isSuccessSubmit) {
      if (submitData?.status?.toLowerCase() === 'softko') {
        setIsLoading(false);
        setIsSuccess(false);
        setIsError(true);
        setError(
          errorMock(`submit`, {
            status: '200',
            statusText: 'SoftKO',
            url: API.SUBMIT,
          })
        );
      } else {
        if (isEnabledFwAmdIntegration) {
          afterSubmitForFastwebAndAmdocsIntegration();
        } else {
          afterSubmitStandard();
        }
      }
    } else if (isErrorSubmit) {
      setIsLoading(false);
      setIsSuccess(false);
      setIsError(true);
      setError(
        errorMock(
          `submit`,
          submitError
            ? submitError
            : {
                status: '500',
                statusText: 'KO',
                url: API.SUBMIT,
              }
        )
      );
    }
  }, [isSuccessSubmit, isLoadingSubmit, isErrorSubmit, submitError]);

  /**
   * Submit for portability condition
   */
  useEffect(() => {
    if (
      portabilityStatus?.isSuccess &&
      portabilityStatus?.isStartFinalPortIn &&
      lightCreditVettingMutation?.isSuccess &&
      !isSuccessSubmit &&
      !isLoadingSubmit &&
      !isErrorSubmit
    ) {
      mutateSubmit();
    } else if (portabilityStatus?.isError) {
      setIsLoading(false);
      setIsSuccess(false);
      setIsError(true);
      setError(modifyPhoneNumberProductError as ITrackError);
    }
  }, [portabilityStatus, lightCreditVettingMutation?.isSuccess, isSuccessSubmit, isLoadingSubmit]);

  useEffect(() => {
    if (!lightCreditVettingMutation.isLoading) {
      if (lightCreditVettingMutation.isSuccess) {
        if (
          portability?.portabilityWant &&
          portability?.portabilityOtp?.isVerified &&
          portabilityStatus?.isSuccessCheck
        ) {
          setPortabilityStatus({
            ...portabilityStatus,
            isStartFinalPortIn: true,
          });
        } else if (!portability?.portabilityWant) {
          mutateSubmit();
        }
      } else if (lightCreditVettingMutation.isError) {
        setIsLoading(false);
        setIsSuccess(false);
        setIsError(true);
        if (lightCreditVettingMutation?.error)
          setError(errorMock(`lightCreditVetting`, lightCreditVettingMutation.error));
      }
    }
  }, [
    lightCreditVettingMutation.isLoading,
    lightCreditVettingMutation.isSuccess,
    lightCreditVettingMutation.isError,
    portability,
  ]);

  useEffect(() => {
    if (!isLoadingReserve) {
      if (isSuccessReserve) {
        if (reserveEquipmentData?.status === 'KO') {
          setIsLoading(false);
          setIsSuccess(false);
          setIsError(true);
          setError(
            errorMock(
              'reserveEquipment',
              {
                status: '200',
                statusText: 'KO',
                url: API.RESERVE_EQUIPMENT,
              },
              'KO'
            )
          );
        } else {
          lightCreditVettingMutation.mutate();
        }
      } else if (isErrorReserve) {
        setIsLoading(false);
        setIsSuccess(false);
        setIsError(true);
        setError(
          errorMock(
            `reserveEquipment`,
            reserveEquipmentError
              ? reserveEquipmentError
              : {
                  status: '500',
                  statusText: 'KO',
                  url: API.RESERVE_EQUIPMENT,
                }
          )
        );
      }
    }
  }, [isSuccessReserve, isErrorReserve, isLoadingReserve]);

  useEffect(() => {
    if (!paymentDistributionMutation.isLoading) {
      if (paymentDistributionMutation.isSuccess) {
        if (paymentDistributionMutation.data?.error) {
          setIsLoading(false);
          setIsSuccess(false);
          setIsError(true);
          setError(paymentDistributionMutation.data as ITrackError);
        } else {
          mutateReserve();
        }
      } else if (paymentDistributionMutation.isError) {
        setIsLoading(false);
        setIsSuccess(false);
        setIsError(true);
        if (paymentDistributionMutation?.error)
          setError(errorMock(`paymentDistribution`, paymentDistributionMutation.error));
      }
    }
  }, [
    paymentDistributionMutation.isLoading,
    paymentDistributionMutation.isSuccess,
    paymentDistributionMutation.isError,
  ]);

  useEffect(() => {
    if (!isLoadingUPC) {
      if (isSuccessUPC) {
        notifyMutation.mutate();
        paymentDistributionMutation.mutate();
      } else if (isErrorUPC) {
        setIsLoading(false);
        setIsSuccess(false);
        setIsError(true);
      }
    }
  }, [isLoadingUPC, isSuccessUPC, isErrorUPC]);

  useEffect(() => {
    if (!isLoadingCheck) {
      mutateUPC('No');
    }
  }, [isLoadingCheck]);

  return {
    isLoading,
    isSuccess,
    isError,
    error,
  };
};
