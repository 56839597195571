import { setTrackView, useTracking } from '@vfit/shared/data-access';
import { useQueryClient } from 'react-query';
import { setTrackLink } from '@vfit/shared/data-access';
import { getTagging, getUserInfo } from '../../checkout.utils';
import { withAnimationDelay } from '../../hoc/withAnimationDelay';

const TaggingComponent = () => {
  const queryClient = useQueryClient();
  const tagging = getTagging('consents', 'fixed', queryClient);
  const { trackView } = useTracking({
    event: ['checkout_step8'],
    event_label: 'consents',
    opts: tagging?.opts,
    cartProduct: tagging?.trackingProduct,
    pageProduct: tagging?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });
  setTrackLink(trackView, 'consents');
  setTrackView(trackView);

  return null;
};

const TaggingConsentCard = withAnimationDelay()(TaggingComponent);

export default TaggingConsentCard;
