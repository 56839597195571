import React, { useEffect, useState } from 'react';
import { EditAddress, StepperAddressModal } from '@vfit/consumer/components';
import { VfModal } from '@vfit/shared/components';
import { useDeviceType } from '@vfit/shared/hooks';
import {
  CONSUMER_CMS_SHOPPING_CART,
  IDeliveryInformationMobile,
  IMapCms,
  isDifferentAddress,
  organizeEditAddressManual,
  organizeEditAddressMobile,
  organizeEditAddressOptionMobile,
  organizeMap,
  useCmsConfig,
} from '@vfit/consumer/data-access';
import { API, trackLink } from '@vfit/shared/data-access';
import {
  ECodeIdentification,
  IAddressDataObject,
  IAddressDataObjectExtended,
  IAddressType,
  IEditAddressCmsConfig,
  IPickUpLocation,
} from '@vfit/shared/models';
import { useSearchPickUpPoints } from '@vfit/consumer/hooks';
import {
  Container,
  Title,
  Description,
  TopContent,
  UserInfo,
  DeliveryCta,
  Divider,
  BottomTitle,
  LabelCta,
  BillingCta,
} from './yourAddress.style';
import { IOtherInformation, IYourAddress } from './yourAddress.model';
import NoteModalDelivery from './components/NoteModalDelivery/noteModalDelivery';
import { useCheckout } from '../../../../../iBuyMobile.context';
import { usePickupPointLocateStore } from './yourAddress.hook';

const YourAddress: React.FC<IYourAddress> = ({
  billingData,
  shippingData,
  billingDataEsim,
  shippingDataEsim,
  editAddressConfig,
  noteModalConfig,
  noteLabels,
  description,
  topTitle,
  disableAddNotes,
  isEsim,
  isEsimFD,
}) => {
  const {
    shippingAddress,
    billingAddress,
    owningData,
    deliveryAdvance,
    pickupLocationStore,
    setShippingAddress,
    setBillingAddress,
    setInvoice,
    setPickupPoint,
  } = useCheckout();
  const { isTablet, isDesktop } = useDeviceType();
  const [isOpenNote, setIsOpenNote] = useState(false);
  const [isOpenEditShipping, setIsOpenEditShipping] = useState(false);
  const [isOpenEditBilling, setIsOpenEditBilling] = useState(false);
  const { setStartingSearchAddress } = useSearchPickUpPoints();
  const { postalOffices, vodafoneStores, pickupPoints } = usePickupPointLocateStore({
    getData: pickupLocationStore.global.isSuccess,
  });
  const setModalHeight = (): number => (isTablet && 647) || (isDesktop && 676) || 1200;
  const { firstName, lastName } = owningData?.owningIndividual || {};
  const deliveryOptions =
    deliveryAdvance?.deliveryOptions?.filter((el) => el.code !== ECodeIdentification.DI) || [];
  const hasShippingAddress = !!shippingAddress?.postalCode;
  const hasBillingAddress = !!billingAddress?.postalCode;
  const isDifferentAdd = isDifferentAddress(
    shippingAddress,
    billingAddress as IAddressDataObjectExtended
  );

  // region CMS
  const mapData = useCmsConfig(CONSUMER_CMS_SHOPPING_CART, API.CMS_GET_MAP_MOBILE) as IMapCms;
  const deliveryInformation = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_DELIVERY_INFORMATION_MOBILE
  ) as IDeliveryInformationMobile;
  const editManualMobile = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_EDIT_ADDRESS_MOBILE
  ) as IEditAddressCmsConfig;
  const editAddressStepper = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_EDIT_ADDRESS_STEPPER_MOBILE
  ) as IEditAddressCmsConfig;
  const multipleOptions = deliveryInformation?.deliveryinformationm?.addressChange?.options;
  const editAddressStepperModalConfig = organizeEditAddressMobile(
    editAddressStepper?.editaddressconfig
  );
  const { mapConfig, mapEditConfig } = organizeMap(mapData);
  const editAddressOptionConfig = organizeEditAddressOptionMobile(deliveryInformation);
  const editManualAddressConfigMobile = organizeEditAddressManual(editManualMobile);
  // endregion

  const toggleModalNote = (link?: string) => {
    if (link) {
      trackLink(link);
    }
    setIsOpenNote((prevState) => !prevState);
  };

  const getAddressDescription = (address: IAddressDataObject) => {
    const { typePickupPoint } = address || {};
    // IS THIS CORRECT GETTING FROM ??
    const officialOfficePlaceholder =
      deliveryInformation?.deliveryinformationm?.officialOfficePlaceholder || '';
    if (!typePickupPoint || !shippingData?.addressData?.postalOfficeDescription)
      return `${firstName || ''} ${lastName || ''}`;
    if (typePickupPoint === IAddressType.PO && shippingData?.addressData?.postalOfficeDescription)
      return `${officialOfficePlaceholder} ${shippingData?.addressData?.postalOfficeDescription.toLowerCase()}`;
    if (shippingData?.addressData?.postalOfficeDescription)
      return `${shippingData?.addressData?.postalOfficeDescription.toLowerCase()}`;
    return `${firstName || ''} ${lastName || ''}`;
  };

  const onChangePickupPoint = (pickupPointLocal: IPickUpLocation) => {
    setPickupPoint(pickupPointLocal);
  };

  const onClickEditShipping = () => {
    pickupLocationStore?.startDeliveryLocateAndPickupPoint?.();
    trackLink(!hasShippingAddress ? shippingData?.labelEdit : shippingData?.changeAddress);
    setIsOpenEditShipping(true);
  };

  const onClickEditBilling = () => {
    trackLink(!hasBillingAddress ? billingData?.changeAddress : billingData?.labelEdit);
    setIsOpenEditBilling(true);
  };

  useEffect(() => {
    if (deliveryOptions && deliveryOptions.length > 0) setStartingSearchAddress(shippingAddress);
  }, [deliveryAdvance]);

  const renderStepperModal = () => (
    <StepperAddressModal
      address={shippingAddress}
      shippingAddress={shippingAddress}
      setShippingAddress={setShippingAddress}
      postalOffices={(postalOffices as any) || []}
      pickupPoints={(pickupPoints as any) || []}
      vodafoneStores={(vodafoneStores as any) || []}
      onChangePickupPoint={onChangePickupPoint}
      isOpen={isOpenEditShipping}
      handleClose={() => setIsOpenEditShipping(false)}
      deliveryOptions={
        deliveryOptions && deliveryOptions?.length > 0
          ? deliveryOptions
          : [
              {
                code: IAddressType.S,
                description: 'CASA',
                isSelected: false,
              },
            ]
      }
      userData={{ lastName, firstName }}
      setPickupPoint={setPickupPoint}
      billingAddress={billingAddress}
      setBillingAddress={setBillingAddress}
      setInvoice={setInvoice}
      multipleOptions={multipleOptions || []}
      editAddressStepperModalConfig={editAddressStepperModalConfig}
      mapConfig={mapConfig}
      mapConfigEdit={mapEditConfig}
      editAddressOptionsConfig={editAddressOptionConfig}
      manualAddressConfig={editManualAddressConfigMobile}
      isLoading={
        pickupLocationStore.global.isLoading ||
        pickupLocationStore?.searchLocateStore.isLoading ||
        pickupLocationStore?.searchPickupLocations.isLoading ||
        pickupLocationStore?.searchPostalOffice.isLoading ||
        deliveryAdvance?.isLoading
      }
    />
  );

  const renderEditModals = () => (
    <>
      <VfModal height={setModalHeight()} isOpen={isOpenNote} handleClose={toggleModalNote}>
        <NoteModalDelivery
          handleClose={toggleModalNote}
          shippingAddress={shippingAddress}
          setShippingAddress={setShippingAddress}
          noteModalConfig={noteModalConfig}
        />
      </VfModal>
      <VfModal
        height={setModalHeight()}
        isOpen={isOpenEditBilling}
        handleClose={() => setIsOpenEditBilling(false)}
      >
        <EditAddress
          firstName={firstName}
          lastName={lastName}
          handleClose={() => setIsOpenEditBilling(false)}
          origin={false}
          billingAddress={billingAddress}
          setBillingAddress={setBillingAddress}
          setShippingAddress={setShippingAddress}
          setInvoice={setInvoice}
          setPickupPoint={setPickupPoint}
          shippingAddress={shippingAddress}
          cmsConfig={editAddressConfig}
          manualAddressConfig={editManualAddressConfigMobile}
        />
      </VfModal>
    </>
  );

  const renderDifferentShippingBilling = () => (
    <>
      <BottomTitle>
        {!hasBillingAddress ? billingData?.matchAddress : billingData?.title}
      </BottomTitle>
      {!hasBillingAddress && <LabelCta>{billingData?.noMatch}</LabelCta>}
      {hasBillingAddress && !!billingAddress && (
        <UserInfo>
          <p data-cs-mask="" className="username">{`${firstName} ${lastName}`}</p>
          <p data-cs-mask="">{billingData?.addressData?.address}</p>
          <p data-cs-mask="">{billingData?.addressData?.city}</p>
          <p data-cs-mask="">{`${billingData?.addressData?.zipCode}-${billingData?.addressData?.province}`}</p>
        </UserInfo>
      )}
      <BillingCta onClick={onClickEditBilling}>
        {!hasBillingAddress ? billingData?.changeAddress : billingData?.labelEdit}
      </BillingCta>
    </>
  );

  const renderESIMDifferentShippingBilling = () => (
    <>
      <BottomTitle>
        {!hasBillingAddress ? billingDataEsim?.matchAddress : billingDataEsim?.title}
      </BottomTitle>
      {!hasBillingAddress && <LabelCta>{billingDataEsim?.noMatch}</LabelCta>}
      {hasBillingAddress && billingAddress && (
        <UserInfo>
          <p data-cs-mask="" className="username">{`${firstName} ${lastName}`}</p>
          <p data-cs-mask="">{billingDataEsim?.addressData?.address}</p>
          <p data-cs-mask="">{billingDataEsim?.addressData?.city}</p>
          <p data-cs-mask="">{`${billingDataEsim?.addressData?.zipCode}-${billingDataEsim?.addressData?.province}`}</p>
        </UserInfo>
      )}
      <BillingCta onClick={onClickEditBilling}>
        {!hasBillingAddress ? billingDataEsim?.changeAddress : billingDataEsim?.labelEdit}
      </BillingCta>
    </>
  );

  const renderSameShippingBilling = (address: IOtherInformation, isBillingAddress?: boolean) => (
    <>
      <BottomTitle>{address?.matchAddress}</BottomTitle>
      <LabelCta>{address?.noMatch}</LabelCta>
      <BillingCta
        onClick={() => {
          trackLink(`${address?.changeAddress}`);
          if (isBillingAddress) setIsOpenEditBilling(true);
          else setIsOpenEditShipping(true);
        }}
      >
        {address?.changeAddress}
      </BillingCta>
    </>
  );

  const renderNotes = () => {
    if (disableAddNotes) return null;
    return (
      <DeliveryCta
        onClick={() => {
          toggleModalNote?.(
            !shippingAddress.note ? noteLabels?.noteTitle : noteLabels?.noteTitleAfterEdit
          );
        }}
      >
        {!shippingAddress.note ? noteLabels?.noteTitle : noteLabels?.noteTitleAfterEdit}
      </DeliveryCta>
    );
  };

  // region Render SIM/ESIM

  const renderIsEsimFullDigital = () => (
    <>
      <BottomTitle>{billingDataEsim?.title || ''}</BottomTitle>
      {hasBillingAddress && !!billingAddress && (
        <UserInfo style={{ textTransform: 'capitalize' }}>
          <p data-cs-mask="">{getAddressDescription(billingAddress)}</p>
          <p data-cs-mask="">{billingDataEsim?.addressData?.address}</p>
          <p data-cs-mask="">{billingDataEsim?.addressData?.city}</p>
          <p data-cs-mask="">{`${billingDataEsim?.addressData?.zipCode}-${billingDataEsim?.addressData?.province}`}</p>
          {billingAddress.note ? (
            <p data-cs-mask="">
              Note: <span className="note">{`${billingAddress.note}`}</span>
            </p>
          ) : (
            ''
          )}
        </UserInfo>
      )}
      {renderNotes()}
      <BillingCta onClick={onClickEditBilling}>
        {!hasBillingAddress ? billingDataEsim?.labelEdit : billingDataEsim?.changeAddress}
      </BillingCta>
      {renderEditModals()}
      {deliveryOptions && renderStepperModal()}
    </>
  );

  const renderIsEsim = () => (
    <>
      <BottomTitle>
        {!hasShippingAddress ? shippingDataEsim?.title : shippingData?.title}
      </BottomTitle>
      {!hasShippingAddress && (
        <LabelCta>{shippingDataEsim?.noMatch || shippingData?.noMatch}</LabelCta>
      )}
      {hasShippingAddress && !!shippingAddress && (
        <UserInfo style={{ textTransform: 'capitalize' }}>
          <p data-cs-mask="">{getAddressDescription(shippingAddress)}</p>
          <p data-cs-mask="">{shippingDataEsim?.addressData?.address || shippingData?.addressData?.address}</p>
          <p data-cs-mask="">{shippingDataEsim?.addressData?.city || shippingData?.addressData?.city}</p>
          <p data-cs-mask="">{`${shippingDataEsim?.addressData?.zipCode || shippingData?.addressData?.zipCode}-${
            shippingDataEsim?.addressData?.province || shippingData?.addressData?.province
          }`}</p>
          {shippingAddress.note ? (
            <p data-cs-mask="">
              Note: <span className="note">{`${shippingAddress.note}`}</span>
            </p>
          ) : (
            ''
          )}
        </UserInfo>
      )}
      {renderNotes()}
      <BillingCta onClick={onClickEditShipping}>
        {!hasShippingAddress
          ? shippingDataEsim?.labelEdit || shippingData?.labelEdit
          : shippingDataEsim?.changeAddress || shippingData?.changeAddress}
      </BillingCta>
      <Divider />
      {!isDifferentAdd && renderSameShippingBilling(billingData, true)}
      {isDifferentAdd && renderESIMDifferentShippingBilling()}
      {renderEditModals()}
      {deliveryOptions && renderStepperModal()}
    </>
  );

  const renderIsNotEsim = () => (
    <>
      <BottomTitle>{!hasShippingAddress ? shippingData?.title : shippingData?.title}</BottomTitle>
      {!hasShippingAddress && <LabelCta>{shippingData?.noMatch}</LabelCta>}
      {hasShippingAddress && !!shippingAddress && (
        <UserInfo style={{ textTransform: 'capitalize' }}>
          <p data-cs-mask="">{getAddressDescription(shippingAddress)}</p>
          <p data-cs-mask="">{shippingData?.addressData?.address}</p>
          <p data-cs-mask="">{shippingData?.addressData?.city}</p>
          <p data-cs-mask="">{`${shippingData?.addressData?.zipCode}-${shippingData?.addressData?.province}`}</p>
          {shippingAddress.note ? (
            <p data-cs-mask="">
              Note: <span className="note">{`${shippingAddress.note}`}</span>
            </p>
          ) : (
            ''
          )}
        </UserInfo>
      )}
      {renderNotes()}
      <BillingCta onClick={onClickEditShipping}>
        {!hasShippingAddress ? shippingData?.labelEdit : shippingData?.changeAddress}
      </BillingCta>
      <Divider />
      {!isDifferentAdd && renderSameShippingBilling(billingData, true)}
      {isDifferentAdd && renderDifferentShippingBilling()}
      {renderEditModals()}
      {deliveryOptions && renderStepperModal()}
    </>
  );

  // endregion

  const checkRender = () => {
    if (isEsimFD) {
      return renderIsEsimFullDigital();
    }
    if (isEsim) {
      return renderIsEsim();
    }
    return renderIsNotEsim();
  };

  return (
    <Container>
      <TopContent>
        <Title>{topTitle}</Title>
        <Description>{description}</Description>
      </TopContent>
      {checkRender()}
    </Container>
  );
};

export default YourAddress;
