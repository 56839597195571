import { checkWindow, useCustomerEligibility } from '@vfit/shared/data-access';
import { useEffect, useState } from 'react';
import { TPO_TKN } from '@vfit/consumer/data-access';

export const useThirdPartyEligibilityCustomer = (data: {
  isStartFlow: boolean;
  customerToken?: string;
  startFlow?: number;
}) => {
  const [thirdPartyEligibilityCustomer, setThirdPartyEligibilityCustomer] = useState({
    isLoading: true,
    isEligible: false,
    token: '',
  });
  const querystringParams = checkWindow() && window.location.search;
  const urlParams = new URLSearchParams(querystringParams);
  const token = data?.customerToken || urlParams?.get('t');
  const {
    data: customerEligibilityData,
    isLoading: isLoadingCustomerEligibility,
    isSuccess: isSuccessCustomerEligibility,
    isError: isErrorCustomerEligibility,
    mutate: mutateCustomerEligibility,
  } = useCustomerEligibility();

  useEffect(() => {
    if (isLoadingCustomerEligibility) {
      setThirdPartyEligibilityCustomer({
        ...thirdPartyEligibilityCustomer,
        isLoading: true,
        isEligible: false,
        token,
      });
    } else if (
      isErrorCustomerEligibility ||
      (isSuccessCustomerEligibility &&
        customerEligibilityData?.returnMsg?.toLowerCase() !== 'success')
    ) {
      setThirdPartyEligibilityCustomer({
        ...thirdPartyEligibilityCustomer,
        isLoading: false,
        isEligible: false,
        token,
      });
    } else if (
      isSuccessCustomerEligibility &&
      customerEligibilityData?.returnMsg?.toLowerCase() === 'success'
    ) {
      setThirdPartyEligibilityCustomer({
        ...thirdPartyEligibilityCustomer,
        isEligible: true,
        isLoading: false,
        token,
      });
      localStorage.setItem(TPO_TKN, token);
    }
  }, [isSuccessCustomerEligibility, isLoadingCustomerEligibility, isErrorCustomerEligibility]);

  useEffect(() => {
    if (data.isStartFlow) {
      if (token) {
        mutateCustomerEligibility({
          token,
          ...(data?.startFlow && { startFlow: data.startFlow }),
        });
      } else {
        setThirdPartyEligibilityCustomer({
          isLoading: false,
          isEligible: false,
          token: undefined,
        });
      }
    }
  }, [data.isStartFlow]);

  return thirdPartyEligibilityCustomer;
};
