import { useQuery } from 'react-query';
import { API, getFromLocalStorageByKey } from '@vfit/shared/data-access';
import {
  ErrorCodes,
  errorMock,
  GetCustomerResponse,
  IBillingAccountResponse,
  IFindCallerResponse,
  IGlobalConfigCMS,
} from '@vfit/consumer/data-access';
import {
  LoggerInstance,
  nextClient,
  INextError,
  CustomOptions
} from '@vfit/shared/data-access';
import { IPayMeanServiceResponse } from '@vfit/shared/models';

/**
 * PayMean Service call
 * Method: GET
 * @param customerId
 * @param owningIndividualId
 * @param searchParams
 * @param customOptions
 */
const getPayMean = (
  customerId: string,
  owningIndividualId: string,
  searchParams: { [key: string]: string },
  customOptions?: CustomOptions
) =>
  nextClient.get(`${API.CUSTOMER}/${customerId}/individual/${owningIndividualId}/${API.PAYMEAN}`, {
    searchParams: {
      ...searchParams,
    },
    ...(customOptions?.headers && { headers: customOptions.headers }),
    ...(customOptions?.silentLoginHeaders && {
      silentLoginHeaders: customOptions.silentLoginHeaders,
    }),
  }) as Promise<GetCustomerResponse[]>;

/**
 * Get paymean
 * This method will replace all get paymean
 * @param customOptions
 */
export const useGetPaymean = (customOptions?: CustomOptions, cmsGlobalConfig?: IGlobalConfigCMS) =>
  useQuery(
    ['payMean'],
    () => {
      const customerData: GetCustomerResponse[] = getFromLocalStorageByKey('customerData');
      const findCaller: IFindCallerResponse = getFromLocalStorageByKey('findCaller');
      // TODO NEW MOP: Remove MMT before production
      const isCustomerXBS = findCaller?.individualRef?.fiscalCode?.includes('XBS');
      const isGlobalBsNewMopFixedMobile =
        cmsGlobalConfig?.globalconfig?.newMop?.enableNewMopFixed === 'true' ||
        cmsGlobalConfig?.globalconfig?.newMop?.enableNewMopMobile === 'true';

      const billingAccount: IBillingAccountResponse = getFromLocalStorageByKey('billingAccount');
      const customerId = customerData?.[0]?.id || findCaller?.customerRef?.id;
      const owningIndividualId =
        customerData?.[0]?.owningIndividual?.id || findCaller?.individualRef?.contactId;

      // default AS-IS
      const defaultSearchParams = {
        salesChannel: 'selfService',
        checkExistingInstallment: 'true',
        autoPay: 'false',
        filters: 'type==bankAccount,creditCard',
        usePaymeanFiltering: 'false',
        ...(billingAccount?.id && { billingArrangementId: billingAccount?.id }),
      };

      // new MOP
      const newMopSearchParams = {
        salesChannel: 'selfService',
        checkExistingInstallment: 'true',
        checkOnGoingTNPDevice: 'true',
        autoPay: 'true',
        filters: 'type==bankAccount,creditCard,debitCard,payPal;availableNewPaymeanTypes==filtered',
        ...(billingAccount?.id && { billingArrangementId: billingAccount?.id }),
      };

      const isNewMopEnabled = isCustomerXBS || isGlobalBsNewMopFixedMobile;
      return getPayMean(
        customerId,
        owningIndividualId,
        isNewMopEnabled ? newMopSearchParams : defaultSearchParams
      );
    },
    {
      enabled: customOptions?.enabled || false,
      onSuccess: (data: IPayMeanServiceResponse) => {
        localStorage.setItem('payMean', JSON.stringify(data));
      },
      onError: (error: INextError) => {
        LoggerInstance.error('ERROR - payMean: ', error, ErrorCodes.PAYMEAN);
        localStorage.setItem(
          'payMean',
          JSON.stringify(errorMock('payMean', error, ErrorCodes.PAYMEAN))
        );
      },
    }
  );

