import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont } from '@vfit/shared/themes';
import { ICSSProps } from './extraText.models';

export const Container = styled.div`
  word-break: break-word;
  display: flex;
  flex-direction: column;
  margin-left: 18px;

  div {
    width: 100%;
    font-style: normal;
    text-align: left;
    color: ${colors.$262626};
    align-items: center;
  }

  p {
    margin: 0 auto;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const Detail = styled.div<ICSSProps>`
  font-family: ${fonts.regular};
  font-weight: 400;
  color: ${colors.$bebebe} !important;
  margin: 0 auto;
  ${pxToCssFont(16, 22)}

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(18, 24)}
  }
`;

export const Title = styled.div<ICSSProps>`
  font-family: ${fonts.exbold};
  font-weight: 400;
  margin: 0 auto;
  ${pxToCssFont(20, 26)}

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(20, 30)}
  }

  ${(props) => {
    switch (props.type) {
      case 3:
        return `{
          font-family: ${fonts.regular};
          font-weight: 700;
          margin-bottom: 8px;
          ${pxToCssFont(16, 22)}
          @media (min-width: ${breakpoints.tablet}) {
            ${pxToCssFont(18, 24)}
            margin-bottom: 12px;
          }
        }`;
      default:
        return '';
    }
  }}
`;

export const Description = styled.div<ICSSProps>`
  div {
    font-family: ${fonts.regular};
    font-weight: 400;
    margin: 0 auto;
    margin-top: 3px;
    ${pxToCssFont(16, 22)}

    b {
      font-family: ${fonts.regular};
      font-weight: 700;
    }

    @media (min-width: ${breakpoints.tablet}) {
      margin-top: 0;
      ${pxToCssFont(18, 24)}
    }
  }
`;

export const ContainerExtra = styled.div<ICSSProps>`
  display: flex;
  flex-direction: row;
  margin-top: 4px;

  &.extraClass {
    padding: 4px 10px;
    gap: 4px;
    width: 155px;
    height: 24px;
    background: #00697c;
    opacity: 0.8;
    border-radius: 20px;
  }

  svg {
    width: 25px;
    vertical-align: middle;
    margin-right: 7.57px;
    ${(props) => `stroke: ${props.color}`}
  }

  @media (min-width: ${breakpoints.tablet}) {
    margin-top: 9px;
  }
`;

export const Extra = styled.div<ICSSProps>`
  div {
    font-family: ${fonts.regular};
    font-weight: 700;
    ${pxToCssFont(16, 22)}
    margin: 0 auto;
    ${(props) => `color: ${props.color} !important;`}
    ${({ variant }) => variant === "action" ? 'text-decoration: underline' : ''}
  }
`;
