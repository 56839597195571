import { IPayments } from '@vfit/consumer/data-access';
import { EMethodCode, IOptionalText } from '@vfit/shared/models';

const organizeCheckBoxPayment = (
  paymentCMS: IPayments,
  availableNewPayMeans: string[],
  isTNPUser?: boolean
) => {
  const checkIfPayPal = (el: string): string => {
    if (el === 'payPal') return el.toLowerCase();
    return el;
  };

  const filteredPaymentMethods = isTNPUser
    ? availableNewPayMeans
    : availableNewPayMeans.filter(
        (availableMethod) => !availableMethod.toLowerCase().includes('card')
      );
  const items: Array<IOptionalText> = [];

  if (!isTNPUser) {
    items.push({
      title: paymentCMS?.paymentsmobile?.choice?.allCards?.title || '',
      description: paymentCMS?.paymentsmobile?.choice?.allCards?.description || '',
      value: EMethodCode.DEBIT_CARD,
      extra: {
        text: paymentCMS?.paymentsmobile?.defaultChoice || '',
        color: '#fff',
        extraClass: 'extraClass',
      },
    });
  }

  filteredPaymentMethods
    ?.forEach((el) => {
      const index = Object.keys(paymentCMS?.paymentsmobile?.choice).indexOf(checkIfPayPal(el));
      items.push({
        title: Object.values(paymentCMS?.paymentsmobile?.choice)[index]?.title || '',
        description: Object.values(paymentCMS?.paymentsmobile?.choice)[index]?.description || '',
        value: checkIfPayPal(el),
      })}
    );

  return {
    title: paymentCMS?.paymentsmobile?.title || '',
    description: paymentCMS?.paymentsmobile?.description || '',
    items: items || [],
    modal: {
      textLink: paymentCMS?.paymentsmobile?.modal?.linkLabel || '',
      titleModal: paymentCMS?.paymentsmobile?.modal?.title || '',
      descriptionModal: paymentCMS?.paymentsmobile?.modal?.description || '',
    },
  };
};

const organizePaymentMethodError = (paymentCMS: IPayments) => ({
  titlePaymentError: paymentCMS?.paymentsmobile?.paymentSelectionError?.title || '',
  messagePaymentError: paymentCMS?.paymentsmobile?.paymentSelectionError?.description || '',
  messageSelectedPaymentError:
    paymentCMS?.paymentsmobile?.paymentError?.notFound?.message ||
    'Siamo spiacenti ma si è verificato un errore sulla scelta del nuovo metodo di pagamento. Riprovare in seguito.',
  titleSelectedPaymentError: paymentCMS?.paymentsmobile?.paymentError?.notFound?.title || 'Ops!',
});

export { organizePaymentMethodError, organizeCheckBoxPayment };
