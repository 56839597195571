import { setTrackView, setTrackWithNewDatalayer, useTracking } from '@vfit/shared/data-access';
import { useQueryClient } from 'react-query';
import { setTrackLink } from '@vfit/shared/data-access';
import { getTagging, getUserInfo } from '../../checkout.utils';
import { withAnimationDelay } from '../../hoc/withAnimationDelay';

const TaggingComponent = () => {
  const client = useQueryClient();
  const taggingMobile = getTagging('personal data', 'mobile', client);

  const { trackView, trackWithNewDatalayer } = useTracking({
    event: ['checkout_step1'],
    event_label: 'personal information',
    opts: taggingMobile?.opts,
    cartProduct: taggingMobile?.trackingProduct,
    pageProduct: taggingMobile?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });
  setTrackLink(trackView, 'personal information');
  setTrackView(trackView);
  setTrackWithNewDatalayer(trackWithNewDatalayer);

  return null;
};

const TaggingNameFiscalCodeCard = withAnimationDelay()(TaggingComponent);

export default TaggingNameFiscalCodeCard;
