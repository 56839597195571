import { ButtonSlide, CheckboxMultiline, LinkWithIcon, LoadingSpinner } from '@vfit/shared/atoms';
import { useEffect, useState } from 'react';
import { useDeviceType } from '@vfit/shared/hooks';
import { EditNumber } from '@vfit/consumer/components';
import {
  useCmsConfig,
  IPersonalInfoCMS,
  checkIsApp,
  CONSUMER_CMS_SHOPPING_CART,
} from '@vfit/consumer/data-access';
import { API, trackLink } from '@vfit/shared/data-access';
import { IOptionalText } from '@vfit/shared/models';
import { VfModal } from '@vfit/shared/components';
import { IAddNumberProps, IPhoneNumber } from './addNumber.models';
import { ButtonDiv, CheckDiv, Container, LinkDiv, Subtitle, Title } from './addNumber.style';
import { organizeAddNumber } from './addNumber.utils';

const AddNumber = ({ onChangeNumber, data, selectedNumber, isLoading }: IAddNumberProps) => {
  const dataFromCms = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_PERSONAL_DATA
  ) as IPersonalInfoCMS;
  const { subtitle, title, ctaLable, buttonText } = organizeAddNumber(dataFromCms);
  const setModalHeight = (isTablet: boolean, isDesktop: boolean): number | undefined =>
    (isTablet && 563) || (isDesktop && 563) || undefined;

  const { isTablet, isDesktop } = useDeviceType();
  const [selIndex, setSelIndex] = useState<number>(-1);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [numbers, setNumbers] = useState<IOptionalText[]>([]);

  useEffect(() => {
    if (data) {
      const ns: IOptionalText[] = [];
      data.forEach((n, index) => {
        ns.push({
          title: `******${n.phoneNumber?.substring(6)}`,
          value: n.phoneNumber || '',
        });
        if (n.phoneNumber === selectedNumber) {
          setSelIndex(index);
        }
      });
      setNumbers(ns);
    }
  }, []);

  const onSelectItem = (index: number) => {
    setSelIndex(index);
  };

  const addNewNumber = (n: IPhoneNumber) => {
    onChangeNumber({ title: '', value: n.phoneNumber || '' });
  };

  const onSave = () => {
    trackLink(buttonText);
    onChangeNumber(numbers[selIndex]);
  };

  useEffect(() => {
    if (!isLoading) {
      setOpenModal(false);
    }
  }, [isLoading]);

  return (
    <>
      <Container>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
        <CheckDiv>
          <CheckboxMultiline
            items={numbers || []}
            onSelectItem={onSelectItem}
            selIndex={selIndex}
          />
        </CheckDiv>

        <LinkDiv>
          <LinkWithIcon onClick={() => setOpenModal(true)} text={ctaLable} />
        </LinkDiv>

        <ButtonDiv>
          <ButtonSlide
            label={buttonText}
            onClick={onSave}
            isApp={checkIsApp()}
            borderColor="#0d0d0d"
            hoverColor="#262626"
            hoverTextColor="#fff"
            clickColor="#7e7e7e"
            clickTextColor="#fff"
            disabled={isLoading || selIndex === -1}
            name="action_addNumber"
          />
        </ButtonDiv>
      </Container>

      <VfModal
        height={setModalHeight(isTablet, isDesktop)}
        isOpen={openModal}
        handleClose={() => setOpenModal(false)}
      >
        <>
          {isLoading && <LoadingSpinner />}
          <EditNumber onSubmit={addNewNumber} handleClose={() => setOpenModal(false)} newNumber />
        </>
      </VfModal>
    </>
  );
};

export default AddNumber;
