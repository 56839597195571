import { breakpoints, fonts, pxToCssFont } from '@vfit/shared/themes';
import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 0 20px;

  @media (min-width: ${breakpoints.tablet}) {
    padding: 0 40px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    padding: 0 54px;
  }
`;

export const Title = styled.div`
  padding: 32px 0 0;
  font-weight: 400;
  ${pxToCssFont(30, 38)}
  font-family: ${fonts.exbold};
  text-align: center;

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(42, 52)}
    padding: 60px 0 0;
  }

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(36, 45)}
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
`;

export const TextBold = styled.p`
  margin: 0;
  text-align: start;
  ${pxToCssFont(20, 26)};
  font-family: ${fonts.exbold};
`;

export const Text = styled.p`
  margin: 0;
  text-align: start;
  ${pxToCssFont(18, 24)};
  font-family: ${fonts.regular};
`;

export const List = styled.ul`
  margin-top: 8px;
  margin-bottom: 24px;
  padding: 0;

  p {
    margin: 0;
  }
`;

