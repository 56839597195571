import { useEffect, useState } from 'react';
import {
  CONSUMER_CMS_SHOPPING_CART,
  ErrorCodes,
  errorMock,
  IGenericErrorCMS,
  IPersonalInfoCmsMobile,
  IPortabilityMobileCMS,
  useCmsConfig,
} from '@vfit/consumer/data-access';
import { trackView } from '@vfit/shared/data-access';
import { API, getFromLocalStorageByKey, openPopup } from '@vfit/shared/data-access';
import { ICommonData, IOptionalText } from '@vfit/shared/models';
import { CheckboxCards } from '@vfit/consumer/components';
import { organizeSimTypeCard } from './simTypeSelectionCardWinback.utils';
import { useCheckout } from '../../../iBuyMobile.context';
import { ISimTypeCardData } from './simTypeSelectionCardWinback.models';
import { useReserveFlow } from '../../hooks/UseReserveFlow/useReserveFlow';
import TaggingSimTypeSelectionCardWinback from './simTypeSelectionCardWinback.tagging';

const SimTypeSelectionCardWinback = ({ handleOnChangeEnableGoNext }: ICommonData) => {
  const {
    simOptions,
    portability,
    portabilityStatus,
    isModalButtonSticky,
    product,
    checkoutErrors,
    ICCID,
    setSimOptions,
    setSlidesGoBack,
    setSimTypeSelectedOption,
    setPortabilityStatus,
  } = useCheckout();
  const [isEnabledFLowReserve, setIsEnabledFlowReserve] = useState(false);
  const releaseResource = getFromLocalStorageByKey('releaseResource');
  const genericError = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_GENERIC_ERROR_MOBILE
  ) as IGenericErrorCMS;
  const dataFromCms = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_PERSONAL_DATA_MOBILE
  ) as IPersonalInfoCmsMobile;
  const errorPortability = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_PORTABILITY_MOBILE
  ) as IPortabilityMobileCMS;
  const organizedDataForSim = organizeSimTypeCard(
    dataFromCms,
    errorPortability
  ) as ISimTypeCardData;

  const trackOpenModal = () => {
    trackView({
      event_name: 'ui_interaction',
      event_label_track: `e-sim option ${organizedDataForSim?.modalInfo?.textLink}`,
      event_category: 'users',
      event_action: 'click',
      tracking_type: 'link',
    });
  };

  useReserveFlow({ flowEnabler: isEnabledFLowReserve });

  const trackGoNext = () => {
    trackView({
      event_name: 'ui_interaction',
      event_label_track: `e-sim option ${
        simOptions?.simOptions?.find((simOption) => simOption?.selection)?.value || ''
      }`,
      event_category: 'users',
      event_action: 'click',
      tracking_type: 'link',
    });
  };

  const handleSelect = (item: IOptionalText | undefined) => {
    if (!item) return;
    const modifyMobileSimDeviceId = getFromLocalStorageByKey('modifyMobileSimDeviceId');
    const selectedOption = simOptions?.simOptions?.find((el) => el.value === item.value);
    if (
      selectedOption &&
      selectedOption?.id !== modifyMobileSimDeviceId?.simOptionId &&
      !portabilityStatus?.isLoadingCheck
    ) {
      setSimTypeSelectedOption(selectedOption);
    }
    setSimOptions({
      ...simOptions,
      simOptions: simOptions?.simOptions?.map((sO) => {
        if (sO.value === item?.value) {
          return {
            ...sO,
            selection: true,
          };
        }
        return {
          ...sO,
          selection: false,
        };
      }),
    });
  };

  const getSelectedItem = () => {
    const simOption = simOptions?.simOptions?.find((sOption) => sOption.selection);
    return {
      title: simOption?.title || '',
      value: simOption?.value || '',
    };
  };

  useEffect(() => {
    handleOnChangeEnableGoNext?.(!portabilityStatus?.isLoadingCheck);
  }, [portabilityStatus?.isLoadingCheck]);

  useEffect(() => {
    const goNextButton = document.getElementById('sticky-offer-next');
    goNextButton?.addEventListener('click', trackGoNext);
    return () => {
      goNextButton?.removeEventListener('click', trackGoNext);
    };
  }, [simOptions]);

  useEffect(() => {
    if (isEnabledFLowReserve) setIsEnabledFlowReserve(false);
  }, [isEnabledFLowReserve]);

  useEffect(() => {
    if (portabilityStatus?.isErrorCheck) {
      const goBackMigration = () => {
        setSlidesGoBack(3);
      };
      const error = errorMock(
        'checkPortInFeasibilityII',
        {
          status: '200',
          url: API.CHECK_IN_PORT,
          statusText: 'failure',
        },
        'failure',
        portabilityStatus?.errorMessage,
        'check portin failure winback',
        false,
        {
          body: {
            currentPlanType: portability?.portabilityMigration?.currentPlan || '',
            currentServiceProviderId: portability?.portabilityMigration?.currentProvider || '',
            currentServiceProviderIdName: portability?.portabilityMigration?.currentProvider || '',
            msisdn: portability.portabilityNumber || '',
            isSimAvailable: ICCID && ICCID?.trim() !== '' ? 'true' : 'false',
            transferRemainingBalance: String(portability?.portabilityMigration?.transferBalance),
            simCardNumber: ICCID?.trim() || '',
          },
          response: {
            ...portabilityStatus?.portInData,
          },
        }
      );
      switch (portabilityStatus?.errorMessage) {
        case ErrorCodes.NUMBER_ERROR:
          checkoutErrors?.showAndTrackError?.(
            genericError,
            goBackMigration,
            error,
            organizedDataForSim?.numberError?.title,
            organizedDataForSim?.numberError?.description,
            goBackMigration,
            organizedDataForSim?.numberError?.buttonLabel,
            () => openPopup(product?.genericErrorCallMeNow?.url || '', goBackMigration),
            product?.removeCTC ? undefined : product?.genericErrorCallMeNow?.label,
            isModalButtonSticky
          );
          break;
        case ErrorCodes.MIGRATION_CODE_ERROR:
          checkoutErrors?.showAndTrackError?.(
            genericError,
            goBackMigration,
            error,
            organizedDataForSim?.migrationCodeError?.title,
            organizedDataForSim?.migrationCodeError?.description,
            goBackMigration,
            organizedDataForSim?.migrationCodeError?.buttonLabel,
            () => openPopup(product?.genericErrorCallMeNow?.url || '', goBackMigration),
            product?.removeCTC ? undefined : product?.genericErrorCallMeNow?.label,
            isModalButtonSticky
          );
          break;
        default:
          checkoutErrors?.showAndTrackError?.(
            genericError,
            goBackMigration,
            error,
            organizedDataForSim?.genericsError?.title,
            organizedDataForSim?.genericsError?.description,
            goBackMigration,
            organizedDataForSim?.genericsError?.buttonLabel,
            () => openPopup(product?.genericErrorCallMeNow?.url || '', goBackMigration),
            product?.removeCTC ? undefined : product?.genericErrorCallMeNow?.label,
            isModalButtonSticky
          );
          break;
      }
    }
  }, [portabilityStatus?.isErrorCheck]);

  useEffect(() => {
    if (portability?.portabilityWant) {
      setPortabilityStatus({
        ...portabilityStatus,
        isStartPortIn: true,
      });
    }
  }, [portability]);

  useEffect(() => {
    const haveSelectedItem = simOptions.simOptions.find((simOption) => simOption.selection);
    handleOnChangeEnableGoNext?.(!!haveSelectedItem);
    if (!releaseResource) {
      setIsEnabledFlowReserve(true);
    }
  }, []);

  return (
    <>
      <TaggingSimTypeSelectionCardWinback />
      <CheckboxCards
        title={organizedDataForSim.cardTitle}
        description={organizedDataForSim.cardDescription}
        items={simOptions?.simOptions?.map((sO) => ({
          title: sO.title || '',
          value: sO.value || '',
          extra: sO.highlight
            ? {
              text: organizedDataForSim.bestChoiceLabel || '',
              color: '#fff',
              extraClass: 'extraClass',
            }
            : undefined,
        }))}
        value={getSelectedItem().value}
        selectedItem={getSelectedItem()}
        setSelectedItem={() => {
          return;
        }}
        onSelectItem={handleSelect}
        bottomModal={organizedDataForSim.modalInfo}
        isSuccess={!portabilityStatus?.isLoadingCheck}
        onOpeneModal={trackOpenModal}
      />
    </>
  );
};

export default SimTypeSelectionCardWinback;
