import { useTracking } from '@vfit/shared/data-access';
import { setTrackLink } from '@vfit/shared/data-access';
import { useQueryClient } from 'react-query';
import { getUserInfo, getTagging } from '../../../../checkout.utils';

export const useVoucherInsertionCardTagging = () => {
  const queryClient = useQueryClient();
  const tagging = getTagging('voucher input', 'fixed', queryClient);
  const { trackView } = useTracking({
    event: ['checkout_step8.2'],
    event_label: 'insert voucher',
    opts: tagging?.opts,
    cartProduct: tagging?.trackingProduct,
    pageProduct: tagging?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });
  setTrackLink(trackView, 'voucher input');

  return null;
};
