import { IPersonalInfoCMS } from '@vfit/consumer/data-access';
import { IConfigDocumentCms } from '@vfit/shared/models';
import { ISkeleton } from '@vfit/shared/components';

export const DEFAULT_CUSTOMER_REFERENCE = {
  nameTitle: [
    {
      rel: 'self',
      href: '/care/v1/nameTitle',
      id: 'EMPTY',
      name: 'EMPTY',
      displayName: ' ',
      default: true,
    },
    {
      rel: 'self',
      href: '/care/v1/nameTitle',
      id: 'Mr.',
      name: 'Mr.',
      displayName: 'Sig.',
      default: false,
    },
    {
      rel: 'self',
      href: '/care/v1/nameTitle',
      id: 'Mrs.',
      name: 'Mrs.',
      displayName: 'Sig.ra',
      default: false,
    },
    {
      rel: 'self',
      href: '/care/v1/nameTitle',
      id: 'Ms.',
      name: 'Ms.',
      displayName: 'Sig.na',
      default: false,
    },
    {
      rel: 'self',
      href: '/care/v1/nameTitle',
      id: 'Dr.',
      name: 'Dr.',
      displayName: 'Dr.',
      default: false,
    },
  ],
  identificationType: [
    {
      rel: 'self',
      href: '/care/v1/identificationType',
      id: '1',
      name: '1',
      displayName: "Carta d'identità",
      default: false,
    },
    {
      rel: 'self',
      href: '/care/v1/identificationType',
      id: '2',
      name: '2',
      displayName: 'Passaporto',
      default: false,
    },
    {
      rel: 'self',
      href: '/care/v1/identificationType',
      id: '3',
      name: '3',
      displayName: 'Patente di guida',
      default: false,
    },
  ],
};

export const organizeDocument = (
  documentIdCardCMS: IPersonalInfoCMS,
  isRecognized: boolean
): IConfigDocumentCms => ({
  title: isRecognized
    ? documentIdCardCMS?.personalinfo?.preFilledDocument?.title || 'Documento'
    : documentIdCardCMS?.personalinfo?.newDocument?.title || 'Documento',
  subtitle: isRecognized
    ? documentIdCardCMS?.personalinfo?.preFilledDocument?.description ||
      "Conferma o modifica i dati del tuo documento. Per verificare la tua identità, dovrai mostrare lo stesso documento durante la consegna o l'attivazione online della tua nuova SIM."
    : documentIdCardCMS?.personalinfo?.newDocument?.description ||
      "Inserisci i dati di un tuo documento. Per verificare la tua identità, dovrai mostrare lo stesso documento durante la consegna o l'attivazione online della tua nuova SIM.",
  docLable: documentIdCardCMS?.personalinfo?.selectInput?.docType || '',
  docNumberLable: documentIdCardCMS?.personalinfo?.selectInput?.docNumber || '',
  docExpirationDateLable: documentIdCardCMS?.personalinfo?.selectInput?.docExpirationDate || '',
  countryLable: documentIdCardCMS?.personalinfo?.selectInput?.nation || '',
  errorMessage: isRecognized
    ? documentIdCardCMS?.personalinfo?.preFilledDocument?.allFieldsAreRequired ||
      'Tutti i campi sono obbligatori'
    : documentIdCardCMS?.personalinfo?.newDocument?.allFieldsAreRequired ||
      'Tutti i campi sono obbligatori',
});

export const SKELETON_SHAPE: ISkeleton = {
  margins: [60, 30],
  tabletMargins: [32, 15],
  groups: [
    { heights: 45, marginBottom: 15 },
    { heights: 60, marginBottom: 32 },
    { heights: 60, marginBottom: 15 },
    { heights: 75, repeat: 4, gap: 15 },
    { heights: 45, marginBottom: 15 },
  ],
};
