import { useEffect, useState } from 'react';
import {
  CONSUMER_CMS_SHOPPING_CART,
  IPersonalInfoCmsMobile,
  useCmsConfig,
} from '@vfit/consumer/data-access';
import { API } from '@vfit/shared/data-access';
import { useTracking } from '@vfit/shared/data-access';
import { IOptionalText } from '@vfit/shared/models';
import { CheckboxCards } from '@vfit/consumer/components';
import { organizeRecognitionSimMethodSelection } from './recognitionSimMethodSelection.utils';
import { getUserInfo, getTagging, isEsim } from '../../../../checkout.utils';
import { IRecognitionSimMethodSelection } from './recognitionSimMethodSelection.models';
import { useCheckout } from '../../../../../iBuyMobile.context';
import { useQueryClient } from 'react-query';

const RecognitionSimMethodSelection = ({
  isMNP,
  price,
  defaultItem,
  isLoading,
  handleSelect: onSelect,
}: IRecognitionSimMethodSelection) => {
  const queryClient = useQueryClient();
  const { simOptions } = useCheckout();
  const data = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_PERSONAL_DATA_MOBILE
  ) as IPersonalInfoCmsMobile;

  const selectedSimOption = simOptions.simOptions.find((simOption) => simOption.selection);
  const isEsimValue = isEsim(selectedSimOption) || false;

  const dataRecognitionSimMethodSelectionCard = organizeRecognitionSimMethodSelection(
    data,
    isMNP,
    isEsimValue,
    price
  );
  const getDefaultItem = (): IOptionalText | undefined => {
    const preflaggedItem = dataRecognitionSimMethodSelectionCard?.items.find(
      (item) => item.isPreflagged === 'true'
    );
    if (!defaultItem) return undefined;
    if (Object.keys(preflaggedItem || {}).length > 0)
      return dataRecognitionSimMethodSelectionCard?.items?.find(
        (item) => item.isPreflagged === 'true'
      );
    return dataRecognitionSimMethodSelectionCard?.items?.find(
      (el) => el.value === defaultItem.code
    );
  };
  const [selectedItem, setSelectedItem] = useState<IOptionalText | undefined>(getDefaultItem());
  const taggingMobile = getTagging(isMNP ? 'mnp' : 'new sim', 'mobile', queryClient);

  useTracking({
    event: ['checkout_step4.7'],
    event_label: 'VR: method selection',
    opts: { ...taggingMobile?.opts, page_subsection: isEsimValue ? 'e-sim' : 'sim fisica' },
    cartProduct: taggingMobile?.trackingProduct,
    pageProduct: taggingMobile?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });

  const handleSelect = (item: IOptionalText | undefined) => {
    onSelect(item);
  };

  useEffect(() => {
    if (selectedItem) handleSelect(selectedItem);
  }, []);

  return (
    <CheckboxCards
      isSuccess={!isLoading}
      title={dataRecognitionSimMethodSelectionCard?.title}
      description={dataRecognitionSimMethodSelectionCard?.description}
      items={dataRecognitionSimMethodSelectionCard?.items}
      value={selectedItem?.value}
      selectedItem={selectedItem}
      setSelectedItem={setSelectedItem}
      onSelectItem={handleSelect}
    />
  );
};

export default RecognitionSimMethodSelection;
