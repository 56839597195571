import { useEffect, useState } from 'react';
import { AddNumber, EditField } from '@vfit/consumer/components';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoggerInstance } from '@vfit/shared/data-access';
import {
  CONSUMER_CMS_SHOPPING_CART,
  IContactMedium,
  useCmsConfig,
  usePatchCustomer,
  IPersonalInfoCMS,
  IGenericErrorCMS,
  errorMock,
  ErrorCodes,
} from '@vfit/consumer/data-access';
import { API, openPopup } from '@vfit/shared/data-access';
import { ICMSApiError, IOptionalText } from '@vfit/shared/models';
import { LoadingSpinner, LinkWithIcon } from '@vfit/shared/atoms';
import { Form, VfModal } from '@vfit/shared/components';
import { contactSchema } from './contact.validation';
import {
  IContact,
  IContactData,
  IContactType,
  IModalConfig,
  IOperationType,
} from './contact.models';
import {
  Checkbox,
  CheckboxDiv,
  Div,
  Field,
  Footer,
  FooterDiv,
  ReadOnlyDiv,
  Text,
  Title,
  Wrapper,
} from './contact.style';
import { CMS_LABELS, handleCRMCodes, handleErrorCustomer, organizeContact } from './contact.utils';
import { useCheckout } from '../../../../../iBuyFixed.context';
import { retrieveProduct } from '../../../../../iBuyFixed.utils';
import { updateContactDataLocal } from '../../../ContactCard/contactCard.utils';

const Contact = ({
  data,
  onChangeData,
  checkInfo,
  onChangeCheck,
  isReadOnly,
  setIsValidForm,
}: IContactData) => {
  const { owningIndividual } = data;
  const ContactCMS = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_PERSONAL_DATA
  ) as IPersonalInfoCMS;
  const { checkoutErrors, isModalButtonSticky, findCaller, setOwningData } = useCheckout();
  const {
    isError: isErrorPatchCustomer,
    error: errorPatchCustomer,
    mutateAsync,
  } = usePatchCustomer();

  const [modalConfig, setModalConfig] = useState<IModalConfig>({
    isOpen: false,
    type: IContactType.EMAIL,
    operationType: IOperationType.EDIT,
  });

  const [indexs, setIndexs] = useState<{ email: number; phone: number }>({
    email: -2,
    phone: -2,
  });

  const [loading, setLoading] = useState<boolean>(false);

  const {
    register,
    getValues,
    setValue,
    formState: { isValid, errors },
  } = useForm<IContact>({
    resolver: yupResolver(contactSchema),
    mode: 'onChange',
  });

  const createField = (type: IContactType, value: string, preferred: boolean) => {
    if (!owningIndividual.contactMedium) return;
    owningIndividual?.contactMedium.push(
      type === IContactType.EMAIL
        ? {
            type,
            preferred,
            emailAddress: value,
          }
        : {
            type,
            preferred,
            phoneNumber: value,
          }
    );
    const contactMediumLength: number = (owningIndividual?.contactMedium?.length ?? 0) - 1;
    if (type === IContactType.EMAIL) indexs.email = contactMediumLength;
    else indexs.phone = contactMediumLength;
    setIndexs(indexs);
    setOwningData({
      ...data,
      owningIndividual,
    });
  };

  const findIndex = (type: IContactType) => {
    let index = -2;
    if (owningIndividual?.contactMedium) {
      index = owningIndividual?.contactMedium.findIndex((e) => e.type === type && e.preferred);
      if (index === -1) {
        index = owningIndividual?.contactMedium.findIndex((e) => e.type === type);
        if (index === -1) {
          createField(type, '', true);
        }
      }
    }
    return index;
  };

  const setShowValues = () => {
    const shouldValidate = (item: string): boolean => item !== '';
    const email = findIndex(IContactType.EMAIL);
    const phone = findIndex(IContactType.MSISDN);
    const emailAddress = owningIndividual?.contactMedium?.[email]?.emailAddress || '';
    const phoneNumber = owningIndividual?.contactMedium?.[phone]?.phoneNumber || '';
    if (email !== -1) {
      indexs.email = email;
      setValue('emailAddress', emailAddress, {
        shouldValidate: shouldValidate(emailAddress),
      });
    }
    if (phone !== -1) {
      indexs.phone = phone;
      setValue('phoneNumber', phoneNumber, {
        shouldValidate: shouldValidate(phoneNumber),
      });
    }
    setIndexs(indexs);
  };

  useEffect(() => {
    if (data.isLogged || data.isValid) {
      // const checkIsValid = () => {
      //   const { owningIndividual: owningIndividualLocal } = data;
      //   const phone = owningIndividualLocal?.contactMedium?.find(
      //     (contact) => contact.type === IContactType.MSISDN
      //   );
      //   const email = owningIndividualLocal?.contactMedium?.find(
      //     (contact) => contact.type === IContactType.EMAIL
      //   );
      //   return !!(
      //     phone?.phoneNumber &&
      //     email?.emailAddress &&
      //     phone.phoneNumber.substring(0, 1) === '3'
      //   );
      // };
      // setTimeout(() => {
      //   console.log('UMBERTO1', data, owningIndividual);
      //   if (onChangeData)
      //     onChangeData(
      //       {
      //         ...data,
      //         owningIndividual,
      //       },
      //       checkIsValid()
      //     );
      // }, 250);
    }
  }, []);

  useEffect(() => {
    setShowValues();
    if (onChangeData)
      onChangeData(
        {
          ...data,
          owningIndividual,
        },
        isValid
      );
  }, [isValid, owningIndividual]);

  const genericError = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_GENERIC_ERROR
  ) as IGenericErrorCMS;

  const { errorcomponent: customerErrors } =
    (useCmsConfig(CONSUMER_CMS_SHOPPING_CART, API.CMS_GET_CUSTOMER_ERROR_FIXED) as ICMSApiError) ||
    {};

  const product = retrieveProduct();

  useEffect(() => {
    if (isErrorPatchCustomer && modalConfig.operationType !== IOperationType.ERROR) {
      const backendError = errorPatchCustomer.headers?.get('ERR-backendErrorCode') || '';
      const errorCode = handleCRMCodes(backendError);
      const errorDetails = handleErrorCustomer(
        errorCode,
        customerErrors?.errors || [],
        genericError
      );
      checkoutErrors?.showAndTrackError?.(
        genericError,
        () => {},
        errorMock('patchCustomer', errorPatchCustomer, ErrorCodes.PATCH_CUSTOMER),
        errorDetails?.title,
        errorDetails?.message,
        () => {},
        errorDetails?.actionText || '',
        () => openPopup(product?.genericErrorCallMeNow?.url || ''),
        product?.genericErrorCallMeNow?.label || '',
        isModalButtonSticky,
        true
      );
    }
  }, [isErrorPatchCustomer]);

  useEffect(() => {
    setIsValidForm?.(isValid);
  }, [isValid]);

  const { footerInfo, subtitle, title, telephoneLable, emailLable } = organizeContact(ContactCMS);

  const onCheckClickHandler = () => {
    onChangeCheck({ checkContactProblem: !checkInfo?.checkContactProblem });
  };

  const resetModal = () => {
    setModalConfig({
      isOpen: false,
      operationType: IOperationType.EDIT,
      type: IContactType.EMAIL,
    });
  };

  const onChangeNumber = (value: IOptionalText) => {
    setLoading(true);
    const payload = [
      { updatedValue: value.value as string, type: 'number', operation: 'replace', index: 1 },
    ];
    mutateAsync(payload)
      .then(() => {
        if (owningIndividual?.contactMedium) {
          owningIndividual?.contactMedium.forEach((p, index) => {
            if (p.type === IContactType.MSISDN && owningIndividual?.contactMedium?.[index]) {
              owningIndividual.contactMedium[index].phoneNumber = value.value;
            }
          });
        }
        const newOwningIndividual = {
          ...data,
          owningIndividual,
        };
        setOwningData({
          ...newOwningIndividual,
        });
        updateContactDataLocal({
          ...newOwningIndividual,
        });
        setLoading(false);
        resetModal();
      })
      .catch((e) => {
        setLoading(false);
        LoggerInstance.debug(e);
      });
  };

  const modifyField = (value: { email?: string; phoneNumber?: string }) => {
    if (value.phoneNumber) {
      onChangeNumber({ title: '', value: value.phoneNumber });
    } else {
      setLoading(true);
      const payload = [
        { updatedValue: value.email as string, type: 'email', operation: 'replace', index: 0 },
      ];
      mutateAsync(payload)
        .then(() => {
          if (owningIndividual?.contactMedium) {
            owningIndividual.contactMedium[indexs.email].emailAddress = value.email;
          }
          const newOwningIndividual = {
            ...data,
            owningIndividual,
          };
          setOwningData({
            ...newOwningIndividual,
          });
          updateContactDataLocal({
            ...newOwningIndividual,
          });
          setLoading(false);
          resetModal();
        })
        .catch((e) => {
          setLoading(false);
          LoggerInstance.debug(e);
        });
    }
  };

  const getAllNumbers = (): IContactMedium[] | undefined =>
    findCaller.subscription
      ?.filter((sub) => sub.serviceType === 'M')
      .map((sub) => ({
        type: 'phone',
        preferred: false,
        phoneNumber: sub.msisdn,
      }));

  const isAddModal = (): boolean => {
    if (!getAllNumbers() || (getAllNumbers() && getAllNumbers()?.length === 0)) {
      return false;
    }
    return true;
  };

  const openModal = (typeContact: IContactType, operationType: IOperationType) => {
    setModalConfig({
      isOpen: true,
      type: typeContact,
      operationType,
    });
  };

  // region Render Already exists / not exists customer
  const alreadyExistsCustomer = () => (
    <ReadOnlyDiv>
      <Field>
        {CMS_LABELS.roTelephoneLable}
        <p>
          {owningIndividual?.contactMedium && indexs.phone !== -2
            ? owningIndividual?.contactMedium[indexs.phone]?.phoneNumber
            : ''}
        </p>
        <LinkWithIcon
          onClick={() =>
            openModal(IContactType.MSISDN, isAddModal() ? IOperationType.ADD : IOperationType.EDIT)
          }
          text={CMS_LABELS.roCtaTelephone}
        />
      </Field>
      <Field>
        {CMS_LABELS.roEmailLable}
        <p>
          {owningIndividual?.contactMedium && indexs.email !== -2
            ? owningIndividual?.contactMedium[indexs.email]?.emailAddress
            : ''}
        </p>
        <LinkWithIcon
          onClick={() => openModal(IContactType.EMAIL, IOperationType.EDIT)}
          text={CMS_LABELS.roCtaEmail}
        />
      </Field>
    </ReadOnlyDiv>
  );

  const changeData = () => {
    const inputs = getValues();
    if (owningIndividual?.contactMedium) {
      owningIndividual.contactMedium[indexs.email].emailAddress = inputs.emailAddress;
      owningIndividual.contactMedium[indexs.phone].phoneNumber = inputs.phoneNumber;
    }
    if (onChangeData)
      onChangeData(
        {
          ...data,
          owningIndividual,
        },
        isValid
      );
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === ' ') {
      e.preventDefault();
    }
  };

  const notExistsCustomer = () => (
    <Div>
      <div className="textInput">
        <Form.TextInput
          label={telephoneLable}
          error={errors.phoneNumber?.message}
          autoComplete="off"
          placeholder=" "
          {...register('phoneNumber', {
            onChange: changeData,
          })}
        />
      </div>

      <div className="textInput">
        <Form.TextInput
          label={emailLable}
          type="email"
          error={errors.emailAddress?.message}
          autoComplete="off"
          placeholder=" "
          onKeyDown={(e) => handleKeyDown(e as unknown as KeyboardEvent)}
          {...register('emailAddress', {
            onChange: (e) => {
              setValue('emailAddress', e.target.value.replace(/\s+/g, ''));
              changeData?.();
            },
          })}
        />
      </div>
    </Div>
  );
  // endregion

  return (
    <Wrapper>
      <Title>{title}</Title>
      <Text>{isReadOnly ? CMS_LABELS.roSubtitle : subtitle}</Text>

      {isReadOnly ? alreadyExistsCustomer() : notExistsCustomer()}

      <Footer>
        <CheckboxDiv>
          <Checkbox>
            <input
              type="checkbox"
              checked={checkInfo.checkContactProblem}
              onChange={onCheckClickHandler}
            />
            <span className="checkmark" />
          </Checkbox>
        </CheckboxDiv>
        <FooterDiv>{footerInfo}</FooterDiv>
      </Footer>

      <VfModal
        isOpen={modalConfig.isOpen && modalConfig.operationType === IOperationType.ADD}
        handleClose={resetModal}
      >
        <>
          {loading && <LoadingSpinner />}
          <AddNumber
            onChangeNumber={onChangeNumber}
            isLoading={loading}
            data={getAllNumbers()}
            selectedNumber={owningIndividual?.contactMedium?.[1].phoneNumber || ''}
          />
        </>
      </VfModal>

      <VfModal
        isOpen={modalConfig.isOpen && modalConfig.operationType === IOperationType.EDIT}
        handleClose={resetModal}
      >
        <>
          {loading && <LoadingSpinner />}
          <EditField
            isLoading={loading}
            onSubmit={modifyField}
            handleClose={resetModal}
            isPhone={modalConfig.type === IContactType.MSISDN}
          />
        </>
      </VfModal>
    </Wrapper>
  );
};

export default Contact;
