import { setTrackView, useTracking } from '@vfit/shared/data-access';
import { useQueryClient } from 'react-query';
import { setTrackLink } from '@vfit/shared/data-access';
import { getTagging, getUserInfo } from '../../checkout.utils';
import { withAnimationDelay } from '../../hoc/withAnimationDelay';

const TaggingComponent = () => {
  const client = useQueryClient();
  const taggingMobile = getTagging('e-sim option', 'mobile', client);
  const { trackView } = useTracking({
    event: ['checkout_step4.7'],
    event_label: 'e-sim option',
    opts: taggingMobile?.opts,
    cartProduct: taggingMobile?.trackingProduct,
    pageProduct: taggingMobile?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });
  setTrackLink(trackView, 'e-sim option');
  setTrackView(trackView);

  return null;
};

const TaggingSimTypeSelectionCardMnpSkip = withAnimationDelay()(TaggingComponent);

export default TaggingSimTypeSelectionCardMnpSkip;
