import {
  CONSUMER_CMS_SHOPPING_CART,
  IPersonalInfoCmsMobile,
  useCmsConfig,
} from '@vfit/consumer/data-access';
import { API } from '@vfit/shared/data-access';
import { useTracking } from '@vfit/shared/data-access';
import { ListCard } from '@vfit/consumer/components';
import { organizeRecognitionSimCourier } from './recognitionSimCourier.utils';
import { IRecognitionSimCourier } from './recognitionSimCourier.models';
import { getUserInfo, getTagging, isEsim } from '../../../../checkout.utils';
import { useCheckout } from '../../../../../iBuyMobile.context';
import { useQueryClient } from 'react-query';

const RecognitionSimCourier = ({ isMNP, isAutoWallet, price }: IRecognitionSimCourier) => {
  const queryClient = useQueryClient();
  const { simOptions } = useCheckout();

  const selectedSimOption = simOptions.simOptions.find((simOption) => simOption.selection);
  const isEsimValue = isEsim(selectedSimOption) || false;

  const dataFromCms = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_PERSONAL_DATA_MOBILE
  ) as IPersonalInfoCmsMobile;

  const taggingMobile = getTagging(isMNP ? 'mnp' : 'new sim', 'mobile', queryClient);

  useTracking({
    event: ['checkout_step4.8'],
    event_label: 'VR: Corriere',
    opts: { ...taggingMobile?.opts, page_section: isEsimValue ? 'e-sim' : 'sim fisica' },
    cartProduct: taggingMobile?.trackingProduct,
    pageProduct: taggingMobile?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });

  const simCourierCardData = organizeRecognitionSimCourier(
    dataFromCms,
    isEsimValue,
    price || '',
    isMNP,
    isAutoWallet
  );
  const list = {
    title: simCourierCardData?.list?.title,
    items: isMNP ? simCourierCardData?.list?.portabilityItems : simCourierCardData.list.items,
  };

  return <ListCard data={simCourierCardData?.data} list={list} />;
};

export default RecognitionSimCourier;
