import { ICoverageToolCongifg, getCompleteAddressValidation } from '@vfit/consumer/data-access';
import * as yup from 'yup';

export const contactSchema = (coverageToolConfig: ICoverageToolCongifg) => {
  const yupContactSchema = yup.object({
    phoneNumber: yup
      .string()
      .required(coverageToolConfig?.coveragetool?.validationMessage?.phoneNumber?.required || '')
      .min(9, coverageToolConfig?.coveragetool?.validationMessage?.phoneNumber?.maximum || '')
      .max(10, coverageToolConfig?.coveragetool?.validationMessage?.phoneNumber?.minimum || '')
      .matches(/^(3)+[0-9]*$/, coverageToolConfig?.coveragetool?.validationMessage?.phoneNumber?.matches || ''),
    emailAddress: yup
      .string()
      .required(coverageToolConfig?.coveragetool?.validationMessage?.emailAddress?.required || '')
      .email(coverageToolConfig?.coveragetool?.validationMessage?.emailAddress?.email || ''),
  });

  const schema = getCompleteAddressValidation(coverageToolConfig);

  return yupContactSchema.concat(schema);
};
