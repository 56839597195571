import { Children, useEffect, useState } from 'react';
import { Check } from '@vfit/shared-icons';
import {
  CONSUMER_CMS_SHOPPING_CART,
  downloadFileFunction,
  useCmsConfig,
  ITermsAndCondition,
} from '@vfit/consumer/data-access';
import { API, trackLink } from '@vfit/shared/data-access';
import { useDeviceType } from '@vfit/shared/hooks';
import { LoadingSpinner } from '@vfit/shared/atoms';
import { GenericDetail, VfModal } from '@vfit/shared/components';
import { ModalWithAccordion } from '@vfit/consumer/components';
import { ITermsProps } from './termsAndConditions.models';
import {
  Container,
  Title,
  Description,
  CheckDiv,
  CheckboxDiv,
  Checkbox,
  DocCheck,
  DocsDiv,
  Divider,
  SubTitle,
  SubDescription,
  Button,
  LoaderIcon,
} from './termsAndConditions.style';
import { organizeModalData, organizeTermsAndCondition } from '../../termsCard.utils';
import { IDocuments, ITermsConditions } from '../../termsCard.models';

const TermsAndConditions = ({
  setNextAble,
  isLoading,
  value,
  pdfContractSummary,
  onChangePopupWithdrawal,
}: ITermsProps) => {
  const termsAndConditionCMS = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_TERMS_AND_CONDITIONS
  ) as ITermsAndCondition;

  const textContractualSummary = 'Sintesi';
  const callResult: ITermsConditions = organizeTermsAndCondition(termsAndConditionCMS);
  const modal = organizeModalData(termsAndConditionCMS);

  const { isDesktop, isTablet } = useDeviceType();
  const setModalHeight = () => (isTablet && 331) || (isDesktop && 370) || undefined;
  const setModalHeightWithAccordion = () => (isTablet && 749) || (isDesktop && 684) || undefined;

  const activationSetting = value?.optionToReconsider === 'no' ? 'yes' : 'no';
  const [activation, setActivation] = useState<string>(activationSetting);
  const [docCheck, setDocCheck] = useState<boolean>(value?.consensus || false);
  const [showActivationModal, setShowActivationModal] = useState(false);
  const [modalConfig, setModalConfig] = useState<{
    isOpen: boolean;
    text: string;
    content: string;
    pdfUrl: string;
    pdfLabel: string;
  }>({
    isOpen: false,
    text: '',
    content: '',
    pdfUrl: '',
    pdfLabel: '',
  });

  const handleClick = (doc?: IDocuments, isActivation?: boolean) => {
    if (isActivation) {
      setShowActivationModal(true);
      const labelToTrack = 'Immediate activation request details';
      trackLink(labelToTrack);
    } else if (doc) {
      trackLink(doc?.text || '');
      setModalConfig({
        isOpen: true,
        text: doc.text,
        content: doc.content,
        pdfUrl: doc.pdfUrl || '',
        pdfLabel: doc.pdfLabel || '',
      });
    }
  };

  const handleCloseModal = (isActivation?: boolean) => {
    if (isActivation) {
      setShowActivationModal(false);
    } else {
      setModalConfig({
        isOpen: false,
        text: '',
        content: '',
        pdfUrl: '',
        pdfLabel: '',
      });
    }
  };

  const handleCheckBox = () => {
    setDocCheck(!docCheck);

    let onOff = 'on';
    if (docCheck) onOff = 'off';
    const labelToTrack = `accepted terms checkbox ${onOff}`;
    trackLink?.(labelToTrack);
  };

  useEffect(() => {
    if (setNextAble) setNextAble(activation, docCheck);
    if (activation === 'no' && docCheck) {
      onChangePopupWithdrawal?.(true);
    }
  }, [activation, docCheck]);

  useEffect(() => {
    onChangePopupWithdrawal?.(false);
  }, []);

  return (
    <>
      <Container>
        <Title>{callResult.title}</Title>
        <Description>{callResult.description}</Description>
        {Children.toArray(
          callResult.documents?.map((doc: IDocuments) => {
            let document = doc;
            if (
              doc?.isSummary ||
              doc?.text?.trim()?.toLowerCase().includes(textContractualSummary.trim().toLowerCase())
            ) {
              document = {
                ...doc,
                pdfUrl: pdfContractSummary?.pdfUrl || doc.pdfUrl,
                pdfLabel: pdfContractSummary?.pdfLabel || doc.pdfLabel || '',
              };
            }
            return (
              <CheckDiv>
                <div className="check-size">
                  <Check />
                </div>
                <button type="button" disabled={isLoading} onClick={() => handleClick(document)}>
                  {doc.text}
                </button>
              </CheckDiv>
            );
          })
        )}
        <DocsDiv>
          <CheckboxDiv>
            <Checkbox>
              <input
                type="checkbox"
                disabled={isLoading}
                checked={docCheck}
                onClick={handleCheckBox}
                onChange={() => {
                  return;
                }}
              />
              <span className="checkmark" />
            </Checkbox>
          </CheckboxDiv>
          <DocCheck>{callResult.documentsCheck}</DocCheck>
        </DocsDiv>

        <Divider />

        <SubTitle>
          {callResult.subtitle}
          {isLoading && (
            <LoaderIcon>
              <LoadingSpinner />
            </LoaderIcon>
          )}
        </SubTitle>
        <Button type="button" onClick={() => handleClick(undefined, true)}>
          {callResult.details.text}
        </Button>
        <SubDescription>{callResult.subDescription}</SubDescription>
        <DocsDiv>
          <CheckboxDiv>
            <Checkbox>
              <input
                type="checkbox"
                disabled={isLoading}
                checked={activation === 'yes'} // activation !== undefined
                onClick={() => setActivation(activation === 'yes' ? 'no' : 'yes')}
                onChange={() => {
                  return;
                }}
              />
              <span className="checkmark" />
            </Checkbox>
          </CheckboxDiv>
          <DocCheck>{callResult.checklist[0].title}</DocCheck>
        </DocsDiv>
      </Container>
      <VfModal height={setModalHeight()} isOpen={modalConfig.isOpen} handleClose={handleCloseModal}>
        <GenericDetail
          title={modalConfig.text}
          description={modalConfig.content}
          secondButtonLabel={modalConfig.pdfLabel}
          secondButtonAction={() => downloadFileFunction(modalConfig.pdfUrl)}
          options={{
            size: 18,
            lineHeight: 24,
            sizeMobile: 20,
            lineHeightMobile: 26,
            isModal: true,
            textAlign: 'left',
          }}
        />
      </VfModal>
      <VfModal
        height={setModalHeightWithAccordion()}
        isOpen={showActivationModal}
        handleClose={() => handleCloseModal(true)}
      >
        <ModalWithAccordion {...modal} />
      </VfModal>
    </>
  );
};

export default TermsAndConditions;
