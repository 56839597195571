import { ITermsAndCondition } from '@vfit/consumer/data-access';
import { ICMSConsentList, IConsentItem, IPartyPrivacyItem } from '@vfit/shared/models';

export const organizeTermsAndConditionConsentCard = (consentsCms: ITermsAndCondition) => ({
  title: consentsCms?.consentsmobile?.card?.title || '',
  description: consentsCms?.consentsmobile?.card?.description || '',
  terms: {
    title: consentsCms?.consentsmobile?.card?.terms?.title || '',
    customLinkLabel: consentsCms?.consentsmobile?.card?.terms?.customLinkLabel || '',
    acceptAll: consentsCms?.consentsmobile?.card?.terms?.acceptAll || '',
  },
  actionText: consentsCms?.consentsmobile?.card?.terms?.customLinkLabel || '',
  modal: {
    title: consentsCms?.consentsmobile?.modal?.title || '',
    description: consentsCms?.consentsmobile?.modal?.description || '',
    buttonLabel: consentsCms?.consentsmobile?.modal?.buttonLabel || '',
    list:
      (consentsCms?.consentsmobile?.modal?.list.length > 0 && [...consentsCms.consentsmobile.modal.list]) || [],
  },
});

export const organizePrivacyItems = (
  privacyItems: IPartyPrivacyItem[],
  listFromCMS?: ICMSConsentList[]
): IConsentItem[] =>
  privacyItems.map((privacyItem) => {
    const item = listFromCMS?.find((itemFromCMS) => privacyItem.id === itemFromCMS.id);
    return {
      ...privacyItem,
      name: item?.title || privacyItem.name,
      description: item?.text || privacyItem.description,
      longDescription: item?.description || privacyItem.longDescription,
      popupText: item?.popupText || '',
      detailLinkLabel: item?.detailLinkLabel || '',
      choice: {
        option1: item?.choice?.option1 || '',
        option2: item?.choice?.option2 || '',
        option3: item?.choice?.option3 || '',
      },
    };
  });

