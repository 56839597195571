import { IValidationCMS } from '@vfit/consumer/data-access';
import * as yup from 'yup';

export const portabilityNumberSchema = (validation?: IValidationCMS) =>
  yup.object({
    phoneNumber: yup
      .string()
      .required(validation?.phoneNumber?.required || '')
      .min(1, validation?.phoneNumber?.minimum || '')
      .max(10, validation?.phoneNumber?.maximum)
      .matches(/^0[1-9]([0-9]{6,8})/, validation?.phoneNumber?.nomatch || ''),
  });
