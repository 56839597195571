import { Form, VfModal } from '@vfit/shared/components';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CustomText } from '@vfit/shared/atoms';
import { useEffect, useState } from 'react';
import { fonts } from '@vfit/shared/themes';
import { getButtonActionByActionType, trackLink } from '@vfit/shared/data-access';
import { useRouter } from 'next/router';
import {
  Description,
  Wrapper,
  MigrationForm,
  OldNumber,
  Checkbox,
  CheckboxDiv,
  Footer,
  FooterDiv,
  Title,
  ModalContent,
  Question,
} from './portabilityMigrationData.style';
import { portabilityDataSchema } from './portabilityMigrationData.validation';
import { IPortDataForm, IPortDataProps } from './portabilityMigrationData.models';
import { useCheckout } from '../../../../../iBuyMobile.context';

const PortabilityMigrationData = ({
  editorialLabels,
  oldNumber,
  currentProvider,
  currentPlan,
  onChangeData,
  portabilityOperatorsFirstAction,
  handleOnChangeEnableGoNext,
}: IPortDataProps) => {
  const {
    title,
    description,
    secondActionLabel,
    modalTitle,
    modalDescription,
    checkboxText,
    operatorLabel,
    contractLabel,
    validationMessage
  } = editorialLabels;
  const { push } = useRouter();
  const { portability } = useCheckout();
  const providerList = currentProvider?.map((provider) => provider.displayValue);
  const currentPlanValues = currentPlan?.map((plan) => plan.displayValue);
  const initialOperator = currentProvider?.find(
    (provider) => provider.value === portability.portabilityMigration?.currentProvider
  )?.displayValue;
  const initialContract = currentPlan?.find(
    (plan) => plan.value === portability.portabilityMigration?.currentPlan
  )?.displayValue;
  const {
    register,
    setValue,
    trigger,
    formState: { errors, isValid },
  } = useForm<IPortDataForm>({
    resolver: yupResolver(portabilityDataSchema(validationMessage)),
    mode: 'all',
  });
  const [openModal, setOpenModal] = useState<boolean>(false);

  const [operatorValue, setOperatorValue] = useState(
    portability?.portabilityMigration?.currentProvider
  );
  const [selectedOperator, setSelectedOperator] = useState(initialOperator || '');

  const [contractValue, setContractValue] = useState(
    portability?.portabilityMigration?.currentPlan
  );
  const [selectedContract, setSelectedContract] = useState(initialContract || '');

  const [transferBalance, setTransferBalance] = useState<boolean>(
    portability?.portabilityMigration?.transferBalance || false
  );
  const initialParamsOperator = localStorage.getItem("operator");

  useEffect(() => {
    if (initialParamsOperator) {
      const operator = currentProvider?.find((provider) => provider.value === initialParamsOperator);
      if(operator) setValue('operator', operator.displayValue);
    }
    else if (initialOperator) {
      setValue('operator', initialOperator);
    }
    if (initialContract) {
      setValue('contract', initialContract);
    }
  }, []);

  useEffect(() => {
    setOperatorValue(
      currentProvider?.find((provider) => provider.displayValue === selectedOperator)?.value || ''
    );
  }, [selectedOperator]);

  useEffect(() => {
    setContractValue(
      currentPlan?.find((plan) => plan.displayValue === selectedContract)?.value || ''
    );
  }, [selectedContract]);

  useEffect(() => {
    if (!isValid && handleOnChangeEnableGoNext) handleOnChangeEnableGoNext(false);
    const data = {
      ...portability,
      portabilityMigration: {
        currentProvider: operatorValue,
        currentPlan: contractValue,
        transferBalance,
      },
    };
    onChangeData(data, isValid);
  }, [operatorValue, contractValue, transferBalance, isValid]);

  return (
    <>
      <Wrapper>
        <Title>{title}</Title>
        <Description>{description}</Description>
        <OldNumber>{oldNumber}</OldNumber>
        <MigrationForm>
          <div className="textInput">
            <Form.SelectInput
              label={operatorLabel}
              onSelectValue={(selectedValue) => {
                setValue('operator', selectedValue);
                trigger('operator').then(() => setSelectedOperator(selectedValue));
              }}
              errorMessage={errors.operator?.message}
              placeholder=" "
              {...register('operator')}
              items={providerList || []}
            />
            {portabilityOperatorsFirstAction?.title && (
              <Question
                onClick={() => {
                  trackLink(`click on ${portabilityOperatorsFirstAction?.title}`);
                  getButtonActionByActionType(portabilityOperatorsFirstAction, push);
                }}
              >
                <span> {portabilityOperatorsFirstAction?.title}</span>
              </Question>
            )}
          </div>
          <div className="textInput">
            <Form.SelectInput
              label={contractLabel}
              style={{ overflowY: 'auto', maxHeight: 10 }}
              onSelectValue={(selectedValue) => {
                setValue('contract', selectedValue);
                trigger('contract').then(() => setSelectedContract(selectedValue));
                setValue('transferBalance', false);
                trigger('transferBalance').then(() => setTransferBalance(false));
              }}
              errorMessage={errors.contract?.message}
              placeholder=" "
              {...register('contract')}
              items={currentPlanValues || []}
            />
            {secondActionLabel && (
              <Question
                onClick={() => {
                  trackLink('click on info box');
                  setOpenModal(true);
                }}
              >
                <span>{secondActionLabel}</span>
              </Question>
            )}
          </div>
        </MigrationForm>

        {selectedContract === 'Ricaricabile' && (
          <Footer>
            <CheckboxDiv>
              <Checkbox>
                <input
                  type="checkbox"
                  checked={transferBalance}
                  onChange={() => setTransferBalance(!transferBalance)}
                />
                <span className="checkmark" />
              </Checkbox>
            </CheckboxDiv>
            <FooterDiv>{checkboxText}</FooterDiv>
          </Footer>
        )}
      </Wrapper>
      <VfModal isOpen={openModal} handleClose={() => setOpenModal(false)}>
        <ModalContent>
          {modalTitle && <Title margin={[0, 0, 32]}>{modalTitle}</Title>}
          {modalDescription && (
            <CustomText
              text={modalDescription}
              size={20}
              textAlign="left"
              modal={false}
              margin={[0]}
              lineHeight={26}
              fontFamily={fonts.regular}
            />
          )}
        </ModalContent>
      </VfModal>
    </>
  );
};

export default PortabilityMigrationData;
