import {Button, ButtonSlide, ImageAtoms} from "@vfit/shared/atoms";
import React, {useEffect, useState} from "react";
import {purify} from "@vfit/shared/themes";
import {VfModal} from "@vfit/shared/components";
import {trackLink} from "@vfit/shared/data-access";
import * as S from "./addonCardOffer.style";
import {IAddonCardOfferProps} from "./addonCardOffer.models";
import {ModalContent} from "./components/modalContent";

const AddonCardOffer = ({
                          title,
                          price,
                          subprice,
                          imageUri,
                          ctaAddLabel,
                          ctaRemoveLabel,
                          ctaAddedLabel,
                          closePopupLabel,
                          description,
                          ctaOpenDetailsLabel,
                          popupBody,
                          addonId,
                          popupTitle,
                          added = false,
                          isLoadingUpdate,
                          saveAddon,
                          removeAddon
                        } : IAddonCardOfferProps) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [showDummyButton, setShowDummyButton] = useState(false);
  const [pDescription] = purify([description]);

  const handleAddAction = (_addonId : string) => {
    if(isOpenModal) setIsOpenModal(false);
    saveAddon(_addonId);
  }

  const handleRemoveAction = (_addonId: string) =>{
    if(isOpenModal) setIsOpenModal(false);
    removeAddon(_addonId);
  }

  const handleClosePopup = (label: string)=> {
    trackLink(
      label || 'chiudi',
      "users",
      "click",
      "ui_interaction"
    );
    setIsOpenModal(false)
  }

  const handleOpenDetails = () => {
    trackLink(ctaOpenDetailsLabel);
    setIsOpenModal(true);
  }

  useEffect(() => {
    if(ctaAddedLabel && added){
      setShowDummyButton(true);
    }
  }, [added]);

  useEffect(() =>{
    setTimeout(() =>{
      setShowDummyButton(false);
    }, 600);
  }, [showDummyButton]);

  return (
    <>
      <S.CardContainer>
        <S.ImageContainer>
          <ImageAtoms
            image={imageUri}
            imageMobile={imageUri}
            style={{borderRadius: '20px 20px 0 0'}}
            nameCard='addon-image'/>
        </S.ImageContainer>
        <S.BodyContainer>
          <S.TitleGroup>
            <S.Title>{title}</S.Title>
            <S.Price>{price}</S.Price>
            <S.Subprice>{subprice}</S.Subprice>
          </S.TitleGroup>
          <S.CtaContainer>
            {!added && !showDummyButton &&
              <Button
                loading={isLoadingUpdate}
                onClick={() => handleAddAction(addonId)}
              >
                {ctaAddLabel}
              </Button>

            }{showDummyButton &&
            <ButtonSlide
              label={ctaAddedLabel}
              onClick={() => {}}
              buttonColor="#e60000"
              labelColor="#fff"
              borderColor="#e60000"
              hoverColor="#bd0000"
              clickColor="#a10000"
            />
          }
            {added && !showDummyButton &&
              <Button
                onClick={() => handleRemoveAction(addonId)}
                variant='secondary'
                loading={isLoadingUpdate}
              >
                {ctaRemoveLabel}
              </Button>
            }
          </S.CtaContainer>
          <S.Description dangerouslySetInnerHTML={{__html: pDescription || ''}} />
          <S.DetailsCta onClick={handleOpenDetails}>{ctaOpenDetailsLabel}</S.DetailsCta>
        </S.BodyContainer>
      </S.CardContainer>
      <VfModal isOpen={isOpenModal} handleClose={() => handleClosePopup('x')} >
        <ModalContent
          added={added}
          addonId={addonId}
          popupTitle={popupTitle}
          ctaRemoveLabel={ctaRemoveLabel}
          ctaAddLabel={ctaAddLabel}
          closePopupLabel={closePopupLabel}
          handleRemoveAction={handleRemoveAction}
          handleAddAction={handleAddAction}
          handleClosePopup={handleClosePopup}
          popupBody={popupBody}
        />
      </VfModal>
    </>
  )
}

export default AddonCardOffer;
