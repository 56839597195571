import { useEffect } from 'react';
import {
  CONSUMER_CMS_SHOPPING_CART,
  IPersonalInfoCmsMobile,
  IPortabilityMobileCMS,
  useCmsConfig,
} from '@vfit/consumer/data-access';
import { API, getFromLocalStorageByKey, setTrackLink, useTracking } from '@vfit/shared/data-access';
import { ICommonData, IOptionalText } from '@vfit/shared/models';
import { CheckboxCards } from '@vfit/consumer/components';
import { organizeSimTypeCard } from '../../simTypeSelectionCard.utils';
import { useCheckout } from '../../../../../iBuyMobile.context';
import { ISimTypeCardData } from '../../simTypeSelection.models';

export const SelectionCard = ({ handleOnChangeEnableGoNext }: ICommonData) => {
  const {
    simOptions,
    portabilityStatus,
    customerDeliveryBaseInfo,
    setSimTypeSelectedOption,
    setSimOptions,
  } = useCheckout();
  const dataFromCms = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_PERSONAL_DATA_MOBILE
  ) as IPersonalInfoCmsMobile;
  const errorPortability = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_PORTABILITY_MOBILE
  ) as IPortabilityMobileCMS;
  const organizedDataForSim = organizeSimTypeCard(
    dataFromCms,
    errorPortability
  ) as ISimTypeCardData;
  const { trackView } = useTracking({
    event: ['view'],
    disableInitialTrack: true,
  });
  setTrackLink(trackView, 'e-sim option');

  const trackOpenModal = () => {
    trackView({
      event_name: 'ui_interaction',
      page_name_override: 'e-sim option',
      event_label_track: `e-sim option ${organizedDataForSim?.modalInfo?.textLink}`,
      event_category: 'users',
      event_action: 'click',
      tracking_type: 'link',
    });
  };

  const trackGoNext = () => {
    trackView({
      event_name: 'ui_interaction',
      page_name_override: 'e-sim option',
      event_label_track: `e-sim option ${
        simOptions?.simOptions?.find((simOption) => simOption?.selection)?.value || ''
      }`,
      event_category: 'users',
      event_action: 'click',
      tracking_type: 'link',
    });
  };

  const handleSelect = (item: IOptionalText | undefined) => {
    if (!item) return;
    const modifyMobileSimDeviceId = getFromLocalStorageByKey('modifyMobileSimDeviceId');
    const selectedOption = simOptions?.simOptions?.find((el) => el.value === item.value);
    if (
      selectedOption &&
      selectedOption?.id !== modifyMobileSimDeviceId?.simOptionId &&
      !portabilityStatus?.isLoadingCheck
    ) {
      setSimTypeSelectedOption(selectedOption);
    }
    setSimOptions({
      ...simOptions,
      simOptions: simOptions?.simOptions?.map((sO) => {
        if (sO.value === item?.value) {
          return {
            ...sO,
            selection: true,
          };
        }
        return {
          ...sO,
          selection: false,
        };
      }),
    });
  };

  const getSelectedItem = () => {
    const simOption = simOptions?.simOptions?.find((sOption) => sOption.selection);
    return {
      title: simOption?.title || '',
      value: simOption?.value || '',
    };
  };

  useEffect(() => {
    const goNextButton = document.getElementById('sticky-offer-next');
    goNextButton?.addEventListener('click', trackGoNext);
    return () => {
      goNextButton?.removeEventListener('click', trackGoNext);
    };
  }, [simOptions]);

  useEffect(() => {
    handleOnChangeEnableGoNext?.(!portabilityStatus?.isLoadingCheck);
  }, [portabilityStatus?.isLoadingCheck, customerDeliveryBaseInfo?.modifySimDevice]);

  useEffect(() => {
    const haveSelectedItem = simOptions.simOptions.find((simOption) => simOption.selection);
    handleOnChangeEnableGoNext?.(!!haveSelectedItem);
  }, []);

  return (
    <CheckboxCards
      title={organizedDataForSim.cardTitle}
      description={organizedDataForSim.cardDescription}
      items={simOptions?.simOptions?.map((sO) => ({
        title: sO.title || '',
        value: sO.value || '',
        extra: sO.highlight
          ? {
              text: organizedDataForSim.bestChoiceLabel || '',
              color: '#fff',
              extraClass: 'extraClass',
            }
          : undefined,
      }))}
      value={getSelectedItem().value}
      selectedItem={getSelectedItem()}
      setSelectedItem={() => {
        return;
      }}
      onSelectItem={handleSelect}
      bottomModal={organizedDataForSim.modalInfo}
      onOpeneModal={trackOpenModal}
      isSuccess={!portabilityStatus?.isLoadingCheck}
    />
  );
};
