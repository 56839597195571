import {
  CONSUMER_CMS_SHOPPING_CART,
  IWinBackCMS,
  useCmsConfig,
  useGenerateOTP,
  useVerifyOTP,
  productSpecificError,
  errorMock,
  IGenericErrorCMS,
} from '@vfit/consumer/data-access';
import { resetData, useTracking } from '@vfit/shared/data-access';
import { useQueryClient } from 'react-query';
import { useEffect, useState } from 'react';
import { API, openPopup, setTrackLink } from '@vfit/shared/data-access';
import { WarningInfo } from '@vfit/shared/components';
import { ICommonData, IWarningInfo } from '@vfit/shared/models';
import { Otp } from '@vfit/consumer/components';
import { useCheckout } from '../../../iBuyMobile.context';
import { OTP_CONSTANTS } from '../PortabilityOtpCrad/portabilityOtpCard.utils';
import { organizeOtpWinback, organizePopupLabels } from './winbackOtp.utils';
import { ID_FLOWS } from '../../checkout.constants';
import { DEFAULT_PORTABILITY } from '../../../iBuyMobile.context.data';
import { getUserInfo, getTagging } from '../../checkout.utils';
import { useWinback } from '../../hooks/UseWinback/useWinback';

const WinbackOtp = ({
  handleOnChangeEnableGoNext,
  handleGoPrevSlide,
  handleGoNextSlide,
}: ICommonData) => {
  const {
    checkoutErrors,
    winBackNumber,
    isModalButtonSticky,
    product,
    setPortability,
    setCurrentStepKey,
    setIsDisabledBackButton,
  } = useCheckout();
  const [isError, setIsError] = useState(false);
  const cmsData = useCmsConfig(CONSUMER_CMS_SHOPPING_CART, API.CMS_GET_WINBACK_DATA) as IWinBackCMS;

  const { urlGenericErrorCallMeNow } = productSpecificError(product);
  const cmsOrganizedData = organizeOtpWinback(cmsData, winBackNumber);
  const { titlePopup, ctaLabel, messagePopup, buttonLabel } = organizePopupLabels(cmsData);
  const queryClient = useQueryClient();
  const generateOtp = useGenerateOTP(OTP_CONSTANTS.MODULE_NAME);
  const taggingMobile = getTagging('winback', 'mobile', queryClient);

  const {
    mutate: verifyOtp,
    data: verifyOtpData,
    isSuccess: isSuccessOtp,
    isError: verifyOptError,
    isLoading: isLoadingOtp,
  } = useVerifyOTP();

  const handleVerify = (otp: string) => {
    verifyOtp(otp);
  };
  const {
    isError: isErrorWinbackFlow,
    isSuccess: isSuccessWinbackFlow,
    isLoading: isLoadingWinbackFlow,
    refetchFlowStart,
    resetState,
  } = useWinback(winBackNumber);
  const handleSendOtp = (field: string, fieldType: string) => {
    resetData(queryClient, ['generateOTP']);
    generateOtp.mutate({
      identifier: `39${field}`,
      identifierType: fieldType,
      identificationScope: 'SUB',
    });
  };

  const handleSetError = () => {
    setIsError(false);
  };

  const closePopUpAndGoBack = () => {
    trackView({
      event_name: 'ui_interaction',
      event_label_track: 'click on modifica numero',
      event_category: 'users',
      event_action: 'click',
      tracking_type: 'link',
    });
    resetState();
    handleGoPrevSlide?.();
  };

  const actionPopup = () => {
    openPopup(urlGenericErrorCallMeNow, () => {
      closePopUpAndGoBack();
    });
  };

  const { trackView } = useTracking({
    event: ['view'],
    event_label: 'OTP',
    opts: {
      event_category: ['sales', 'journey'],
      journey_name: 'winback',
      journey_type: 'journey',
      page_section: 'campaign',
      page_subsection: 'check number',
    },
    cartProduct: taggingMobile?.trackingProduct,
    pageProduct: taggingMobile?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });
  const genericError = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_GENERIC_ERROR_MOBILE
  ) as IGenericErrorCMS;
  setTrackLink(trackView, 'OTP');

  useEffect(() => {
    if (verifyOptError || (!verifyOptError && isSuccessOtp && !verifyOtpData)) {
      setIsError(true);
    }
    if (isSuccessOtp && verifyOtpData?.id) {
      setPortability({
        ...DEFAULT_PORTABILITY,
        portabilityNumber: winBackNumber,
        portabilityWant: true,
      });
      refetchFlowStart();
    }
  }, [isSuccessOtp, verifyOtpData, verifyOptError]);

  useEffect(() => {
    setCurrentStepKey(ID_FLOWS.WINBACK_OTP);
    handleSendOtp(winBackNumber, OTP_CONSTANTS.FIELD_TYPE);
    return () => {
      resetData(queryClient, ['generateOTP', 'verifyOTP']);
    };
  }, []);

  useEffect(() => {
    handleOnChangeEnableGoNext?.(isSuccessWinbackFlow);
    if (isSuccessWinbackFlow) handleGoNextSlide?.();
  }, [isSuccessWinbackFlow]);

  useEffect(() => {
    if (isErrorWinbackFlow) {
      checkoutErrors?.showAndTrackError?.(
        genericError,
        closePopUpAndGoBack,
        errorMock('campaignWinBack', { url: 'campaign', status: '500', statusText: 'KO_OTP' }),
        titlePopup,
        messagePopup,
        actionPopup,
        ctaLabel,
        closePopUpAndGoBack,
        buttonLabel,
        isModalButtonSticky,
        true
      );
    }
  }, [isErrorWinbackFlow]);

  useEffect(() => {
    setIsDisabledBackButton(isLoadingOtp || isLoadingWinbackFlow);
  }, [isLoadingOtp, isLoadingWinbackFlow]);

  const waitingInfo: IWarningInfo = {
    title: cmsOrganizedData?.warning?.title || '',
    subtitle: cmsOrganizedData?.warning?.subtitle || '',
    description: cmsOrganizedData?.warning?.description || '',
    lottie: product?.loaders?.choosePayment || '',
  };

  return (
    <>
      {(isLoadingOtp || isLoadingWinbackFlow) && <WarningInfo {...waitingInfo} />}
      <div
        style={{
          display: isLoadingOtp || isLoadingWinbackFlow || isSuccessWinbackFlow ? 'none' : 'block',
        }}
      >
        <Otp
          contactMedium={{
            field: winBackNumber,
            fieldType: OTP_CONSTANTS.FIELD_TYPE,
          }}
          isError={isError}
          isLoading={isLoadingOtp || isLoadingWinbackFlow}
          reSendOtp={handleSendOtp}
          onVerify={handleVerify}
          setIsError={handleSetError}
          noAccessEnabled={false}
          configOtp={cmsOrganizedData}
        />
      </div>
    </>
  );
};

export default WinbackOtp;
