import { useValidateVoucher } from '@vfit/consumer/data-access';
import { useEffect, useState } from 'react';
import { useCheckout } from '../../../iBuyMobile.context';

export const useWinBackLegacy = () => {
  const VALID_VOUCHER_CODE = '0';
  const EXPIRED_VOUCHER_CODE = '1002';
  const [isExpiredVoucher, setExpiredVoucher] = useState(false);
  const [isValidVoucher, setValidVoucher] = useState(false);
  const [isInvalidVoucher, setInvalidVoucher] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const { winBackNumber } = useCheckout();

  const {
    mutate: validateVoucherMutation,
    data: validateVoucherData,
    isLoading: isLoadingVoucher,
    isSuccess: isSuccessVoucher,
    isError: isErrorVoucher,
  } = useValidateVoucher();

  const startWinbackFlow = () => {
    if (winBackNumber) validateVoucherMutation({ voucherId: winBackNumber });
  };

  useEffect(() => {
    setIsLoading(isLoadingVoucher);
  }, [isLoadingVoucher]);

  useEffect(() => {
    setIsError(isErrorVoucher);
  }, [isErrorVoucher]);

  useEffect(() => {
    if (isSuccessVoucher && validateVoucherData) {
      if (validateVoucherData.returnedCode == EXPIRED_VOUCHER_CODE) {
        setExpiredVoucher(true);
      } else if (validateVoucherData.returnedCode == VALID_VOUCHER_CODE) {
        setValidVoucher(true);
      } else {
        setInvalidVoucher(true);
      }
    }
  }, [isSuccessVoucher, validateVoucherData]);

  return {
    startWinbackFlow,
    isLoading,
    isValidVoucher,
    isExpiredVoucher,
    isInvalidVoucher,
    isError,
  };
};

