export interface IAddressResponse {
  href?: string;
  id?: string;
  city: string;
  country?: string;
  displayCountry?: string;
  postalCode: string;
  stateOrProvince: string;
  street: string;
  formattedAddress?: string;
  formattedAddress1?: string;
  formattedAddress2?: string;
  addressStatus?: string;
  externalId?: string;
  streetNumber: string;
  homeZone?: string;
  istatCode?: string;
  latitude?: number;
  longitude?: number;
  displayedAddress?: string;
  typePickupPoint?: string;
}

export enum ECodeIdentification {
  NA = 'NA', // nessuno
  CO = 'CO', // corriere
  VR = 'VR', // video recognition
  DI = 'DI', // digital delivery
}

export enum IAddressType {
  S = 'S',
  PO = 'PO',
  PP = 'PP',
  P = 'PP',
}

export type IIdentificationDetails = {
  code: ECodeIdentification;
  description: string;
  isSelected: boolean;
};

export interface IAddress extends IAddressResponse {
  displayedAddress?: string;
}

export interface IEditAddressCmsConfig {
  editaddressconfig: IEditAddressConfig;
}

export interface IEditAddressConfig {
  title: string;
  billingSubtitle: string;
  shippingSubtitle: string;
  lable: string;
  paragraph_manual: string;
  labels: {
    addressInput: string;
    city: string;
    postalCode: string;
    stateOrProvince: string;
    streetNumber: string;
    street: string;
    addressInputPlaceHolderAutocomplete?: string;
  };
  placeHolders: {
    addressInput: string;
    city: string;
    postalCode: string;
    stateOrProvince: string;
    streetNumber: string;
    street: string;
  };
  submitButton: string;
  submitButton_manual: string;
  genericError: string;
  apiError: string;
}

export interface IMapConfig {
  titlePO: string;
  titlePP: string;
  titleP: string;
  subTitlePO: string;
  subTitlePP: string;
  subTitleP: string;
  ctaModifyLable: string;
  switchLabelSx: string;
  switchLabelDx: string;
  ctaSaveLable: string;
}
export interface IMapConfigEdit {
  title: string;
  subtitle: string;
}

export interface IEditAddressOptionsConfig {
  title: string;
  subtitle: string;
  description: string;
  ctaLable: string;
}
export interface ICmsConfigAddressManual {
  addressInputCard: {
    title: string;
    description: string;
    labels: {
      title: string;
      street: string;
      streetNumber: string;
      displayStateOrProvince: string;
      city: string;
      postalCode: string;
    };
    buttonLabel: string;
  };
  addressErrors?: IAddressErrors;
  genericErrorAddress: string;
}

export interface IAddressErrors {
  city?: IValidationAddress;
  postalCode?: IValidationAddress;
  stateOrProvince?: IValidationAddress;
  street?: IValidationAddress;
  streetNumber?: IValidationAddress;
}

export interface IValidationAddress {
  min?: string;
  matchRegex?: string;
  required?: string;
}
