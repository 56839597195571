import { useEffect } from 'react';
import { LoggerInstance } from '@vfit/shared/data-access';
import {
  CONSUMER_CMS_SHOPPING_CART,
  useCmsConfig,
  IPersonalInfoCmsMobile,
  IUserIdentityField,
} from '@vfit/consumer/data-access';
import { API } from '@vfit/shared/data-access';
import { ConfirmIdentity } from '@vfit/shared/components';
import { ICheckoutData } from '../../checkout.models';
import { organizeConfirmIdentityCard } from './confirmIdentityCard.utils';
import { useCheckout } from '../../../iBuyMobile.context';
import TaggingConfirmIdentityCard from './confirmIdentityCard.tagging';

const ConfirmIdentityCard = ({ handleOnChangeEnableGoNext }: ICheckoutData) => {
  const { findCaller, setFindCaller } = useCheckout();

  const handleSelect = (selectedItem: IUserIdentityField) => {
    setFindCaller({
      ...findCaller,
      selectedContactMedium: selectedItem.field?.trim() ? selectedItem : undefined,
    });
  };

  const dataFromCms = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_PERSONAL_DATA_MOBILE
  ) as IPersonalInfoCmsMobile;
  const { descriptionCms, placeholderCms, titleCms, ctaLabelCms } =
    organizeConfirmIdentityCard(dataFromCms);

  const cardConfig = {
    getSelectedData: handleSelect,
    identityTypeItems: [
      findCaller.individualRef.email || '',
      findCaller.individualRef.msisdn || '',
    ],
    title: titleCms,
    description: descriptionCms,
    placeholder: placeholderCms,
    cta: {
      label: ctaLabelCms,
      action: () => LoggerInstance.debug('click from'),
    },
  };

  useEffect(() => {
    setFindCaller({
      ...findCaller,
      selectedContactMedium: undefined,
    });
  }, []);

  useEffect(() => {
    if (handleOnChangeEnableGoNext) {
      handleOnChangeEnableGoNext(
        !!(findCaller.selectedContactMedium?.field && findCaller.selectedContactMedium.fieldType)
      );
    }
  }, [findCaller.selectedContactMedium]);

  return (
    <>
      <TaggingConfirmIdentityCard />
      <ConfirmIdentity data={cardConfig} />
    </>
  );
};

export default ConfirmIdentityCard;
