import { setTrackView, useTracking } from '@vfit/shared/data-access';
import { useQueryClient } from 'react-query';
import { setTrackLink } from '@vfit/shared/data-access';
import { getTagging, getUserInfo } from '../../../../checkout.utils';
import { withAnimationDelay } from '../../../../hoc/withAnimationDelay';

const TaggingComponent = () => {
  const client = useQueryClient();
  const tagging = getTagging('confirm id', 'fixed', client);

  const { trackView } = useTracking({
    event: ['checkout_step1.1'],
    event_label: 'verify id',
    opts: tagging?.opts,
    cartProduct: tagging?.trackingProduct,
    pageProduct: tagging?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });
  setTrackLink(trackView, 'verify id');
  setTrackView(trackView);

  return null;
};

const TaggingConfirmIdentityApp = withAnimationDelay()(TaggingComponent);

export default TaggingConfirmIdentityApp;
