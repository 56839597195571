import { ITermsAndCondition } from '@vfit/consumer/data-access';
import { ICMSConsentList, IConsentItem, IPartyPrivacyItem } from '@vfit/shared/models';

export const organizeTermsAndConditionConsentCard = (consentsCms: ITermsAndCondition) => ({
  title: consentsCms?.consents?.card?.title || '',
  description: consentsCms?.consents?.card?.description || '',
  terms: {
    title: consentsCms?.consents?.card?.terms?.title || '',
    customLinkLabel: consentsCms?.consents?.card?.terms?.customLinkLabel || '',
    acceptAll: consentsCms?.consents?.card?.terms?.acceptAll || '',
  },
  actionText: consentsCms?.consents?.card?.terms?.customLinkLabel || '',
  modal: {
    title: consentsCms?.consents?.modal?.title || '',
    description: consentsCms?.consents?.modal?.description || '',
    buttonLabel: consentsCms?.consents?.modal?.buttonLabel || '',
    list: 
      (consentsCms?.consents?.modal?.list.length > 0 && [...consentsCms.consents.modal.list]) || []
  }
});

export const organizePrivacyItems = (
  privacyItems: IPartyPrivacyItem[],
  listFromCMS?: ICMSConsentList[]
): IConsentItem[] =>
  privacyItems.map((privacyItem) => {
    const item = listFromCMS?.find((itemFromCMS) => privacyItem.id === itemFromCMS.id);
    return {
      ...privacyItem,
      name: item?.title || privacyItem.name,
      description: item?.text || privacyItem.description,
      longDescription: item?.description || privacyItem.longDescription,
      popupText: item?.popupText || '',
      detailLinkLabel: item?.detailLinkLabel || '',
      choice: {
        option1: item?.choice?.option1 || '',
        option2: item?.choice?.option2 || '',
        option3: item?.choice?.option3 || '',
      },
    };
  });