import React, { useContext, useState } from 'react';
import {
  IAddressDataObject,
  IInvoiceObject,
  IPickUpLocation,
  IOptionalText,
  IPersonalDataObject,
  IFindCallerObject,
  IAvailablePaymentMethods,
  IPayMean,
  IInputsCoverageTool,
  IInputs,
} from '@vfit/shared/models';
import { LoggerInstance } from '@vfit/shared/data-access';
import { getValueToEncode } from '@vfit/shared/data-access';
import { IProduct, updateProduct } from '@vfit/consumer/data-access';
import {
  IContactInfo,
  IConsentsObject,
  ITermsAndConditionsCheck,
  IUpfrontSelection,
  IInitContext,
  IOwningIndividual,
  IUpdateProductPriceControl,
  IPickUpLocationStore,
  IDeliveryAdvance,
  IPortabilityObject,
  IPortabilityStatus, IAddonState,
} from './Checkout/checkout.models';
import {
  CONTEXT_KEY,
  DEFAULT_BILLING_ADDRESS,
  DEFAULT_CONSENTS,
  DEFAULT_CONTACT_INFO,
  DEFAULT_DATA,
  DEFAULT_FIND_CALLER,
  DEFAULT_INVOICE,
  DEFAULT_IS_DISABLED_BACK_BUTTON,
  DEFAULT_IS_FLOW_READY,
  DEFAULT_LAST_CARD,
  DEFAULT_SLIDES_GO_BACK,
  DEFAULT_TERMS_AND_CONDITIONS,
  DEFAULT_UPFRONT_SELECTION,
  DEFAULT_PORTABILITY,
  DEFAULT_SHIPPING_ADDRESS,
  DEFAULT_PICKUP_POINT,
  DEFAULT_ADDRESS,
  DEFAULT_KEEPS_NUMBER,
  DEFAULT_UPDATE_PRODUCT_PRICE,
  DEFAULT_IS_VALID_NUMBER_PORTABILITY,
  DEFAULT_WINBACK_NUMBER,
  DEFAULT_OFFER_ID,
  DEFAULT_CUSTOMER_ID,
  DEFAULT_PICKUP_LOCATION_STORE,
  DEFAULT_IS_START_RECOGNITION,
  DEFAULT_DELIVERY_ADVANCE,
  DEFAULT_IS_NEW_ADDRESS,
  DEFAULT_IS_START_DELIVERY_BASE,
  DEFAULT_BILLING_REPLACE,
  DEFAULT_CART_ASYNC_INFO,
  DEFAULT_CUSTOMER_DELIVERY_BASE_INFO,
  DEFAULT_CUSTOMER_ASYNC_INFO,
  DEFAULT_AUTHORIZATION_ASYNC_INFO,
  DEFAULT_IS_START_AUTHORIZATION_ASYNC_INFO,
  DEFAULT_IS_START_CUSTOMER_FLOW,
  DEFAULT_CUSTOMER_FLOW,
  DEFAULT_VOUCHER_DISCOUNT,
  DEFAULT_CHECKOUT_ERRORS,
  DEFAULT_PRODUCT,
  DEFAULT_IS_SHOW_CHECKOUT,
  DEFAULT_IS_START_LOCKIN_MM_FLOW,
  DEFAULT_IS_START_LOCKIN_FLOW,
  DEFAULT_IS_START_LOCKIN_APP_FLOW,
  DEFAULT_LOCKIN_MM_FLOW,
  DEFAULT_LOCKIN_APP_FLOW,
  DEFAULT_LOCKIN_FLOW,
  DEFAULT_INSTALLATION_ADDRESS,
  DEFAULT_AUTHORIZE_FLOW,
  DEFAULT_BILLING_EMAIL,
  DEFAULT_PORTABILITY_STATUS, DEFAULT_ADDON_STATE,
} from './iBuyFixed.context.data';
import { retrieveOwningIndividual } from './Checkout/checkout.utils';
import { ID_FLOWS, DEFAULT_CURRENT_STEP_KEY } from './Checkout/checkout.constants';
import { IUseCartAsyncInformationResponse } from './Checkout/hooks/UseCartAsyncInformation/useCartAsyncInformation.models';
import { IUseCustomerDeliveryBaseResponse } from './Checkout/hooks/UseCustomerDeliveryBase/useCustomerDeliveryBase.models';
import { IUseCustomerAsyncInformationResponse } from './Checkout/hooks/UseCustomerAsyncInformation/useCustomerAsyncInformation.models';
import { IUseDeliveryBillingResponse } from './Checkout/hooks/UseDeliveryBillingFlow/useDeliveryBillingFlow.models';
import { IUseAuthorizationAsyncInformationResponse } from './Checkout/hooks/UseAuthorizationAsyncInformation/useAuthorizationAsyncInformation.models';
import { IUseCustomerResponse } from './Checkout/hooks/UseCustomerFlow/useCustomerFlow.models';
import { IUseVoucherDiscountResponse } from './Checkout/hooks/UseVoucherDiscount/useVoucherDiscount.models';
import { IUseAuthorizeFlow } from './Checkout/hooks/UseAuhorizeFlow/useAuthorizeFlow.models';
import { IUseCheckoutErrors } from './Checkout/hooks/UseCheckoutErrors/useCheckoutErrors.models';
import { IUseLockInMMFlowResponse } from './Checkout/hooks/UseLockInMMFlow/useLockInMMFlow.models';
import { IUseLockInAppFlowResponse } from './Checkout/hooks/UseLockInAppFlow/useLockInAppFlow.models';
import { getLocalAddress } from './ShoppingCart/shoppingCart.utils';
import { IUseLockInFlowResponse } from './Checkout/hooks/UseLockInFlow/useLockInFlow.models';
import { getSerializeContext, retrieveProduct } from './iBuyFixed.utils';

interface ICheckoutProvider {
  children: React.ReactNode;
}

export type ICheckoutContext = {
  customerId: string;
  owningData: IPersonalDataObject;
  deliveryAdvance: IDeliveryAdvance;
  contactInfo: IContactInfo;
  consents: IConsentsObject;
  portability: IPortabilityObject;
  portabilityStatus: IPortabilityStatus;
  addonState: IAddonState;
  findCaller: IFindCallerObject;
  isLastCard?: boolean;
  isFlowReady?: boolean;
  termsAndConditions: ITermsAndConditionsCheck;
  invoice: IInvoiceObject;
  slidesGoBack: number;
  upfrontSelection: IUpfrontSelection;
  currentStepKey: string;
  address: IInputsCoverageTool;
  pickupPoint: IPickUpLocation;
  selectedPickupPoint: IPickUpLocation;
  billingAddress: IAddressDataObject;
  billingEmail: string;
  installationAddress: IAddressDataObject;
  shippingAddress: IAddressDataObject;
  availablePaymentMethods: IAvailablePaymentMethods[];
  hideStickyBar: boolean;
  keepsNumber?: IOptionalText;
  setKeepsNumber: (payload: IOptionalText) => void;
  updateProductPriceCtrl: IUpdateProductPriceControl;
  isValidNumberPortability: boolean;
  billDeliveryDone: boolean;
  upfrontPayment: IPayMean | undefined;
  isLoadingCart: boolean;
  pickupLocationStore: IPickUpLocationStore;
  isStartRecognition: boolean;
  isStartDeliveryBase: boolean;
  isNewShippingAddress: boolean;
  billingReplace: IUseDeliveryBillingResponse;
  cartAsyncInfo: IUseCartAsyncInformationResponse;
  customerDeliveryBaseInfo: IUseCustomerDeliveryBaseResponse;
  customerAsyncInfo: IUseCustomerAsyncInformationResponse;
  authorizationAsyncInfo: IUseAuthorizationAsyncInformationResponse;
  isStartAuthorizationAsyncInfo: boolean;
  isModalButtonSticky: boolean;
  isStartCustomerFlow: boolean;
  isStartLockInMMFlow: boolean;
  isStartLockInFlow: boolean;
  isStartLockInAppFlow: boolean;
  customerFlow: IUseCustomerResponse;
  lockInMMFlow: IUseLockInMMFlowResponse;
  lockInFlow: IUseLockInFlowResponse;
  lockInAppFlow: IUseLockInAppFlowResponse;
  voucher: IUseVoucherDiscountResponse;
  authorizeFlow: IUseAuthorizeFlow;
  offerId: string;
  product: IProduct;
  addons: string[];
  isPreselectedCard: boolean;
  configuredStickyStepperLabel: string;
  checkoutErrors: IUseCheckoutErrors;
  isShowCheckout: boolean;
  setCustomerId: (customerId: string) => void;
  setOwningData: (dataOwning: IPersonalDataObject) => void;
  setPortability: (dataPortability: IPortabilityObject) => void;
  setPortabilityStatus: (portabilityStat: IPortabilityStatus) => void;
  setAddonState: (addonStateData: IAddonState) => void;
  setContactInfo: (dataContact: IContactInfo) => void;
  setConsents: (dataConsents: IConsentsObject) => void;
  setBillingAddress: (dataAddress: IAddressDataObject) => void;
  setBillingEmail: (dataEmail: string) => void;
  setInstallationAddress: (dataAddress: IAddressDataObject) => void;
  setShippingAddress: (dataAddress: IAddressDataObject) => void;
  setFindCaller: (dataCaller: IFindCallerObject) => void;
  setIsLastCard: (isLastCard: boolean) => void;
  setIsFlowReady: (isFlowReady: boolean) => void;
  setTermsAndConditions: (termsAndConditions: ITermsAndConditionsCheck) => void;
  setInvoice: (invoice: IInvoiceObject) => void;
  setSlidesGoBack: (slidesGoback: number) => void;
  setUpfrontSelection: (upfrontSelection: IUpfrontSelection) => void;
  setCurrentStepKey: (currentStepKey: string) => void;
  setIsDisabledBackButton: (isDisabledBackButton: boolean) => void;
  serializeContext: (ignoreUserData?: boolean) => void;
  deleteSerializedContext: () => void;
  checkInitContext: () => boolean;
  printContext: () => void;
  isDisabledBackButton: boolean;
  initContext: (initContext?: IInitContext) => boolean;
  setOfferId: (offerId: string) => void;
  setAddress: (address: IInputs) => void;
  setSelectedPickupPoint: (pickupPoint: IPickUpLocation) => void;
  setPickupPoint: (pickupPoint: IPickUpLocation) => void;
  setAvailablePaymentMethods: (availablePaymentMethods: IAvailablePaymentMethods[]) => void;
  setHideStickyBar: (hideStickybar: boolean) => void;
  setUpdateProductPriceCtrl: (updateProductPriceCtrl: IUpdateProductPriceControl) => void;
  setIsValidNumberPortability: (isValidNumberPortability: boolean) => void;
  setBillDeliveryDone: (billDeliveryDone: boolean) => void;
  setUpfrontPayment: (upfrontPayment: IPayMean | undefined) => void;
  setIsLoadingCart: (isLoadingCart: boolean) => void;
  setIsPreselectedCard: (isPreselectedcard: boolean) => void;
  setDeliveryAdvance: (deliveryAdvance: IDeliveryAdvance) => void;
  setPickupLocationStore: (pickupLocationStore: IPickUpLocationStore) => void;
  setIsStartRecognition: (isStart: boolean) => void;
  setBillingReplace: (billingReplace: IUseDeliveryBillingResponse) => void;
  setIsNewShippingAddress: (isNewAddress: boolean) => void;
  setIsStartDeliveryBase: (dB: boolean) => void;
  setCartAsyncInfo: (cartAsyncInfo: IUseCartAsyncInformationResponse) => void;
  setCustomerDeliveryBaseInfo: (cDeliveryBaseInfo: IUseCustomerDeliveryBaseResponse) => void;
  setCustomerAsyncInfo: (cAsyncInfo: IUseCustomerAsyncInformationResponse) => void;
  setAuthorizationAsyncInfo: (aAsyncInfo: IUseAuthorizationAsyncInformationResponse) => void;
  setIsStartAuthorizationAsyncInfo: (iSAsyncInfo: boolean) => void;
  setIsModalButtonSticky: (isModalButtonSticky: boolean) => void;
  setIsStartCustomerFlow: (isStartCustomerFlow: boolean) => void;
  setIsStartLockInMMFlow: (isStartLockInMMFlow: boolean) => void;
  setIsStartLockInFlow: (isStartLockInFlow: boolean) => void;
  setIsStartLockInAppFlow: (isStartLockInAppFlow: boolean) => void;
  setCustomerFlow: (customerRes: IUseCustomerResponse) => void;
  setLockInMMFlow: (lockInRes: IUseLockInMMFlowResponse) => void;
  setLockInFlow: (lockInRes: IUseLockInFlowResponse) => void;
  setLockInAppFlow: (lockInAppRes: IUseLockInAppFlowResponse) => void;
  setVoucher: (voucherRes: IUseVoucherDiscountResponse) => void;
  setAuthorizeFlow: (useAuthFlow: IUseAuthorizeFlow) => void;
  setConfiguredStickyStepperLabel: (label: string) => void;
  setCheckoutErrors: (checkoutObj: IUseCheckoutErrors) => void;
  setProduct: (prod: IProduct) => void;
  setIsShowCheckout: (isShowCheckout: boolean) => void;
  setAddons: (addons: string[]) => void;
};

export const CheckoutContext = React.createContext<ICheckoutContext>({
  customerId: DEFAULT_CUSTOMER_ID,
  owningData: DEFAULT_DATA,
  contactInfo: DEFAULT_CONTACT_INFO,
  portability: DEFAULT_PORTABILITY,
  portabilityStatus: DEFAULT_PORTABILITY_STATUS,
  addonState: DEFAULT_ADDON_STATE,
  consents: DEFAULT_CONSENTS,
  findCaller: DEFAULT_FIND_CALLER,
  isLastCard: DEFAULT_LAST_CARD,
  isFlowReady: DEFAULT_IS_FLOW_READY,
  termsAndConditions: DEFAULT_TERMS_AND_CONDITIONS,
  invoice: DEFAULT_INVOICE,
  slidesGoBack: DEFAULT_SLIDES_GO_BACK,
  currentStepKey: ID_FLOWS.PERSONAL_INFO,
  upfrontSelection: DEFAULT_UPFRONT_SELECTION,
  isDisabledBackButton: DEFAULT_IS_DISABLED_BACK_BUTTON,
  address: DEFAULT_ADDRESS,
  pickupPoint: DEFAULT_PICKUP_POINT,
  selectedPickupPoint: DEFAULT_PICKUP_POINT,
  offerId: DEFAULT_OFFER_ID,
  installationAddress: DEFAULT_INSTALLATION_ADDRESS,
  shippingAddress: DEFAULT_SHIPPING_ADDRESS,
  billingAddress: DEFAULT_BILLING_ADDRESS,
  availablePaymentMethods: [],
  hideStickyBar: false,
  keepsNumber: DEFAULT_KEEPS_NUMBER,
  updateProductPriceCtrl: DEFAULT_UPDATE_PRODUCT_PRICE,
  isValidNumberPortability: DEFAULT_IS_VALID_NUMBER_PORTABILITY,
  billDeliveryDone: false,
  upfrontPayment: undefined,
  isLoadingCart: true,
  isPreselectedCard: false,
  pickupLocationStore: DEFAULT_PICKUP_LOCATION_STORE,
  isStartRecognition: DEFAULT_IS_START_RECOGNITION,
  deliveryAdvance: DEFAULT_DELIVERY_ADVANCE,
  billingReplace: DEFAULT_BILLING_REPLACE,
  isNewShippingAddress: DEFAULT_IS_NEW_ADDRESS,
  isStartDeliveryBase: DEFAULT_IS_START_DELIVERY_BASE,
  cartAsyncInfo: DEFAULT_CART_ASYNC_INFO,
  customerDeliveryBaseInfo: DEFAULT_CUSTOMER_DELIVERY_BASE_INFO,
  customerAsyncInfo: DEFAULT_CUSTOMER_ASYNC_INFO,
  authorizationAsyncInfo: DEFAULT_AUTHORIZATION_ASYNC_INFO,
  isStartAuthorizationAsyncInfo: DEFAULT_IS_START_AUTHORIZATION_ASYNC_INFO,
  isModalButtonSticky: false,
  isStartCustomerFlow: DEFAULT_IS_START_CUSTOMER_FLOW,
  customerFlow: DEFAULT_CUSTOMER_FLOW,
  lockInFlow: DEFAULT_LOCKIN_FLOW,
  lockInMMFlow: DEFAULT_LOCKIN_MM_FLOW,
  lockInAppFlow: DEFAULT_LOCKIN_APP_FLOW,
  voucher: DEFAULT_VOUCHER_DISCOUNT,
  configuredStickyStepperLabel: '',
  checkoutErrors: DEFAULT_CHECKOUT_ERRORS,
  product: DEFAULT_PRODUCT as IProduct,
  isShowCheckout: DEFAULT_IS_SHOW_CHECKOUT,
  authorizeFlow: DEFAULT_AUTHORIZE_FLOW,
  isStartLockInMMFlow: DEFAULT_IS_START_LOCKIN_MM_FLOW,
  isStartLockInFlow: DEFAULT_IS_START_LOCKIN_FLOW,
  isStartLockInAppFlow: DEFAULT_IS_START_LOCKIN_APP_FLOW,
  billingEmail: DEFAULT_BILLING_EMAIL,
  addons: [],
  setCustomerId: () => {},
  setOwningData: () => {},
  setContactInfo: () => {},
  setPortability: () => {},
  setPortabilityStatus: () => {},
  setAddonState: () => {},
  setConsents: () => {},
  setBillingAddress: () => {},
  setInstallationAddress: () => {},
  setShippingAddress: () => {},
  setFindCaller: () => {},
  setIsLastCard: () => {},
  setIsFlowReady: () => {},
  setTermsAndConditions: () => {},
  setInvoice: () => {},
  printContext: () => {},
  setSlidesGoBack: () => {},
  setUpfrontSelection: () => {},
  setCurrentStepKey: () => {},
  serializeContext: () => {},
  deleteSerializedContext: () => {},
  checkInitContext: () => false,
  setIsDisabledBackButton: () => {},
  initContext: () => false,
  setOfferId: () => {},
  setAddress: () => {},
  setPickupPoint: () => {},
  setSelectedPickupPoint: () => {},
  setAvailablePaymentMethods: () => {},
  setHideStickyBar: () => {},
  setUpdateProductPriceCtrl: () => {},
  setKeepsNumber: () => {},
  setIsValidNumberPortability: () => false,
  setBillDeliveryDone: () => false,
  setUpfrontPayment: () => {},
  setIsLoadingCart: () => {},
  setIsPreselectedCard: () => false,
  setPickupLocationStore: () => {},
  setIsStartRecognition: () => {},
  setDeliveryAdvance: () => {},
  setBillingReplace: () => {},
  setIsNewShippingAddress: () => {},
  setIsStartDeliveryBase: () => {},
  setCartAsyncInfo: () => {},
  setCustomerDeliveryBaseInfo: () => {},
  setCustomerAsyncInfo: () => {},
  setAuthorizationAsyncInfo: () => {},
  setIsStartAuthorizationAsyncInfo: () => {},
  setIsModalButtonSticky: () => {},
  setIsStartCustomerFlow: () => {},
  setCustomerFlow: () => {},
  setLockInMMFlow: () => {},
  setLockInFlow: () => {},
  setLockInAppFlow: () => {},
  setVoucher: () => {},
  setConfiguredStickyStepperLabel: () => {},
  setCheckoutErrors: () => {},
  setProduct: () => {},
  setIsShowCheckout: () => {},
  setAuthorizeFlow: () => {},
  setIsStartLockInMMFlow: () => {},
  setIsStartLockInFlow: () => {},
  setIsStartLockInAppFlow: () => {},
  setBillingEmail: () => {},
  setAddons: () => {},
});

export const CheckoutProvider = ({ children }: ICheckoutProvider) => {
  const [customerId, setCustomerIdData] = useState<string>('');

  const [owningData, setCheckoutModalData] = useState<IPersonalDataObject>({
    ...DEFAULT_DATA,
  });

  const [contactInfo, setContact] = useState<IContactInfo>({
    ...DEFAULT_CONTACT_INFO,
  });

  const [portability, setPortabilityData] = useState<IPortabilityObject>({
    ...DEFAULT_PORTABILITY,
  });

  const [portabilityStatus, setPortabilityStatusData] = useState<IPortabilityStatus>({
    ...DEFAULT_PORTABILITY_STATUS,
  });

  const [addonState, setAddonState] = useState<IAddonState>({
    ...DEFAULT_ADDON_STATE,
  })

  const [consents, setConsents] = useState<IConsentsObject>({
    ...DEFAULT_CONSENTS,
  });

  const [winBackNumber, setWinBackNumber] = useState(DEFAULT_WINBACK_NUMBER);

  const [billingAddress, setBilling] = useState<IAddressDataObject>({
    ...DEFAULT_BILLING_ADDRESS,
  });

  const [billingEmail, setBillingEmailData] = useState(DEFAULT_BILLING_EMAIL);

  const [shippingAddress, setShipping] = useState<IAddressDataObject>({
    ...DEFAULT_SHIPPING_ADDRESS,
  });

  const [installationAddress, setInstallation] = useState<IAddressDataObject>({
    ...DEFAULT_INSTALLATION_ADDRESS,
  });

  const [findCaller, setCaller] = useState<IFindCallerObject>({
    ...DEFAULT_FIND_CALLER,
  });

  const [isLastCard, setLastCard] = useState<boolean>(DEFAULT_LAST_CARD);

  const [isFlowReady, setFlowReady] = useState<boolean>(DEFAULT_IS_FLOW_READY);

  const [termsAndConditions, setTermsAndConditionsData] = useState<ITermsAndConditionsCheck>(
    DEFAULT_TERMS_AND_CONDITIONS
  );

  const [invoice, setInvoiceOption] = useState<IInvoiceObject>(DEFAULT_INVOICE);

  const [isLoadingCart, setIsLoadingCartData] = useState<boolean>(true);

  const [slidesGoBack, setSlidesGoBackData] = useState<number>(DEFAULT_SLIDES_GO_BACK);

  const [upfrontSelection, setUpfrontSelectionOption] =
    useState<IUpfrontSelection>(DEFAULT_UPFRONT_SELECTION);

  const [currentStepKey, setCurrentStepKeyData] = useState<string>(DEFAULT_CURRENT_STEP_KEY);

  const [isDisabledBackButton, setIsDisabledBack] = useState<boolean>(
    DEFAULT_IS_DISABLED_BACK_BUTTON
  );

  const [isPreselectedCard, setIsPreselectedCardData] = useState<boolean>(false);

  const [offerId, setOfferIdData] = useState<string>(DEFAULT_OFFER_ID);

  const [address, setInfoAddress] = useState<IInputs>({
    ...DEFAULT_ADDRESS,
  });

  const [pickupPoint, setPickupPointData] = useState<IPickUpLocation>({
    ...DEFAULT_PICKUP_POINT,
  });

  const [selectedPickupPoint, setSelectedPickupPointData] = useState<IPickUpLocation>({
    ...DEFAULT_PICKUP_POINT,
  });

  const [hideStickyBar, setHideStickyBarData] = useState<boolean>(false);

  const [updateProductPriceCtrl, setUpdateProductPriceCtrlData] =
    useState<IUpdateProductPriceControl>(DEFAULT_UPDATE_PRODUCT_PRICE);

  const [availablePaymentMethods, setAvailablePaymentMethodsData] = useState<
    IAvailablePaymentMethods[]
  >([]);

  const [keepsNumber, setKeepsNumber] = useState<IOptionalText>();

  const [isValidNumberPortability, setIsValidNumberPortabilityData] = useState<boolean>(
    DEFAULT_IS_VALID_NUMBER_PORTABILITY
  );

  const [billDeliveryDone, setBillDeliveryDoneData] = useState<boolean>(false);

  const [upfrontPayment, setUpfrontPaymentData] = useState<IPayMean | undefined>(undefined);

  const [pickupLocationStore, setPickupLocationStoreData] = useState<IPickUpLocationStore>({
    ...DEFAULT_PICKUP_LOCATION_STORE,
  });

  const [isStartRecognition, setIsStartRecognitionData] = useState<boolean>(
    DEFAULT_IS_START_RECOGNITION
  );

  const [isNewShippingAddress, setIsNewShippingAddressData] =
    useState<boolean>(DEFAULT_IS_NEW_ADDRESS);

  const [isStartDeliveryBase, setIsStartDeliveryBaseData] = useState<boolean>(
    DEFAULT_IS_START_DELIVERY_BASE
  );

  const [isStartAuthorizationAsyncInfo, setIsStartAuthorizationAsyncInfoData] = useState<boolean>(
    DEFAULT_IS_START_AUTHORIZATION_ASYNC_INFO
  );

  const [customerDeliveryBaseInfo, setCustomerDeliveryBaseInfoData] =
    useState<IUseCustomerDeliveryBaseResponse>(DEFAULT_CUSTOMER_DELIVERY_BASE_INFO);

  const [billingReplace, setBillingReplaceData] =
    useState<IUseDeliveryBillingResponse>(DEFAULT_BILLING_REPLACE);

  const [deliveryAdvance, setDeliveryAdvanceData] =
    useState<IDeliveryAdvance>(DEFAULT_DELIVERY_ADVANCE);

  const [cartAsyncInfo, setCartAsyncInfoData] =
    useState<IUseCartAsyncInformationResponse>(DEFAULT_CART_ASYNC_INFO);

  const [customerAsyncInfo, setCustomerAsyncInfoData] =
    useState<IUseCustomerAsyncInformationResponse>(DEFAULT_CUSTOMER_ASYNC_INFO);

  const [authorizationAsyncInfo, setAuthorizationAsyncInfoData] =
    useState<IUseAuthorizationAsyncInformationResponse>(DEFAULT_AUTHORIZATION_ASYNC_INFO);

  const [isModalButtonSticky, setIsModalButtonStickyStatus] = useState<boolean>(false);

  const [isStartCustomerFlow, setIsStartCustomerFlowData] = useState<boolean>(false);

  const [isStartLockInMMFlow, setIsStartLockInMMFlowData] = useState<boolean>(false);

  const [isStartLockInFlow, setIsStartLockInFlowData] = useState<boolean>(false);

  const [isStartLockInAppFlow, setIsStartLockInAppFlowData] = useState<boolean>(false);

  const [customerFlow, setCustomerFlowData] = useState<IUseCustomerResponse>(DEFAULT_CUSTOMER_FLOW);

  const [lockInMMFlow, setLockInMMFlowData] =
    useState<IUseLockInMMFlowResponse>(DEFAULT_LOCKIN_MM_FLOW);

  const [lockInFlow, setLockInFlowData] = useState<IUseLockInFlowResponse>(DEFAULT_LOCKIN_FLOW);

  const [lockInAppFlow, setLockInAppFlowData] =
    useState<IUseLockInAppFlowResponse>(DEFAULT_LOCKIN_APP_FLOW);

  const [voucher, setVoucherData] = useState<IUseVoucherDiscountResponse>(DEFAULT_VOUCHER_DISCOUNT);

  const [authorizeFlow, setAuthorizeData] = useState<IUseAuthorizeFlow>(DEFAULT_AUTHORIZE_FLOW);

  const [checkoutErrors, setCheckoutErrorsData] =
    useState<IUseCheckoutErrors>(DEFAULT_CHECKOUT_ERRORS);

  const [configuredStickyStepperLabel, setConfiguredStickyStepperLabel] = useState('');

  const [product, setProductData] = useState<IProduct>(DEFAULT_PRODUCT as IProduct);

  const [isShowCheckout, setIsShowCheckoutData] = useState<boolean>(DEFAULT_IS_SHOW_CHECKOUT);

  const [addons, setAddonsData] = useState<string[]>([]);

  const setCustomerId = (customerIdData: string) => {
    setCustomerIdData(customerIdData);
  };

  const setOwningData = (checkoutData: IPersonalDataObject) => {
    localStorage.setItem('name_cus', checkoutData?.owningIndividual?.firstName);
    setCheckoutModalData({ ...checkoutData });
  };

  const setContactInfo = (contactData: IContactInfo) => {
    setContact({ ...contactData });
  };

  const setPortability = (portabilityData: IPortabilityObject) => {
    setPortabilityData({ ...portabilityData });
  };

  const setPortabilityStatus = (portabilityStatusData: IPortabilityStatus) => {
    setPortabilityStatusData({ ...portabilityStatusData });
  };

  const setBillingAddress = (billingAddressData: IAddressDataObject) => {
    setBilling({ ...billingAddressData });
  };

  const setBillingEmail = (emailData: string) => {
    setBillingEmailData(emailData);
  };

  const setInstallationAddress = (installationAddressData: IAddressDataObject) => {
    setInstallation({ ...installationAddressData });
  };

  const setShippingAddress = (shippingAddressData: IAddressDataObject) => {
    setShipping({ ...shippingAddressData });
  };

  const setFindCaller = (findCallerData: IFindCallerObject) => {
    setCaller({ ...findCallerData });
  };

  const setIsLastCard = (isLastCardLocal: boolean) => {
    setLastCard(isLastCardLocal);
  };

  const setIsFlowReady = (isFlowReadyLocal: boolean) => {
    setFlowReady(isFlowReadyLocal);
  };

  const setTermsAndConditions = (termsAndConditionsLocal: ITermsAndConditionsCheck) => {
    setTermsAndConditionsData({ ...termsAndConditionsLocal });
  };

  const setInvoice = (invoiceLocal: IInvoiceObject) => {
    setInvoiceOption({ ...invoiceLocal });
  };

  const setSlidesGoBack = (slidesBack: number) => {
    setSlidesGoBackData(slidesBack);
  };

  const setUpfrontSelection = (upfrontSelectionLocal: IUpfrontSelection) => {
    setUpfrontSelectionOption(upfrontSelectionLocal);
  };

  const setCurrentStepKey = (currentStepKeyLocal: string) => {
    setCurrentStepKeyData(currentStepKeyLocal);
  };

  const setIsDisabledBackButton = (disabledBackButton: boolean) => {
    setIsDisabledBack(disabledBackButton);
  };

  const setIsNewShippingAddress = (isNewShippingAddressData: boolean) => {
    setIsNewShippingAddressData(isNewShippingAddressData);
  };

  const setIsValidNumberPortability = (isValid: boolean) => {
    setIsValidNumberPortabilityData(isValid);
  };

  const setBillDeliveryDone = (billDelivery: boolean) => {
    setBillDeliveryDoneData(billDelivery);
  };

  const setUpfrontPayment = (upfrontPaymentLocal: IPayMean | undefined) => {
    setUpfrontPaymentData(upfrontPaymentLocal);
  };

  const setIsPreselectedCard = (isPreselectedC: boolean) => {
    setIsPreselectedCardData(isPreselectedC);
  };

  const setOfferId = (offerIdData: string) => {
    setOfferIdData(offerIdData);
  };

  const setAddress = (addressInfo: IInputs) => {
    setInfoAddress({ ...addressInfo });
  };

  const setAvailablePaymentMethods = (availablePaymentMethodsData: IAvailablePaymentMethods[]) => {
    setAvailablePaymentMethodsData(availablePaymentMethodsData);
  };

  const setPickupPoint = (pickupPointLocal: IPickUpLocation) => {
    setPickupPointData(pickupPointLocal);
  };

  const setSelectedPickupPoint = (selectedPickupPointLocal: IPickUpLocation) => {
    setSelectedPickupPointData(selectedPickupPointLocal);
  };

  const setHideStickyBar = (hSB: boolean) => {
    setHideStickyBarData(hSB);
  };

  const setUpdateProductPriceCtrl = (updateProductPriceCtrlState: IUpdateProductPriceControl) => {
    setUpdateProductPriceCtrlData(updateProductPriceCtrlState);
  };

  const setIsLoadingCart = (isLoadC: boolean) => {
    setIsLoadingCartData(isLoadC);
  };

  const setPickupLocationStore = (pickuplocationStoreData: IPickUpLocationStore) => {
    setPickupLocationStoreData(pickuplocationStoreData);
  };

  const setIsStartRecognition = (isStartRecognitionData: boolean) => {
    setIsStartRecognitionData(isStartRecognitionData);
  };

  const setDeliveryAdvance = (deliveryAdvanceData: IDeliveryAdvance) => {
    setDeliveryAdvanceData(deliveryAdvanceData);
  };

  const setBillingReplace = (bP: IUseDeliveryBillingResponse) => {
    setBillingReplaceData(bP);
  };

  const setIsStartDeliveryBase = (isDb: boolean) => {
    setIsStartDeliveryBaseData(isDb);
  };

  const setCartAsyncInfo = (cAInfo: IUseCartAsyncInformationResponse) => {
    setCartAsyncInfoData(cAInfo);
  };

  const setCustomerDeliveryBaseInfo = (cDBInfo: IUseCustomerDeliveryBaseResponse) => {
    setCustomerDeliveryBaseInfoData(cDBInfo);
  };

  const setCustomerAsyncInfo = (cAsyncInfo: IUseCustomerAsyncInformationResponse) => {
    setCustomerAsyncInfoData(cAsyncInfo);
  };

  const setAuthorizationAsyncInfo = (aAsyncInfo: IUseAuthorizationAsyncInformationResponse) => {
    setAuthorizationAsyncInfoData(aAsyncInfo);
  };

  const setIsStartAuthorizationAsyncInfo = (iSAsyncInfo: boolean) => {
    setIsStartAuthorizationAsyncInfoData(iSAsyncInfo);
  };

  const setIsModalButtonSticky = (isModalButtonStickyProps: boolean) => {
    setIsModalButtonStickyStatus(isModalButtonStickyProps);
  };

  const setIsStartCustomerFlow = (isStartCustomerFlowData: boolean) => {
    setIsStartCustomerFlowData(isStartCustomerFlowData);
  };

  const setIsStartLockInMMFlow = (isStartLockInMMFlowData: boolean) => {
    setIsStartLockInMMFlowData(isStartLockInMMFlowData);
  };

  const setIsStartLockInFlow = (isStartLockInFlowData: boolean) => {
    setIsStartLockInFlowData(isStartLockInFlowData);
  };

  const setIsStartLockInAppFlow = (isStartLockInAppFlowData: boolean) => {
    setIsStartLockInAppFlowData(isStartLockInAppFlowData);
  };

  const setCustomerFlow = (customerFlowData: IUseCustomerResponse) => {
    setCustomerFlowData(customerFlowData);
  };

  const setLockInMMFlow = (lockInMMFlowData: IUseLockInMMFlowResponse) => {
    setLockInMMFlowData(lockInMMFlowData);
  };

  const setLockInFlow = (lockInFlowData: IUseLockInFlowResponse) => {
    setLockInFlowData(lockInFlowData);
  };

  const setLockInAppFlow = (lockInAppFlowData: IUseLockInAppFlowResponse) => {
    setLockInAppFlowData(lockInAppFlowData);
  };

  const setVoucher = (voucherData: IUseVoucherDiscountResponse) => {
    setVoucherData(voucherData);
  };

  const setAuthorizeFlow = (pAF: IUseAuthorizeFlow) => {
    setAuthorizeData(pAF);
  };

  const setProduct = (prodData: IProduct) => {
    updateProduct(prodData);
    setProductData(prodData);
  };

  const setAddons = (addonsData: string[]) => {
    setAddonsData(addonsData);
  }

  const setCheckoutErrors = (cErr: IUseCheckoutErrors) => {
    setCheckoutErrorsData(cErr);
  };

  const setIsShowCheckout = (isSC: boolean) => {
    setIsShowCheckoutData(isSC);
  };

  const printContext = () => {
    LoggerInstance.info('context', {
      customerId,
      owningData: {
        ...owningData,
      },
      portability: {
        ...portability,
      },
      portabilityStatus: {
        ...portabilityStatus,
      },
      addonState: {
        ...addonState
      },
      findCaller: {
        ...findCaller,
      },
      termsAndConditions: {
        ...termsAndConditions,
      },
      invoice: {
        ...invoice,
      },
      billingAddress: {
        ...billingAddress,
      },
      billingEmail,
      shippingAddress: {
        ...shippingAddress,
      },
      installationAddress: {
        ...installationAddress,
      },
      consents: {
        ...consents,
      },
      contactInfo: {
        ...contactInfo,
      },
      upfrontSelection: {
        ...upfrontSelection,
      },
      isLastCard: isLastCard || false,
      isFlowReady: isFlowReady || true,
      winBackNumber: winBackNumber || '',
      address: {
        ...address,
      },
      pickupPoint: {
        ...pickupPoint,
      },
      selectedPickupPoint: {
        ...selectedPickupPoint,
      },
      offerId,
      availablePaymentMethods,
      updateProductPriceCtrl: {
        ...updateProductPriceCtrl,
      },
      upfrontPayment: {
        ...upfrontPayment,
      },
      pickupLocationStore: {
        ...pickupLocationStore,
      },
      deliveryAdvance: {
        ...deliveryAdvance,
      },
      billingReplace: {
        ...billingReplace,
      },
      cartAsyncInfo: {
        ...cartAsyncInfo,
      },
      customerDeliveryBaseInfo: {
        ...customerDeliveryBaseInfo,
      },
      customerAsyncInfo: {
        ...customerAsyncInfo,
      },
      authorizationAsyncInfo: {
        ...authorizationAsyncInfo,
      },
      customerFlow: {
        ...customerFlow,
      },
      lockInMMFlow: {
        ...lockInMMFlow,
      },
      lockInFlow: {
        ...lockInFlow,
      },
      lockInAppFlow: {
        ...lockInAppFlow,
      },
      voucher: {
        ...voucher,
      },
      product: {
        ...product,
      },
      authorizeFlow: {
        ...authorizeFlow,
      },
      isValidNumberPortability,
      billDeliveryDone,
      currentStepKey,
      isLoadingCart,
      isStartRecognition,
      isNewShippingAddress,
      isStartDeliveryBase,
      isStartAuthorizationAsyncInfo,
      isStartCustomerFlow,
      isStartLockInMMFlow,
      isStartLockInFlow,
      isStartLockInAppFlow,
      isShowCheckout,
      configuredStickyStepperLabel,
    });
  };

  /**
   * This method is used to serialize context in local storage in base64
   */
  const serializeContext = () => {
    const contextValue = getValueToEncode(
      btoa(
        encodeURIComponent(
          JSON.stringify({
            customerId,
            owningData: {
              ...owningData,
            },
            portability: {
              ...portability,
            },
            portabilityStatus: {
              ...portabilityStatus,
            },
            addonState: {
              ...addonState,
            },
            findCaller: {
              ...findCaller,
            },
            termsAndConditions: {
              ...termsAndConditions,
            },
            invoice: {
              ...invoice,
            },
            billingAddress: {
              ...billingAddress,
            },
            billingEmail,
            shippingAddress: {
              ...shippingAddress,
            },
            installationAddress: {
              ...installationAddress,
            },
            consents: {
              ...consents,
            },
            contactInfo: {
              ...contactInfo,
            },
            upfrontSelection: {
              ...upfrontSelection,
            },
            isLastCard: isLastCard || false,
            isFlowReady: isFlowReady || true,
            winBackNumber: winBackNumber || '',
            address: {
              ...address,
            },
            pickupPoint: {
              ...pickupPoint,
            },
            selectedPickupPoint: {
              ...selectedPickupPoint,
            },
            offerId,
            availablePaymentMethods,
            updateProductPriceCtrl: {
              ...updateProductPriceCtrl,
            },
            upfrontPayment: {
              ...upfrontPayment,
            },
            pickupLocationStore: {
              ...pickupLocationStore,
            },
            deliveryAdvance: {
              ...deliveryAdvance,
            },
            billingReplace: {
              ...billingReplace,
            },
            cartAsyncInfo: {
              ...cartAsyncInfo,
            },
            customerDeliveryBaseInfo: {
              ...customerDeliveryBaseInfo,
            },
            customerAsyncInfo: {
              ...customerAsyncInfo,
            },
            authorizationAsyncInfo: {
              ...authorizationAsyncInfo,
            },
            customerFlow: {
              ...customerFlow,
            },
            lockInMMFlow: {
              ...lockInMMFlow,
            },
            lockInFlow: {
              ...lockInFlow,
            },
            lockInAppFlow: {
              ...lockInAppFlow,
            },
            voucher: {
              ...voucher,
            },
            authorizeFlow: {
              ...authorizeFlow,
            },
            isValidNumberPortability,
            billDeliveryDone,
            currentStepKey,
            isLoadingCart,
            isStartRecognition,
            isNewShippingAddress,
            isStartDeliveryBase,
            isStartAuthorizationAsyncInfo,
            isModalButtonSticky,
            isStartCustomerFlow,
            isStartLockInMMFlow,
            isStartLockInFlow,
            isStartLockInAppFlow,
            isShowCheckout,
            configuredStickyStepperLabel,
            addons
          })
        )
      )
    );
    localStorage.setItem(CONTEXT_KEY, contextValue);
  };

  /**
   * This methos is used to delete the context key from local storage
   */
  const deleteSerializedContext = () => {
    localStorage.removeItem(CONTEXT_KEY);
  };

  /**
   * Method to check context exists
   */
  const checkInitContext = (): boolean => {
    const ctx = localStorage.getItem(CONTEXT_KEY);
    return !!ctx;
  };

  /**
   * Method to init context if exists
   */
  const initContext = (initContextData?: IInitContext): boolean => {
    const { customerData, countries, isLogged } = initContextData || {};
    let owningIndividual: IOwningIndividual;
    let isValid = false;
    if (customerData && countries) {
      isValid = !!isLogged;
      const cusData = Array.isArray(customerData) ? customerData?.[0] : customerData;
      owningIndividual = { ...retrieveOwningIndividual(cusData, countries) };
    }
    const ctx = getSerializeContext();
    setTimeout(() => {
      const localProduct = retrieveProduct() as IProduct;
      setCustomerId(ctx?.customerId || DEFAULT_CUSTOMER_ID);
      setOwningData(
        {
          ...ctx?.owningData,
          owningIndividual: {
            ...ctx?.owningData?.owningIndividual,
            ...owningIndividual,
          },
          isLogged: ctx?.owningData?.isLogged || isLogged,
          isValid: ctx?.owningData?.isValid || isValid,
        } || DEFAULT_DATA
      );
      setContactInfo(ctx?.contactInfo || DEFAULT_CONTACT_INFO);
      setPortability(ctx?.portability || DEFAULT_PORTABILITY);
      setPortabilityStatus(ctx?.portabilityStatus || DEFAULT_PORTABILITY_STATUS);
      setAddonState(ctx?.addonState || DEFAULT_ADDON_STATE);
      setConsents(ctx?.consents || DEFAULT_CONSENTS);
      setFindCaller(ctx?.findCaller || DEFAULT_FIND_CALLER);
      setIsLastCard(ctx?.isLastCard || false);
      setIsFlowReady(ctx?.isFlowReady || true);
      setTermsAndConditions(
        { ...termsAndConditions, ...ctx?.termsAndConditions } || DEFAULT_TERMS_AND_CONDITIONS
      );
      setInvoice(ctx?.invoice || DEFAULT_INVOICE);
      setBillingAddress(ctx?.billingAddress || getLocalAddress() || DEFAULT_BILLING_ADDRESS);
      setBillingEmail(ctx?.billingEmail || DEFAULT_BILLING_EMAIL);
      setInstallationAddress(ctx?.installationAddress || DEFAULT_INSTALLATION_ADDRESS);
      setShippingAddress(ctx?.shippingAddress || getLocalAddress() || DEFAULT_SHIPPING_ADDRESS);
      setPickupPoint(ctx?.pickupPoint || DEFAULT_PICKUP_POINT);
      setSelectedPickupPoint(ctx?.selectedPickupPoint || DEFAULT_PICKUP_POINT);
      setCurrentStepKey(ctx?.currentStepKey || DEFAULT_CURRENT_STEP_KEY);
      setUpfrontSelection(ctx?.upfrontSelection || DEFAULT_UPFRONT_SELECTION);
      setAvailablePaymentMethods(ctx?.availablePaymentMethods || []);
      setUpdateProductPriceCtrl({ ...ctx?.updateProductPriceCtrl } || DEFAULT_UPDATE_PRODUCT_PRICE);
      setIsValidNumberPortability(
        ctx?.isValidNumberPortability || DEFAULT_IS_VALID_NUMBER_PORTABILITY
      );
      setBillDeliveryDone(ctx?.billDeliveryDone || DEFAULT_IS_VALID_NUMBER_PORTABILITY);
      setUpfrontPayment(ctx?.upfrontPayment || undefined);
      setIsLoadingCart(ctx?.isLastCard ? false : ctx?.isLoadingCart || true);
      setIsPreselectedCard(ctx?.isPreselectedCard || false);
      setWinBackNumber(ctx?.winBackNumber || DEFAULT_WINBACK_NUMBER);
      setPickupLocationStore(ctx?.pickupLocationStore || DEFAULT_PICKUP_LOCATION_STORE);
      setIsStartRecognition(ctx?.isStartRecognition || DEFAULT_IS_START_RECOGNITION);
      setDeliveryAdvance(ctx?.deliveryAdvance || DEFAULT_DELIVERY_ADVANCE);
      setBillingReplace(ctx?.billingReplace || DEFAULT_BILLING_REPLACE);
      setIsNewShippingAddress(ctx?.isNewShippingAddress || DEFAULT_IS_NEW_ADDRESS);
      setIsStartDeliveryBase(ctx?.isStartDeliveryBase || DEFAULT_IS_START_DELIVERY_BASE);
      setCartAsyncInfo(ctx?.cartAsyncInfo || DEFAULT_CART_ASYNC_INFO);
      setCustomerDeliveryBaseInfo(
        ctx?.customerDeliveryBaseInfo || DEFAULT_CUSTOMER_DELIVERY_BASE_INFO
      );
      setCustomerAsyncInfo(ctx?.customerAsyncInfo || DEFAULT_CUSTOMER_ASYNC_INFO);
      setAuthorizationAsyncInfo(ctx?.authorizationAsyncInfo || DEFAULT_AUTHORIZATION_ASYNC_INFO);
      setIsStartAuthorizationAsyncInfo(
        ctx?.isStartAuthorizationAsyncInfo || DEFAULT_IS_START_AUTHORIZATION_ASYNC_INFO
      );
      setIsModalButtonSticky(ctx?.isModalButtonSticky || false);
      setIsStartCustomerFlow(ctx?.isStartCustomerFlow || false);
      setIsStartLockInMMFlow(ctx?.isStartLockInMMFlow || false);
      setIsStartLockInFlow(ctx?.isStartLockInFlow || false);
      setIsStartLockInAppFlow(ctx?.isStartLockInAppFlow || false);
      setCustomerFlow(ctx?.customerFlow || DEFAULT_CUSTOMER_FLOW);
      setLockInMMFlow(ctx?.lockInMMFlow || DEFAULT_LOCKIN_MM_FLOW);
      setLockInFlow(ctx?.lockInFlow || DEFAULT_LOCKIN_FLOW);
      setLockInAppFlow(ctx?.lockInAppFlow || DEFAULT_LOCKIN_APP_FLOW);
      setVoucher(ctx?.voucher || DEFAULT_VOUCHER_DISCOUNT);
      setConfiguredStickyStepperLabel(ctx?.configuredStickyStepperLabel || '');
      if (localProduct) setProduct(localProduct);
      setAuthorizeFlow(ctx?.authorizeFlow || DEFAULT_AUTHORIZE_FLOW);
      setAddons([]);
    }, 0);
    return true;
  };

  return (
    <CheckoutContext.Provider
      /* eslint-disable-next-line react/jsx-no-constructed-context-values */
      value={{
        customerId,
        setCustomerId,
        owningData,
        setOwningData,
        contactInfo,
        setContactInfo,
        portability,
        setPortability,
        portabilityStatus,
        setPortabilityStatus,
        addonState,
        setAddonState,
        consents,
        setConsents,
        findCaller,
        setFindCaller,
        isLastCard,
        setIsLastCard,
        isFlowReady,
        setIsFlowReady,
        termsAndConditions,
        setTermsAndConditions,
        invoice,
        setInvoice,
        slidesGoBack,
        setSlidesGoBack,
        currentStepKey,
        setCurrentStepKey,
        serializeContext,
        deleteSerializedContext,
        printContext,
        checkInitContext,
        initContext,
        upfrontSelection,
        setUpfrontSelection,
        isDisabledBackButton,
        setIsDisabledBackButton,
        offerId,
        setOfferId,
        address,
        setAddress,
        pickupPoint,
        setPickupPoint,
        selectedPickupPoint,
        setSelectedPickupPoint,
        billingAddress,
        setBillingAddress,
        installationAddress,
        setInstallationAddress,
        shippingAddress,
        setShippingAddress,
        availablePaymentMethods,
        setAvailablePaymentMethods,
        hideStickyBar,
        setHideStickyBar,
        keepsNumber,
        setKeepsNumber,
        updateProductPriceCtrl,
        setUpdateProductPriceCtrl,
        isValidNumberPortability,
        setIsValidNumberPortability,
        billDeliveryDone,
        setBillDeliveryDone,
        upfrontPayment,
        setUpfrontPayment,
        isLoadingCart,
        setIsLoadingCart,
        isPreselectedCard,
        setIsPreselectedCard,
        deliveryAdvance,
        setDeliveryAdvance,
        pickupLocationStore,
        setPickupLocationStore,
        isStartRecognition,
        setIsStartRecognition,
        billingReplace,
        setBillingReplace,
        isNewShippingAddress,
        setIsNewShippingAddress,
        isStartDeliveryBase,
        setIsStartDeliveryBase,
        cartAsyncInfo,
        setCartAsyncInfo,
        customerDeliveryBaseInfo,
        setCustomerDeliveryBaseInfo,
        customerAsyncInfo,
        setCustomerAsyncInfo,
        authorizationAsyncInfo,
        setAuthorizationAsyncInfo,
        isStartAuthorizationAsyncInfo,
        setIsStartAuthorizationAsyncInfo,
        isModalButtonSticky,
        setIsModalButtonSticky,
        isStartCustomerFlow,
        setIsStartCustomerFlow,
        customerFlow,
        setCustomerFlow,
        voucher,
        setVoucher,
        configuredStickyStepperLabel,
        setConfiguredStickyStepperLabel,
        checkoutErrors,
        setCheckoutErrors,
        product,
        setProduct,
        isShowCheckout,
        setIsShowCheckout,
        authorizeFlow,
        setAuthorizeFlow,
        isStartLockInMMFlow,
        setIsStartLockInMMFlow,
        isStartLockInFlow,
        setIsStartLockInFlow,
        isStartLockInAppFlow,
        setIsStartLockInAppFlow,
        lockInMMFlow,
        setLockInMMFlow,
        lockInFlow,
        setLockInFlow,
        lockInAppFlow,
        setLockInAppFlow,
        billingEmail,
        setBillingEmail,
        addons,
        setAddons,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};

export const useCheckout = () => useContext(CheckoutContext);

