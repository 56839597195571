import { useEffect } from 'react';
import {
  CONSUMER_CMS_SHOPPING_CART,
  ErrorCodes,
  errorMock,
  IGenericErrorCMS,
  IPersonalInfoCmsMobile,
  IPortabilityMobileCMS,
  productSpecificError,
  useCmsConfig,
} from '@vfit/consumer/data-access';
import { API, getFromLocalStorageByKey, openPopup, trackView } from '@vfit/shared/data-access';
import { ICMSApiError, ICMSError, ICommonData, IOptionalText } from '@vfit/shared/models';
import { CheckboxCards } from '@vfit/consumer/components';
import { useCheckout } from '../../../iBuyMobile.context';
import { checkErrorCustomer } from '../../checkout.utils';
import { useAddressMobileFlow } from '../../hooks/UseAddressMobileFlow/useAddressMobileFlow';
import { IEditorialErrorConfig } from '../../checkout.models';
import { organizeSimTypeCard } from '../SimTypeSelectionCard/simTypeSelectionCard.utils';
import { ISimTypeCardData } from '../SimTypeSelectionCard/simTypeSelection.models';
import { ID_FLOWS } from '../../checkout.constants';
import TaggingSimTypeSelectionCardMnpSkip from './simTypeSelectionCardMnpSkip.tagging';

const SimTypeSelectionCardMnpSkip = ({
  handleOnChangeEnableGoNext,
  handleGoPrevSlide,
  handleOnClose,
}: ICommonData) => {
  const {
    simOptions,
    customerDeliveryBaseInfo,
    isModalButtonSticky,
    product,
    checkoutErrors,
    customerFlow,
    cartAsyncInfo,
    setCurrentStepKey,
    setSlidesGoBack,
    setSimTypeSelectedOption,
    setSimOptions,
    setBillingAddress,
    setShippingAddress,
    setIsStartCustomerFlow,
    setIsDisabledBackButton,
  } = useCheckout();
  const { urlGenericErrorCallMeNow } = productSpecificError(product);
  const isLoadingSim = cartAsyncInfo?.simTypeSelection?.isLoading || false;
  const genericError = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_GENERIC_ERROR_MOBILE
  ) as IGenericErrorCMS;
  const dataFromCms = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_PERSONAL_DATA_MOBILE
  ) as IPersonalInfoCmsMobile;
  const errorPortability = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_PORTABILITY_MOBILE
  ) as IPortabilityMobileCMS;
  const organizedDataForSim = organizeSimTypeCard(
    dataFromCms,
    errorPortability
  ) as ISimTypeCardData;
  const { errorcomponent: errorComponentOrder } =
    (useCmsConfig(
      CONSUMER_CMS_SHOPPING_CART,
      API.CMS_GET_RECURRENT_ORDER_ERROR_MOBILE
    ) as ICMSApiError) || {};
  const orderErrors = errorComponentOrder?.errors || [];

  const { errorcomponent: errorComponentCustomer } =
    (useCmsConfig(CONSUMER_CMS_SHOPPING_CART, API.CMS_GET_CUSTOMER_ERROR_MOBILE) as ICMSApiError) ||
    {};
  const customerErrors = errorComponentCustomer?.errors || [];

  const errors: ICMSError[] = [...orderErrors, ...customerErrors];

  const isLoadingCustomer =
    customerFlow?.postCustomer?.isLoading ||
    customerFlow?.patchCustomer?.isLoading ||
    customerFlow?.customerInfo?.isLoading ||
    customerFlow?.associateCustomer?.isLoading;
  const isErrorCustomer =
    customerFlow?.postCustomer?.isError ||
    customerFlow?.patchCustomer?.isError ||
    customerFlow?.customerInfo?.isError ||
    customerFlow?.associateCustomer?.isError;
  const errorCustomer =
    customerFlow?.postCustomer?.error ||
    customerFlow?.patchCustomer?.error ||
    customerFlow?.associateCustomer?.error;

  const {
    data: dataAddress,
    isSuccess: isSuccessAddress,
    isLoading: isLoadingAddress,
    isError: isErrorAddress,
  } = useAddressMobileFlow({
    onConfirmAddress: true,
  });

  const trackOpenModal = () => {
    trackView({
      event_name: 'ui_interaction',
      event_label_track: `e-sim option ${organizedDataForSim?.modalInfo?.textLink}`,
      event_category: 'users',
      event_action: 'click',
      tracking_type: 'link',
    });
  };

  const trackGoNext = () => {
    trackView({
      event_name: 'ui_interaction',
      event_label_track: `e-sim option ${
        simOptions?.simOptions?.find((simOption) => simOption?.selection)?.value || ''
      }`,
      event_category: 'users',
      event_action: 'click',
      tracking_type: 'link',
    });
  };

  const handleSelect = (item: IOptionalText | undefined) => {
    if (!item) return;
    const modifyMobileSimDeviceId = getFromLocalStorageByKey('modifyMobileSimDeviceId');
    const selectedOption = simOptions?.simOptions?.find((el) => el.value === item.value);
    if (
      selectedOption &&
      selectedOption?.id !== modifyMobileSimDeviceId?.simOptionId &&
      !isLoadingCustomer
    ) {
      setSimTypeSelectedOption(selectedOption);
    }
    setSimOptions({
      ...simOptions,
      simOptions: simOptions?.simOptions?.map((sO) => {
        if (sO.value === item?.value) {
          return {
            ...sO,
            selection: true,
          };
        }
        return {
          ...sO,
          selection: false,
        };
      }),
    });
  };

  const getSelectedItem = () => {
    const simOption = simOptions?.simOptions?.find((sOption) => sOption.selection);
    return {
      title: simOption?.title || '',
      value: simOption?.value || '',
    };
  };

  useEffect(() => {
    if (isSuccessAddress && dataAddress) {
      setBillingAddress(dataAddress);
      setShippingAddress(dataAddress);
      setIsStartCustomerFlow(true);
    }
  }, [isSuccessAddress, dataAddress]);

  useEffect(() => {
    if (isErrorCustomer) {
      const errorDetail: IEditorialErrorConfig = checkErrorCustomer(
        errors,
        errorCustomer?.errorCode || '',
        {
          title: genericError?.genericerror?.title || '',
          description: genericError?.genericerror?.description || '',
        }
      );
      const getAction = (isPopup?: boolean) => {
        customerFlow?.resetPatchAndPost?.(customerFlow);
        switch (errorCustomer?.errorCode) {
          case ErrorCodes.CREATE_CUSTOMER_FISCAL_CODE_ALREADY_PRESENT:
            setSlidesGoBack(4);
            break;
          case ErrorCodes.CREATE_CUSTOMER_DOCUMENT_ALREADY_PRESENT:
          case ErrorCodes.CREATE_CUSTOMER_MISSING_NATION_ADDRESS:
            if (errorDetail?.isPopup && isPopup) {
              openPopup(errorDetail?.url || urlGenericErrorCallMeNow, () => {
                if (handleOnClose) {
                  handleOnClose();
                }
              });
            } else {
              setSlidesGoBack(2);
            }
            break;
          case ErrorCodes.CREATE_CUSTOMER_PHONE_NUMBER_ALREADY_PRESENT:
          case ErrorCodes.CREATE_CUSTOMER_EMAIL_ADDRESS_ALREADY_PRESENT:
          case ErrorCodes.CREATE_CUSTOMER_CONTACT_ALREADY_PRESENT:
            setSlidesGoBack(3);
            break;
          case ErrorCodes.ASSOCIATE_CUSTOMER_BLOCKED:
            if (errorDetail?.isPopup && isPopup) {
              openPopup(errorDetail?.url || urlGenericErrorCallMeNow, () => {
                if (handleOnClose) {
                  handleOnClose();
                }
              });
            } else if (handleOnClose) {
              handleOnClose();
            }
            break;
          default:
            if (handleOnClose) {
              handleOnClose();
            }
            break;
        }
      };
      checkoutErrors?.showAndTrackError?.(
        {
          genericerror: {
            title: errorDetail?.title || '',
            description: errorDetail?.message || '',
            buttonLabel: errorDetail?.actionText || '',
          },
        },
        getAction,
        errorCustomer,
        '',
        '',
        () => getAction(true),
        ''
      );
    }
  }, [isErrorCustomer]);

  useEffect(() => {
    if (isErrorAddress) {
      const closeModalAction = () => {
        handleGoPrevSlide?.();
      };
      checkoutErrors?.showAndTrackError?.(
        genericError,
        closeModalAction,
        errorMock('address', { url: 'validateAddress', status: '500', statusText: '500' }),
        undefined,
        undefined,
        closeModalAction,
        // will change this ?
        'Chiudi',
        () => openPopup(product?.genericErrorCallMeNow?.url || '', closeModalAction),
        product?.removeCTC ? undefined : product?.genericErrorCallMeNow?.label,
        isModalButtonSticky,
        true
      );
    }
  }, [isErrorAddress]);

  useEffect(() => {
    setIsDisabledBackButton(isLoadingCustomer || isLoadingAddress || isLoadingSim);
  }, [isLoadingCustomer, isLoadingAddress, isLoadingSim]);

  useEffect(() => {
    const goNextButton = document.getElementById('sticky-offer-next');
    goNextButton?.addEventListener('click', trackGoNext);
    return () => {
      goNextButton?.removeEventListener('click', trackGoNext);
    };
  }, [simOptions]);

  useEffect(() => {
    handleOnChangeEnableGoNext?.(!isLoadingCustomer && !isLoadingAddress && !isLoadingSim);
  }, [
    isLoadingCustomer,
    isLoadingAddress,
    customerDeliveryBaseInfo?.modifySimDevice,
    isLoadingSim,
  ]);

  useEffect(() => {
    localStorage.setItem('portability', '0');
    const haveSelectedItem = simOptions.simOptions.find((simOption) => simOption.selection);
    handleOnChangeEnableGoNext?.(!!haveSelectedItem);
    setCurrentStepKey(ID_FLOWS.RECOGNITION_ESIM_SKIP_MNP);
  }, []);

  return (
    <>
      <TaggingSimTypeSelectionCardMnpSkip />
      <CheckboxCards
        title={organizedDataForSim.cardTitle}
        description={organizedDataForSim.cardDescription}
        items={simOptions?.simOptions?.map((sO) => ({
          title: sO.title || '',
          value: sO.value || '',
          extra: sO.highlight
            ? {
              text: organizedDataForSim.bestChoiceLabel || '',
              color: '#fff',
              extraClass: 'extraClass',
            }
            : undefined,
        }))}
        value={getSelectedItem().value}
        selectedItem={getSelectedItem()}
        setSelectedItem={() => {
          return;
        }}
        onSelectItem={handleSelect}
        bottomModal={organizedDataForSim.modalInfo}
        isSuccess={!isLoadingCustomer && !isLoadingAddress}
        onOpeneModal={trackOpenModal}
      />
    </>
  );
};

export default SimTypeSelectionCardMnpSkip;
