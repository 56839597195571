import { IValidationPortabilityMobile } from '@vfit/consumer/data-access';
import * as yup from 'yup';

export const portabilityNumberSchema = (validationMessage: IValidationPortabilityMobile) =>
  yup.object({
    phoneNumber: yup
      .string()
      .required(validationMessage?.phoneNumber?.required || '')
      .min(1, validationMessage?.phoneNumber?.minimum || '')
      .max(10,validationMessage?.phoneNumber?.maximum || '')
      .matches(/^0[1-9]([0-9]{6,8})/,validationMessage?.phoneNumber?.matches || ''),
  });
