import { useEffect, useState } from 'react';
import {
  CONSUMER_CMS_SHOPPING_CART,
  errorMock,
  IPortabilityMobileCMS,
  IProduct,
  useCmsConfig,
  useGenerateOTP,
  useVerifyOTP,
} from '@vfit/consumer/data-access';
import { API, trackView } from '@vfit/shared/data-access';
import { resetData } from '@vfit/shared/data-access';
import { useQueryClient } from 'react-query';
import { ICommonData } from '@vfit/shared/models';
import { organizeOtpPortability, Otp } from '@vfit/consumer/components';
import { useCheckout } from '../../../iBuyMobile.context';
import { OTP_CONSTANTS } from './portabilityOtpCard.utils';
import { getTrackError } from '../../checkout.utils';
import TaggingPortabilityOtpCard from './portabilityOtpCard.tagging';

const PortabilityOtpCard = ({ handleOnChangeEnableGoNext }: ICommonData) => {
  const queryClient = useQueryClient();
  const generateOtp = useGenerateOTP(OTP_CONSTANTS.MODULE_NAME);
  const { portability, product, setPortability } = useCheckout();
  const [isError, setIsError] = useState(false);
  const {
    mutate,
    data: verifyOtpData,
    isSuccess,
    isError: isErrorOtp,
    isLoading,
    error: verifyOtpErrorData,
  } = useVerifyOTP();

  const {
    portabilitymobile: { otp: dataFromCms },
  } = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_PORTABILITY_MOBILE
  ) as IPortabilityMobileCMS;

  const handleSendOtp = (field: string, fieldType: string) => {
    resetData(queryClient, ['generateOTP']);
    generateOtp.mutate({
      identifier: `39${field}`,
      identifierType: fieldType,
    });
  };

  const handleVerify = (otp: string) => {
    mutate(otp);
  };

  const handleSetError = () => {
    setIsError(false);
  };

  useEffect(() => {
    handleSendOtp(portability.portabilityNumber, OTP_CONSTANTS.FIELD_TYPE);
    return () => {
      resetData(queryClient, ['generateOTP', 'verifyOTP']);
    };
  }, []);

  useEffect(() => {
    if (generateOtp.isError && generateOtp.error) {
      trackView({
        ...getTrackError(errorMock('GenerateOtp', generateOtp.error), 'OTP', queryClient),
        page_error_code: generateOtp.error.status || '',
      });
    }
  }, [generateOtp.isError]);

  useEffect(() => {
    if (isErrorOtp || (!isErrorOtp && isSuccess && !verifyOtpData)) {
      setIsError(true);
      if (verifyOtpErrorData) {
        trackView({
          ...getTrackError(errorMock('VerifyOtp', verifyOtpErrorData), 'OTP', queryClient),
          page_error_code: verifyOtpErrorData.status || '',
        });
      }
    } else if (verifyOtpData?.id) {
      setPortability({
        ...portability,
        portabilityOtp: { isVerified: true, numberValidate: portability.portabilityNumber },
      });
      setTimeout(() => {
        handleOnChangeEnableGoNext?.(true);
      }, 500);
    }
  }, [isSuccess, verifyOtpData, isErrorOtp, verifyOtpErrorData]);

  return (
    <>
      <TaggingPortabilityOtpCard />
      <Otp
        product={product as IProduct}
        contactMedium={{
          field: portability.portabilityNumber,
          fieldType: OTP_CONSTANTS.FIELD_TYPE,
        }}
        isError={isError}
        isLoading={isLoading}
        reSendOtp={handleSendOtp}
        onVerify={handleVerify}
        setIsError={handleSetError}
        noAccessEnabled={false}
        configOtp={organizeOtpPortability(dataFromCms, true, {
          field: portability.portabilityNumber,
          fieldType: OTP_CONSTANTS.FIELD_TYPE,
        })}
      />
    </>
  );
};

export default PortabilityOtpCard;
