import { setTrackView, useTracking } from '@vfit/shared/data-access';
import { useQueryClient } from 'react-query';
import { getTagging, getUserInfo } from '../../checkout.utils';
import { withAnimationDelay } from '../../hoc/withAnimationDelay';

const TaggingComponent = () => {
  const queryClient = useQueryClient();
  const tagging = getTagging('document', 'fixed', queryClient);

  const { trackView } = useTracking({
    event: ['checkout_step3'],
    event_label: 'document',
    opts: tagging?.opts,
    cartProduct: tagging?.trackingProduct,
    pageProduct: tagging?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });
  setTrackView(trackView);
  return null;
};

const TaggingDocumentIdCard = withAnimationDelay()(TaggingComponent);

export default TaggingDocumentIdCard;
