import { UseQueryResult } from 'react-query';
import {
  nextClient,
  CustomOptions,
  UseQueryConfig,
  handleUseQuery,
  INextError,
  LoggerInstance,
} from '@vfit/shared/data-access';
import { API, getFromLocalStorageByKey } from '@vfit/shared/data-access';
import {
  errorMock,
  GetCustomerResponse,
  IBillingAccountResponse,
  IFindCallerResponse,
  IGlobalConfigCMS,
} from '@vfit/consumer/data-access';

/**
 * This method is used run the billing account service from the customerId.
 * Method: GET
 * SearchParams: salesChannel: selfService, checkExistingInstallment: true
 * @param customerId
 * @param customOptions
 * @returns
 */
const billingAccountService = (
  customerId: string,
  customOptions?: CustomOptions,
  isNewMopEnabled?: boolean
) =>
  nextClient.get(`${API.CUSTOMER}/${customerId}/${API.BILLING_ACCOUNT}`, {
    searchParams: {
      salesChannel: 'selfService',
      checkExistingInstallment: true,
      ...(isNewMopEnabled && { checkOnGoingTNPDevice: true }),
    },
    ...(customOptions?.headers && { headers: customOptions.headers }),
    ...(customOptions?.silentLoginHeaders && {
      silentLoginHeaders: customOptions.silentLoginHeaders,
    }),
  }) as Promise<IBillingAccountResponse>;

/**
 * This method is used to instantiate a custom useQuery hook to handle the billingAccountService
 * @param customOptions
 * @returns
 */
export const useBillingAccount = (
  customOptions?: CustomOptions,
  cmsGlobalConfig?: IGlobalConfigCMS
): UseQueryResult<IBillingAccountResponse, INextError> => {
  const queryEnabler = customOptions?.enabled !== undefined ? customOptions.enabled : false;
  const keysDependency: string | string[] =
    customOptions && customOptions.keyDependency ? customOptions.keyDependency : 'billingAccount';
  const options: UseQueryConfig = {
    queryKey: keysDependency,
    queryFn: () => {
      const customerData: GetCustomerResponse[] = getFromLocalStorageByKey('customerData');
      const findCaller: IFindCallerResponse = getFromLocalStorageByKey('findCaller');
      const customerId = customerData?.[0]?.id || findCaller?.customerRef?.id;
      const customerDataCF =
        customerData?.[0]?.owningIndividual?.fiscalCode || findCaller?.individualRef?.fiscalCode;

      const isCustomerXBS = customerDataCF?.includes('XBS');
      const isGlobalBsNewMopFixedMobile =
        cmsGlobalConfig?.globalconfig?.newMop?.enableNewMopFixed === 'true' ||
        cmsGlobalConfig?.globalconfig?.newMop?.enableNewMopMobile === 'true';
      const isNewMopEnabled = isCustomerXBS || isGlobalBsNewMopFixedMobile;
      return billingAccountService(customerId, customOptions, isNewMopEnabled);
    },
    options: {
      ...(customOptions && { ...customOptions }),
      enabled:
        queryEnabler && (customOptions?.enabled !== undefined ? customOptions?.enabled : true),
      onSuccess: (data: IBillingAccountResponse) => {
        localStorage.setItem('billingAccount', JSON.stringify(data));
        customOptions?.onSuccess?.(data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - billingAccount: `, error);
        localStorage.setItem(`billingAccount`, JSON.stringify(errorMock(`billingAccount`, error)));
        customOptions?.onError?.(error);
      },
    },
  };
  return handleUseQuery(options) as UseQueryResult<IBillingAccountResponse, INextError>;
};

