import { useCheckout } from '../../../iBuyMobile.context';
import { ICheckoutData } from '../../checkout.models';
import AutoWalletRecurrentPaymentCard from './components/AutoWalletRecurrentPaymentCard/autoWalletRecurrentPaymentCard';
import DefaultRecurrentPaymentCard from './components/DefaultRecurrentPaymentCard/defaultRecurrentPaymentCard';

const RecurrentPaymentCard = (props: ICheckoutData) => {
  const { product } = useCheckout();
  if (product?.isAutoWallet) return <AutoWalletRecurrentPaymentCard {...props} />;
  return <DefaultRecurrentPaymentCard {...props} />;
};

export default RecurrentPaymentCard;
