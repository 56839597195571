import {
  IListRecognitionSimMethodsSelections,
  IPersonalInfoCmsMobile,
  replaceShippingCost,
} from '@vfit/consumer/data-access';
import { ECodeIdentification } from '@vfit/shared/models';

export const getItemValue = (valueFromCMS?: string) => {
  switch (valueFromCMS?.trim().toUpperCase()) {
    case ECodeIdentification.CO:
      return ECodeIdentification.CO;
    case ECodeIdentification.DI:
      return ECodeIdentification.DI;
    case ECodeIdentification.VR:
      return ECodeIdentification.VR;
    case ECodeIdentification.NA:
    default:
      return ECodeIdentification.NA;
  }
};

export const organizeRecognitionSimMethodSelection = (
  data: IPersonalInfoCmsMobile,
  isMNP: boolean,
  isEsim: boolean,
  price = ''
) => {
  let itemsToReturn: Array<IListRecognitionSimMethodsSelections> = [];
  if (!isEsim && !isMNP) {
    itemsToReturn = [
      {
        ...data?.personalinfomobile?.recognitionMethodSelection?.elements?.[0],
        value: getItemValue(
          data?.personalinfomobile?.recognitionMethodSelection?.elements?.[0]?.value
        ),
        extra: data?.personalinfomobile?.recognitionMethodSelection?.elements?.[0]?.isHighlighted === "true" ? {
          text: data?.personalinfomobile?.recognitionMethodSelection?.highlightedLabel || '',
          color: '#fff',
          extraClass: 'extraClass',
        } : {
          text : '',
        }
      },
      {
        ...data?.personalinfomobile?.recognitionMethodSelection?.elements?.[1],
        value: getItemValue(
          data?.personalinfomobile?.recognitionMethodSelection?.elements?.[1]?.value
        ),
        extra: data?.personalinfomobile?.recognitionMethodSelection?.elements?.[1]?.isHighlighted === "true" ? {
          text: data?.personalinfomobile?.recognitionMethodSelection?.highlightedLabel || '',
          color: '#fff',
          extraClass: 'extraClass',
        } : {
          text : '',
        }
      },
    ];
  }
  if (!isEsim && isMNP) {
    itemsToReturn = [
      {
        ...data?.personalinfomobile?.recognitionMethodSelection?.elementsMnp?.[0],
        value: getItemValue(
          data?.personalinfomobile?.recognitionMethodSelection?.elementsMnp?.[0]?.value
        ),
        extra: data?.personalinfomobile?.recognitionMethodSelection?.elementsMnp?.[0]?.isHighlighted === "true" ? {
          text: data?.personalinfomobile?.recognitionMethodSelection?.highlightedLabel || '',
          color: '#fff',
          extraClass: 'extraClass',
        } : {
          text : '',
        }
      },
      {
        ...data?.personalinfomobile?.recognitionMethodSelection?.elementsMnp?.[1],
        value: getItemValue(
          data?.personalinfomobile?.recognitionMethodSelection?.elementsMnp?.[1]?.value
        ),
        extra: data?.personalinfomobile?.recognitionMethodSelection?.elementsMnp?.[1]?.isHighlighted === "true" ? {
          text: data?.personalinfomobile?.recognitionMethodSelection?.highlightedLabel || '',
          color: '#fff',
          extraClass: 'extraClass',
        } : {
          text : '',
        }
      },
    ];
  }
  if (isEsim && !isMNP) {
    itemsToReturn = [
      {
        ...data?.personalinfomobile?.recognitionMethodSelectionEsim?.elements?.[0],
        value: getItemValue(
          data?.personalinfomobile?.recognitionMethodSelectionEsim?.elements?.[0]?.value
        ),
        extra: data?.personalinfomobile?.recognitionMethodSelectionEsim?.elements?.[0]?.isHighlighted === "true" ? {
          text: data?.personalinfomobile?.recognitionMethodSelectionEsim?.highlightedLabel || '',
          color: '#fff',
          extraClass: 'extraClass',
        } : {
          text : '',
        }
      },
      {
        ...data?.personalinfomobile?.recognitionMethodSelectionEsim?.elements?.[1],
        value: getItemValue(
          data?.personalinfomobile?.recognitionMethodSelectionEsim?.elements?.[1]?.value
        ),
        extra: data?.personalinfomobile?.recognitionMethodSelectionEsim?.elements?.[1]?.isHighlighted === "true" ? {
          text: data?.personalinfomobile?.recognitionMethodSelectionEsim?.highlightedLabel || '',
          color: '#fff',
          extraClass: 'extraClass',
        } : {
          text : '',
        }
      },
    ];
  }
  if (isEsim && isMNP) {
    itemsToReturn = [
      {
        ...data?.personalinfomobile?.recognitionMethodSelectionEsim?.elementsMnp?.[0],
        value: getItemValue(
          data?.personalinfomobile?.recognitionMethodSelectionEsim?.elementsMnp?.[0]?.value
        ),
        extra: data?.personalinfomobile?.recognitionMethodSelectionEsim?.elementsMnp?.[0]?.isHighlighted === "true" ? {
          text: data?.personalinfomobile?.recognitionMethodSelectionEsim?.highlightedLabel || '',
          color: '#fff',
          extraClass: 'extraClass',
        } : {
          text : '',
        }
      },
      {
        ...data?.personalinfomobile?.recognitionMethodSelectionEsim?.elementsMnp?.[1],
        value: getItemValue(
          data?.personalinfomobile?.recognitionMethodSelectionEsim?.elementsMnp?.[1]?.value
        ),
        extra: data?.personalinfomobile?.recognitionMethodSelectionEsim?.elementsMnp?.[1]?.isHighlighted === "true" ? {
          text: data?.personalinfomobile?.recognitionMethodSelectionEsim?.highlightedLabel || '',
          color: '#fff',
          extraClass: 'extraClass',
        } : {
          text : '',
        }
      },
    ];
  }

  return {
    title: replaceShippingCost(
      data?.personalinfomobile?.recognitionMethodSelection?.title || '',
      price
    ),
    description: replaceShippingCost(
      data?.personalinfomobile?.recognitionMethodSelection?.description || '',
      price
    ),
    items: itemsToReturn,
  };
};

