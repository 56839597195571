import { ITermsAndCondition } from '@vfit/consumer/data-access';
import { ITermsConditions } from './termsCard.models';

export const organizeModalData = (termsAndConditionCMS: ITermsAndCondition) => ({
  title: termsAndConditionCMS?.termsandconditions?.activationDetail?.title || '',
  itemList: termsAndConditionCMS?.termsandconditions?.activationDetail?.descriptions?.map((el) => ({
    subtitle: el?.title || '',
    paragraph: el?.text || '',
  })),
  accordions: termsAndConditionCMS?.termsandconditions?.activationDetail?.accordion || [],
});

export const organizeTermsAndCondition = (termsAndConditionCMS: ITermsAndCondition) => {
  const prefix = termsAndConditionCMS?.termsandconditions?.card;
  const dataToReturn: ITermsConditions = {
    title: prefix?.title || '',
    description: prefix?.description || '',
    documents: termsAndConditionCMS?.termsandconditions?.modals?.map((modals) => ({
      text: modals?.title || '',
      content: modals?.description || '',
      pdfUrl: modals?.url || '',
      isSummary: modals?.isSummary === 'true' ? 'true' : '',
      pdfLabel: modals?.buttonLabel || '',
    })),
    documentsCheck: prefix?.conditions || '',
    subtitle: prefix?.activation?.title || '',
    details: {
      text: prefix?.activation?.detailLinkLabel || '',
      content: prefix?.activation?.description || '',
    },
    subDescription: prefix?.activation?.description || '',
    checklist: [
      {
        title: prefix?.activation?.choice?.option1 || '',
        value: 'yes',
      },
      {
        title: prefix?.activation?.choice?.option2 || '',
        value: 'no',
      },
    ],
  };
  return dataToReturn;
};

export const getWithdrawalLabels = (cmsData: ITermsAndCondition) => {
  const withdrawal = cmsData?.termsandconditions?.withdrawal;
  return {
    titlePopup: withdrawal?.title || 'Sei sicuro?',
    descriptionPopup: withdrawal?.description || '',
    imagePopup: withdrawal?.imageUrl || '',
    buttonWaitLabel: withdrawal?.labelButtonSubmit || '',
    buttonActiveLabel: withdrawal?.labelButtonSecond || '',
  };
};

export const organizeTermsCard = (cmsData: ITermsAndCondition) => ({
  titleModal: cmsData?.termsandconditions?.card?.modalError?.title || '',
  messageModal: cmsData?.termsandconditions?.card?.modalError?.message || '',
  buttonLabel: cmsData?.termsandconditions?.card?.modalError?.buttonLabel || '',
});
