import {
  CONSUMER_CMS_SHOPPING_CART,
  ErrorCodes,
  errorMock,
  getCurrentUserType,
  GetCustomerResponse,
  IGenericErrorCMS,
  isLockInIdServiceable,
  IUserType,
  useCmsConfig,
  useGetCustomerReference,
} from '@vfit/consumer/data-access';
import { useQueryClient } from 'react-query';
import { API, getFromLocalStorageByKey, openPopup } from '@vfit/shared/data-access';
import { resetData } from '@vfit/shared/data-access';
import { useEffect } from 'react';
import { ICMSApiError, ICMSError, ICommonData, ICountryObject } from '@vfit/shared/models';
import { formatDate } from './contactLockInCard.utils';
import { retrieveOwningIndividual } from '../../checkout.utils';
import { useCheckout } from '../../../iBuyFixed.context';

export const useContactLockInCard = (props: ICommonData) => {
  const { handleOnClose } = props;
  const {
    product,
    customerFlow,
    customerAsyncInfo,
    owningData,
    checkoutErrors,
    isModalButtonSticky,
    lockInFlow,
    setIsStartCustomerFlow,
    setIsStartLockInFlow,
    setCustomerId,
    setOwningData,
    setFindCaller,
  } = useCheckout();
  const findCallerPostOtp = getFromLocalStorageByKey('findCaller');
  const queryClient = useQueryClient();
  const countries: ICountryObject[] = getFromLocalStorageByKey('getCountry');
  const {
    isSuccess: isReferenceSuccess,
    isLoading: isReferenceLoading,
    isError: isReferenceError,
    error: errorCustomerReference,
  } = useGetCustomerReference();
  const { associateCustomer } = customerFlow;
  const genericError = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_GENERIC_ERROR
  ) as IGenericErrorCMS;
  const {
    errorcomponent: { errors },
  } = useCmsConfig(CONSUMER_CMS_SHOPPING_CART, API.CMS_GET_ORDER_ERROR) as ICMSApiError;
  const blockedCustomer = errors?.find(
    (item: ICMSError) => item?.error === ErrorCodes.ASSOCIATE_CUSTOMER_BLOCKED
  );
  const isLoadingLockin =
    lockInFlow?.campaigns?.isLoading ||
    lockInFlow?.accept?.isLoading ||
    lockInFlow?.shoppingCart?.isLoading ||
    lockInFlow?.associateCustomer?.isLoading;
  const isLoadingCustomerInfo =
    isReferenceLoading ||
    !isReferenceSuccess ||
    (!isLockInIdServiceable() && !associateCustomer.isSuccess);
  const isErrorLockin =
    lockInFlow?.campaigns?.isError ||
    lockInFlow?.accept?.isError ||
    lockInFlow?.shoppingCart?.isError ||
    lockInFlow?.associateCustomer?.isError;
  const isErrorCustomer =
    customerFlow?.findcaller?.isError || customerFlow?.associateCustomer?.isError;

  const preconditionCustomer = () => {
    if (associateCustomer.isSuccess || associateCustomer.isError || isReferenceError) {
      setFindCaller({
        ...findCallerPostOtp,
        ...(customerFlow?.findcaller?.data?.paymean && {
          paymean: customerFlow?.findcaller?.data?.paymean,
        }),
        showOtp: false,
        verifyOtp: '',
        isVerified: true,
      });
      if (
        associateCustomer.isError &&
        associateCustomer.error?.error?.statusText === ErrorCodes.ASSOCIATE_CUSTOMER_BLOCKED
      ) {
        const handleClose = () => {
          if (handleOnClose) {
            resetData(queryClient);
            handleOnClose();
          }
        };
        const goBackAndReset = () => {
          if (blockedCustomer?.button?.urlForRedirect) {
            openPopup(blockedCustomer?.button?.urlForRedirect || '', () => {
              if (handleOnClose) {
                resetData(queryClient);
                handleOnClose();
              }
            });
          } else if (handleOnClose) {
            resetData(queryClient);
            handleOnClose();
          }
        };
        checkoutErrors?.showAndTrackError?.(
          genericError,
          () => handleClose(),
          associateCustomer.error,
          blockedCustomer?.title || 'Attenzione',
          blockedCustomer?.message ||
            'I seguenti articoli non sono attivabili dal cliente selezionato',
          () => goBackAndReset(),
          blockedCustomer?.button?.title || 'Ti richiamiamo',
          () => openPopup(product?.genericErrorCallMeNow?.url || '', handleClose),
          product?.genericErrorCallMeNow?.label,
          isModalButtonSticky
        );
      } else if (associateCustomer.isError || isReferenceError) {
        let error;
        if (associateCustomer.isError && customerAsyncInfo?.customerInfo?.isError) {
          error = customerAsyncInfo?.customerInfo?.error;
        }
        if (isReferenceError && errorCustomerReference) {
          error = errorMock(ErrorCodes.REFERENCE_CUSTOMER, errorCustomerReference);
        }
        checkoutErrors?.showAndTrackError?.(
          genericError,
          handleOnClose,
          { ...error, isBlocking: true },
          '',
          '',
          handleOnClose,
          genericError?.genericerror?.buttonLabel || '',
          () => openPopup(product?.genericErrorCallMeNow?.url || '', () => handleOnClose),
          product?.genericErrorCallMeNow?.label,
          isModalButtonSticky
        );
      }
    }
  };

  const checkStartLockInFlow = () => {
    const userType = getCurrentUserType();
    return (
      isLockInIdServiceable() &&
      (userType === IUserType.NEXT_USER_ACTIVE || userType === IUserType.LOGGED_USER)
    );
  };

  const showErrorLockIn = () => {
    const error =
      lockInFlow?.campaigns?.error ||
      lockInFlow?.accept?.error ||
      lockInFlow?.shoppingCart?.error ||
      lockInFlow?.associateCustomer?.error;
    checkoutErrors?.showAndTrackError?.(
      genericError,
      () => {
        lockInFlow?.resetLockInFlow?.();
        handleOnClose?.();
      },
      { ...error, isBlocking: true },
      undefined,
      undefined,
      () => {
        lockInFlow?.resetLockInFlow?.();
        handleOnClose?.();
      },
      genericError?.genericerror?.buttonLabel || 'Chiudi'
    );
  };

  const showError = () => {
    const error = customerFlow?.findcaller?.error || customerFlow?.associateCustomer?.error;
    checkoutErrors?.showAndTrackError?.(
      genericError,
      () => {
        lockInFlow?.resetLockInFlow?.();
        handleOnClose?.();
      },
      { ...error, isBlocking: true },
      undefined,
      undefined,
      () => {
        lockInFlow?.resetLockInFlow?.();
        handleOnClose?.();
      },
      genericError?.genericerror?.buttonLabel || 'Chiudi'
    );
  };

  useEffect(() => {
    preconditionCustomer();
    if (associateCustomer.isSuccess) {
      const customer = customerAsyncInfo.customerInfo.data?.[0];
      if (customer) {
        const retrivedIndividual = retrieveOwningIndividual(
          customer,
          countries as ICountryObject[]
        );
        setOwningData({
          ...owningData,
          owningIndividual: {
            ...owningData.owningIndividual,
            ...retrivedIndividual,
          },
        });
        setCustomerId(customer.id);
      }
    }
  }, [associateCustomer, isReferenceError, customerAsyncInfo]);

  useEffect(() => {
    if (isErrorLockin) {
      showErrorLockIn();
    }
  }, [isErrorLockin]);

  useEffect(() => {
    if (isErrorCustomer) {
      showError();
    }
  }, [isErrorCustomer]);

  /**
   * IF is a lockin product start campaign, accept ecc (LockinFlow)
   * else continue asis with mm fallback product
   */
  const initContactLockIn = () => {
    const customerData: GetCustomerResponse[] = getFromLocalStorageByKey('customerData');
    setFindCaller({
      ...findCallerPostOtp,
      showOtp: false,
    });
    setOwningData({
      ...owningData,
      isValid: true,
      owningIndividual: {
        firstName: customerData?.[0]?.owningIndividual?.firstName,
        lastName: customerData?.[0]?.owningIndividual?.lastName,
        contactMedium: customerData?.[0]?.owningIndividual?.contactMedium || [],
        fiscalCode: customerData?.[0]?.owningIndividual?.fiscalCode || '',
        nation: customerData?.[0]?.owningIndividual?.nation || '',
        identification: customerData?.[0]?.owningIndividual?.identification?.map(
          (identification) => {
            const nationality = countries?.filter(
              (element) => element.id === identification?.nationality
            );
            let identificationType = '';
            if (identification?.identificationType === '1') identificationType = "Carta d'identità";
            else if (identification?.identificationType === '2') identificationType = 'Passaporto';
            else if (identification?.identificationType === '3')
              identificationType = 'Patente di guida';
            return {
              ...identification,
              identificationType,
              nationality: nationality?.[0].displayName || '',
              expirationDate: identification?.expirationDate
                ? formatDate(identification.expirationDate as any)
                : '',
            };
          }
        ),
      },
    });
    if (checkStartLockInFlow()) {
      setIsStartLockInFlow(true);
    } else {
      if (customerData?.[0]?.id) setCustomerId(customerData?.[0]?.id);
      setTimeout(() => {
        setIsStartCustomerFlow(true);
      }, 200);
    }
  };

  useEffect(() => {
    if (isReferenceSuccess) initContactLockIn();
  }, [isReferenceSuccess]);

  return {
    isLoading: isLoadingLockin || isLoadingCustomerInfo,
  };
};
