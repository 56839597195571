import { setTrackView, useTracking } from '@vfit/shared/data-access';
import { useQueryClient } from 'react-query';
import { setTrackLink } from '@vfit/shared/data-access';
import { getTagging, getUserInfo } from '../../checkout.utils';
import { withAnimationDelay } from '../../hoc/withAnimationDelay';

const TaggingComponent = () => {
  const client = useQueryClient();
  const taggingMobile = getTagging('contract terms', 'mobile', client);
  const { trackView } = useTracking({
    event: ['checkout_step7'],
    event_label: 'terms and conditions',
    opts: taggingMobile?.opts,
    cartProduct: taggingMobile?.trackingProduct,
    pageProduct: taggingMobile?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });
  setTrackLink(trackView, 'terms and conditions');
  setTrackView(trackView);

  return null;
};

const TaggingTermsCard = withAnimationDelay()(TaggingComponent);

export default TaggingTermsCard;
