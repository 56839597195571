import { ShadowsGroup } from '@vfit/shared/components';
import {
  CONSUMER_CMS_SHOPPING_CART,
  IPortabilityMobileCMS,
  IPortInfoResponse,
  useCmsConfig,
} from '@vfit/consumer/data-access';
import { API, getFromLocalStorageByKey, setTrackLink, trackLink } from '@vfit/shared/data-access';
import { useEffect } from 'react';
import { useTracking } from '@vfit/shared/data-access';
import { IPortabilityObject } from '@vfit/shared/models';
import { ICheckoutData } from '../../checkout.models';
import PortabilityMigrationData from './components/PortabilityMigrationData/PortabilityMigrationData';
import { useCheckout } from '../../../iBuyMobile.context';
import { evaluatePortabilityLabels } from './portabilityDataCard.utils';
import { getUserInfo, getTagging } from '../../checkout.utils';
import { useQueryClient } from 'react-query';

const PortabilityDataCard = ({ handleOnChangeEnableGoNext }: ICheckoutData) => {
  const queryClient = useQueryClient();
  const { portability, product, setPortability, setIsValidNumberPortability } = useCheckout();
  const portabilityInfo: IPortInfoResponse = getFromLocalStorageByKey('portabilityInfo');
  const availableOperators =
    portabilityInfo?.portInDetails?.mobilePortInDetails?.[0]?.portInProviderCust?.availableValues;
  const currentPlan =
    portabilityInfo?.portInDetails?.mobilePortInDetails?.[0]?.currentPlan?.availableValues;
  const oldNumber = portability.portabilityNumber || '';
  const taggingMobile = getTagging('portability', 'mobile', queryClient);
  const { trackView, trackWithNewDatalayer } = useTracking({
    event: ['checkout_step4.4'],
    disableInitialTrack: true,
  });
  setTrackLink(trackView, 'migration code');

  const portabilityCardCms = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_PORTABILITY_MOBILE
  ) as IPortabilityMobileCMS;

  const editorialLabels = evaluatePortabilityLabels(portabilityCardCms);

  const trackTransferBalance = (transferBalance: boolean) => {
    trackLink(transferBalance ? 'trasferimento credito on' : 'trasferimento credito off');
  };

  const onChangeData = (data: IPortabilityObject, isValid: boolean) => {
    if (handleOnChangeEnableGoNext) {
      const { transferBalance } = data.portabilityMigration || {};
      if (transferBalance != portability?.portabilityMigration?.transferBalance)
        trackTransferBalance(transferBalance || false);
      setPortability(data);
      if (isValid) handleOnChangeEnableGoNext(true);
    }
  };

  const trackCard = () => {
    trackWithNewDatalayer({
      event: ['checkout_step4.4'],
      event_label: 'migration code',
      opts: taggingMobile?.opts,
      cartProduct: taggingMobile?.trackingProduct,
      pageProduct: taggingMobile?.pageProductsInfo,
      visitorTrackingOpts: getUserInfo(),
    });
  };

  useEffect(() => {
    setIsValidNumberPortability(true);
    const timer = setTimeout(() => {
      trackCard();
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const isLoading = false;

  return (
    <div>
      {isLoading ? (
        <ShadowsGroup heights={80} space={30} quantity={5} />
      ) : (
        <PortabilityMigrationData
          editorialLabels={editorialLabels}
          portabilityOperatorsFirstAction={product?.portabilityOperatorsFirstAction}
          oldNumber={oldNumber}
          currentPlan={currentPlan}
          currentProvider={availableOperators}
          onChangeData={onChangeData}
          handleOnChangeEnableGoNext={handleOnChangeEnableGoNext}
        />
      )}
    </div>
  );
};

export default PortabilityDataCard;
