import { setTrackView, useTracking } from '@vfit/shared/data-access';
import { useQueryClient } from 'react-query';
import { setTrackLink } from '@vfit/shared/data-access';
import { getTagging, getUserInfo } from '../../checkout.utils';
import { withAnimationDelay } from '../../hoc/withAnimationDelay';

const TaggingComponent = () => {
  const client = useQueryClient();
  const taggingMobile = getTagging('portability', 'mobile', client);

  const { trackView } = useTracking({
    event: ['checkout_step4.1'],
    event_label: 'number portability',
    opts: taggingMobile?.opts,
    cartProduct: taggingMobile?.trackingProduct,
    pageProduct: taggingMobile?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });
  setTrackLink(trackView, 'number portability');
  setTrackView(trackView);

  return null;
};

const TaggingPortabilityNumberCard = withAnimationDelay()(TaggingComponent);

export default TaggingPortabilityNumberCard;
