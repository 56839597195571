import { setTrackLink, setTrackView, useTracking } from '@vfit/shared/data-access';
import { useQueryClient } from 'react-query';
import { getTagging, getUserInfo } from '../../../checkout.utils';
import { withAnimationDelay } from '../../../hoc/withAnimationDelay';

const TaggingComponent = () => {
  const client = useQueryClient();
  const tagging = getTagging('checkout', 'fixed', client);
  const { trackView } = useTracking({
    event: ['checkout_step10.1'],
    event_label: 'upfront payment',
    opts: tagging?.opts,
    cartProduct: tagging?.trackingProduct,
    pageProduct: tagging?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });
  setTrackLink(trackView, 'upfront payment');
  setTrackView(trackView);
  return null;
};

const TaggingUpfrontPayment = withAnimationDelay()(TaggingComponent);

export default TaggingUpfrontPayment;
