import { setTrackView, useTracking } from '@vfit/shared/data-access';
import { useQueryClient } from 'react-query';
import { setTrackLink } from '@vfit/shared/data-access';
import { getTagging, getUserInfo } from '../../../../checkout.utils';
import { withAnimationDelay } from '../../../../hoc/withAnimationDelay';

const TaggingComponent = () => {
  const client = useQueryClient();
  const tagging = getTagging('billing', 'fixed', client);

  const { trackView } = useTracking({
    event: ['checkout_step6'],
    event_label: 'billing',
    opts: tagging?.opts,
    cartProduct: tagging?.trackingProduct,
    pageProduct: tagging?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });
  setTrackView(trackView);
  setTrackLink(trackView);
  return null;
};

const TaggingChooseInvoice = withAnimationDelay()(TaggingComponent);

export default TaggingChooseInvoice;
