import { setTrackLink, setTrackView, useTracking } from '@vfit/shared/data-access';
import { useQueryClient } from 'react-query';
import { getTagging, getUserInfo } from '../../checkout.utils';
import { withAnimationDelay } from '../../hoc/withAnimationDelay';

const TaggingComponent = () => {
  const client = useQueryClient();
  const tagging = getTagging('address confirmation', 'fixed', client);
  const { trackView } = useTracking({
    event: ['checkout_step5'],
    event_label: 'address confirmation',
    opts: tagging?.opts,
    cartProduct: tagging?.trackingProduct,
    pageProduct: tagging?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });
  setTrackLink(trackView, 'address confirmation');
  setTrackView(trackView);
  return null;
};

const TaggingYourAddressCard = withAnimationDelay()(TaggingComponent);

export default TaggingYourAddressCard;
