import styled from "styled-components";
import {Skeleton} from "@vfit/shared/components";

export const SkeletonLoader = () => (
  <Container>
    <Skeleton
      margins={[8,8,8,8]}
    />
  </Container>
)

const Container = styled.div`
  padding: 20px
`;
