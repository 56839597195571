import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { recognizedUser, updateContactProblemDataLocal } from '@vfit/consumer/data-access';
import { resetData, useTracking } from '@vfit/shared/data-access';
import { IPersonalDataObject } from '@vfit/shared/models';
import { setTrackLink } from '@vfit/shared/data-access';
import { ICheckoutData, IContactInfo } from '../../checkout.models';
import { updateContactDataLocal } from './contactCard.utils';
import { useCheckout } from '../../../iBuyMobile.context';
import { usePostOtpFlow } from '../../hooks/UsePostOtpFlow/usePostOtpFlow.hook';
import { DEFAULT_ADDRESS } from '../../../iBuyMobile.context.data';
import { ID_FLOWS } from '../../checkout.constants';
import Contact from './components/Contact/contact';
import { getTagging, getUserInfo } from '../../checkout.utils';
import LoadingCard from '../LoadingCard/loadingCart';
import CampaignCard from '../CampaignCard/campaignCard';

const ContactCard = (props: ICheckoutData) => {
  const {
    owningData,
    address,
    contactInfo,
    isReadOnly,
    campaign,
    setAddress,
    setOwningData,
    setContactInfo,
    setCurrentStepKey,
    setIsDisabledBackButton,
  } = useCheckout();
  const { handleOnChangeEnableGoNext } = props;
  const { isLoading } = usePostOtpFlow(props);
  const queryClient = useQueryClient();
  const [isValidForm, setIsValidForm] = useState(false);
  const taggingMobile = getTagging('contact', 'mobile', queryClient);

  const { trackWithNewDatalayer, trackView } = useTracking({
    event: ['contacts'],
    disableInitialTrack: true,
  });
  setTrackLink(trackView, 'contacts');

  useEffect(() => {
    if (owningData.owningIndividual) updateContactDataLocal(owningData);
  }, [owningData?.owningIndividual]);

  useEffect(() => {
    if (handleOnChangeEnableGoNext) {
      if (isLoading) {
        setIsDisabledBackButton(isLoading);
        handleOnChangeEnableGoNext(false);
      } else {
        setIsDisabledBackButton(false);
        if (isReadOnly) handleOnChangeEnableGoNext(true);
        if (recognizedUser()) {
          handleOnChangeEnableGoNext(isValidForm || (recognizedUser() as boolean));
        } else {
          handleOnChangeEnableGoNext(isValidForm);
        }
      }
    }
  }, [isValidForm, isLoading]);

  useEffect(() => {
    if (!isLoading) {
      trackWithNewDatalayer({
        event: ['checkout_step2'],
        event_label: 'contacts',
        opts: taggingMobile?.opts,
        cartProduct: taggingMobile?.trackingProduct,
        pageProduct: taggingMobile?.pageProductsInfo,
        visitorTrackingOpts: getUserInfo(),
      });
    }
  }, [isLoading]);

  useEffect(() => {
    setCurrentStepKey(ID_FLOWS.CONTACT_CARD);
    updateContactProblemDataLocal(contactInfo);
    resetData(queryClient, ['validatedAddress', 'createdAddress', 'modifyMobileSimDeviceId']);
  }, []);

  const onChangeData = (data: IPersonalDataObject) => {
    updateContactDataLocal(data);
    setAddress(data.address || DEFAULT_ADDRESS);
    setOwningData(data);
  };

  const onChangeCheck = (data: IContactInfo) => {
    let trackData = 'off';

    if (data.checkContactProblem) {
      trackData = 'on';
    }

    trackView({
      event_name: 'ui_interaction',
      event_label_track: `re-contact checkbox ${trackData}`,
      event_category: 'users',
      event_action: 'click',
      tracking_type: 'link',
    });
    updateContactProblemDataLocal(data);
    setContactInfo(data);
  };

  if (isLoading) {
    return (
      <>
        {!campaign?.updateCart?.isLoading && <LoadingCard />}
        <CampaignCard {...props} />
      </>
    );
  }

  return (
    <Contact
      data={owningData}
      address={address}
      checkInfo={contactInfo}
      onChangeData={onChangeData}
      onChangeCheck={onChangeCheck}
      isReadOnly={isReadOnly}
      showEditMail
      setIsValidForm={(valid: boolean) => setIsValidForm(valid)}
    />
  );
};

export default ContactCard;
