import styled from "styled-components";
import {breakpoints, fonts, pxToCssFont} from "@vfit/shared/themes";

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 255px;
  border-radius: 16px;
  border: 1px #bebebe solid;
  @media(min-width: ${breakpoints.tablet}){
    width: 280px
  };
  @media(min-width: ${breakpoints.desktop}){
    width: 320px
  };
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 120px;
  border-radius: 20px 20px 0 0;
`;

export const BodyContainer = styled.div`
  padding: 16px 16px 24px 16px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const TitleGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Title = styled.div`
  font-family: ${fonts.vodafone};
  font-weight: 800;
  ${pxToCssFont(28,36)};
  text-align: left;
`;

export const Price = styled.div`
  font-family: ${fonts.vodafone};
  font-weight: 800;
  ${pxToCssFont(24,32)};
  @media(min-width: ${breakpoints.desktop}){
    ${pxToCssFont(28,36)};
  }
`;

export const Subprice = styled.div`
  font-family: ${fonts.regular};
  ${pxToCssFont(16,22)};
`;

export const CtaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
  gap: 16px;
`;

export const Description = styled.div`
  font-family: ${fonts.regular};
  ${pxToCssFont(16,22)};
  color: #7e7e7e;
  text-align: left;
  p{
    margin:0;
  }
`;

export const DetailsCta = styled.a`
  font-family: ${fonts.regular};
  ${pxToCssFont(16,22)}
  font-weight: 700;
  text-align: left;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #262626;
  cursor: pointer;
`;

