import {
  ICheckPortInFeasibilityIIResponse,
  INoOfferErrorPopUp,
  IOfferAvaiability,
  IOfferData,
  IOperatorGroup,
  IPersonalInfoCmsMobile,
  IPortabilityMobileCMS,
  IPortInfoResponse,
  IProduct,
} from '@vfit/consumer/data-access';
import { getFromLocalStorageByKey } from '@vfit/shared/data-access';
import { ISimTypeCardData, IOfferCategorizationType } from './simTypeSelection.models';

export const organizeSimTypeCard = (
  dataFromCms: IPersonalInfoCmsMobile,
  errorPortability: IPortabilityMobileCMS
): ISimTypeCardData => ({
  cardTitle: dataFromCms?.personalinfomobile?.simType?.card?.title || '',
  cardDescription: dataFromCms?.personalinfomobile?.simType?.card?.description || '',
  bestChoiceLabel: dataFromCms?.personalinfomobile?.simType?.card?.bestChoiceLabel || '',
  modalInfo: {
    textLink: dataFromCms?.personalinfomobile?.simType?.modalInfo?.textLink || '',
    titleModal: dataFromCms?.personalinfomobile?.simType?.modalInfo?.title || '',
    descriptionModal: dataFromCms?.personalinfomobile?.simType?.modalInfo?.description || '',
  },
  migrationCodeError: {
    title: errorPortability?.portabilitymobile?.errorOnPortability?.title || '',
    description:
      errorPortability?.portabilitymobile?.errorOnPortability?.message ||
      '',
    buttonLabel: errorPortability?.portabilitymobile?.errorOnPortability?.buttonLabel || '',
  },
  genericsError: {
    title: errorPortability?.portabilitymobile?.errorModal?.title || '',
    description:
      errorPortability?.portabilitymobile?.errorModal?.message || '',
    buttonLabel: errorPortability?.portabilitymobile?.errorModal?.buttonLabel || '',
  },
  numberError: {
    title: errorPortability?.portabilitymobile?.errorNumber?.title || '',
    description:
      errorPortability?.portabilitymobile?.errorNumber?.message ||
      '',
    buttonLabel: errorPortability?.portabilitymobile?.errorNumber?.buttonLabel || '',
  },
});

export const purifyPrice = (price: string) => parseFloat(price.replace(',', '.').replace('€', ''));

export const comparePrice = (firstProduct: IProduct, secondProduct: IProduct) =>
  purifyPrice(firstProduct.price) - purifyPrice(secondProduct.price);

export const alreadyDonePortability = () => {
  const checkPortInFeasibility: ICheckPortInFeasibilityIIResponse = getFromLocalStorageByKey(
    'checkPortInFeasibilityIIQuery'
  );
  return (
    checkPortInFeasibility?.resultDescription?.toLowerCase() === 'success' ||
    (checkPortInFeasibility && Object.keys(checkPortInFeasibility).length === 0)
  );
};

export const checkOperator = (
  chosenOperator: string,
  groups?: IOperatorGroup[],
  avaiableOperators?: IOfferAvaiability
) => {
  let isChosenOperatorAvaiable = false;

  if (
    avaiableOperators?.avaiableOperators?.find(
      (operator) => operator.elements?.trim() === chosenOperator
    ) !== undefined
  )
    isChosenOperatorAvaiable = true;

  avaiableOperators?.avaiableGroups?.forEach((avaiableGropus) => {
    const group = groups?.find((groupFromProps) => groupFromProps.id === avaiableGropus.elements);
    if (
      group?.operator?.find((operator) => operator?.operators?.trim() === chosenOperator) !==
      undefined
    )
      isChosenOperatorAvaiable = true;
  });

  return isChosenOperatorAvaiable;
};

export const checkProductAvaiable = (
  product: IProduct,
  chosenOperator: string,
  productList?: IProduct[],
  groups?: IOperatorGroup[]
) => {
  const filteredProducts = productList?.filter(
    (element) => element.productCategory === product.productCategory
  );
  let avaiableFallbackProducts: IProduct[] | undefined = [];

  switch (product.offerCategorization) {
    case IOfferCategorizationType.BTL:
      avaiableFallbackProducts = filteredProducts?.filter((filteredProduct) =>
        checkOperator(chosenOperator, groups, filteredProduct.offerAvaiability)
      );
      break;
    case IOfferCategorizationType.ATL:
      if (product.fallbackToBtl)
        avaiableFallbackProducts = filteredProducts?.filter((filteredProduct) =>
          checkOperator(chosenOperator, groups, filteredProduct.offerAvaiability)
        );
      else {
        avaiableFallbackProducts = filteredProducts
          ?.filter(
            (categorizedProduct) =>
              categorizedProduct.offerCategorization === IOfferCategorizationType.ATL
          )
          .filter((filteredProduct) =>
            checkOperator(chosenOperator, groups, filteredProduct.offerAvaiability)
          );
      }
      break;
    default:
      break;
  }

  if (avaiableFallbackProducts && avaiableFallbackProducts.length > 0) {
    if (avaiableFallbackProducts.length === 1) return avaiableFallbackProducts[0];
    return avaiableFallbackProducts.sort(comparePrice)[0];
  }
  return undefined;
};

export const findOperatorValue = (chosenOperator?: string) => {
  const portabilityInfo: IPortInfoResponse = getFromLocalStorageByKey('portabilityInfo');

  if (portabilityInfo !== undefined && Object.keys(portabilityInfo).length > 0) {
    const operatorsList =
      (portabilityInfo?.portInDetails?.mobilePortInDetails &&
        portabilityInfo?.portInDetails?.mobilePortInDetails[0]?.portInProviderCust
          ?.availableValues) ||
      [];

    const foundOperator = operatorsList.find((operator) => operator.value === chosenOperator);

    return foundOperator?.displayValue;
  }

  return undefined;
};

export const organizeNewProductData = (product: IProduct, chosenOperator?: string) => {
  const {
    offerDetails,
    title,
    price,
    conditions,
    description,
    recurrence,
    specs,
    digitalBundleConfig,
    fallbackCtcEnabled,
  } = product;

  const offerData: IOfferData = {
    price: price || '',
    recurrence: recurrence || '',
    cTCEnabled: fallbackCtcEnabled || false,
    offerDetails: {
      conditions: conditions || [],
      offerDetails: offerDetails || [],
      disableSecondAction: digitalBundleConfig?.disableSecondAction || false,
      title,
    },
    priceDescription: description || '',
    offerProposition: {
      title:
        digitalBundleConfig?.title.replace('$OPERATOR', findOperatorValue(chosenOperator) || '') ||
        '',
      description: digitalBundleConfig?.description || '',
      showDetailLabel: digitalBundleConfig?.showDetailsLabel || '',
    },
    specs: specs || [],
    firstButtonAction: digitalBundleConfig?.firstAction || {},
    secondButtonAction: digitalBundleConfig?.secondAction || {},
  };

  return offerData;
};

export const organizeNoOfferPopUp = (
  dataFromCms?: INoOfferErrorPopUp,
  chosenOperator?: string
): INoOfferErrorPopUp => ({
  title: dataFromCms?.title || '',
  description:
    dataFromCms?.description?.replace('$OPERATOR', findOperatorValue(chosenOperator) || '') || '',
  buttonLabel: dataFromCms?.buttonLabel || '',
  url: dataFromCms?.url,
});

