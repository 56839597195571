import { useEffect } from 'react';
import { SELECTED_PAYMENT, SELECTED_UPFRONT_PAYMENT } from '@vfit/consumer/data-access';
import { EMethodCode, ICommonData } from '@vfit/shared/models';
import { useCheckout } from '../../../../iBuyFixed.context';
import { ID_FLOWS } from '../../../checkout.constants';
import PreselectedUpfrontPayment from '../PreselectedUpfrontPayment/preselectedUpfrontPayment';
import ChooseUpfrontPayment from '../ChooseUpfrontPayment/chooseUpfrontPayment';
import UpfrontPaymentTagging from './upfrontPayment.tagging';

const UpfrontPayment = (props: ICommonData) => {
  const { setIsLastCard, setCurrentStepKey, setUpdateProductPriceCtrl } = useCheckout();
  const selectedPayment = localStorage.getItem(SELECTED_PAYMENT);
  const selectedPaymentMethodIsCCard =
    selectedPayment?.toLowerCase() === EMethodCode.CREDIT_CARD.toLowerCase();

  useEffect(() => {
    setCurrentStepKey(ID_FLOWS.UPFRONT_PAYMENT);
    window.history.pushState(null, '', window.location.pathname);
    setUpdateProductPriceCtrl({
      isPriceUpdated: true,
      isUpdatePrice: false,
    });

    if (selectedPaymentMethodIsCCard)
      localStorage.setItem(SELECTED_UPFRONT_PAYMENT, EMethodCode.CREDIT_CARD);
    setIsLastCard(true);
    return () => {
      setIsLastCard(false);
    };
  }, []);

  return (
    <>
      <UpfrontPaymentTagging />
      {selectedPaymentMethodIsCCard && <PreselectedUpfrontPayment {...props} />}
      {!selectedPaymentMethodIsCCard && <ChooseUpfrontPayment {...props} />}
    </>
  );
};

export default UpfrontPayment;
