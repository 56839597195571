import { setTrackView, useTracking } from '@vfit/shared/data-access';
import { useQueryClient } from 'react-query';
import { setTrackLink } from '@vfit/shared/data-access';
import { getTagging, getUserInfo } from '../../checkout.utils';
import { withAnimationDelay } from '../../hoc/withAnimationDelay';

const TaggingComponent = ({ isMultipleOperators }: { isMultipleOperators: boolean }) => {
  const client = useQueryClient();
  const taggingFixed = getTagging('portability', 'fixed', client);

  const { trackView } = useTracking({
    event: ['checkout_step4.4'],
    event_label: isMultipleOperators ? 'two carrier' : 'one carrier',
    opts: taggingFixed.opts,
    cartProduct: taggingFixed.trackingProduct,
    pageProduct: taggingFixed?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });
  setTrackView(trackView);
  setTrackLink(trackView, 'portability');
  return null;
};

const TaggingPortabilityMigrationCard = withAnimationDelay()(TaggingComponent);

export default TaggingPortabilityMigrationCard;
