import { ButtonSlide } from '@vfit/shared/atoms';
import { ProductDetail } from '@vfit/shared/components';
import { checkIsApp } from '@vfit/consumer/data-access';
import { IDetailsStepProps } from './detailsStep.models';
import { ButtonDiv, DetailsWrapper } from './detailsStep.style';
import { getUserInfo } from '../../../../../../checkout.utils';

const DetailsStep = ({
  details,
  modalInfo,
  onButtonClick,
  onSecondButtonClick,
}: IDetailsStepProps) => (
  <DetailsWrapper>
    <ProductDetail
      trackingOpt={{
        event: ['ui_interaction'],
        event_label: 'offer details',
        opts: { event_category: 'users', event_action: 'click'},
        visitorTrackingOpts: getUserInfo(),
        disableInitialTrack: true,
      }}
      details={details}
      modalInfo={modalInfo}
    />
    <ButtonDiv>
      <ButtonSlide
        onClick={onButtonClick}
        isApp={checkIsApp()}
        label={details.firstButtonLabel || ''}
        buttonColor="#e60000"
        hoverColor="#bd0000"
        clickColor="#a10000"
        labelColor="#fff"
      />
      {onSecondButtonClick && details.secondButtonLabel && !details.disableSecondAction && (
        <ButtonSlide
          onClick={onSecondButtonClick}
          isApp={checkIsApp()}
          label={details.secondButtonLabel}
          buttonColor="#fff"
          hoverColor="#262626"
          hoverTextColor="#fff"
          clickColor="#7e7e7e"
          clickTextColor="#fff"
          borderColor="#0d0d0d"
        />
      )}
    </ButtonDiv>
  </DetailsWrapper>
);

export default DetailsStep;
