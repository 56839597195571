import { IStepCard } from '@vfit/shared/models';
import {
  IFlow,
  IOtpFLow,
  IPersonalInfoFlow,
  IPortabilityFlow,
  IDeliveryFlow,
  IEsimSimFlow,
  ITermsFlow,
  IPaymentRecognizedFlow,
  IPaymentFlow,
  IUpfrontPaymentFlow,
  IWinBackFlow,
  IVoucherFlow,
  IFlowApp,
  IAddonFlow,
} from './checkout.models';
import DocumentIdCard from './components/DocumentIdCard/documentIdCard';
import NameFiscalCodeCard from './components/NameFiscalCodeCard/nameFiscalCodeCard';
import ContactCard from './components/ContactCard/contactCard';
import OtpCard from './components/OtpCard/otpCard';
import ConfirmIdentityCard from './components/ConfirmIdentityCard/confirmIdentityCard';
import PortabilityCard from './components/PortabilityCard/portabilityCard';
import PortabilityNumberCard from './components/PortabilityNumberCard/portabilityNumberCard';
import YourAddressCard from './components/YourAddressCard/yourAddressCard';
import PortabilityOtpCard from './components/PortabilityOtpCrad/portabilityOtpCard';
import PortabilityDataCard from './components/PortabilityDataCard/portabilityDataCard';
import RecognitionCard from './components/RecognitionCard/recognitionCard';
import SimTypeSelectionCard from './components/SimTypeSelectionCard/simTypeSelectionCard';
import TermsCard from './components/TermsCard/termsCard';
import ConsentCard from './components/ConsentCard/consentCard';
import RecurrentPaymentCard from './components/RecurrentPaymentCard/recurrentPaymentCard';
import PaymentMethodCard from './components/PaymentMethodCard/paymentMethodCard';
import WarningCard from './components/WarningCard/warningCard';
import UpfrontPaymentCard from './components/UpfrontPaymentCard/upfrontPaymentCard';
import WinbackNumberCard from './components/WinbackNumberCard/winbackNumberCard';
import WinbackOtp from './components/WinbackOtp/winbackOtp';
import NameFiscalCodeCardWinback from './components/NameFiscalCodeCardWinback/nameFiscalCodeCardWinback';
import SimTypeSelectionCardWinback from './components/SimTypeSelectionCardWinback/simTypeSelectionCardWinback';
import PortabilityNumberPortabilityRequiredCard from './components/PortabilityNumberPortabilityRequiredCard/portabilityNumberPortabilityRequiredCard';
import PortabilityNumberCardWinback from './components/PortabilityNumberCardWinback/portabilityNumberCardWinback';
import Voucher from './components/VoucherCard/voucherCard';
import { ID_FLOWS } from './checkout.constants';
import SimTypeSelectionCardMnpSkip from './components/SimTypeSelectionCardMnpSkip/simTypeSelectionCardMnpSkip';
import RecognitionCardSkipMnp from './components/RecognitionCardSkipMnp/recognitionCardSkipMnp';
import ConfirmIdentityAppCard from './components/ConfirmIdentityAppCard/confirmIdentityAppCard';
import WinbackLegacyNumberCard from './components/WinbackLegacyNumberCard/winbackLegacyNumberCard';
import WinbackLegacyOtp from './components/WinbackLegacyOtp/winbackLegacyOtp';
import { AddonCard } from './components/AddonCard/addonCard';

export const COMPONENTS_FLOW = [
  {
    title: ID_FLOWS.PERSONAL_INFO_WINBACK,
    component: NameFiscalCodeCardWinback,
  },
  {
    title: ID_FLOWS.ADDON_CARD,
    component: AddonCard,
  },
  {
    title: ID_FLOWS.PERSONAL_INFO,
    component: NameFiscalCodeCard,
  },
  {
    title: ID_FLOWS.DOCUMENT_ID,
    component: DocumentIdCard,
  },
  {
    title: ID_FLOWS.CONTACT_CARD,
    component: ContactCard,
  },
  {
    title: ID_FLOWS.CONFIRM_IDENDITY,
    component: ConfirmIdentityCard,
  },
  {
    title: ID_FLOWS.CONFIRM_IDENDITY_APP,
    component: ConfirmIdentityAppCard,
  },
  {
    title: ID_FLOWS.OTP,
    component: OtpCard,
  },
  {
    title: ID_FLOWS.PORTABILITY,
    component: PortabilityCard,
  },
  {
    title: ID_FLOWS.PORTABILITY_NUMBER,
    component: PortabilityNumberCard,
  },
  {
    title: ID_FLOWS.PORTABILITY_NUMBER_WINBACK,
    component: PortabilityNumberCardWinback,
  },
  {
    title: ID_FLOWS.PORTABILITY_NUMBER_PORTABILITY_REQUIRED,
    component: PortabilityNumberPortabilityRequiredCard,
  },
  {
    title: ID_FLOWS.PORTABILITY_OTP,
    component: PortabilityOtpCard,
  },
  {
    title: ID_FLOWS.PORTABILITY_DATA,
    component: PortabilityDataCard,
  },
  {
    title: ID_FLOWS.TERMS_AND_CONDITIONS,
    component: TermsCard,
  },
  {
    title: ID_FLOWS.CONSENTS,
    component: ConsentCard,
  },
  {
    title: ID_FLOWS.RECOGNITION_ESIM,
    component: SimTypeSelectionCard,
  },
  {
    title: ID_FLOWS.RECOGNITION_ESIM_WINBACK,
    component: SimTypeSelectionCardWinback,
  },
  {
    title: ID_FLOWS.RECOGNITION_ESIM_SKIP_MNP,
    component: SimTypeSelectionCardMnpSkip,
  },
  {
    title: ID_FLOWS.RECOGNITION_CARD,
    component: RecognitionCard,
  },
  {
    title: ID_FLOWS.RECOGNITION_CARD_SKIP_MNP,
    component: RecognitionCardSkipMnp,
  },
  {
    title: ID_FLOWS.DELIVERY_ADDRESS,
    component: YourAddressCard,
  },
  {
    title: ID_FLOWS.PAYMENT_METHOD,
    component: PaymentMethodCard,
  },
  {
    title: ID_FLOWS.RECURRENT_PAYMENT_CARD,
    component: RecurrentPaymentCard,
  },
  {
    title: ID_FLOWS.WARNING_PRICE_CHANGE,
    component: WarningCard,
  },
  {
    title: ID_FLOWS.UPFRONT_PAYMENT,
    component: UpfrontPaymentCard,
  },
  {
    title: ID_FLOWS.WINBACK_NUMBER,
    component: WinbackNumberCard,
  },
  {
    title: ID_FLOWS.WINBACK_OTP,
    component: WinbackOtp,
  },
  {
    title: ID_FLOWS.WINBACK_LEGACY_NUMBER,
    component: WinbackLegacyNumberCard,
  },
  {
    title: ID_FLOWS.WINBACK_LEGACY_OTP,
    component: WinbackLegacyOtp,
  },
  {
    title: ID_FLOWS.VOUCHER,
    component: Voucher,
  },
];

export const getFlowComponentByTitle = (title: string): IStepCard => {
  const component = COMPONENTS_FLOW.find((el: { title: string }) => el.title === title) || {};
  return component;
};

export const ADDON_FLOW: IAddonFlow = {
  ADDONS: [getFlowComponentByTitle(ID_FLOWS.ADDON_CARD)],
};

export const VOUCHER_FLOW: IVoucherFlow = {
  VOUCHER: [getFlowComponentByTitle(ID_FLOWS.VOUCHER)],
};

export const WINBACK_FLOW: IWinBackFlow = {
  WINBACK_NUMBER: [getFlowComponentByTitle(ID_FLOWS.WINBACK_NUMBER)],
  WINBACK_OTP: [getFlowComponentByTitle(ID_FLOWS.WINBACK_OTP)],
};

export const WINBACK_LEGACY_FLOW: IWinBackFlow = {
  WINBACK_NUMBER: [getFlowComponentByTitle(ID_FLOWS.WINBACK_LEGACY_NUMBER)],
  WINBACK_OTP: [getFlowComponentByTitle(ID_FLOWS.WINBACK_LEGACY_OTP)],
};

const PERSONAL_INFO_FLOW: IPersonalInfoFlow = {
  PERSONAL_INFO: [
    getFlowComponentByTitle(ID_FLOWS.PERSONAL_INFO),
    getFlowComponentByTitle(ID_FLOWS.CONTACT_CARD),
    getFlowComponentByTitle(ID_FLOWS.DOCUMENT_ID),
  ],
};
const PERSONAL_INFO_FLOW_WINBACK: IPersonalInfoFlow = {
  PERSONAL_INFO: [
    getFlowComponentByTitle(ID_FLOWS.PERSONAL_INFO_WINBACK),
    getFlowComponentByTitle(ID_FLOWS.CONTACT_CARD),
    getFlowComponentByTitle(ID_FLOWS.DOCUMENT_ID),
  ],
};
const PERSONAL_INFO_APP_FLOW: IPersonalInfoFlow = {
  PERSONAL_INFO: [
    getFlowComponentByTitle(ID_FLOWS.CONTACT_CARD),
    getFlowComponentByTitle(ID_FLOWS.DOCUMENT_ID),
  ],
};

const OTP_FLOW: IOtpFLow = {
  OTP: [getFlowComponentByTitle(ID_FLOWS.CONFIRM_IDENDITY), getFlowComponentByTitle(ID_FLOWS.OTP)],
};

export const OTP_FLOW_APP: IOtpFLow = {
  OTP: [
    getFlowComponentByTitle(ID_FLOWS.CONFIRM_IDENDITY_APP),
    getFlowComponentByTitle(ID_FLOWS.OTP),
  ],
};

const PORTABILITY_FLOW: IPortabilityFlow = {
  PORTABILITY: [getFlowComponentByTitle(ID_FLOWS.PORTABILITY)],
  PORTABILITY_NUMBER: [
    getFlowComponentByTitle(ID_FLOWS.PORTABILITY_NUMBER),
    getFlowComponentByTitle(ID_FLOWS.PORTABILITY_DATA),
  ],
  PORTABILITY_OTP: [getFlowComponentByTitle(ID_FLOWS.PORTABILITY_OTP)],
};

const PORTABILITY_FLOW_REQUIRED: Omit<IPortabilityFlow, 'PORTABILITY'> = {
  PORTABILITY_NUMBER: [
    getFlowComponentByTitle(ID_FLOWS.PORTABILITY_NUMBER_PORTABILITY_REQUIRED),
    getFlowComponentByTitle(ID_FLOWS.PORTABILITY_DATA),
  ],
  PORTABILITY_OTP: [getFlowComponentByTitle(ID_FLOWS.PORTABILITY_OTP)],
};

const PORTABILITY_FLOW_WINBACK: Omit<IPortabilityFlow, 'PORTABILITY'> = {
  PORTABILITY_NUMBER: [
    getFlowComponentByTitle(ID_FLOWS.PORTABILITY_NUMBER_WINBACK),
    getFlowComponentByTitle(ID_FLOWS.PORTABILITY_DATA),
  ],
  PORTABILITY_OTP: [getFlowComponentByTitle(ID_FLOWS.PORTABILITY_OTP)],
};

export const TERMS_FLOW: ITermsFlow = {
  TERMS: [
    getFlowComponentByTitle(ID_FLOWS.TERMS_AND_CONDITIONS),
    getFlowComponentByTitle(ID_FLOWS.CONSENTS),
  ],
};

export const ESIM_SIM_RECOGNITION: IEsimSimFlow = {
  RECOGNITION: [
    getFlowComponentByTitle(ID_FLOWS.RECOGNITION_ESIM),
    getFlowComponentByTitle(ID_FLOWS.RECOGNITION_CARD),
  ],
};

export const ESIM_ONLY: IEsimSimFlow = {
  RECOGNITION: [getFlowComponentByTitle(ID_FLOWS.RECOGNITION_CARD)],
};

export const ESIM_SIM_RECOGNITION_WINBACK: IEsimSimFlow = {
  RECOGNITION: [
    getFlowComponentByTitle(ID_FLOWS.RECOGNITION_ESIM_WINBACK),
    getFlowComponentByTitle(ID_FLOWS.RECOGNITION_CARD),
  ],
};

export const ESIM_SIM_RECOGNITION_SKIP_MNP: IEsimSimFlow = {
  RECOGNITION: [
    getFlowComponentByTitle(ID_FLOWS.RECOGNITION_ESIM_SKIP_MNP),
    getFlowComponentByTitle(ID_FLOWS.RECOGNITION_CARD),
  ],
};

export const ESIM_ONLY_SKIP_MPN: IEsimSimFlow = {
  RECOGNITION: [getFlowComponentByTitle(ID_FLOWS.RECOGNITION_CARD_SKIP_MNP)],
};

export const DELIVERY_FLOW: IDeliveryFlow = {
  DELIVERY: [getFlowComponentByTitle(ID_FLOWS.DELIVERY_ADDRESS)],
};

export const PAYMENT_METHOD_FLOW: IPaymentRecognizedFlow = {
  PAYMENT_METHOD: [getFlowComponentByTitle(ID_FLOWS.PAYMENT_METHOD)],
};

const PAYMENT_FLOW: IPaymentFlow = {
  RECURRENT_PAYMENT_CARD: [getFlowComponentByTitle(ID_FLOWS.RECURRENT_PAYMENT_CARD)],
  WARNING_PRICE_CHANGE: [getFlowComponentByTitle(ID_FLOWS.WARNING_PRICE_CHANGE)],
};

export const UPFRONT_PAYMENT_FLOW: IUpfrontPaymentFlow = {
  UPFRONT_PAYMENT: [getFlowComponentByTitle(ID_FLOWS.UPFRONT_PAYMENT)],
};

export const EMPTY_FLOWS = {
  EMPTY: [
    {
      title: '',
      component: undefined,
    },
    {
      title: '',
      component: undefined,
    },
  ],
};

const MOBILE_LINE_APP_FLOW: IFlowApp = {
  DEFAULT: [
    ...OTP_FLOW_APP.OTP,
    ...PERSONAL_INFO_APP_FLOW.PERSONAL_INFO,
    ...PORTABILITY_FLOW.PORTABILITY,
    ...ESIM_SIM_RECOGNITION.RECOGNITION,
    ...DELIVERY_FLOW.DELIVERY,
    ...TERMS_FLOW.TERMS,
    ...VOUCHER_FLOW.VOUCHER,
    ...PAYMENT_FLOW.RECURRENT_PAYMENT_CARD,
    ...UPFRONT_PAYMENT_FLOW.UPFRONT_PAYMENT,
    ...EMPTY_FLOWS.EMPTY,
  ],
  ESIM_ONLY: [
    ...OTP_FLOW_APP.OTP,
    ...PERSONAL_INFO_APP_FLOW.PERSONAL_INFO,
    ...PORTABILITY_FLOW.PORTABILITY,
    ...ESIM_ONLY.RECOGNITION,
    ...DELIVERY_FLOW.DELIVERY,
    ...TERMS_FLOW.TERMS,
    ...VOUCHER_FLOW.VOUCHER,
    ...PAYMENT_FLOW.RECURRENT_PAYMENT_CARD,
    ...UPFRONT_PAYMENT_FLOW.UPFRONT_PAYMENT,
    ...EMPTY_FLOWS.EMPTY,
  ],
  SKIP_MNP: [
    ...OTP_FLOW_APP.OTP,
    ...PERSONAL_INFO_APP_FLOW.PERSONAL_INFO,
    ...ESIM_SIM_RECOGNITION_SKIP_MNP.RECOGNITION,
    ...DELIVERY_FLOW.DELIVERY,
    ...TERMS_FLOW.TERMS,
    ...VOUCHER_FLOW.VOUCHER,
    ...PAYMENT_FLOW.RECURRENT_PAYMENT_CARD,
    ...UPFRONT_PAYMENT_FLOW.UPFRONT_PAYMENT,
    ...EMPTY_FLOWS.EMPTY,
  ],
  ESIM_ONLY_SKIP_MNP: [
    ...OTP_FLOW_APP.OTP,
    ...PERSONAL_INFO_APP_FLOW.PERSONAL_INFO,
    ...ESIM_ONLY_SKIP_MPN.RECOGNITION,
    ...DELIVERY_FLOW.DELIVERY,
    ...TERMS_FLOW.TERMS,
    ...VOUCHER_FLOW.VOUCHER,
    ...PAYMENT_FLOW.RECURRENT_PAYMENT_CARD,
    ...UPFRONT_PAYMENT_FLOW.UPFRONT_PAYMENT,
    ...EMPTY_FLOWS.EMPTY,
  ],
  PORTABILITY_REQUIRED: [
    ...OTP_FLOW_APP.OTP,
    ...PERSONAL_INFO_APP_FLOW.PERSONAL_INFO,
    ...PORTABILITY_FLOW_REQUIRED.PORTABILITY_NUMBER,
    ...ESIM_SIM_RECOGNITION.RECOGNITION,
    ...DELIVERY_FLOW.DELIVERY,
    ...TERMS_FLOW.TERMS,
    ...VOUCHER_FLOW.VOUCHER,
    ...PAYMENT_FLOW.RECURRENT_PAYMENT_CARD,
    ...UPFRONT_PAYMENT_FLOW.UPFRONT_PAYMENT,
    ...EMPTY_FLOWS.EMPTY,
  ],
};

const MOBILE_LINE_FLOW: IFlow = {
  DEFAULT: [
    ...PERSONAL_INFO_FLOW.PERSONAL_INFO,
    ...PORTABILITY_FLOW.PORTABILITY,
    ...ESIM_SIM_RECOGNITION.RECOGNITION,
    ...DELIVERY_FLOW.DELIVERY,
    ...TERMS_FLOW.TERMS,
    ...VOUCHER_FLOW.VOUCHER,
    ...PAYMENT_FLOW.RECURRENT_PAYMENT_CARD,
    ...UPFRONT_PAYMENT_FLOW.UPFRONT_PAYMENT,
    ...EMPTY_FLOWS.EMPTY,
  ],
  ESIM_ONLY: [
    ...PERSONAL_INFO_FLOW.PERSONAL_INFO,
    ...PORTABILITY_FLOW.PORTABILITY,
    ...ESIM_ONLY.RECOGNITION,
    ...DELIVERY_FLOW.DELIVERY,
    ...TERMS_FLOW.TERMS,
    ...VOUCHER_FLOW.VOUCHER,
    ...PAYMENT_FLOW.RECURRENT_PAYMENT_CARD,
    ...UPFRONT_PAYMENT_FLOW.UPFRONT_PAYMENT,
    ...EMPTY_FLOWS.EMPTY,
  ],
  WINBACK: [
    ...WINBACK_FLOW.WINBACK_NUMBER,
    ...WINBACK_FLOW.WINBACK_OTP,
    ...PERSONAL_INFO_FLOW_WINBACK.PERSONAL_INFO,
    ...PORTABILITY_FLOW_WINBACK.PORTABILITY_NUMBER,
    ...ESIM_SIM_RECOGNITION_WINBACK.RECOGNITION,
    ...DELIVERY_FLOW.DELIVERY,
    ...TERMS_FLOW.TERMS,
    ...VOUCHER_FLOW.VOUCHER,
    ...PAYMENT_FLOW.RECURRENT_PAYMENT_CARD,
    ...UPFRONT_PAYMENT_FLOW.UPFRONT_PAYMENT,
    ...EMPTY_FLOWS.EMPTY,
  ],
  WINBACK_LEGACY: [
    ...WINBACK_LEGACY_FLOW.WINBACK_NUMBER,
    ...WINBACK_LEGACY_FLOW.WINBACK_OTP,
    ...PERSONAL_INFO_FLOW_WINBACK.PERSONAL_INFO,
    ...PORTABILITY_FLOW_WINBACK.PORTABILITY_NUMBER,
    ...ESIM_SIM_RECOGNITION_WINBACK.RECOGNITION,
    ...DELIVERY_FLOW.DELIVERY,
    ...TERMS_FLOW.TERMS,
    ...VOUCHER_FLOW.VOUCHER,
    ...PAYMENT_FLOW.RECURRENT_PAYMENT_CARD,
    ...UPFRONT_PAYMENT_FLOW.UPFRONT_PAYMENT,
    ...EMPTY_FLOWS.EMPTY,
  ],
  SKIP_MNP: [
    ...PERSONAL_INFO_FLOW.PERSONAL_INFO,
    ...ESIM_SIM_RECOGNITION_SKIP_MNP.RECOGNITION,
    ...DELIVERY_FLOW.DELIVERY,
    ...TERMS_FLOW.TERMS,
    ...VOUCHER_FLOW.VOUCHER,
    ...PAYMENT_FLOW.RECURRENT_PAYMENT_CARD,
    ...UPFRONT_PAYMENT_FLOW.UPFRONT_PAYMENT,
    ...EMPTY_FLOWS.EMPTY,
  ],
  ESIM_ONLY_SKIP_MNP: [
    ...PERSONAL_INFO_FLOW.PERSONAL_INFO,
    ...ESIM_ONLY_SKIP_MPN.RECOGNITION,
    ...DELIVERY_FLOW.DELIVERY,
    ...TERMS_FLOW.TERMS,
    ...VOUCHER_FLOW.VOUCHER,
    ...PAYMENT_FLOW.RECURRENT_PAYMENT_CARD,
    ...UPFRONT_PAYMENT_FLOW.UPFRONT_PAYMENT,
    ...EMPTY_FLOWS.EMPTY,
  ],
  PORTABILITY_REQUIRED: [
    ...PERSONAL_INFO_FLOW.PERSONAL_INFO,
    ...PORTABILITY_FLOW_REQUIRED.PORTABILITY_NUMBER,
    ...ESIM_SIM_RECOGNITION.RECOGNITION,
    ...DELIVERY_FLOW.DELIVERY,
    ...TERMS_FLOW.TERMS,
    ...VOUCHER_FLOW.VOUCHER,
    ...PAYMENT_FLOW.RECURRENT_PAYMENT_CARD,
    ...UPFRONT_PAYMENT_FLOW.UPFRONT_PAYMENT,
    ...EMPTY_FLOWS.EMPTY,
  ],
};

export {
  MOBILE_LINE_FLOW,
  MOBILE_LINE_APP_FLOW,
  PERSONAL_INFO_FLOW,
  OTP_FLOW,
  PORTABILITY_FLOW,
  PAYMENT_FLOW,
};

