import { useEffect, useState } from 'react';
import { API } from '@vfit/shared/data-access';
import { ICommonData, IOptionalText } from '@vfit/shared/models';
import {
  CONSUMER_CMS_SHOPPING_CART,
  useCmsConfig,
  IPortabilityCMS,
  errorMock,
  IGenericErrorCMS,
} from '@vfit/consumer/data-access';
import { LoggerInstance } from '@vfit/shared/data-access';
import { CheckboxCards } from '@vfit/consumer/components';
import { Skeleton } from '@vfit/shared/components';
import { organizePortabilityOperatorsCard, skeletonShape } from './portabilityOperatorsCard.utils';
import { useCheckout } from '../../../iBuyFixed.context';
import { ID_FLOWS } from '../../checkout.constants';
import TaggingPortabilityOperatorsCard from './portabilityOperatorsCard.tagging';

const PortabilityOperatorsCard = ({ handleOnChangeEnableGoNext }: ICommonData) => {
  const {
    checkoutErrors,
    isModalButtonSticky,
    portability,
    portabilityStatus,
    setSlidesGoBack,
    setPortability,
    setPortabilityStatus,
    setCurrentStepKey,
  } = useCheckout();
  const [selectedItem, setSelectedItem] = useState<IOptionalText>();
  const genericError = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_GENERIC_ERROR
  ) as IGenericErrorCMS;

  const portabilityOperatorsCardCMS = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_PORTABILITY
  ) as IPortabilityCMS;
  const { title, description, items } = organizePortabilityOperatorsCard(
    portabilityOperatorsCardCMS
  );

  const handleSelect = (item: IOptionalText | undefined) => {
    setPortability({
      ...portability,
      portabilityOperators: item?.value,
    });
    if (item && handleOnChangeEnableGoNext) handleOnChangeEnableGoNext(true);
  };

  useEffect(() => {
    if (portabilityStatus?.isErrorCheck) {
      // TODO add error in portability hook
      LoggerInstance.log('Error: portability_error');
      checkoutErrors?.showAndTrackError?.(
        genericError,
        () => {
          setPortabilityStatus({
            ...portabilityStatus,
            isLoadingCheck: false,
            isSuccessCheck: false,
            isErrorCheck: false,
          });
          setPortability({
            ...portability,
          });
          setSlidesGoBack(3);
        },
        errorMock(
          'portability',
          { url: 'portabilityOperators', status: '500', statusText: '500' },
          portabilityStatus?.errorMessage
        ),
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        isModalButtonSticky,
        true
      );
    }
  }, [portability]);

  useEffect(() => {
    setCurrentStepKey(ID_FLOWS.PORTABILITY_INFORMATIONS);
    setSelectedItem(undefined);
    setPortabilityStatus({
      ...portabilityStatus,
      isStartPortIn: false,
    });
    setPortability({
      ...portability,
      portabilityOperators: '',
    });
  }, []);

  if (portabilityStatus?.isLoadingCheck) {
    return <Skeleton {...skeletonShape} />;
  }

  return (
    <>
      <TaggingPortabilityOperatorsCard />
      <CheckboxCards
        title={title}
        description={description}
        items={items}
        value={portability.portabilityOperators}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        onSelectItem={handleSelect}
      />
    </>
  );
};

export default PortabilityOperatorsCard;
