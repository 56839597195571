import {
  API,
  CONSUMER_CMS_SHOPPING_CART,
  IGenericErrorCMS,
  useCmsConfig,
  useGetFindCaller,
} from '@vfit/consumer/data-access';
import { useEffect } from 'react';
import { getFromLocalStorageByKey, resetData } from '@vfit/shared/data-access';
import { ICommonData, IFindCallerObject } from '@vfit/shared/models';
import { Skeleton } from '@vfit/shared/components';
import { useQueryClient } from 'react-query';
import ConfirmIdentityApp from './components/ConfirmIdentityApp/confirmIdentityApp';
import { SKELETON_SHAPE } from './confirmIdentityAppCard.utils';
import { useCheckout } from '../../../iBuyMobile.context';

const ConfirmIdentityAppCard = (props: ICommonData) => {
  const queryClient = useQueryClient();
  const { handleOnClose } = props;
  const {
    owningData,
    findCaller,
    isLoadingCart: isLoadingCartReq,
    cartAsyncInfo,
    checkoutErrors,
    isModalButtonSticky,
    product,
    setOwningData,
    setFindCaller,
  } = useCheckout();
  const IS_DISABLED_CREATE_CART = product?.isFamilyPlusProduct || product?.forceCampaign;
  const isLoadingCart = IS_DISABLED_CREATE_CART ? false : isLoadingCartReq;
  const appSession = getFromLocalStorageByKey('appSession');
  const {
    isError: isErrorGetFindCaller,
    isSuccess: isSuccessGetFindCaller,
    data: findCallerData,
    refetch: refetchFindCaller,
  } = useGetFindCaller(
    {
      fiscalCode: appSession?.fiscalcode || '',
    },
    {
      enabled: false,
    }
  );
  const genericError = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_GENERIC_ERROR_MOBILE
  ) as IGenericErrorCMS;

  const onErrorFindCaller = () => {
    handleOnClose?.();
  };

  const showError = () => {
    checkoutErrors?.showAndTrackError?.(
      genericError,
      handleOnClose,
      undefined,
      undefined,
      undefined,
      handleOnClose,
      genericError?.genericerror?.buttonLabel || 'Chiudi',
      undefined,
      undefined,
      isModalButtonSticky,
      undefined,
      true
    );
  };

  useEffect(() => {
    if (isErrorGetFindCaller) {
      onErrorFindCaller();
    }
  }, [isErrorGetFindCaller]);

  useEffect(() => {
    const backButton = document.getElementById('back-button');
    if (isSuccessGetFindCaller && findCallerData) {
      if (!findCallerData?.individualRef) {
        onErrorFindCaller();
      } else {
        setOwningData({
          ...owningData,
          owningIndividual: {
            ...owningData.owningIndividual,
            firstName: appSession?.firstname || '',
            lastName: appSession?.lastname || '',
            fiscalCode: appSession?.fiscalcode || '',
          },
          isValid: true,
        });
        setFindCaller({
          ...(findCallerData as unknown as IFindCallerObject),
          showOtp: true,
          verifyOtp: '',
          isVerified: false,
          selectedContactMedium: undefined,
        });
      }
    }
    const reset = () => {
      resetData(queryClient, ['findCaller']);
    };
    backButton?.addEventListener('click', reset);
    return () => {
      backButton?.removeEventListener('click', reset);
    };
  }, [isSuccessGetFindCaller]);

  useEffect(() => {
    if (!isLoadingCart) {
      if (appSession?.fiscalcode) {
        if (cartAsyncInfo?.simTypeSelection?.isError) {
          showError();
        } else if (IS_DISABLED_CREATE_CART || cartAsyncInfo?.simTypeSelection?.isSuccess) {
          refetchFindCaller();
        }
      } else {
        showError();
      }
    }
  }, [isLoadingCart, cartAsyncInfo?.simTypeSelection]);

  if (!isSuccessGetFindCaller || !findCaller?.showOtp || isLoadingCart) {
    return <Skeleton {...SKELETON_SHAPE} />;
  }

  return <ConfirmIdentityApp {...props} />;
};

export default ConfirmIdentityAppCard;
