import { LinkWithIcon, PaymentCard } from '@vfit/shared/atoms';
import { SELECTED_PAYMENT } from '@vfit/consumer/data-access';
import { useEffect, useState } from 'react';
import { purify } from '@vfit/shared/themes';
import { EMethodCode, IPayMean } from '@vfit/shared/models';
import { IPaymentMethods, IPaymentMethodsProps } from './paymentMethod.models';
import { LinkWrapper, Subtitle, TextLoader, Title, Wrapper } from './paymentMethod.style';
import { AmericanExpressLight, MastercardLight, VisaLight, Paypal } from '@vfit/shared-icons';

const PaymentMethod = ({
  owningIndividual,
  handleChangePayment,
  config,
  payMean: paymentData,
  showLoader,
}: IPaymentMethodsProps) => {
  const {
    title,
    subtitle,
    buttonLabel,
    yourIbanTitle,
    yourCreditCardTitle,
    yourDebitCardTitle,
    yourPrepaidCardTitle,
    yourPaypalTitle,
    expirationLabelTitle,
  } = config;
  const [pSubtitle] = purify([subtitle || '']);
  const [paymentInfo, setPaymentInfo] = useState<IPaymentMethods | null>(null);

  const getDynamicTitle = (actualPaymentData: IPayMean) => {
    const actualMethodType = actualPaymentData?.type?.toLowerCase();
    switch (actualMethodType) {
      case EMethodCode.BANK_ACCOUNT?.toLowerCase():
        return yourIbanTitle;
      case EMethodCode.CREDIT_CARD?.toLowerCase():
        return yourCreditCardTitle;
      case EMethodCode.DEBIT_CARD?.toLowerCase():
        return yourDebitCardTitle;
      case EMethodCode.PREPAID_CARD?.toLowerCase():
        return yourPrepaidCardTitle;
      case EMethodCode.PAYPAL?.toLowerCase():
        return yourPaypalTitle;
      case EMethodCode.BANK_ACCOUNT?.toLowerCase():
      case EMethodCode.WALLET?.toLowerCase():
      default:
        return '';
    }
  };

  useEffect(() => {
    if (paymentData) {
      const isCard =
        paymentData?.type?.toLowerCase() === EMethodCode?.CREDIT_CARD.toLowerCase() ||
        paymentData?.type?.toLowerCase() === EMethodCode?.DEBIT_CARD.toLowerCase() ||
        paymentData?.type?.toLowerCase() === EMethodCode?.PREPAID_CARD.toLowerCase();

      localStorage.setItem(SELECTED_PAYMENT, paymentData?.type);

      const expirationMonth =
        paymentData?.expiryMonth?.length === 1
          ? `0${paymentData?.expiryMonth}`
          : paymentData.expiryMonth;

      let cardNumber = '';
      if (isCard) {
        cardNumber = `****${paymentData?.lastFourDigits || ''}`;
      } else if (paymentData?.ibanNumber) {
        cardNumber = `****${paymentData?.ibanNumber || ''}`;
      }

      setPaymentInfo({
        cardNumber,
        circuit: isCard ? paymentData?.displayBrand || '' : '',
        cardOwner: `${owningIndividual?.firstName || ''} ${owningIndividual?.lastName || ''}`,
        title: getDynamicTitle(paymentData) || '',
        expirationLabel: isCard
          ? expirationLabelTitle?.replace(
              '$expirationDate',
              `${expirationMonth}/${paymentData?.expiryYear}`
            ) || `Valida fino al ${expirationMonth}/${paymentData?.expiryYear}`
          : '',
      });
    }
  }, [paymentData]);

  const getIconCard = (): JSX.Element => {
    if (!paymentData) return <div />;
    const { brand } = paymentData || {};
    if (!brand) return <div />;
    switch (brand.trim().toLowerCase()) {
      case 'mastercard':
        return <MastercardLight />;
      case 'visa':
        return <VisaLight />;
      case 'americanexpress': {
        return <AmericanExpressLight />;
      }
      case 'paypal': {
        return <Paypal />;
      }
      default:
        return <div />;
    }
  };

  return (
    <Wrapper>
      <>
        <Title>{title}</Title>
        <Subtitle>
          <div
            dangerouslySetInnerHTML={{
              __html: pSubtitle || '',
            }}
          />
        </Subtitle>
        {showLoader && <TextLoader />}
        {!showLoader && (paymentInfo?.cardOwner as string) && (
          <>
            <PaymentCard {...paymentInfo} icon={getIconCard()} />
            {buttonLabel && handleChangePayment && (
              <LinkWrapper>
                <LinkWithIcon onClick={handleChangePayment} text={buttonLabel} />
              </LinkWrapper>
            )}
          </>
        )}
      </>
    </Wrapper>
  );
};

export default PaymentMethod;
