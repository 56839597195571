import { GetCustomerResponse, IDeliveryItem, IProduct } from '@vfit/consumer/data-access';
import {
  ICommonData,
  ICountryObject,
  IIdentificationDetails,
  IOriginalPrice,
  IPartyPrivacyItem,
  IStepCard,
} from '@vfit/shared/models';
import { ITrackError } from '@vfit/shared/data-access';
import { IUseDeliveryLocateAndPickupPointStatus } from './hooks/UseDeliveryLocateAndPickupPoint/useDeliveryLocateAndPickupPoint.models';

export interface IFlow {
  DEFAULT: IStepCard[];
  LOCKIN: IStepCard[];
  LOCKIN_MM: IStepCard[];
  LOCKIN_PROSPECT: IStepCard[];
  DEFAULT_WITH_RECURRING_PAYMENT: IStepCard[];
  LOCKIN_WITH_RECURRING_PAYMENT: IStepCard[];
  LOCKIN_MM_WITH_RECURRING_PAYMENT: IStepCard[];
  LOCKIN_PROSPECT_WITH_RECURRING_PAYMENT: IStepCard[];
  APP: IStepCard[];
  APP_WITH_RECURRING_PAYMENT: IStepCard[];
}

export interface IPersonalInfoFlow {
  PERSONAL_INFO: IStepCard[];
}

export interface IPersonalInfoLockInMMFlow {
  PERSONAL_INFO_LOCKIN_MM: IStepCard[];
}

export interface IPersonalInfoLockInFlow {
  PERSONAL_INFO_LOCKIN: IStepCard[];
}

export interface IPersonalInfoLockInProspectFlow {
  PERSONAL_INFO_LOCKIN_PROSPECT: IStepCard[];
}

export interface IOtpFLow {
  OTP: IStepCard[];
}

export interface IPortabilityFlow {
  PORTABILITY_LINE: IStepCard[];
  PORTABILITY_LINE_APP: IStepCard[];
  PORTABILITY: IStepCard[];
  PORTABILITY_INTERNET: IStepCard[];
  PORTABILITY_INFO: IStepCard[];
  PORTABILITY_OPERATORS: IStepCard[];
}

export interface ITermsFlow {
  TERMS: IStepCard[];
}

export interface IDeliveryFlow {
  DELIVERY: IStepCard[];
}

export interface IAddonFlow {
  ADDONS: IStepCard[];
}

export interface IVoucherFlow {
  VOUCHER: IStepCard[];
}

export interface IPaymentFlow {
  RECURRENT_PAYMENT_CARD: IStepCard[];
}

export interface IPaymentFlowWithoutRecurring {
  PAYMENT: IStepCard[];
}

export interface IUpfrontPaymentFlow {
  UPFRONT_PAYMENT: IStepCard[];
}

export interface IPaymentRecognizedFlow {
  PAYMENT_METHOD: IStepCard[];
}

export interface IUpfrontSelectionFlow {
  UPFRONT_SELECTION: IStepCard[];
}

export interface IContactInfo {
  checkContactProblem: boolean;
}

export interface IButtonFooter {
  buttonGoNext: string;
  buttonAcceptAndGoNext: string;
}

export interface IInstallmentPrice {
  type: string;
  originalprice: IOriginalPrice;
}

export interface IInstallmentOption {
  id?: string;
  value?: string;
  installmentPrice?: IInstallmentPrice[];
}

export interface IGetInstallmentOptions {
  options: IInstallmentOption[];
  selectedInstallment: string;
}

export interface ICheckoutProps {
  backgroundImage: string;
  isOpen: boolean;
  product?: IProduct;
  productDetailAddress: string;
  handleClose: () => void;
  isErrorOnPayment?: boolean;
  isErrorOnPaymentShowErrorModal?: boolean;
  isOnResetUser?: boolean;
  isLoadingCart?: boolean;
  buttonFooter: IButtonFooter;
}

export interface ICheckoutModalProps {
  backgroundImage: string;
  isOpen: boolean;
  handleClose: () => void;
  isLoadingCart?: boolean;
  hideCards?: boolean;
  isErrorOnPayment?: boolean;
  isErrorOnPaymentShowErrorModal?: boolean;
  isOnResetUser?: boolean;
}

export type ICheckoutModalChildrenProps = Omit<ICheckoutModalProps, 'isOpen'>;

export type ICheckoutData = ICommonData;

export interface IDeliveryBase {
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
  deliveryOptions: any;
  identificationDetails: IIdentificationDetails | IIdentificationDetails[];
  deliveryItems?: IDeliveryItem[];
}

export interface IDeliveryAdvance {
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  error?: ITrackError;
  deliveryOptions: any;
  identificationDetails: IIdentificationDetails | IIdentificationDetails[];
}

export interface IContactMedium {
  type: string;
  preferred: boolean;
  emailAddress?: string;
  phoneNumber?: string;
}

export interface IIdentification {
  identificationType: string;
  nationality: string;
  identificationNumber: string;
  expirationDate: string;
}

export interface IOwningIndividual {
  nation: string;
  fiscalCode: string;
  firstName: string;
  lastName: string;
  contactMedium?: IContactMedium[];
  identification: IIdentification[];
}

export type IPersonalDataObject = {
  owningIndividual: IOwningIndividual;
  isValid?: boolean;
};

export interface IConsentsObject {
  partyPrivacyItems: IPartyPrivacyItem[];
}

export interface ITermsAndConditionsCheck {
  consensus: boolean;
  preConsensus?: boolean;
  optionToReconsider: string;
  isSuccessTermsAndConditions: boolean;
  isLoadingTermsAndConditions: boolean;
  isErrorTermsAndConditions: boolean;
}

export interface IPickUpLocationStore {
  startDeliveryLocateAndPickupPoint: () => void;
  global: IUseDeliveryLocateAndPickupPointStatus;
  searchPickupLocations: IUseDeliveryLocateAndPickupPointStatus;
  searchPostalOffice: IUseDeliveryLocateAndPickupPointStatus;
  searchLocateStore: IUseDeliveryLocateAndPickupPointStatus;
}

export interface IUpfrontSelection {
  option: string;
}

export interface IInitContext {
  customerData?: GetCustomerResponse;
  countries?: ICountryObject[];
  isLogged?: boolean;
  isValid?: boolean;
}

export interface ICheckoutSIA {
  isShow: boolean;
}

export interface IUpdateProductPriceControl {
  isUpdatePrice: boolean;
  isPriceUpdated: boolean;
}

export interface IPortabilityOtp {
  numberValidate: string;
  isVerified: boolean;
}

export interface IMigrationObject {
  firstOperator?: string;
  firstCode?: string;
  firstCodeValid?: boolean;
  secondOperator?: string;
  secondCode?: string;
  secondCodeValid?: boolean;
  noCode?: boolean;
  currentProvider?: string;
  currentPlan?: string;
  transferBalance?: boolean;
}

export interface IPortabilityObject {
  portabilityNumber: string;
  portabilityWant?: boolean;
  portabilityOtp?: IPortabilityOtp;
  portabilityLine?: string;
  portabilityInfo?: string;
  portabilityOperators?: string;
  portabilityMigration?: IMigrationObject;
}

export interface IPortabilityStatus {
  isStartPortIn: boolean;
  isStartFinalPortIn: boolean;
  isSuccessCheck: boolean;
  isLoadingCheck: boolean;
  isErrorCheck: boolean;
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
}

export interface IAddonState {
  addonCmsId: string;
  addonMecId: string;
  callGetAddons: boolean;
  callSaveAddon: boolean;
  callRemoveAddon: boolean;
  availableAddons: string[];
  isLoading: boolean;
  isLoadingUpdate: boolean;
  isLoadingPriceUpdate: boolean;
  isSuccess: boolean;
  isError: boolean;
  errorMessage: string;
}

export const PHONE_CUSTOMER = 'phone_cus';
export const EMAIL_CUSTOMER = 'email_cus';

export interface IEditorialErrorConfig {
  title: string;
  message: string;
  actionText: string;
  url: string;
  isPopup?: boolean;
}

