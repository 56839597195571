import { useEffect, useState } from 'react';
import {
  CONSUMER_CMS_SHOPPING_CART,
  IGenericErrorCMS,
  IPayments,
  SELECTED_UPFRONT_PAYMENT,
  useCmsConfig,
} from '@vfit/consumer/data-access';
import { API, openPopup, trackView } from '@vfit/shared/data-access';
import { EMethodCode, ICommonData, IOptionalText, ISIAFlowType } from '@vfit/shared/models';
import { CheckboxCards } from '@vfit/consumer/components';
import {
  organizePaymentMethodError,
  organizeUpfrontSelectionCard,
} from '../upfrontPaymentCard.utils';
import { useCheckout } from '../../../../iBuyFixed.context';
import { useAuthorizeAuthorizationFlow } from '../../../hooks/UseAuthorizeAuthorizationFlow/useAuthorizeAuthorizationFlow';
import TaggingChooseUpfrontPayment from './chooseUpfrontPayment.tagging';

const UpfrontPayment = ({ handleOnChangeEnableGoNext, handleGoPrevSlide }: ICommonData) => {
  const goNextButton = document.getElementById('sticky-offer-next');
  const dataFromCms = useCmsConfig(CONSUMER_CMS_SHOPPING_CART, API.CMS_GET_PAYMENTS) as IPayments;
  const { titlePaymentError, messagePaymentError } = organizePaymentMethodError(dataFromCms);
  const { titleUpFront, descriptionCreditRemaning, paymentMethod, bottomModalConfig } =
    organizeUpfrontSelectionCard(dataFromCms);
  const { checkoutErrors, isModalButtonSticky, product } = useCheckout();
  const genericError = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_GENERIC_ERROR
  ) as IGenericErrorCMS;
  const [selectedItem, setSelectedItem] = useState<IOptionalText | undefined>();

  useEffect(() => {
    const trackGoNext = () => {
      let event_label: string;

      if (selectedItem?.value === 'creditCard') {
        event_label = 'Credit Card';
      } else if (selectedItem?.value === 'creditCardAndPaypal') {
        event_label = 'Paypal';
      } else {
        event_label = '';
      }
      trackView({
        event_name: 'ui_interaction',
        event_label_track: event_label,
        event_category: 'users',
        event_action: 'click',
        tracking_type: 'link',
      });
    };

    if (selectedItem?.value)
      localStorage.setItem(
        SELECTED_UPFRONT_PAYMENT,
        selectedItem?.value?.toLowerCase() === 'creditcardandpaypal'
          ? EMethodCode.PAYPAL
          : selectedItem?.value
      );
    goNextButton?.addEventListener('click', trackGoNext);

    return () => {
      goNextButton?.removeEventListener('click', trackGoNext);
    };
  }, [selectedItem]);

  const { authorize } = useAuthorizeAuthorizationFlow(
    selectedItem?.value,
    ISIAFlowType.INSTANT,
    undefined
  );

  const isSuccesAuthorize = authorize?.isSuccess;
  const isErrorAuthorize = authorize?.isError;

  const showError = () => {
    const action = () => {
      if (handleGoPrevSlide) handleGoPrevSlide();
    };
    checkoutErrors?.showAndTrackError?.(
      genericError,
      action,
      authorize?.error,
      titlePaymentError,
      messagePaymentError,
      action,
      'Chiudi',
      () => openPopup(product?.genericErrorCallMeNow?.url || '', action),
      product?.genericErrorCallMeNow?.label || '',
      isModalButtonSticky,
      true
    );
  };

  useEffect(() => {
    if (isErrorAuthorize) {
      showError();
    }
  }, [isErrorAuthorize]);

  useEffect(() => {
    handleOnChangeEnableGoNext?.(isSuccesAuthorize);
  }, [isSuccesAuthorize]);

  return (
    <>
      <TaggingChooseUpfrontPayment />
      <CheckboxCards
        title={titleUpFront}
        description={descriptionCreditRemaning}
        items={paymentMethod}
        value={false}
        setSelectedItem={setSelectedItem}
        disableItems={selectedItem && !isSuccesAuthorize}
        isSuccess
        bottomModal={bottomModalConfig}
      />
    </>
  );
};

export default UpfrontPayment;
