import { setTrackView, useTracking } from '@vfit/shared/data-access';
import { useQueryClient } from 'react-query';
import { setTrackLink } from '@vfit/shared/data-access';
import { getUserInfo } from '../../checkout.utils';
import { withAnimationDelay } from '../../hoc/withAnimationDelay';
import { SoftManagerService } from '@vfit/consumer/providers';
import { retrieveProductTechnology } from '@vfit/consumer/data-access';
import { useCheckout } from '../../../iBuyMobile.context';
import { removeCurrency } from '@vfit/consumer/hooks';

const TaggingComponent = ({ addonIdList }: { addonIdList: string[] }) => {
  const { product } = useCheckout();
  const queryClient = useQueryClient();
  const { getCartProductsWithAddon, retrieveCartAddons } = SoftManagerService(queryClient);
  const { trackView } = useTracking({
    event: ['view'],
    event_label: 'addons:view',
    opts: {
      product_target_segment: 'consumer',
      page_section: 'mobile offers',
      page_subsection: 'checkout',
      page_type: 'addons',
      event_category: 'sales',
      journey_type: 'journey',
      journey_name: 'checkout',
      product_technology: retrieveProductTechnology() || '',
    },
    cartProduct: {
      cart_product_quantity: '1',
      cart_product_id: `${product?.offerId}`,
      cart_product_name: product?.slug,
      cart_product_category: 'mobile',
      cart_product_price: product?.price && removeCurrency(product.price),
      ...getCartProductsWithAddon(product, retrieveCartAddons(addonIdList)),
    },
    visitorTrackingOpts: getUserInfo(),
  });
  setTrackLink(trackView, 'addons:');
  setTrackView(trackView);

  return null;
};

const TaggingAddonCard = withAnimationDelay()(TaggingComponent);

export default TaggingAddonCard;
