import { useForm } from 'react-hook-form';
import { API, PAGES, trackLink } from '@vfit/shared/data-access';
import { useEffect } from 'react';
import {
  CONSUMER_CMS_SHOPPING_CART,
  IVoucherCMS,
  organizeVoucherInsertionCard,
  useCmsConfig,
} from '@vfit/consumer/data-access';
import { IVoucherForm } from './voucherInsertionCard.models';
import { voucherCodeFieldName } from './voucherInsertion.shema';
import { useCheckout } from '../../../../../iBuyFixed.context';
import { useVoucherInsertionCardTagging } from './voucherInsertionCard.tagging';

export const useVoucherInsertionCard = () => {
  const {
    setVoucher,
    setConfiguredStickyStepperLabel,
    voucher,
    product: { vouchers },
  } = useCheckout();
  const dataFromCms = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_FIXED_VOUCHERS
  ) as IVoucherCMS;
  const voucherCms = organizeVoucherInsertionCard(dataFromCms);
  useVoucherInsertionCardTagging();

  const {
    register,
    formState: { isValid, errors },
    watch,
    setError,
  } = useForm<IVoucherForm>({
    mode: 'all',
    defaultValues: {
      [voucherCodeFieldName]:
        localStorage.getItem('voucherId') ||
        (vouchers?.enableAutoApply ? vouchers?.voucherToApply : ''),
    },
  });

  // This process needs to be separated from the general form validation
  // because we want to disable the btn when there's no pattern match,
  // while avoiding triggering errors if a voucher is not in the field.
  const voucherField = watch(voucherCodeFieldName);

  const handleClick = () => {
    setVoucher({ ...voucher, voucherId: voucherField });
  };

  const trackSkipButton = () => {
    trackLink(voucherCms?.voucherstwilight?.insertion?.skipLabel);
  };

  useEffect(() => {
    voucher.resetData?.();
    setConfiguredStickyStepperLabel(voucherCms?.voucherstwilight?.insertion?.skipLabel);
    const skipButton = document.getElementById('sticky-offer-next');
    skipButton?.addEventListener('click', trackSkipButton);
    localStorage.removeItem('voucherId');
    return () => {
      setConfiguredStickyStepperLabel('');
      skipButton?.removeEventListener('click', trackSkipButton);
    };
  }, []);

  useEffect(() => {
    if (voucher?.validateVoucher?.isSuccess) {
      if (voucher?.isExpiredVoucher) {
        setError(voucherCodeFieldName, {
          type: 'manual',
          message: voucherCms.voucherstwilight.insertion.expiredMessage,
        });
      } else if (voucher?.isInvalidVoucher) {
        setError(voucherCodeFieldName, {
          type: 'manual',
          message: voucherCms.voucherstwilight.insertion.invalidMessage,
        });
      }
    }
  }, [voucher?.validateVoucher?.isSuccess]);

  useEffect(() => {
    if (voucher.validateVoucher.isError) {
      setError(voucherCodeFieldName, {
        type: 'manual',
        message: voucherCms.voucherstwilight.insertion.invalidMessage,
      });
    }
  }, [voucher.validateVoucher.isError]);

  return {
    voucherCms,
    errors,
    handleClick,
    isLoading: voucher.validateVoucher.isLoading,
    isValid,
    register,
    watch,
  };
};
