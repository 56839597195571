import { IVoucherCMS } from '@vfit/consumer/data-access';
import { purify } from '@vfit/shared/themes';

export const organizeVoucherInsertionCard = (
  cmsData?: IVoucherCMS,
  voucher?: string
): IVoucherCMS => ({
  voucherstwilight: {
    insertion: {
      title: cmsData?.voucherstwilight?.insertion?.title || '',
      message: cmsData?.voucherstwilight?.insertion?.message || '',
      inputName: cmsData?.voucherstwilight?.insertion?.inputName || '',
      buttonLabel: cmsData?.voucherstwilight?.insertion?.buttonLabel || '',
      expiredMessage: cmsData?.voucherstwilight?.insertion?.expiredMessage || '',
      invalidMessage: cmsData?.voucherstwilight?.insertion?.invalidMessage || '',
      skipLabel: cmsData?.voucherstwilight?.insertion?.skipLabel || 'salta',
    },
    success: {
      title: cmsData?.voucherstwilight?.success?.title || '',
      subtitle: cmsData?.voucherstwilight?.success?.subtitle?.replace('$VOUCHER', voucher || ''),
      loadingMessage: purify([cmsData?.voucherstwilight?.success?.loadingMessage || ''])?.[0] || '',
      backTitle: purify([cmsData?.voucherstwilight?.success?.backTitle || ''])?.[0] || '',
    },
    validationMinLength: {
      message: cmsData?.voucherstwilight?.validationMinLength?.message
    }
  },
});
