import { useEffect, useState } from 'react';

const withAnimationDelay = () => (Component) => (props) => {
  const [isLoadingAnimation, setIsLoadingAnimation] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoadingAnimation(false);
    }, 800);
  }, []);

  if (isLoadingAnimation) return null;

  return <Component {...props} />;
};

export { withAnimationDelay };
