import { Children, useRef, useState } from 'react';
import { CustomText, LinkWithIcon, Button } from '@vfit/shared/atoms';
import { VfModal } from '@vfit/shared/components';
import { fonts, purify } from '@vfit/shared/themes';
import { IOfferStepProps } from './offerStep.models';
import {
  Title,
  OfferDescription,
  OfferPrice,
  BottomContainer,
  ButtonDiv,
  ProductCardSpecTitle,
  ProductCardSpecContainer,
  ProductCardSpecItem,
  ModalTitle,
  ModalContent,
  SpecsDiv,
} from './offerStep.style';
import { ButtonContainer, Wrapper } from '../../fallbackModal.style';
import { Check } from '@vfit/shared-icons';

const OfferStep = ({
  data,
  onSeeDetailClick,
  onFirstButtonClick,
  onSecondButtonClick,
  seconButtonEnabled,
}: IOfferStepProps) => {
  const {
    title,
    offerDescription,
    productTitle,
    price,
    recurrence,
    priceDescription,
    productSpecs,
    detailsLabel,
    firstButtonLabel,
    fistButtonVariant,
    secondButtonLabel,
    secondButtonVariant,
  } = data;

  const [purifyDescription] = purify([priceDescription || '']);
  const specRef = useRef<HTMLDivElement | null>(null);
  const [modal, setModal] = useState({
    show: false,
    text: '',
    title: '',
  });

  const renderSpecs = () => {
    const specs = productSpecs;
    return (
      <div
        ref={specRef}
        className="product-specs"
        style={{
          marginBottom: 32,
        }}
      >
        {Children.toArray(
          specs &&
            specs.length > 0 &&
            specs.map((spec) => {
              const { title: specTitle, items } = spec;
              return (
                <div>
                  {specTitle && <ProductCardSpecTitle>{specTitle}</ProductCardSpecTitle>}
                  {items &&
                    items.length > 0 &&
                    Children.toArray(
                      items
                        .filter((item) => !!item.title)
                        .map((item) => (
                          <ProductCardSpecContainer>
                            <ProductCardSpecItem>
                              <div>
                                <Check />
                              </div>
                              <CustomText text={item.title} textAlign="left" />
                            </ProductCardSpecItem>
                          </ProductCardSpecContainer>
                        ))
                    )}
                </div>
              );
            })
        )}
      </div>
    );
  };

  return (
    <>
      <Wrapper>
        <Title>{title}</Title>
        <CustomText text={offerDescription} modal size={20} lineHeight={28} textAlign="left" />
        <Title>{productTitle}</Title>
        <OfferPrice>
          {price}
          <span className="rec">{recurrence && `/${recurrence}`}</span>
        </OfferPrice>
        <OfferDescription>
          {purifyDescription && <div dangerouslySetInnerHTML={{ __html: purifyDescription }} />}
        </OfferDescription>
        <SpecsDiv>{renderSpecs()}</SpecsDiv>
        <BottomContainer>
          <LinkWithIcon text={detailsLabel} onClick={onSeeDetailClick} size="2" />
        </BottomContainer>
        <ButtonContainer>
          <ButtonDiv>
            <Button onClick={onFirstButtonClick} variant={fistButtonVariant || 'primary'}>
              {firstButtonLabel}
            </Button>
            {onSecondButtonClick && secondButtonLabel && seconButtonEnabled && (
              <Button onClick={onSecondButtonClick} variant={secondButtonVariant || 'secondary'}>
                {secondButtonLabel}
              </Button>
            )}
          </ButtonDiv>
        </ButtonContainer>
      </Wrapper>
      <VfModal
        height={563}
        isOpen={modal.show}
        handleClose={() => setModal({ show: false, text: '', title: '' })}
      >
        <>
          {modal.title && (
            <ModalTitle>
              <CustomText fontFamily={fonts.exbold} textAlign="left" text={modal.title} />
            </ModalTitle>
          )}
          <ModalContent>
            <CustomText size={18} lineHeight={24} textAlign="left" text={modal.text} />
          </ModalContent>
        </>
      </VfModal>
    </>
  );
};

export default OfferStep;

