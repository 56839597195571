import { useEffect } from 'react';
import {
  CONSUMER_CMS_SHOPPING_CART,
  getLastPaymentMethod,
  IGenericErrorCMS,
  IPayments,
  productSpecificError,
  useCmsConfig,
} from '@vfit/consumer/data-access';
import { API, openPopup, setTrackLink } from '@vfit/shared/data-access';
import { EMethodCode, ICommonData, IPayMeanServiceResponse } from '@vfit/shared/models';
import { useTracking } from '@vfit/shared/data-access';
import { PaymentMethod } from '@vfit/consumer/components';
import { WarningInfo } from '@vfit/shared/components';
import {
  organizePaymentMethod,
  organizePaymentMethodError,
} from './paymentMethodWithoutRecurringCard.utils';
import { useCheckout } from '../../../iBuyFixed.context';
import { ID_FLOWS } from '../../checkout.constants';
import { getUserInfo, getTagging } from '../../checkout.utils';
import TaggingPaymentMethodWithoutRecurringCard from './paymentMethodWithoutRecurringCard.tagging';

const PaymentMethodWithoutRecurringCard = ({
  handleOnChangeEnableGoNext,
  handleGoNextSlide,
  handleGoPrevSlide,
}: ICommonData) => {
  const {
    checkoutErrors,
    product,
    authorizeFlow,
    owningData,
    isModalButtonSticky,
    customerFlow,
    setIsLastCard,
    setCurrentStepKey,
    setHideStickyBar,
    setIsDisabledBackButton,
    setIsPreselectedCard,
    setAuthorizeFlow,
  } = useCheckout();
  const tagging = getTagging('payment method pre-filled', 'fixed');
  const { paymean } = customerFlow;
  const { authorize } = authorizeFlow;
  const selectedPaymean = getLastPaymentMethod(paymean?.data as IPayMeanServiceResponse);
  const dataFromCms = useCmsConfig(CONSUMER_CMS_SHOPPING_CART, API.CMS_GET_PAYMENTS) as IPayments;
  const { buttonPaymentError, urlPaymentErrorCallMeNow } = productSpecificError(product);
  const {
    title,
    subtitle,
    buttonLabel,
    yourIbanTitle,
    yourCreditCardTitle,
    yourDebitCardTitle,
    yourPrepaidCardTitle,
    yourPaypalTitle,
    expirationLabelTitle,
  } = organizePaymentMethod(dataFromCms);
  const { titlePaymentError, messagePaymentError } = organizePaymentMethodError(dataFromCms);
  const paymentCMS = useCmsConfig(CONSUMER_CMS_SHOPPING_CART, API.CMS_GET_PAYMENTS) as IPayments;
  const genericError = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_GENERIC_ERROR
  ) as IGenericErrorCMS;
  const cmsWarningPayment = {
    title: paymentCMS?.paymentsmobile?.title || 'Grazie!',
    subtitle: 'Stiamo elaborando il tuo ordine',
    description:
      paymentCMS?.paymentsmobile?.description ||
      "Per completare l'operazione potrebbe volerci qualche istante. Per favore attendi qui, senza lasciare la pagina.",
  };

  const { trackView } = useTracking({
    event: ['checkout_step9.0'],
    event_label: 'payment method pre-filled',
    opts: tagging?.opts,
    cartProduct: tagging?.trackingProduct,
    pageProduct: tagging?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });
  setTrackLink(trackView, 'payment method pre-filled');

  const onChangePayment = () => {
    setIsLastCard(false);
    setTimeout(() => {
      trackView({
        event_name: 'ui_interaction',
        event_label_track: 'change paym method',
        event_category: 'users',
        event_action: 'click',
        tracking_type: 'link',
      });
      handleGoNextSlide?.();
    }, 300);
  };

  const checkErrors = () => {
    const action = () => {
      if (handleGoPrevSlide) handleGoPrevSlide();
    };
    checkoutErrors?.showAndTrackError?.(
      genericError,
      action,
      authorize?.error || undefined,
      titlePaymentError,
      messagePaymentError,
      action,
      'Chiudi',
      () => openPopup(urlPaymentErrorCallMeNow || '', action),
      buttonPaymentError,
      isModalButtonSticky,
      true
    );
  };

  const handleStartPaymentFlow = (event: Event) => {
    event?.stopPropagation();
    trackView({
      event_name: 'ui_interaction',
      event_label_track: selectedPaymean?.type,
      event_category: 'users',
      event_action: 'click',
      tracking_type: 'link',
    });
    setAuthorizeFlow({
      ...authorizeFlow,
      selectedMethod: (selectedPaymean?.type || '') as EMethodCode,
      authorizationOperationMethod: 'verify',
      isStartAuthorizeFlow: true,
    });
  };

  const submitOrder = () => {
    setTimeout(() => {
      const goNextButton = document.getElementById('sticky-offer-next');
      goNextButton?.removeEventListener('click', handleStartPaymentFlow);
      if (goNextButton?.firstChild) {
        (goNextButton?.firstChild as HTMLButtonElement)?.click();
      }
    }, 800);
  };

  const checkLoading = () => {
    setIsDisabledBackButton(authorize?.isLoading);
    setHideStickyBar(authorize?.isLoading);
  };

  useEffect(() => {
    if (authorize?.isError) {
      checkErrors();
    }
    checkLoading();
    if (authorize?.isSuccess) {
      submitOrder();
    }
  }, [authorize]);

  useEffect(() => {
    setCurrentStepKey(ID_FLOWS.PAYMENT_METHOD_WITHOUT_RECURRING);
    setIsPreselectedCard(true);
    setIsLastCard(true);
    authorizeFlow?.resetReserveAuthorization?.();
    const goNextButton = document.getElementById('sticky-offer-next');
    handleOnChangeEnableGoNext?.(true);
    goNextButton?.addEventListener('click', handleStartPaymentFlow);
    return () => {
      setIsLastCard(false);
      goNextButton?.removeEventListener('click', handleStartPaymentFlow);
    };
  }, []);

  return (
    <>
      <TaggingPaymentMethodWithoutRecurringCard />
      {authorize?.isLoading && <WarningInfo {...cmsWarningPayment} />}
      {!authorize?.isLoading && (
        <PaymentMethod
          owningIndividual={owningData.owningIndividual}
          handleChangePayment={onChangePayment}
          handleGoPrevSlide={handleGoPrevSlide}
          payMean={selectedPaymean}
          config={{
            title,
            subtitle,
            buttonLabel,
            titlePaymentError,
            messagePaymentError,
            yourIbanTitle,
            yourCreditCardTitle,
            yourDebitCardTitle,
            yourPrepaidCardTitle,
            yourPaypalTitle,
            expirationLabelTitle,
          }}
        />
      )}
    </>
  );
};

export default PaymentMethodWithoutRecurringCard;
