import { useEffect } from 'react';
import {
  CONSUMER_CMS_SHOPPING_CART,
  IGenericErrorCMS,
  IPayments,
  useCmsConfig,
} from '@vfit/consumer/data-access';
import { API, openPopup, trackView } from '@vfit/shared/data-access';
import { EMethodCode, ICommonData, ISIAFlowType } from '@vfit/shared/models';
import { PaymentMethod } from '@vfit/consumer/components';
import {
  DEFAULT_CREDIT_CARD,
  organizePaymentMethod,
  organizePaymentMethodError,
  organizeUpfrontSelectionCard,
} from '../upfrontPaymentCard.utils';
import { useCheckout } from '../../../../iBuyFixed.context';
import { useAuthorizeAuthorizationFlow } from '../../../hooks/UseAuthorizeAuthorizationFlow/useAuthorizeAuthorizationFlow';
import PreselectedUpfrontPaymentTagging from './preselectedUpfrontPayment.tagging';

const UpfrontPayment = ({ handleOnChangeEnableGoNext, handleGoPrevSlide }: ICommonData) => {
  const goNextButton = document.getElementById('sticky-offer-next');
  const dataFromCms = useCmsConfig(CONSUMER_CMS_SHOPPING_CART, API.CMS_GET_PAYMENTS) as IPayments;
  const { title, buttonLabel } = organizePaymentMethod(dataFromCms);
  const { titlePaymentError, messagePaymentError } = organizePaymentMethodError(dataFromCms);
  const { descriptionCC, paymentMethod } = organizeUpfrontSelectionCard(dataFromCms);
  const { checkoutErrors, owningData, upfrontPayment, isModalButtonSticky, product } =
    useCheckout();
  const genericError = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_GENERIC_ERROR
  ) as IGenericErrorCMS;
  const selectedItem = paymentMethod?.find(
    (el) => el.value.toLowerCase() === EMethodCode.CREDIT_CARD.toLowerCase()
  );

  const { authorize } = useAuthorizeAuthorizationFlow(
    selectedItem?.value,
    ISIAFlowType.INSTANT,
    'verify'
  );

  const isSuccesAuthorize = authorize?.isSuccess;
  const isErrorAuthorize = authorize?.isError;

  const showError = () => {
    const action = () => {
      if (handleGoPrevSlide) handleGoPrevSlide();
    };
    checkoutErrors?.showAndTrackError?.(
      genericError,
      action,
      authorize?.error,
      titlePaymentError,
      messagePaymentError,
      action,
      'Chiudi',
      () => openPopup(product?.genericErrorCallMeNow?.url || '', action),
      product?.genericErrorCallMeNow?.label || '',
      isModalButtonSticky,
      true
    );
  };

  useEffect(() => {
    if (isErrorAuthorize) {
      showError();
    }
  }, [isErrorAuthorize]);

  useEffect(() => {
    handleOnChangeEnableGoNext?.(isSuccesAuthorize);
  }, [isSuccesAuthorize]);

  useEffect(() => {
    if (!selectedItem) {
      showError();
    }
    const trackGoNext = () => {
      trackView({
        event_name: 'ui_interaction',
        event_label_track: 'Credit Card',
        event_category: 'users',
        event_action: 'click',
        tracking_type: 'link',
      });
    };
    goNextButton?.addEventListener('click', trackGoNext);
    return () => {
      goNextButton?.removeEventListener('click', trackGoNext);
    };
  }, []);

  return (
    <>
      <PreselectedUpfrontPaymentTagging />
      <PaymentMethod
        owningIndividual={owningData.owningIndividual}
        handleGoPrevSlide={handleGoPrevSlide}
        payMean={upfrontPayment || DEFAULT_CREDIT_CARD}
        showLoader={!isSuccesAuthorize}
        config={{
          title,
          subtitle: descriptionCC,
          buttonLabel,
          titlePaymentError,
          messagePaymentError,
        }}
      />
    </>
  );
};

export default UpfrontPayment;
