import {IPortabilityMobileCMS} from "@vfit/consumer/data-access";

const organizePortabilityNumberCard = (
  portabilityNumberCardCMS: IPortabilityMobileCMS,
) => ({
  title: portabilityNumberCardCMS?.portabilitymobile?.telephone?.title || '',
  description:
    portabilityNumberCardCMS?.portabilitymobile?.telephone?.description || '',
  label: portabilityNumberCardCMS?.portabilitymobile?.selectInput?.telephone || '',
  disclaimer: portabilityNumberCardCMS?.portabilitymobile?.telephone?.disclaimer || '',
  validationMessage: portabilityNumberCardCMS?.portabilitymobile?.validationMessage || {}
});

const organizePortabilityError = (
  portabilityNumberCardCMS: IPortabilityMobileCMS
) => ({
  titleError: portabilityNumberCardCMS?.portabilitymobile?.errorModal?.title || '',
  messageError: portabilityNumberCardCMS?.portabilitymobile?.errorModal?.message || ''
});

export { organizePortabilityError, organizePortabilityNumberCard };
