import { useMutation, useQueryClient } from 'react-query';
import { CustomOptions, INextError, LoggerInstance, nextClient } from '@vfit/shared/data-access';
import {
  checkFwAmdIntegration,
  errorMock,
  GetCustomerResponse,
  IAuthorizeResponse,
  IBillingAccountResponse,
  IFindCallerResponse,
  IShoppingCartResponse,
} from '@vfit/consumer/data-access';
import { API, getFromLocalStorageByKey } from '@vfit/shared/data-access';
import { ISubmitWithPaymentPayload } from './submitWithPayment.models';

/**
 * API service to submit user's order
 * @param cartId
 * @param payload
 * @param customOptions
 */
export const submitWithPayloadService = async (
  cartId: string,
  payload: ISubmitWithPaymentPayload,
  customOptions?: CustomOptions
) =>
  nextClient.post(`${API.ORDER}/${cartId}/${API.SUBMIT_WITH_PAYMENT}`, payload, {
    ...(customOptions?.headers && { headers: customOptions.headers }),
    ...(customOptions?.silentLoginHeaders && {
      silentLoginHeaders: customOptions.silentLoginHeaders,
    }),
  });

export const useSubmitWithPaymentFixed = () => {
  const queryClient = useQueryClient();

  return useMutation(
    'submitMutation',
    () => {
      const shoppingCart: IShoppingCartResponse = getFromLocalStorageByKey('shoppingCart');
      const customerData: GetCustomerResponse[] = getFromLocalStorageByKey('customerData');
      const billingAccount: IBillingAccountResponse = getFromLocalStorageByKey('billingAccount');
      const deliveryData = getFromLocalStorageByKey('postDeliveryServiceDetails');
      const authorizeCC: IAuthorizeResponse = getFromLocalStorageByKey('authorize');
      const findCaller: IFindCallerResponse = getFromLocalStorageByKey('findCaller');
      const portability: string = localStorage.getItem('portability');
      const cartId = shoppingCart && shoppingCart?.id;
      const cartItems = shoppingCart && shoppingCart?.cartItem[0];
      const customerId = customerData?.[0]?.id || findCaller?.customerRef?.id;
      const siaOrderId = authorizeCC?.authorizationParameters?.siaOrderId;
      const deliveryItems = [];

      const getDeliveryCode = (dI: any): string => {
        if (dI?.length > 0) {
          const details = [];
          dI?.forEach((el) => details.push(el?.details?.[0] || {}));
          const filteredItem = details?.find((itemToFilter) => itemToFilter?.isSelected);
          return filteredItem?.code || 'S';
        }
        return 'T';
      };

      const getFixedDeliveryItems = (dItems) => {
        const items = [];
        dItems?.forEach((el) => {
          if (el?.items?.length > 0) {
            el?.items?.forEach((item) => {
              if (item) items.push(item);
            });
          }
        });
        return items;
      };

      const updateDeliveryItems = (dItems) => {
        const dItemsOrganized = getFixedDeliveryItems(dItems || []);
        dItemsOrganized.forEach((el) => deliveryItems.push(el));
      };

      if (Array.isArray(deliveryData)) {
        deliveryData?.forEach((delivery) => {
          updateDeliveryItems(delivery?.deliveryItems || []);
        });
      } else {
        updateDeliveryItems(deliveryData?.deliveryItems || []);
      }

      const haveAntType = deliveryItems?.some((el) => el.itemType?.toLowerCase() === 'ant');
      let deliveryMethod = getDeliveryCode(deliveryItems);
      if (haveAntType) deliveryMethod = 'T';

      const payload: ISubmitWithPaymentPayload = {
        customerId,
        immediatePayment: true,
        deliveryMethod,
        shipmentCountry: 'IT',
        barId: billingAccount?.id || '',
        isPortIn: portability === '1',
        productsInOrders: [
          { orderItemId: cartItems?.dataSim?.[0]?.id },
          { orderItemId: cartItems?.fixedVoice?.[0]?.id },
          { orderItemId: cartItems?.internet?.[0]?.id },
        ],
        deliveryItems,
        invoiceRequired: false,
        siaOrderId,
        isFlexiFlow: false,
        ...(customerData?.[0].financialAccount?.[0]?.id && {
          faId: customerData?.[0].financialAccount?.[0]?.id,
        }),
      };

      LoggerInstance.debug('SUBMIT PAYMENT payload', payload);
      return submitWithPayloadService(cartId, payload);
    },
    {
      onSuccess: (data) => {
        localStorage.setItem('submitOrder', JSON.stringify(data));
        queryClient.setQueryData('submitQuery', data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - submitQuery: `, error);
        queryClient.setQueryData('submitQuery', errorMock(`submitQuery`, error));
        localStorage.setItem(`submitQuery`, JSON.stringify(errorMock(`submitQuery`, error)));
      },
    }
  );
};

export const useSubmitWithPaymentMobile = () => {
  const queryClient = useQueryClient();
  return useMutation(
    'submitMutation',
    () => {
      const shoppingCart: IShoppingCartResponse = getFromLocalStorageByKey('shoppingCart');
      const customerData: GetCustomerResponse[] = getFromLocalStorageByKey('customerData');
      const billingAccount: IBillingAccountResponse = getFromLocalStorageByKey('billingAccount');
      const deliveryDetails = getFromLocalStorageByKey('postDeliveryServiceDetails');
      const authorizeCC: IAuthorizeResponse = getFromLocalStorageByKey('authorize');
      const findCaller: IFindCallerResponse = getFromLocalStorageByKey('findCaller');
      const portability: string = localStorage.getItem('portability');
      const cartId = shoppingCart && shoppingCart?.id;
      const cartItems = shoppingCart && shoppingCart?.cartItem[0];
      const customerId = customerData?.[0]?.id || findCaller?.customerRef?.id;
      const deliveryMethod = deliveryDetails?.deliveryDetails?.[0]?.code || '';
      const siaOrderId = authorizeCC?.authorizationParameters?.siaOrderId;
      const fwtoken = checkFwAmdIntegration();
      const payload: ISubmitWithPaymentPayload = {
        fwtoken,
        customerId,
        immediatePayment: true,
        deliveryMethod,
        shipmentCountry: 'IT',
        barId: billingAccount?.id || '',
        isPortIn: portability === '1',
        productsInOrders: [{ orderItemId: cartItems?.id }],
        deliveryItems: deliveryDetails?.deliveryItems || [],
        invoiceRequired: false,
        siaOrderId,
        isFlexiFlow: false,
        ...(customerData?.[0].financialAccount?.[0]?.id && {
          faId: customerData?.[0].financialAccount?.[0]?.id,
        }),
      };
      return submitWithPayloadService(cartId, payload);
    },
    {
      onSuccess: (data) => {
        localStorage.setItem('submitOrder', JSON.stringify(data));
        queryClient.setQueryData('submitQuery', data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - submitQuery: `, error);
        queryClient.setQueryData('submitQuery', errorMock(`submitQuery`, error));
        localStorage.setItem(`submitQuery`, JSON.stringify(errorMock(`submitQuery`, error)));
      },
    }
  );
};
